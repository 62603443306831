import Title from "components/Title/index";
import { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import SlidingPanel from "components/SlidingPanel/index";
import { Row, Col, Button } from "react-bootstrap";
import { useAppSelector } from "hooks/appSelector";
import { LteStorageSettingsModel } from "models/view/LteStorageSettingsModel";
import { getLteAccountingSettingsById, getLteCalendarSettingsById, getLteGeneralSettingsById, getLteStorageSettingsById } from "actions/lte";
import ViewLteStorageSettingsSummary from "./LteStorageSettings/ViewLteStorageSettingsSummary/ViewLteStorageSettingsSummary";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import EditLteStorageSettingsSummary from "./LteStorageSettings/EditLteStorageSettingsSummary/EditLteStorageSettingsSummary";
import { LteCalendarSettingsModel } from "models/view/LteCalendarSettingsModel";
import EditLteCalendarSettingsSummary from "./LteCalendarSettings/EditLteCalendarSettingsSummary/EditLteCalendarSettingsSummary";
import ViewLteCalendarSettingsSummary from "./LteCalendarSettings/ViewLteCalendarSettingsSummary/ViewLteCalendarSettingsSummary";
import { LteGeneralSettingsModel } from "models/view/LteGeneralSettingsModel";
import EditLteGeneralSettingsSummary from "./LteGeneralSettings/EditLteGeneralSettingsSummary/EditLteGeneralSettingsSummary";
import ViewLteGeneralSettingsSummary from "./LteGeneralSettings/ViewLteGeneralSettingsSummary/ViewLteGeneralSettingsSummary";
import DelegatedLTEsList from "./DelegatedLTEs/DelegatedLTEsList";
import LTEShareAddressBookList from "./LTEShareAddressBook/LTEShareAddressBookList";
import { LteAccountingSettingsModel } from "models/view/LteAccountingSettingsModel";
import EditLteAccountingSettingsSummary from "./LteAccountingSettings/EditLteAccountingSettingsSummary/EditLteAccountingSettingsSummary";
import ViewLteAccountingSettingsSummary from "./LteAccountingSettings/ViewLteAccountingSettingsSummary/ViewLteAccountingSettingsSummary";

type Props = {
  lteId: string
}

export default function ViewLteSystemSettings(props: Props) {
  const [editStorageSettings, setEditStorageSettings] = useState<boolean>(false);
  const [editCalendarSettings, setEditCalendarSettings] = useState<boolean>(false);
  const [editGeneralSettings, setEditGeneralSettings] = useState<boolean>(false);
  const [editAccountingSettings, setEditAccountingSettings] = useState<boolean>(false);

  const [isLoadingStorageSettings, setIsLoadingStorageSettings] = useState<boolean>(false);
  const [isLoadingCalendarSettings, setIsLoadingCalendarSettings] = useState<boolean>(false);
  const [isLoadingGeneralSettings, setIsLoadingGeneralSettings] = useState<boolean>(false);
  const [isLoadingAccountingSettings, setIsLoadingAccountingSettings] = useState<boolean>(false);

  const [genericErrors, setGenericErrors] = useState(null);

  const [lteStorageSettings, setLteStorageSettings] = useState<LteStorageSettingsModel | undefined>(undefined);
  const [lteCalendarSettings, setLteCalendarSettings] = useState<LteCalendarSettingsModel | undefined>(undefined);
  const [lteGeneralSettings, setLteGeneralSettings] = useState<LteGeneralSettingsModel | undefined>(undefined);
  const [lteAccountingSettings, setLteAccountingSettings] = useState<LteAccountingSettingsModel | undefined>(undefined);
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    loadStorageSettings();
    loadCalendarSettings();
    loadGeneralSettings();
    loadAccountingSettings();
  }, [props.lteId]);

  const loadStorageSettings = () => {
    setIsLoadingStorageSettings(true);
    getLteStorageSettingsById(props.lteId).then((response) => {
      setLteStorageSettings(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingStorageSettings(false);
    });
  };

  const loadCalendarSettings = () => {
    setIsLoadingCalendarSettings(true);
    getLteCalendarSettingsById(props.lteId).then((response) => {
      setLteCalendarSettings(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingCalendarSettings(false);
    });
  };

  const loadGeneralSettings = () => {
    setIsLoadingGeneralSettings(true);
    getLteGeneralSettingsById(props.lteId).then((response) => {
      setLteGeneralSettings(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingGeneralSettings(false);
    });
  };

  const loadAccountingSettings = () => {
    setIsLoadingAccountingSettings(true);
    getLteAccountingSettingsById(props.lteId).then((response) => {
      setLteAccountingSettings(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingAccountingSettings(false);
    });
  };

  const submitEditStorageSettingsCallback = (data: LteStorageSettingsModel) => {
    setLteStorageSettings(data);
    setEditStorageSettings(false);
  };

  const submitEditCalendarSettingsCallback = (data: LteCalendarSettingsModel) => {
    setLteCalendarSettings(data);
    setEditCalendarSettings(false);
  };

  const submitEditGeneralSettingsCallback = (data: LteGeneralSettingsModel) => {
    setLteGeneralSettings(data);
    setEditGeneralSettings(false);
  };

  const submitEditAccountingSettingsCallback = (data: LteAccountingSettingsModel) => {
    setLteAccountingSettings(data);
    setEditAccountingSettings(false);
  };

  const cancelEditStorageSettingsCallback = () => {
    setEditStorageSettings(false);
  };

  const cancelEditCalendarSettingsCallback = () => {
    setEditCalendarSettings(false);
  };

  const cancelEditGeneralSettingsCallback = () => {
    setEditGeneralSettings(false);
  };

  const cancelEditAccountingSettingsCallback = () => {
    setEditAccountingSettings(false);
  };

  return (
    <>
      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}
      
      <Row>
        <Col xl={6} xxl={4}>
          <Title type="section" title={"General Settings"}>
            {(user.userPermissions?.some(a => a == UserPermissionsNames.ManageLTESystemSettings) && 
              editGeneralSettings !== true) &&
              <Button onClick={() => setEditGeneralSettings(true)} className="btn-icon" variant="primary">
                <MdEdit />
              </Button>
            }
          </Title>
          {lteGeneralSettings &&
            <>
              {(user.userPermissions?.some(a => a == UserPermissionsNames.ManageLTESystemSettings) && 
                editGeneralSettings === true) ? (
 
                <EditLteGeneralSettingsSummary
                  lteId={props.lteId}
                  lteGeneralSettings={lteGeneralSettings}
                  submitCallbackFn={(data: LteGeneralSettingsModel) => submitEditGeneralSettingsCallback(data)}
                  cancelCallbackFn={cancelEditGeneralSettingsCallback}
                  isLoading={isLoadingGeneralSettings}
                />
              ) : (
                <ViewLteGeneralSettingsSummary
                  lteGeneralSettings={lteGeneralSettings}
                  isLoading={isLoadingGeneralSettings}
                />
              )}
            </>
          }
        </Col>

        <Col xl={6} xxl={4} className="mt-4 mt-xl-0">
          <Title type="section" title={"Storage Settings"}>
            {(user.userPermissions?.some(a => a == UserPermissionsNames.ManageLTESystemSettings) && editStorageSettings !== true) &&
              <Button onClick={() => setEditStorageSettings(true)} className="btn-icon" variant="primary">
                <MdEdit />
              </Button>
            }
          </Title>
          {lteStorageSettings &&
            <>
              {(user.userPermissions?.some(a => a == UserPermissionsNames.ManageLTESystemSettings) && editStorageSettings === true) ? (
                <EditLteStorageSettingsSummary
                  lteId={props.lteId}
                  lteStorageSettings={lteStorageSettings}
                  submitCallbackFn={(data: LteStorageSettingsModel) => submitEditStorageSettingsCallback(data)}
                  cancelCallbackFn={cancelEditStorageSettingsCallback}
                  isLoading={isLoadingStorageSettings}
                />
              ) : (
                <ViewLteStorageSettingsSummary
                  lteStorageSettings={lteStorageSettings}
                  isLoading={isLoadingStorageSettings}
                />
              )}
            </>
          }
        </Col>

        <Col xl={6} xxl={4} className="mt-4 mt-xxl-0">
          <Title type="section" title={"Calendar Settings"}>
          {(user.userPermissions?.some(a => a == UserPermissionsNames.ManageLTESystemSettings) && editCalendarSettings !== true) &&
            <Button onClick={() => setEditCalendarSettings(true)} className="btn-icon" variant="primary">
              <MdEdit />
            </Button>
          }
          </Title>
          {lteStorageSettings &&
            <>
              {(user.userPermissions?.some(a => a == UserPermissionsNames.ManageLTESystemSettings) && editCalendarSettings === true) ? (
                <EditLteCalendarSettingsSummary
                  lteId={props.lteId}
                  lteCalendarSettings={lteCalendarSettings}
                  submitCallbackFn={(data: LteCalendarSettingsModel) => submitEditCalendarSettingsCallback(data)}
                  cancelCallbackFn={cancelEditCalendarSettingsCallback}
                  isLoading={isLoadingCalendarSettings}
                />
              ) : (
                <ViewLteCalendarSettingsSummary
                  lteId={props.lteId}
                  lteCalendarSettings={lteCalendarSettings}
                  isLoading={isLoadingCalendarSettings}
                />
              )}
            </>
          }
        </Col>

        <Col xl={6} xxl={4} className="mt-4">
          <Title type="section" title={"Accounting Settings"}>
            {(user.userPermissions?.some(a => a == UserPermissionsNames.ManageLTESystemSettings) && 
              editGeneralSettings !== true) &&
              <Button onClick={() => setEditAccountingSettings(true)} className="btn-icon" variant="primary">
                <MdEdit />
              </Button>
            }
          </Title>
          {lteAccountingSettings &&
            <>
              {(user.userPermissions?.some(a => a == UserPermissionsNames.ManageLTESystemSettings) && 
                editAccountingSettings === true) ? (
 
                <EditLteAccountingSettingsSummary
                  lteId={props.lteId}
                  lteAccountingSettings={lteAccountingSettings}
                  submitCallbackFn={(data: LteAccountingSettingsModel) => submitEditAccountingSettingsCallback(data)}
                  cancelCallbackFn={cancelEditAccountingSettingsCallback}
                  isLoading={isLoadingAccountingSettings}
                />
              ) : (
                <ViewLteAccountingSettingsSummary
                  lteId={props.lteId}
                  lteAccountingSettings={lteAccountingSettings}
                  disconnectXeroCallbackFn={(data: LteAccountingSettingsModel) => submitEditAccountingSettingsCallback(data)}
                  reloadAccountingSettingsFn={loadAccountingSettings}
                  isLoading={isLoadingAccountingSettings}
                />
              )}
            </>
          }
        </Col>
      </Row>

      <Row>
        <Col xl={6} xxl={6} className="mt-4 mt-xxl-0">
          <DelegatedLTEsList lteId={props.lteId} />
        </Col>
        <Col xl={6} xxl={6} className="mt-4 mt-xxl-0">
          <LTEShareAddressBookList lteId={props.lteId} />
        </Col>
      </Row>

      <SlidingPanel />
    </>
  );
}
