import { enforce, test, create, omitWhen } from "vest";
import isDate from "validator/lib/isDate";
import { MatterInvoiceParticipatingEntityModel } from "models/view/MatterInvoiceParticipatingEntityModel";

export const validationSuite = create((data = {}) => {
  enforce.extend({ isDate });

  test('date', 'Please select a Date', () => {
    enforce(data.date).isDate({ strictMode: false });
  });

  test('recordableItemsAmount', `Recordable Items Amount needs to be a number <= ${data.maxRecordableItemsAmountPossible}`, () => {
    enforce(data.recordableItemsAmount).lessThanOrEquals(data.maxRecordableItemsAmountPossible);
  });

  test('incidentalExpensesAmount', `Incidental Expenses Amount needs to be a number <= ${data.maxIncidentalExpensesAmountPossible}`, () => {
    enforce(data.incidentalExpensesAmount).lessThanOrEquals(data.maxIncidentalExpensesAmountPossible);
  });

  test('disbursementsAmount', `Disbursements Amount needs to be a number <= ${data.maxDisbursementsAmountPossible}`, () => {
    enforce(data.disbursementsAmount).lessThanOrEquals(data.maxDisbursementsAmountPossible);
  });

  test('description', 'Description is required', () => {
    enforce(data.description).isNotEmpty();
    enforce(data.description).isString();
  });

  omitWhen(!data.forSingleClient, () => {
    test('matterParticipatingEntityId', 'Please select a Client', () => {
      enforce(data.matterParticipatingEntityId).isNotEmpty();
      enforce(data.matterParticipatingEntityId).isString();
    });

    test('recordableItemsAmount', `Recordable Items Amount exceeds client amount on the invoice`, () => {
      const matterInvoiceParticipatingEntity = data.matterInvoiceParticipatingEntities?.find((x: MatterInvoiceParticipatingEntityModel) => x.matterParticipatingEntityId == data.matterParticipatingEntityId);
      enforce(data.recordableItemsAmount).lessThanOrEquals(matterInvoiceParticipatingEntity.invoicedTotalRecordableItemsGrossValue ?? 0);
    });

    test('incidentalExpensesAmount', `Incidental Expenses Amount exceeds client amount on the invoice`, () => {
      const matterInvoiceParticipatingEntity = data.matterInvoiceParticipatingEntities?.find((x: MatterInvoiceParticipatingEntityModel) => x.matterParticipatingEntityId == data.matterParticipatingEntityId);
      enforce(data.incidentalExpensesAmount).lessThanOrEquals(matterInvoiceParticipatingEntity.totalIncidentalExpensesGrossValue ?? 0);
    });

    test('disbursementsAmount', `Disbursements Amount exceeds client amount on the invoice`, () => {
      const matterInvoiceParticipatingEntity = data.matterInvoiceParticipatingEntities?.find((x: MatterInvoiceParticipatingEntityModel) => x.matterParticipatingEntityId == data.matterParticipatingEntityId);
      enforce(data.disbursementsAmount).lessThanOrEquals(matterInvoiceParticipatingEntity.totalDisbursementsGrossValue ?? 0);
    });
  });
});