import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import CustomSelect from "components/Select/Select";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { bulkDeleteMatterActivityHistoryTaxonomyTerms } from "actions/matter";
import { MatterActivityHistoryModel } from "models/view/MatterActivityHistoryModel";
import { getMatterTaxonomies, getMatterTaxonomyTermsSummary } from "actions/taxonomy";
import { TaxonomyModel } from "models/view/TaxonomyModel";
import React from "react";
import CustomTreeSelect from "components/TreeSelect/CustomTreeSelect";
import TreeSelect from "rc-tree-select";
import { BulkDeleteMatterActivityHistoryTaxonomyTermsModel } from "models/create/BulkDeleteMatterActivityHistoryTaxonomyTermsModel";
import usePageActions from "actions/page";
import FormSubmitButton from "components/Buttons/FormSubmitButton";

type Props = {
  matterId: string,
  activityHistoryRecords: MatterActivityHistoryModel[]
}

export default function BulkDeleteActivityHistoryTaxonomyTermsForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [taxonomies, setTaxonomies] = useState<TaxonomyModel[]>([]);
  const slidingPanelActions = useSlidingPanelActions();
  const pageActions = usePageActions();

  const {reset, control, handleSubmit, formState: {errors}} = useForm<any>({
    resolver: vestResolver(validationSuite)
  });

  useEffect(() => {
    setIsLoading(true);

    getMatterTaxonomies(props.matterId).then(response => {
      setTaxonomies(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
      
  }, []);

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: any) {
    setIsLoading(true);
    removeEmptyFields(data);
    const requestBody: BulkDeleteMatterActivityHistoryTaxonomyTermsModel = {
      taxonomyTermIds: [],
      activityHistoryIds: props.activityHistoryRecords.map(x => x.id)
    };

    Object.keys(data).forEach(function (key: string) {
      const value = Reflect.get(data, key);
      if(Array.isArray(value)) {
        if(value.length > 0 && typeof value[0] === 'object') {
          requestBody.taxonomyTermIds = requestBody.taxonomyTermIds.concat(value.map(x => x.value));
        }
        else {
          requestBody.taxonomyTermIds = requestBody.taxonomyTermIds.concat(value);
        }
      }
      else {
        requestBody.taxonomyTermIds = requestBody.taxonomyTermIds.concat([value]);
      }
    });

    bulkDeleteMatterActivityHistoryTaxonomyTerms(props.matterId, requestBody)
      .then((response) => {
        pageActions.triggerReloadPage(true, false);

        slidingPanelActions.clearSlidingPanel();
        reset();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        {taxonomies.map((x: TaxonomyModel, index: number) => {
          return (
            <React.Fragment key={index}>
              <Row>
                <Form.Group as={Col} controlId={`taxonomy-${x.id}`}>
                  <Form.Label>{x.displayName}</Form.Label>
                  <Controller
                    control={control}
                    name={`taxonomy-${x.id}`}
                    shouldUnregister={true}
                    render={({field: { onChange, value, name, ref }}) => (
                      <>
                        {x.isHierarchical ? 
                          <CustomTreeSelect
                            id={`taxonomy-${x.id}`}
                            className={`lp-select w-100${errors?.parentTaxonomyTermId?.message ? ' invalid' : ''}`}
                            endpointCall={() => getMatterTaxonomyTermsSummary(props.matterId, x.id)}
                            value={value}
                            onChange={(val: any) => onChange(val ?? null)}
                            dropdownMatchSelectWidth
                            treeDefaultExpandAll
                            treeCheckable={x.isMultiSelect}
                            showCheckedStrategy={x.isMultiSelect ? TreeSelect.SHOW_ALL : undefined}
                            treeCheckStrictly={x.isMultiSelect}
                            maxTagCount={4}
                            maxTagTextLength={25}
                          />
                        :
                          <CustomSelect
                            id={`taxonomy-${x.id}`}
                            inputRef={ref}
                            endpointCall={() => getMatterTaxonomyTermsSummary(props.matterId, x.id)}
                            isMulti={x.isMultiSelect}
                            isClearable
                            value={value}
                            onChange={val => {
                              x.isMultiSelect ? 
                                (onChange(val?.map((x: { id: any; }) => x.id) ?? null))
                              : 
                                (onChange(val?.id ?? null))
                            }}
                          />
                        }
                      </>
                      
                    )}
                  />
                </Form.Group>
              </Row>
            </React.Fragment>
          )
        })}

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <FormSubmitButton text="Delete" errors={{...errors}} />
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
