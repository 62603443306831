import { ICellRendererParams } from "ag-grid-community";
import TooltipIcon from 'components/TooltipIcon';

export default function EntityDisplayNameCellRenderer(props: ICellRendererParams) {
  return (
    <>
      {props.data &&
        <>
          {props.data.isShared && 
            <TooltipIcon type="shared" text={`Managed by ${props.data.lawPageTradingEntity?.name}`} iconPosition="left" />
          }

          <span>{props.value}</span>
        </>
      }
    </>
  );
}
