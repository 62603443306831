import { ColDef } from "ag-grid-community";
import { EntityModel } from "models/view/EntityModel";
import store from "state/store";
import { changeEntityStatus, deleteEntity, getEntityTypes } from "actions/entity";
import { NavigateFunction } from "react-router-dom";
import { setRowData } from "state/gridSlice";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { getEntityRolesSummary } from "actions/settings";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";
import EntityDisplayNameCellRenderer from "components/Grid/EntityDisplayNameCellRenderer";

const viewCallback = (id: string, navigate: NavigateFunction) => {
  navigate(`/addressBook/${id}`);
};

const changeStatusModal = (id: string) => {
  let modal: ModalState = {
    title: "Confirmation",
    body: "Are you sure you want to change the status for this Entity?",
    onAction: () => changeStatus(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
}

const changeStatus = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  changeEntityStatus(id).then(() => {
    let index: number = gridState.rowData.findIndex((x: any) => x.id == id);
    let newEntry: EntityModel = { ...gridState.rowData[index], active: !gridState.rowData[index].active };
    let newArray: Array<any> = [...gridState.rowData];
    newArray[index] = newEntry;
    store.dispatch(setRowData(newArray));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

const deleteModal = (id: string) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this entity?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const deleteCallback = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteEntity(id).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: any) => x.id !== id)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const getColumnDefs = () => {
  const loggedInUser = store.getState().user;
  const buttonsColumnWidth = loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) ? 105 : 65;
  
  const columnDefs: (ColDef<EntityModel> | {excludeFromExport: boolean})[] = [
    {
      headerName: 'Display Name',
      field: 'displayName',
      filter: 'agTextColumnFilter',
      cellRenderer: EntityDisplayNameCellRenderer,
    },
    {
      headerName: 'Type',
      field: 'entityType.name',
      filter: DropdownFilter,
      filterParams: {
        property: 'entityTypeId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getEntityTypes
      },
      minWidth: 220
    },
    {
      headerName: 'Default Role',
      field: 'defaultRole.name',
      filter: DropdownFilter,
      filterParams: {
        property: 'defaultRoleId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getEntityRolesSummary
      },
      minWidth: 220
    },
    {
      headerName: 'Active',
      field: 'active',
      filter: BooleanFilter,
      filterParams: {
        property: 'active',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
        ]
      },
      minWidth: 100,
      width: 100,
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter
    },
    {
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRendererSelector: (params) => {
        return {
          component: ActionsCellRenderer,
          params: {
            buttonsData: [
              {
                type: ActionButtonTypes.View,
                callback: (id: string, navigate: NavigateFunction) => viewCallback(id, navigate)
              },
              ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && !params.data?.isShared ? [{
                type: params.data?.active ? ActionButtonTypes.Deactivate : ActionButtonTypes.Activate,
                callback: (id: string) => changeStatusModal(id)
              }] : []),
              ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && !params.data?.isShared ? [{
                type: ActionButtonTypes.Delete,
                callback: (id: string) => deleteModal(id)
              }] : [])
            ]
          }
        }
      },
      excludeFromExport: true,
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: buttonsColumnWidth,
      maxWidth: buttonsColumnWidth,
      width: buttonsColumnWidth,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];
  return columnDefs;
}
