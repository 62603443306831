import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import useGridActions from "actions/grid";
import { useAppSelector } from "hooks/appSelector";
import { Form, Button, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import { getValidationSuite } from "./validationSuite";
import Loader from "components/Loader/index";
import { getLteTaxonomyById, updateLteTaxonomy } from "actions/taxonomy";
import { CreateOrUpdateTaxonomyModel } from "models/create/CreateOrUpdateTaxonomyModel";
import { TaxonomyModel } from "models/view/TaxonomyModel";
import CustomSelect from "components/Select/Select";
import { getMatterTypes } from "actions/matter";
import FormSubmitButton from "components/Buttons/FormSubmitButton";

type Props = {
  taxonomyId: string
}

export default function EditTaxonomyForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const gridActions = useGridActions();
  const grid = useAppSelector((state) => state.grid);
  const slidingPanelActions = useSlidingPanelActions();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    getLteTaxonomyById(props.taxonomyId).then((response) => {
      const initialState = {
        displayName: response.data.displayName,
        description: response.data.description,
        isHierarchical: response.data.isHierarchical,
        isMultiSelect: response.data.isMultiSelect,
        active: response.data.active,
        canBeOnTemplates: response.data.canBeOnTemplates,
        matterTypeIds: response.data.matterTypes?.map((x: any) => x.id)
      };
      reset(initialState);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const {register, reset, control, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateTaxonomyModel>({
    resolver: vestResolver(getValidationSuite(props.taxonomyId!))
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateTaxonomyModel) {
    setIsLoading(true);
    updateLteTaxonomy(props.taxonomyId, data).then((response) => {
      let index: number = grid.rowData.findIndex((x: any) => x.id === response.data.id);
      let newEntry: TaxonomyModel = {...response.data};
      let newArray: Array<any> = [...grid.rowData];
      newArray[index] = newEntry;
      gridActions.setGridRowData(newArray);
      
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }
      
  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} controlId="displayName">
            <Form.Label className="required">Display Name</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.displayName?.message ? 'invalid' : ''}`}
              {...register("displayName", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.displayName?.message && (errors.displayName.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              className={`${errors?.description?.message ? 'invalid' : ''}`}
              {...register("description", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.description?.message && (errors.description.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="isHierarchical">
            <Form.Label>Is Hierarchical</Form.Label>
            <Controller
              control={control}
              name="isHierarchical"
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <Form.Check type="switch" id="isHierarchical">
                  <Form.Check.Input
                    className="form-check-input"
                    ref={ref}
                    checked={value ?? false}
                    onChange={(ev: any) => {
                      onChange(ev.target.checked);
                    }}
                  />
                </Form.Check>
              )}
            />
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="isMultiSelect">
            <Form.Label>Is MultiSelect</Form.Label>
            <Controller
              control={control}
              name="isMultiSelect"
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <Form.Check type="switch" id="isMultiSelect">
                  <Form.Check.Input
                    className="form-check-input"
                    ref={ref}
                    checked={value ?? false}
                    onChange={(ev: any) => {
                      onChange(ev.target.checked);
                    }}
                  />
                </Form.Check>
              )}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="matterTypeIds">
            <Form.Label className="required">Matter Types</Form.Label>
            <Controller
              control={control}
              name="matterTypeIds"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <CustomSelect
                  id="matterTypeIds"
                  inputRef={ref}
                  className={`lp-select${errors?.matterTypeIds?.message ? ' invalid' : ''}`}
                  endpointCall={getMatterTypes}
                  value={value}
                  onChange={val => onChange(val?.map((x: { id: any; }) => x.id) ?? null)}
                  isMulti
                  isClearable
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.matterTypeIds?.message && (errors.matterTypeIds.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="canBeOnTemplates">
            <Form.Label>Can Be Added To Templates</Form.Label>
            <Controller
              control={control}
              name="canBeOnTemplates"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="canBeOnTemplates">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="active">
            <Form.Label>Active</Form.Label>
            <Controller
              control={control}
              name="active"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="active">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <FormSubmitButton text="Update" errors={{...errors}} />
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
