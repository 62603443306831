import Title from "components/Title";
import { TabPanel } from "react-tabs";
import ActivityList from "./Activities/ActivityList";
import DefaultActivityList from "./DefaultActivities/DefaultActivityList";
import DefaultFeeEarnerLevelList from "./DefaultFeeEarnerLevels/DefaultFeeEarnerLevelList";
import FeeEarnerLevelList from "./FeeEarnerLevels/FeeEarnerLevelList";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import CustomTabs from "components/CustomTabs";
import usePageActions from "actions/page";
import { useEffect } from "react";

export default function ViewSettings() {
  const loggedInUser = useAppSelector((state) => state.user);
  const pageActions = usePageActions();

  useEffect(() => {
    return () => {
      pageActions.abortRequests();
    }
  }, []);

  return (
    <div className="lp-page-content">
      <Title type="page" title="Charging Scheme Settings"></Title>

      <CustomTabs 
        tabList={[
          ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewDefaultSettings || a == UserPermissionsNames.ManageDefaultSettings) ? [{
            name: 'Default Activities',
            key: 'defaultActivities',
            panel: <TabPanel>
              <DefaultActivityList />
            </TabPanel>
          },
          {
            name: 'Default Fee Earner Levels',
            key: 'defaultFeeEarnerLevels',
            panel: <TabPanel>
              <DefaultFeeEarnerLevelList />
            </TabPanel>
          }] : []),
          ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewLTESettings || a == UserPermissionsNames.ManageLTESettings) ? [{
            name: 'Activities',
            key: 'activities',
            panel: <TabPanel>
              <ActivityList />
            </TabPanel>
          },
          {
            name: 'Fee Earner Levels',
            key: 'feeEarnerLevels',
            panel: <TabPanel>
              <FeeEarnerLevelList />
            </TabPanel>
          }] : [])
        ]}
      />
    </div>
  );
}
