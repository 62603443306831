import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Loader from "components/Loader";
import { getAccountLedgerTransactionById, getAccountLedgerTransactionEntriesForTransaction } from "actions/matter";
import { AccountLedgerTransactionEntryModel } from "models/view/AccountLedgerTransactionEntryModel";
import Field from "components/Fields/Field";
import { AccountLedgerTransactionModel } from "models/view/AccountLedgerTransactionModel";
import moment from "moment";
import { DateFormat } from "utils/constants";
import { AccountDisplayOn } from "enums/AccountDisplayOn";
import ViewAccountLedgerTransactionTable from "./ViewAccountLedgerTransactionTable";

type Props = {
  accountLedgerTransactionId: string,
  accountLedgerTransactionEntryId: string,
  showMatterInfo?: boolean,
}

export default function ViewAccountLedgerTransaction(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accountLedgerTransaction, setAccountLedgerTransaction] = useState<AccountLedgerTransactionModel>();
  const [accountLedgerTransactionEntries, setAccountLedgerTransactionEntries] = useState<AccountLedgerTransactionEntryModel[]>([]);

  useEffect(() => {
    setIsLoading(true);

    const promises = [
      getAccountLedgerTransactionById(props.accountLedgerTransactionId),
      getAccountLedgerTransactionEntriesForTransaction(props.accountLedgerTransactionId)
    ];

    Promise.all(promises)
      .then(([transactionResponse, transactionEntriesResponse]) => {
        setAccountLedgerTransaction(transactionResponse.data);
        setAccountLedgerTransactionEntries(transactionEntriesResponse.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [props.accountLedgerTransactionEntryId]);

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col sm={6}>
          <Field
            label={"Date"}
            value={accountLedgerTransaction?.date ? moment(accountLedgerTransaction?.date).format(DateFormat.Moment) : "—"}
          />
        </Col>
        {props.showMatterInfo && 
          <Col sm={6} className="mt-4 mt-sm-0">
            <Field
              label={"Matter"}
              value={accountLedgerTransaction?.matter?.name ?? "—"}
            />
          </Col>
        }
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            label={"Client"}
            value={accountLedgerTransaction?.matterParticipatingEntity?.name ?? "—"}
          />
        </Col>
        <Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Display Name"}
            value={accountLedgerTransaction?.displayName}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Description"}
            value={accountLedgerTransaction?.description?.length ? accountLedgerTransaction.description : "—"}
          />
        </Col>
      </Row>

      <div>
        {accountLedgerTransactionEntries.some((x) => x.account?.displayOn == AccountDisplayOn.BusinessLedger ||
          x.account?.displayOn == AccountDisplayOn.ClientLedgerBusinessSide ) &&
          <>
            <div className="lp-color-title primary full-width-no-bottom-padding my-4">Business Ledger</div>
            <ViewAccountLedgerTransactionTable entries={accountLedgerTransactionEntries.filter((x) =>
              x.account?.displayOn == AccountDisplayOn.BusinessLedger || x.account?.displayOn == AccountDisplayOn.ClientLedgerBusinessSide)}
            />
          </>
        }
      </div>
      <div>
        {accountLedgerTransactionEntries.some((x) => x.account?.displayOn == AccountDisplayOn.ClientLedgerClientSide) &&
          <>
            <div className="lp-color-title primary full-width-no-bottom-padding my-4">Client Ledger</div>
            <ViewAccountLedgerTransactionTable entries={accountLedgerTransactionEntries.filter((x) => x.account?.displayOn == AccountDisplayOn.ClientLedgerClientSide)} />
          </>
        }
      </div>
    </>
  );
}