import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { SlidingPanelIds } from "enums/SlidingPanelIds";

export type SlidingPanelState = {
  id?: SlidingPanelIds;
  isShown: boolean;
  title: string;
  children?: React.ReactNode;
  width?: string;
  onCancel?: Function;
  onEdit?: Function;
  moreButtons?: { type: ActionButtonTypes, callback: Function }[];
  allowNavigation?: boolean;
  forceSlidingPanelView?: boolean;
  showPin?: boolean;
}

export const initialState: SlidingPanelState = {
  id: undefined,
  isShown: false,
  title: "",
  children: undefined,
  width: undefined,
  onCancel: undefined,
  onEdit: undefined,
  moreButtons: undefined,
  allowNavigation: false,
  forceSlidingPanelView: false,
  showPin: false
}

export const slidingPanelSlice = createSlice({
  name: 'slidingPanel',
  initialState,
  reducers: {
    setSlidingPanelData: (state, action: PayloadAction<SlidingPanelState>) => {
      state.id = action.payload.id;
      state.title = action.payload.title;
      state.isShown = action.payload.isShown;
      state.children = action.payload.children;
      state.width = action.payload.width;
      state.onCancel = action.payload.onCancel;
      state.onEdit = action.payload.onEdit;
      state.moreButtons = action.payload.moreButtons;
      state.allowNavigation = action.payload.allowNavigation;
      state.forceSlidingPanelView = action.payload.forceSlidingPanelView;
      state.showPin = action.payload.showPin;
    },
    setOnEditFunction: (state, action: PayloadAction<Function>) => {
      state.onEdit = action.payload;
    },
    setMoreButtonsFunction: (state, action: PayloadAction<{ type: ActionButtonTypes, callback: Function }[]>) => {
      state.moreButtons = action.payload;
    },
    toggleSlidingPanel: (state) => {
      state.isShown = !state.isShown;
    },
    clearSlidingPanelData: (state) => {
      state.id = undefined;
      state.title = "";
      state.isShown = false;
      state.children = undefined;
      state.width = undefined;
      state.onCancel = undefined;
      state.onEdit = undefined;
      state.moreButtons = undefined;
      state.allowNavigation = false;
      state.forceSlidingPanelView = false;
      state.showPin = false;
    },
  },
})

export const {
  setSlidingPanelData,
  setOnEditFunction,
  setMoreButtonsFunction,
  toggleSlidingPanel,
  clearSlidingPanelData
} = slidingPanelSlice.actions;

export default slidingPanelSlice.reducer;
