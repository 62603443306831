import { vestResolver } from "@hookform/resolvers/vest";
import { 
  createMatterInvoicePayment,
  deleteMatterInvoicePayment,
  getMatterInvoiceById,
  getMatterInvoicePayments,
  getMatterClientAccountBalances,
  getMatterParticipatingEntitiesForInvoiceSummary
} from "actions/matter";
import Loader from "components/Loader/index";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { DateFormat } from "utils/constants";
import { removeEmptyFields } from "utils/form";
import { getValidationSuite } from "./validationSuite";
import { getDateOnly } from "utils/date";
import { CreateOrUpdateMatterInvoicePaymentModel } from "models/create/CreateOrUpdateMatterInvoicePaymentModel";
import { MatterInvoiceModel } from "models/view/MatterInvoiceModel";
import Field from "components/Fields/Field";
import { formatCurrency } from "utils/misc";
import { MatterInvoicePaymentModel } from "models/view/MatterInvoicePaymentModel";
import { MdClose } from "react-icons/md";
import moment from "moment";
import _ from 'lodash';
import { MatterLedgerBalanceSummaryModel } from "models/view/MatterLedgerBalanceSummaryModel";
import { useAppSelector } from "hooks/appSelector";
import InvoiceStatusField from "components/Fields/InvoiceStatusField";
import CustomSelect from "components/Select/Select";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import usePageActions from "actions/page";
import useModalActions from "actions/modal";
import { ModalState } from "state/modalSlice";

type Props = {
  matterId: string,
  matterInvoiceId: string
}

export default function ViewMatterInvoicePayments(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const genericErrorsRef = useRef<HTMLDivElement>(null);
  const [matterInvoice, setMatterInvoice] = useState<MatterInvoiceModel>();
  const [clientAccountBalanceSummaries, setClientAccountBalanceSummaries] = useState<MatterLedgerBalanceSummaryModel[]>();
  const [payments, setPayments] = useState<MatterInvoicePaymentModel[]>([]);
  const [showAddNewPaymentForm, setShowAddNewPaymentForm] = useState(false);
  const loggedInUser = useAppSelector((state) => state.user);
  const pageActions = usePageActions();
  const modalActions = useModalActions();
  const dataChanged = useRef(false);

  const { handleSubmit, control, setValue, register, watch, reset, formState: { errors } } = useForm<CreateOrUpdateMatterInvoicePaymentModel>({
    resolver: vestResolver(getValidationSuite(loggedInUser.lteHasClientBankAccounts))
  });

  useEffect(() => {
    loadData();

    return () => {
      // When closing this panel, refresh page data ONLY IF something changed
      if(dataChanged.current) {
        pageActions.triggerReloadPage();
      }
    };
  }, []);

  useEffect(() => {
    if(genericErrorsRef.current)
    {
      genericErrorsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [genericErrors]);

  const loadData = () => {
    setIsLoading(true);

    const promises = [
      getMatterInvoiceById(props.matterId, props.matterInvoiceId),
      getMatterInvoicePayments(props.matterId, props.matterInvoiceId),
      loggedInUser.lteHasClientBankAccounts ? getMatterClientAccountBalances(props.matterId, props.matterInvoiceId) : Promise.resolve()
    ];

    Promise.all(promises)
      .then(([matterInvoiceResponse, matterInvoicePaymentsResponse, clientAccountBalancesResponse]) => {
        setMatterInvoice(matterInvoiceResponse.data);
        setPayments(matterInvoicePaymentsResponse.data);

        setValue('invoiceAmountDue', matterInvoiceResponse.data.amountDue);
        setValue('fullAmountPaid', false);
        setValue('forSingleClient', false);
        setValue('matterInvoiceParticipatingEntities', matterInvoiceResponse.data.matterInvoiceParticipatingEntities);
        if(loggedInUser.lteHasClientBankAccounts) {
          setClientAccountBalanceSummaries(clientAccountBalancesResponse.data);
          setValue('clientAccountBalances', clientAccountBalancesResponse.data);
          setValue('hasFundsFromClientAccount', false);
        }
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateMatterInvoicePaymentModel) {
    setIsLoading(true);
    const requestBody = { ...data };
    requestBody.clientAccountBalances = undefined;
    requestBody.invoiceAmountDue = undefined;
    requestBody.fullAmountPaid = undefined;
    removeEmptyFields(requestBody);
    createMatterInvoicePayment(props.matterId, props.matterInvoiceId, requestBody).then((response) => {
      hideAddNewPayment();
      setGenericErrors(null);
      dataChanged.current = true;
      loadData();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      setIsLoading(false);
    });
  }

  function removePayment(paymentId: string) {
    setIsLoading(true);
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Payment? This cannot be undone.",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => removePaymentCallback(paymentId),
      onClose: () => {setIsLoading(false);},
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();  
  }

  const removePaymentCallback = (paymentId: string) => {
    modalActions.toggleModalLoading();
    deleteMatterInvoicePayment(props.matterId, props.matterInvoiceId, paymentId).then(() => {
      modalActions.toggleModalShownStatus();
      dataChanged.current = true;
      loadData();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
      setIsLoading(false);
    });
  };

  const onChangeFullAmountPaid = (value: boolean) => {
    if(value) {
      setValue('amountPaid', matterInvoice?.amountDue ?? 0);
    }
  }

  const onChangeHasFundsFromClientAccount = (value: boolean) => {
    if(!value) {
      setValue('amountPaidFromClientAccount', undefined);
    }
  }

  const onChangeForSingleClient = (value: boolean) => {
    if(value) {
      setValue('matterParticipatingEntityId', undefined);
    }
  }

  const onClickAddNewPayment = () => {
    if (showAddNewPaymentForm) {
      hideAddNewPayment();
      return;
    }
    setShowAddNewPaymentForm(true);
  }

  const hideAddNewPayment = () => {
    setShowAddNewPaymentForm(false);
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div ref={genericErrorsRef} className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit}>
        <Row>
          <Form.Group as={Col} sm={6}>
            <Field
              label={"Invoice Ref Number"}
              value={matterInvoice?.invoiceRefNumber}
            />
          </Form.Group>
          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0">
            <Field
              label={"Display Name"}
              value={matterInvoice?.displayName}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} sm={6}>
            <Field
              label={"Amount Paid"}
              value={formatCurrency(matterInvoice?.amountPaid)}
            />
          </Form.Group>
          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0">
            <Field
              label={"Amount Due"}
              value={formatCurrency(matterInvoice?.amountDue)}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} sm={6}>
            {matterInvoice?.matterInvoiceStatus?.name ? 
              <InvoiceStatusField
                label={"Status"}
                value={matterInvoice?.matterInvoiceStatus}
              />
              :
              <Field
                label={"Status"}
                value={"—"}
              />
            }
          </Form.Group>
          {loggedInUser.lteHasClientBankAccounts && 
            <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0">
              <Field
                label={"Client Account Balances"}
                value={clientAccountBalanceSummaries?.map(x => x.matterParticipatingEntity?.name + ": " +  formatCurrency(x?.balance)).join(", ")}
              />
            </Form.Group>
          }
        </Row>

        {(matterInvoice?.amountDue ?? 0) > 0 && !matterInvoice?.matter?.isClosed &&
          <>
            {showAddNewPaymentForm ? (
              <>
                <div className="lp-color-title primary full-width">New Payment</div>
                <Row>
                  <Form.Group as={Col} sm={6} controlId="date">
                    <Form.Label className="required">Date</Form.Label>
                    <Controller
                      control={control}
                      name="date"
                      shouldUnregister={true}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          className={`${errors?.date?.message ? 'invalid' : ''}`}
                          id="date"
                          dateFormat={DateFormat.Datepicker}
                          selected={value ? getDateOnly(value) : null}
                          onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                          showMonthDropdown
                          showYearDropdown
                          autoComplete="off"
                        />
                      )}
                    />
                    <Form.Text className="lp-error">
                      {errors?.date?.message && (errors.date.message)}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="displayName">
                    <Form.Label className="required">Display Name</Form.Label>
                    <Form.Control
                      type="text"
                      className={`${errors?.displayName?.message ? 'invalid' : ''}`}
                      {...register(`displayName`, {shouldUnregister: true})}
                    />
                    <Form.Text className="lp-error">
                      {errors?.displayName?.message && (errors.displayName?.message)}
                    </Form.Text>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col} controlId="description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      className={`${errors?.description?.message ? 'invalid' : ''}`}
                      {...register(`description`, {shouldUnregister: true})}
                    />
                    <Form.Text className="lp-error">
                      {errors?.description?.message && (errors.description?.message)}
                    </Form.Text>
                  </Form.Group>
                </Row>

                {loggedInUser.lteHasClientBankAccounts &&
                  <>
                    <Row>
                      <Form.Group as={Col} sm={6} controlId="fullAmountPaid">
                        <Form.Label>Full Amount Paid</Form.Label>
                        <Controller
                          control={control}
                          name="fullAmountPaid"
                          shouldUnregister={true}
                          render={({ field: { onChange, value, name, ref } }) => (
                            <Form.Check type="switch" id="fullAmountPaid">
                              <Form.Check.Input
                                className="form-check-input"
                                ref={ref}
                                checked={value ?? false}
                                onChange={(ev: any) => {
                                  onChange(ev.target.checked);
                                  onChangeFullAmountPaid(ev.target.checked);
                                }}
                              />
                            </Form.Check>
                          )}
                        />
                      </Form.Group>

                      <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="hasFundsFromClientAccount">
                        <Form.Label>Include Client Account Funds</Form.Label>
                        <Controller
                          control={control}
                          name="hasFundsFromClientAccount"
                          shouldUnregister={true}
                          render={({ field: { onChange, value, name, ref } }) => (
                            <Form.Check type="switch" id="hasFundsFromClientAccount">
                              <Form.Check.Input
                                className="form-check-input"
                                ref={ref}
                                checked={value ?? false}
                                onChange={(ev: any) => {
                                  onChange(ev.target.checked);
                                  onChangeHasFundsFromClientAccount(ev.target.checked);
                                }}
                              />
                            </Form.Check>
                          )}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} sm={6} controlId="amountPaid">
                        <Form.Label className="required">Amount Paid</Form.Label>
                        <Form.Control
                          type="number"
                          className={`${errors?.amountPaid?.message ? 'invalid' : ''}`}
                          disabled={watch("fullAmountPaid")}
                          {...register(`amountPaid`, {shouldUnregister: true})}
                          min="0.00"
                          step="0.01"
                          onWheel={e => e.currentTarget.blur()}
                        />
                        <Form.Text className="lp-error">
                          {errors?.amountPaid?.message && (errors.amountPaid?.message)}
                        </Form.Text>
                      </Form.Group>

                      {watch("hasFundsFromClientAccount") &&
                        <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="amountPaidFromClientAccount">
                          <Form.Label className="required">Amount From Client Account</Form.Label>
                          <Form.Control
                            type="number"
                            className={`${errors?.amountPaidFromClientAccount?.message ? 'invalid' : ''}`}
                            {...register(`amountPaidFromClientAccount`, {shouldUnregister: true})}
                            min="0.00"
                            step="0.01"
                            onWheel={e => e.currentTarget.blur()}
                          />
                          <Form.Text className="lp-error">
                            {errors?.amountPaidFromClientAccount?.message && (errors.amountPaidFromClientAccount?.message)}
                          </Form.Text>
                        </Form.Group>
                      }
                    </Row>
                  </>
                }

                {!loggedInUser.lteHasClientBankAccounts &&
                  <Row>
                    <Form.Group as={Col} sm={6} controlId="fullAmountPaid">
                      <Form.Label>Full Amount Paid</Form.Label>
                      <Controller
                        control={control}
                        name="fullAmountPaid"
                        shouldUnregister={true}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Form.Check type="switch" id="fullAmountPaid">
                            <Form.Check.Input
                              className="form-check-input"
                              ref={ref}
                              checked={value ?? false}
                              onChange={(ev: any) => {
                                onChange(ev.target.checked);
                                onChangeFullAmountPaid(ev.target.checked);
                              }}
                            />
                          </Form.Check>
                        )}
                      />
                    </Form.Group>

                    <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="amountPaid">
                      <Form.Label className="required">Amount Paid</Form.Label>
                      <Form.Control
                        type="number"
                        className={`${errors?.amountPaid?.message ? 'invalid' : ''}`}
                        disabled={watch("fullAmountPaid")}
                        {...register(`amountPaid`, {shouldUnregister: true})}
                        min="0.00"
                        step="0.01"
                        onWheel={e => e.currentTarget.blur()}
                      />
                      <Form.Text className="lp-error">
                        {errors?.amountPaid?.message && (errors.amountPaid?.message)}
                      </Form.Text>
                    </Form.Group>
                  </Row>
                }

                <Row>
                  <Form.Group as={Col} sm={6} controlId="forSingleClient">
                    <Form.Label>For Single Client</Form.Label>
                    <Controller
                      control={control}
                      name="forSingleClient"
                      shouldUnregister={true}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Form.Check type="switch" id="forSingleClient">
                          <Form.Check.Input
                            className="form-check-input"
                            ref={ref}
                            checked={value ?? false}
                            onChange={(ev: any) => {
                              onChange(ev.target.checked);
                              onChangeForSingleClient(ev.target.checked);
                            }}
                          />
                        </Form.Check>
                      )}
                    />
                  </Form.Group>

                  {watch("forSingleClient") && 
                    <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="matterParticipatingEntityId">
                      <Form.Label className="required">Client</Form.Label>
                      <Controller
                        control={control}
                        name={`matterParticipatingEntityId`}
                        shouldUnregister={true}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <CustomSelect
                            id="matterParticipatingEntityId"
                            inputRef={ref}
                            className={`lp-select${errors?.matterParticipatingEntityId?.message ? ' invalid' : ''}`}
                            endpointCall={() => getMatterParticipatingEntitiesForInvoiceSummary(props.matterId, props.matterInvoiceId)}
                            value={value}
                            onChange={val => {onChange(val?.id ?? null);}}
                            menuPlacement="top"
                          />
                        )}
                      />
                    </Form.Group>
                  }
                </Row>

                <Row>
                  <Form.Group as={Col} className="d-flex justify-content-between">
                    <Button variant="success" type="submit">Create</Button>
                    <Button variant="secondary-400" onClick={hideAddNewPayment}>Cancel</Button>
                  </Form.Group>
                </Row>
              </>
            ) : (loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) &&
              <Button variant="success" onClick={onClickAddNewPayment} className="my-4">
                Add new payment
              </Button>
            )}
          </>
        }

        <div className="lp-color-title primary full-width">Payments</div>
        <div className="lp-box-list">
          {payments.length == 0 &&
            <div className="lp-list-empty">No payments found</div>
          }
          {payments.length > 0 &&
            _.orderBy(payments, x => x.date, "desc").map((x: MatterInvoicePaymentModel, index: number) => (
              <div key={x.id} className="lp-box-list-item">
                {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) && 
                  !matterInvoice?.matter?.isClosed &&
                  <Button
                    variant="danger"
                    onClick={() => removePayment(x.id)}
                    className="delete-item btn-icon"
                  >
                    <MdClose />
                  </Button>
                }

                <span className="type-icon date">
                  {moment(x?.date).format(DateFormat.Moment) + " - " + x.displayName}
                </span>

                <div className="lp-box-list-full-row">
                  <Field label={'Description'} value={x.description} />
                </div>

                <Field
                  label={"Amount Paid"}
                  value={formatCurrency(x.amountPaid)}
                />

                {x.hasFundsFromClientAccount &&
                  <Field
                    label={"Amount From Client Account"}
                    value={formatCurrency(x.amountPaidFromClientAccount)}
                  />
                }
                {x.forSingleClient &&
                  <Field
                    label={"Client"}
                    value={x.matterParticipatingEntity?.name ?? "—"}
                  />
                }
              </div>
            ))
          }
        </div>

      </Form>
    </>
  );
}
