import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { get } from "utils/request";

const callXeroCallback = async (code: string, state?: string): Promise<any> => {
  let url = `/management/xero/callback?code=${code}`;
  if(state){
    url += `&state=${state}`;
  }
  const response = await get<any>(url);
  return response;
}

const disconnectFromXero = async (lteId: string): Promise<any> => {
  const response = await get<any>(`/management/lte/${lteId}/xero/disconnect`);
  return response;
}

const getXeroTenantsSummary = async (lteId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/lte/${lteId}/xero/tenant/summary`);
  return response;
}

const getXeroAccountsSummary = async (lteId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/lte/${lteId}/xero/account/summary`);
  return response;
}

const getXeroTaxRatesSummary = async (lteId: string): Promise<any> => {
  const response = await get<DropDownOptionModel[]>(`/management/lte/${lteId}/xero/taxRate/summary`);
  return response;
}

export { 
  callXeroCallback,
  disconnectFromXero,
  getXeroTenantsSummary,
  getXeroAccountsSummary,
  getXeroTaxRatesSummary
};
