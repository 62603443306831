import { vestResolver } from "@hookform/resolvers/vest";
import useSlidingPanelActions from "actions/slidingPanel";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { addChargingRate, getChargingBasis } from "actions/chargingSchemes";
import Loader from "components/Loader/index";
import { Row, Col, Form, Button } from "react-bootstrap";
import { CreateChargingRateModel } from "models/create/CreateChargingRateModel";
import CustomSelect from "components/Select/Select";
import { ChargingSchemeModel } from "models/view/ChargingSchemeModel";
import { useAppSelector } from "hooks/appSelector";
import store from "state/store";
import { setRowData } from "state/gridSlice";
import { ChargingBasisIds } from "enums/ChargingBasisIds";
import { MdAdd, MdClose } from 'react-icons/md';
import '../style.scss';
import { getFeeEarnerLevelsSummary, getActivitiesSummaryForLte } from "actions/settings";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import FormSubmitButton from "components/Buttons/FormSubmitButton";

type Props = {
  chargingScheme?: ChargingSchemeModel
}

export default function CreateChargingRate(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const [feeEarnerLevels, setFeeEarnerLevels] = useState<DropDownOptionModel[]>();

  const user = useAppSelector((state) => state.user);
  const slidingPanelActions = useSlidingPanelActions();

  useEffect(() => {
    setIsLoading(true);
    getFeeEarnerLevelsSummary().then((response) => {
      response.data.unshift({
        id: "allLevels",
        name: "[All Levels]"
      });
      setFeeEarnerLevels(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);
  
  const { register, handleSubmit, control, clearErrors, watch, reset, formState: { errors } } = useForm<CreateChargingRateModel>({
    resolver: vestResolver(validationSuite),
    defaultValues: {
      chargingSchemeId: props.chargingScheme?.id!,
      chargingRateLevelViewModels: [{
        feeEarnerLevelId: "allLevels",
        chargingBasisId: "",
        minutesPerUnit: props.chargingScheme?.minutesPerUnit,
        chargeRate: undefined,
      }]
    }
  });
  
  const { fields, append, remove } = useFieldArray({
    name: "chargingRateLevelViewModels",
    control,
    rules: {
      required: "Please append at least 1 item"
    }
  });

  const appendNewLevel = () => {
    append({
      feeEarnerLevelId: "allLevels",
      chargingBasisId: "",
      minutesPerUnit: props.chargingScheme?.minutesPerUnit,
      chargeRate: undefined
    }, { shouldFocus: false });
  }

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateChargingRateModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    data.chargingRateLevelViewModels = data.chargingRateLevelViewModels.map(x => x.feeEarnerLevelId === "allLevels" ? {...x, feeEarnerLevelId: undefined} : x);
    addChargingRate(data).then((response) => {
      const gridState = store.getState().grid;
      let newGrid: Array<any> = [...gridState.rowData, ...response.data];
      store.dispatch(setRowData(newGrid));
      setIsLoading(false);
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} controlId="activityId">
            <Form.Label className="required">Activity</Form.Label>
            <Controller
              control={control}
              name="activityId"
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="activityId"
                  inputRef={ref}
                  className={`lp-select${errors?.activityId?.message ? ' invalid' : ''}`}
                  endpointCall={() => getActivitiesSummaryForLte(user.lawPageTradingEntityId!)}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.activityId?.message && (errors.activityId.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        {fields.map((field, index) => {
          return (
            <section key={field.id} className="lp-charge-rate-item">
              <Button
                variant="danger"
                onClick={() => remove(index)}
                className={`delete-item btn-icon${fields.length === 1 ? ' disabled' : ''}`}
              >
                <MdClose />
              </Button>

              <Row>
                <Form.Group as={Col} sm={6} controlId={`chargingRateLevelViewModels.${index}.feeEarnerLevelId`}>
                  <Form.Label className="required">Fee Earner Level</Form.Label>
                  <Controller
                    control={control}
                    name={`chargingRateLevelViewModels.${index}.feeEarnerLevelId`}
                    shouldUnregister={true}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <CustomSelect
                        id={`chargingRateLevelViewModels.${index}.feeEarnerLevelId`}
                        inputRef={ref}
                        className={`lp-select${errors?.chargingRateLevelViewModels?.[index]?.feeEarnerLevelId?.message ? ' invalid' : ''}`}
                        options={feeEarnerLevels}
                        value={value}
                        onChange={val => onChange(val?.id ?? null)}
                      />
                    )}
                  />
                  <Form.Text className="lp-error">
                    {errors?.chargingRateLevelViewModels?.[index]?.feeEarnerLevelId?.message && (errors?.chargingRateLevelViewModels?.[index]?.feeEarnerLevelId?.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId={`chargingRateLevelViewModels.${index}.chargingBasisId`}>
                  <Form.Label className="required">Charging Basis</Form.Label>
                  <Controller
                    control={control}
                    name={`chargingRateLevelViewModels.${index}.chargingBasisId`}
                    shouldUnregister={true}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <CustomSelect
                        id={`chargingRateLevelViewModels.${index}.chargingBasisId`}
                        inputRef={ref}
                        className={`lp-select${errors?.chargingRateLevelViewModels?.[index]?.chargingBasisId?.message ? ' invalid' : ''}`}
                        endpointCall={getChargingBasis}
                        value={value}
                        onChange={val => {onChange(val?.id ?? null); clearErrors(`chargingRateLevelViewModels.${index}.minutesPerUnit`); }}
                      />
                    )}
                  />
                  <Form.Text className="lp-error">
                    {errors?.chargingRateLevelViewModels?.[index]?.chargingBasisId?.message && (errors?.chargingRateLevelViewModels?.[index]?.chargingBasisId?.message)}
                  </Form.Text>
                </Form.Group>
              </Row>
              
              <Row>
                <Form.Group as={Col} sm={6} controlId={`chargingRateLevelViewModels.${index}.chargeRate`}>
                  <Form.Label className="required">Charge Rate</Form.Label>
                  <Form.Control
                    type="number"
                    className={`${errors?.chargingRateLevelViewModels?.[index]?.chargeRate?.message ? 'invalid' : ''}`}
                    {...register(`chargingRateLevelViewModels.${index}.chargeRate`)}
                    min="0"
                    step="0.001"
                    onWheel={e => e.currentTarget.blur()}
                  />
                  <Form.Text className="lp-error">
                    {errors?.chargingRateLevelViewModels?.[index]?.chargeRate?.message && (errors.chargingRateLevelViewModels?.[index]?.chargeRate?.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId={`chargingRateLevelViewModels.${index}.minutesPerUnit`}>
                  <Form.Label
                    className={`${(watch(`chargingRateLevelViewModels.${index}.chargingBasisId`) == ChargingBasisIds.TimeId)? " required" : ""}`}
                  >
                    Minutes per Unit
                  </Form.Label>
                  <Form.Control
                    type="number"
                    className={`${errors?.chargingRateLevelViewModels?.[index]?.minutesPerUnit?.message ? 'invalid' : ''}`}
                    {...register(`chargingRateLevelViewModels.${index}.minutesPerUnit`)}
                    min="0"
                    onWheel={e => e.currentTarget.blur()}
                  />
                  <Form.Text className="lp-error">
                    {errors?.chargingRateLevelViewModels?.[index]?.minutesPerUnit?.message && (errors.chargingRateLevelViewModels?.[index]?.minutesPerUnit?.message)}
                  </Form.Text>
                </Form.Group>
              </Row>
            </section>
          );
        })}

        <Row>
          <Form.Group as={Col}>
            <Button variant="light" onClick={() => appendNewLevel()}>
              <MdAdd />
              Add New Level
            </Button>
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <FormSubmitButton text="Create" errors={{...errors}} />
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
