import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { getEntityDocumentExchange, getPreferredEntityDocumentExchange, updateEntityDocumentExchange } from "actions/entity";
import { CreateOrUpdateEntityDocumentExchangeModel } from "models/create/CreateOrUpdateEntityDocumentExchangeModel";
import { removeEmptyFields } from "utils/form";
import useModalActions from 'actions/modal';
import { MdWarning } from 'react-icons/md';
import { ModalState } from 'state/modalSlice';
import FormSubmitButton from "components/Buttons/FormSubmitButton";

type Props = {
  entityId: string,
  documentExchangeId: string,
  submitCallback?: Function
}

export default function EditEntityDocumentExchangeForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();

  useEffect(() => {
    setIsLoading(true);
    getEntityDocumentExchange(props.documentExchangeId).then( (response) => {
      reset(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const {register, reset, control, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateEntityDocumentExchangeModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateEntityDocumentExchangeModel) {
    setIsLoading(true);
    if(data.isPreferred == true) {
      getPreferredEntityDocumentExchange(props.entityId).then((response) => {
        let bodyMessage: React.ReactNode = <></>;
        if(response.data?.id && response.data?.id != props.documentExchangeId) {
          bodyMessage = <div className="lp-modal-warning">
            <MdWarning />This Document Exchange is set as preferred which means that he will replace the current one.<br/>
            Are you sure you want to do that? If not, uncheck the Is Preferred checkbox.
          </div>;
        }
        else {
          updateDX(data, false);
          return;
        }
        
        let modal: ModalState = {
          title: "Update confirmation",
          body: bodyMessage,
          actionText: "Update",
          onAction: () => updateDX(data),
          show: false,
          onClose: () => setIsLoading(false)
        }
        modalActions.setModal(modal);
        modalActions.toggleModalShownStatus();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
        setIsLoading(false);
      });
    }
    else {
      updateDX(data, false);
    }
  }

  function updateDX(data: CreateOrUpdateEntityDocumentExchangeModel, fromModal: boolean = true) {
    if(fromModal)
    {
      modalActions.toggleModalLoading();
    }

    removeEmptyFields(data);
    data.entityId = props.entityId;
    updateEntityDocumentExchange(props.documentExchangeId, data).then((response) => {
      if(props.submitCallback) {
        props.submitCallback();
      }
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
      if(fromModal)
      {
        modalActions.toggleModalLoading();
        modalActions.toggleModalShownStatus();
      }
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }
      
  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} sm={6} controlId="dxExchange">
            <Form.Label className="required">DX Exchange</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.dxExchange?.message ? 'invalid' : ''}`}
              {...register("dxExchange", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.dxExchange?.message && (errors.dxExchange.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="dxNumber">
            <Form.Label className="required">DX Number</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.dxNumber?.message ? 'invalid' : ''}`}
              {...register("dxNumber", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.dxNumber?.message && (errors.dxNumber.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="isPreferred">
            <Form.Label>Is Preferred</Form.Label>
            <Controller
              control={control}
              name="isPreferred"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="isPreferred">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
            <Form.Text className="lp-error">
              {errors?.isPreferred?.message && (errors.isPreferred.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <FormSubmitButton text="Update" errors={{...errors}} />
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
