import { useEffect, useState } from 'react';
import Title from 'components/Title';
import { Link } from 'react-router-dom';
import { MdArrowOutward } from 'react-icons/md';
import { MatterInvoiceModel } from 'models/view/MatterInvoiceModel';
import ListMatterInvoices from 'components/List/ListMatterInvoices';
import { getMatterInvoicesForUser } from 'actions/user';
import { useAppSelector } from 'hooks/appSelector';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type Props = {
  title?: string;
  dragIcon?: React.ReactNode;
}

export default function DashboardMatterInvoices(props: Props) {
  const [matterInvoices, setMatterInvoices] = useState<MatterInvoiceModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(undefined);
  const loggedInUser = useAppSelector((state) => state.user);

  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: 'invoices' });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition: transition || undefined,
  };

  useEffect(() => {
    loadMatterInvoices();
  }, []);

  const loadMatterInvoices = () => {
    setIsLoading(true);
    getMatterInvoicesForUser(10).then((response) => {
      setMatterInvoices(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <div ref={setNodeRef} style={style} className={`lp-dashboard-dnd-draggable-item ${isDragging ? 'dragging' : ''}`}>
      <Title
        type="section"
        title={props.title ?? "Recent Invoices"}
        icon={props.dragIcon}
        dragAttributes={{ listeners: listeners, attributes: attributes }}
      >
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewInvoicePages) &&
          <Link to="/invoice/all" rel="noopener noreferrer" className="link">
            View all
            <MdArrowOutward />
          </Link>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <ListMatterInvoices
        listItems={matterInvoices}
        listButtons={[]}
        loadState={isLoading}
      />
    </div>
  );
}