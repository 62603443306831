import { create, test, enforce, omitWhen } from "vest";

export const getValidationSuite = (isXeroLinked: boolean) => {
  return create((data = {}) => {

    omitWhen(!isXeroLinked, () => {
      test('xeroTenantId', 'Please select a tenant', () => {
        enforce(data.xeroTenantId).isNotEmpty();
        enforce(data.xeroTenantId).isString();
      });
    });
  });
}