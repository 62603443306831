import { AccountIncreaseRepresentedBy } from 'enums/AccountIncreaseRepresentedBy';
import { MatterActivityHistorySubtypes } from 'enums/MatterActivityHistorySubtypes';
import { MatterActivityHistoryTypes } from 'enums/MatterActivityHistoryTypes';
import { MatterEmailTypes } from 'enums/MatterEmailTypes';
import { HeaderAndFooterTemplateTypeIds } from "enums/HeaderAndFooterTemplateTypeIds";
import { LTETemplateTypeIds } from "enums/LTETemplateTypeIds";
import { TemplateResourceType } from 'enums/TemplateResourceType';
import { CalendarFreeBusyStatus } from 'enums/CalendarFreeBusyStatus';
import { Priority } from 'enums/Priority';
import { VatRateTypes } from 'enums/VatRateTypes';
import { getEnumKeys } from './misc';
import { MatterAttachmentTypes } from 'enums/MatterAttachmentTypes';

export function getMatterActivityHistoryTypeName(type: number) {
  switch (type) {
    case MatterActivityHistoryTypes.RecordableEvent: {
      return 'Recordable Event';
    }
    case MatterActivityHistoryTypes.Note: {
      return 'Note';
    }
    case MatterActivityHistoryTypes.File: {
      return 'File';
    }
    case MatterActivityHistoryTypes.Mail: {
      return 'Mail';
    }
    //case MatterActivityHistoryTypes.Sms: {
    //  return 'SMS';
    //}
    //case MatterActivityHistoryTypes.WhatsApp: {
    //  return 'WhatsApp';
    //}
    //case MatterActivityHistoryTypes.Appointment: {
    //  return 'Appointment';
    //}
    case MatterActivityHistoryTypes.Disbursement: {
      return 'Disbursement';
    }
    case MatterActivityHistoryTypes.IncidentalExpense: {
      return 'Incidental Expense';
    }
    case MatterActivityHistoryTypes.Invoice: {
      return 'Invoice';
    }
    default: {
      return 'Other';
    }
  }
}

export function getMatterActivityHistorySubtypeName(subtype: number) {
  switch (subtype) {
    case MatterActivityHistorySubtypes.RecordableEvent: {
      return 'Recordable Event';
    }
    case MatterActivityHistorySubtypes.UserNote: {
      return 'User Note';
    }
    case MatterActivityHistorySubtypes.SystemNote: {
      return 'System Note';
    }
    case MatterActivityHistorySubtypes.WordFile: {
      return 'Microsoft Word';
    }
    case MatterActivityHistorySubtypes.PDFFile: {
      return 'PDF';
    }
    case MatterActivityHistorySubtypes.ExcelFile: {
      return 'Microsoft Excel';
    }
    case MatterActivityHistorySubtypes.CSVFile: {
      return 'CSV';
    }
    case MatterActivityHistorySubtypes.PowerPointFile: {
      return 'Microsoft PowerPoint';
    }
    case MatterActivityHistorySubtypes.TextFile: {
      return 'Text File';
    }
    case MatterActivityHistorySubtypes.Image: {
      return 'Image';
    }
    case MatterActivityHistorySubtypes.AudioVideo: {
      return 'Audio/Video';
    }
    case MatterActivityHistorySubtypes.Archive: {
      return 'Archive';
    }
    case MatterActivityHistorySubtypes.EmailFile: {
      return 'Email File';
    }
    case MatterActivityHistorySubtypes.HtmlFile: {
      return 'HTML File';
    }
    case MatterActivityHistorySubtypes.OneNoteFile: {
      return 'Microsoft OneNote';
    }
    case MatterActivityHistorySubtypes.OtherFile: {
      return 'Other File';
    }
    case MatterActivityHistorySubtypes.ReceivedMail: {
      return 'Received Mail';
    }
    case MatterActivityHistorySubtypes.SentMail: {
      return 'Sent Mail';
    }
    //case MatterActivityHistorySubtypes.Sms: {
    //  return 'SMS';
    //}
    //case MatterActivityHistorySubtypes.WhatsApp: {
    //  return 'WhatsApp';
    //}
    //case MatterActivityHistorySubtypes.Appointment: {
    //  return 'Appointment';
    //}
    case MatterActivityHistorySubtypes.Disbursement: {
      return 'Disbursement';
    }
    case MatterActivityHistorySubtypes.IncidentalExpense: {
      return 'Incidental Expense';
    }
    case MatterActivityHistorySubtypes.Invoice: {
      return 'Invoice';
    }
    default: {
      return 'Other';
    }
  }
}

export function getAccountIncreaseRepresentedByName(type: number) {
  switch (type) {
    case AccountIncreaseRepresentedBy.DR: {
      return 'DR';
    }
    case AccountIncreaseRepresentedBy.CR: {
      return 'CR';
    }
  }
}

export function getEmailTypeName(type: number) {
  switch (type) {
    case MatterEmailTypes.Sent: {
      return 'Sent';
    }
    case MatterEmailTypes.Received: {
      return 'Received';
    }
    case MatterEmailTypes.Draft: {
      return 'Draft';
    }
  }
}

export function getMatterEmailAttachmentTypeName(type: number) {
  switch (type) {
    case MatterAttachmentTypes.File: {
      return 'Files';
    }
    case MatterAttachmentTypes.ReceivedEmail: {
      return 'Received Emails';
    }
    case MatterAttachmentTypes.SentEmail: {
      return 'Sent Emails';
    }
    case MatterAttachmentTypes.DraftEmail: {
      return 'Draft Emails';
    }
  }
}

export function getPriority(priority: Priority | number) {
  switch (priority) {
    case Priority.High:
      return 'High';
    case Priority.Medium:
      return 'Medium';
    case Priority.Low:
      return 'Low';
    default:
      return '';
  }
}

export function getTemplateResourceTypeName(type: number) {
  switch (type) {
    case TemplateResourceType.RecordableEvent: {
      return 'Recordable Event';
    }
    case TemplateResourceType.RecordableItem: {
      return 'Recordable Item';
    }
    case TemplateResourceType.IncidentalExpense: {
      return 'Incidental Expense';
    }
    case TemplateResourceType.User: {
      return 'User';
    }
    case TemplateResourceType.LawPageTradingEntity: {
      return 'LawPage Trading Entity';
    }
    case TemplateResourceType.Matter: {
      return 'Matter';
    }
    case TemplateResourceType.Disbursement: {
      return 'Disbursement';
    }
    case TemplateResourceType.Invoice: {
      return 'Invoice';
    }
    case TemplateResourceType.InvoiceParticipatingEntity: {
      return 'Invoice Client';
    }
    case TemplateResourceType.InvoiceInvoicingParty: {
      return 'Invoice Invoicing Party';
    }
    case TemplateResourceType.InvoicePayment: {
      return 'Invoice Payment';
    }
    case TemplateResourceType.Payment: {
      return 'Payment';
    }
    case TemplateResourceType.DisbursementPayment: {
      return 'Disbursement Payment';
    }
  }
}

export function getShowAsTypeName(type: number): string {
  switch (type) {
    case CalendarFreeBusyStatus.Unknown:
      return 'Unknown';
    case CalendarFreeBusyStatus.Free:
      return 'Free';
    case CalendarFreeBusyStatus.Tentative:
      return 'Tentative';
    case CalendarFreeBusyStatus.Busy:
      return 'Busy';
    case CalendarFreeBusyStatus.OutOfOffice:
      return 'Out of Office';
    case CalendarFreeBusyStatus.WorkingElsewhere:
      return 'Working Elsewhere';
    default:
      return '';
  }
}

export function getReminderTextByNumberOfMinutes(numberOfMinutes?: number) {
  switch (numberOfMinutes) {
    case undefined:
      return "Don't remind me";
    case 0:
      return "At the time of the Event";
    case 5:
      return "5 minutes";
    case 15:
      return "15 minutes";
    case 30:
      return "30 minutes";
    case 60:
      return "1 hour";
    case 120:
      return "2 hours";
    case 720:
      return "12 hours";
    case 1440:
      return "1 day";
    case 10080:
      return "1 week before Event";
    default:
      return `${numberOfMinutes} minutes`;
  }
}

export default function getTemplate(typeId: string) {
  switch (typeId) {
    case LTETemplateTypeIds.RecordableEventTemplateId:
      return "Recordable Event Template";
    case LTETemplateTypeIds.DisbursementTemplateId:
      return "Disbursement Template";
    case LTETemplateTypeIds.IncidentalExpenseTemplateId:
      return "Incidental Expense Template";
    case LTETemplateTypeIds.InvoiceShortTemplateId:
      return "Invoice Short Template";
    case LTETemplateTypeIds.InvoiceDetailedTemplateId:
      return "Invoice Detailed Template";
    case LTETemplateTypeIds.InvoiceReceiptTemplateId:
      return "Invoice Receipt Template";
    case LTETemplateTypeIds.RemittanceAdviceTemplateId:
      return "Remittance Advice Template";
    case HeaderAndFooterTemplateTypeIds.HeaderAndFooterTemplateId:
      return "Header and Footer Template";
    default:
      return "Unknown Template";
  }
}

export function getVatRateTypeStr(type: VatRateTypes | number) {
  switch (type) {
    case VatRateTypes.SalesTaxRate:
      return 'Sales Tax Rate';
    case VatRateTypes.PurchasesTaxRate:
      return 'Purchases Tax Rate';
    default:
      return '';
  }
}

export function getVatRateTypes(): { id: VatRateTypes, name: string }[] {
  const vatRateTypes = getEnumKeys(VatRateTypes).map((type) => {
    return { id: VatRateTypes[type], name: getVatRateTypeStr(VatRateTypes[type])};
  });

  return vatRateTypes;
}

