import { useEffect, useState } from "react";
import TreeSelectForFilter from "components/TreeSelect";
import { CheckedStrategy } from "rc-tree-select/lib/utils/strategyUtil";
import TreeSelect from "rc-tree-select";
import { CustomFloatingFilterProps } from "ag-grid-react";
import { DropDownOptionWithChildrenModel } from "models/view/DropDownOptionWithChildrenModel";

interface Props extends CustomFloatingFilterProps {
  endpointCall: Function,
  showCheckedStrategy?: CheckedStrategy,
  defaultModel?: any
}

export const DropdownFloatingFilter = ({ endpointCall, showCheckedStrategy, model, onModelChange, defaultModel }: Props) => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    if(defaultModel) {
      onModelChange(defaultModel);
    }
    // else {
    //   onModelChange(null);
    // }

    endpointCall().then((response: any) => {
      setData(response.data);
    })
    .catch((error: any) => {
    });
  }, []);

  const onOptionChange = (input: any) => {
    // filter out null values from input
    input = input?.filter((x: any) => x != null);

    if (input?.length === 0) {
      // clear the filter
      onModelChange(null);
      return;
    }

    onModelChange(input.map((x: any) => ({
      id: x,
      name: data.find((values: DropDownOptionWithChildrenModel) => values.id === x)?.name
        ?? flatMapDataChildren(data).find(values => values?.id === x)?.name
    })));
  }

  // Flatten the data to easily access children names
  const flatMapDataChildren = (items: DropDownOptionWithChildrenModel[]): DropDownOptionWithChildrenModel[] => {
    return items.flatMap(item => item.children ? [item, ...flatMapDataChildren(item.children)] : item);
  };

  return (
    <TreeSelectForFilter
      data={data}
      value={model ? model.filter((x: any) => x != null).map((x: any) => x.id) : undefined}
      onChange={onOptionChange}
      showCheckedStrategy={showCheckedStrategy ?? TreeSelect.SHOW_CHILD}
      treeCheckable={true}
    />
  );
}
