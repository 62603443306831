import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { 
  deleteMatterInvoiceAbatement,
  getMatterInvoiceById,
  getMatterParticipatingEntitiesForInvoiceSummary,
  updateMatterInvoiceAbatement
} from "actions/matter";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { DateFormat } from "utils/constants";
import { getDateOnly } from "utils/date";
import moment from "moment";
import Field from "components/Fields/Field";
import { ModalState } from "state/modalSlice";
import { formatCurrency } from "utils/misc";
import useModalActions from "actions/modal";
import { MatterInvoiceModel } from "models/view/MatterInvoiceModel";
import { useAppSelector } from "hooks/appSelector";
import CustomSelect from "components/Select/Select";
import { UpdateAbatementModel } from "models/update/UpdateAbatementModel";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import usePageActions from "actions/page";
import FormSubmitButton from "components/Buttons/FormSubmitButton";

type Props = {
  matterId: string,
  matterInvoiceId: string,
  onSubmitCallback?: Function,
  onDeleteCallback?: Function
}

export default function MatterInvoiceAbatementForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const [invoice, setInvoice] = useState<MatterInvoiceModel>();
  const modalActions = useModalActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const loggedInUser = useAppSelector((state) => state.user);
  const pageActions = usePageActions();

  const {reset, control, register, setValue, watch, handleSubmit, formState: {errors}} = useForm<UpdateAbatementModel>({
    resolver: vestResolver(validationSuite)
  });

  useEffect(() => {
    setIsLoading(true);
    getMatterInvoiceById(props.matterId, props.matterInvoiceId).then((response) => {
      setInvoice(response.data);
      let initialState: UpdateAbatementModel = {
        date: response.data.abatementDate,
        recordableItemsAmount: response.data.abatementRecordableItemsAmount ?? 0,
        incidentalExpensesAmount: response.data.abatementIncidentalExpensesAmount ?? 0,
        disbursementsAmount: response.data.abatementDisbursementsAmount ?? 0,
        description: response.data.abatementDescription,
        maxRecordableItemsAmountPossible: response.data.invoicedTotalRecordableItemsGrossValue,
        maxIncidentalExpensesAmountPossible: response.data.totalIncidentalExpensesGrossValue,
        maxDisbursementsAmountPossible: response.data.totalDisbursementsGrossValue,
        forSingleClient: response.data.abatementForSingleClient,
        matterParticipatingEntityId: response.data.abatementMatterParticipatingEntityId,
        matterInvoiceParticipatingEntities: response.data.matterInvoiceParticipatingEntities
      };

      reset(initialState);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: UpdateAbatementModel) {
    setIsLoading(true);
    if(data) {
      removeEmptyFields(data);
    }
    updateMatterInvoiceAbatement(props.matterId, props.matterInvoiceId, data)
      .then((response) => {
        pageActions.triggerReloadPage();

        slidingPanelActions.clearSlidingPanel();
        reset();

        props.onSubmitCallback && props.onSubmitCallback();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();

    currentSlidingPanelState.onCancel !== undefined && currentSlidingPanelState?.onCancel();
  }

  const deleteModal = () => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Matter Invoice Abatement?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  }

  const deleteCallback = () => {
    modalActions.toggleModalLoading();
    deleteMatterInvoiceAbatement(props.matterId, props.matterInvoiceId)
      .then((response) => {
        pageActions.triggerReloadPage();

        modalActions.toggleModalShownStatus();
        slidingPanelActions.clearSlidingPanel();
        reset();

        props.onDeleteCallback && props.onDeleteCallback();
      })
      .catch((error) => {
        modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        modalActions.toggleModalLoading();
      });
  }

  const onChangeForSingleClient = (value: boolean) => {
    if(value) {
      setValue('matterParticipatingEntityId', undefined);
    }
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      {invoice &&
        <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
          <Row>
            <Col>
              <Field
                label={"Invoiced Total Gross Value"}
                value={formatCurrency(invoice?.invoicedTotalGrossValue)}
              />
            </Col>
          </Row>

          {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) && 
            !invoice.abatementRecordableItemsAmount && !invoice.abatementIncidentalExpensesAmount && !invoice.abatementDisbursementsAmount && 
            <>
              <Row>
                <Form.Group as={Col} sm={6} controlId="date">
                  <Form.Label className="required">Date</Form.Label>
                    <Controller
                      control={control}
                      name="date"
                      shouldUnregister={true}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          className={`${errors?.date?.message ? 'invalid' : ''}`}
                          id="date"
                          dateFormat={DateFormat.Datepicker}
                          selected={value ? getDateOnly(value) : null}
                          onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                          showMonthDropdown
                          showYearDropdown
                          autoComplete="off"
                        />
                      )}
                    />
                    <Form.Text className="lp-error">
                      {errors?.date?.message && (errors.date.message)}
                    </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="recordableItemsAmount">
                  <Form.Label>Recordable Items Amount</Form.Label>
                  <Form.Control
                    type="number"
                    className={`${errors?.recordableItemsAmount?.message ? 'invalid' : ''}`}
                    {...register(`recordableItemsAmount`, {shouldUnregister: true})}
                    min="0.00"
                    step="0.01"
                    max={invoice.invoicedTotalRecordableItemsGrossValue}
                    onWheel={e => e.currentTarget.blur()}
                  />
                  <Form.Text className="lp-error">
                    {errors?.recordableItemsAmount?.message && (errors.recordableItemsAmount?.message)}
                  </Form.Text>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} sm={6} controlId="incidentalExpensesAmount">
                  <Form.Label>Incidental Expenses Amount</Form.Label>
                  <Form.Control
                    type="number"
                    className={`${errors?.incidentalExpensesAmount?.message ? 'invalid' : ''}`}
                    {...register(`incidentalExpensesAmount`, {shouldUnregister: true})}
                    min="0.00"
                    step="0.01"
                    max={invoice.totalIncidentalExpensesGrossValue}
                    onWheel={e => e.currentTarget.blur()}
                  />
                  <Form.Text className="lp-error">
                    {errors?.incidentalExpensesAmount?.message && (errors.incidentalExpensesAmount?.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="disbursementsAmount">
                  <Form.Label>Disbursements Amount</Form.Label>
                  <Form.Control
                    type="number"
                    className={`${errors?.disbursementsAmount?.message ? 'invalid' : ''}`}
                    {...register(`disbursementsAmount`, {shouldUnregister: true})}
                    min="0.00"
                    step="0.01"
                    max={invoice.totalDisbursementsGrossValue}
                    onWheel={e => e.currentTarget.blur()}
                  />
                  <Form.Text className="lp-error">
                    {errors?.disbursementsAmount?.message && (errors.disbursementsAmount?.message)}
                  </Form.Text>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} controlId="description">
                  <Form.Label className="required">Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    className={`${errors?.description?.message ? 'invalid' : ''}`}
                    {...register(`description`, {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.description?.message && (errors.description?.message)}
                  </Form.Text>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} sm={6} controlId="forSingleClient">
                  <Form.Label>For Single Client</Form.Label>
                  <Controller
                    control={control}
                    name="forSingleClient"
                    shouldUnregister={true}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Form.Check type="switch" id="forSingleClient">
                        <Form.Check.Input
                          className="form-check-input"
                          ref={ref}
                          checked={value ?? false}
                          onChange={(ev: any) => {
                            onChange(ev.target.checked);
                            onChangeForSingleClient(ev.target.checked);
                          }}
                        />
                      </Form.Check>
                    )}
                  />
                </Form.Group>

                {watch("forSingleClient") && 
                  <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="matterParticipatingEntityId">
                    <Form.Label className="required">Client</Form.Label>
                    <Controller
                      control={control}
                      name={`matterParticipatingEntityId`}
                      shouldUnregister={true}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <CustomSelect
                          id="matterParticipatingEntityId"
                          inputRef={ref}
                          className={`lp-select${errors?.matterParticipatingEntityId?.message ? ' invalid' : ''}`}
                          endpointCall={() => getMatterParticipatingEntitiesForInvoiceSummary(props.matterId, props.matterInvoiceId)}
                          value={value}
                          onChange={val => {onChange(val?.id ?? null);}}
                        />
                      )}
                    />
                  </Form.Group>
                }
              </Row>
            </>
          }

          {(!loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) ||
            invoice.abatementRecordableItemsAmount || invoice.abatementIncidentalExpensesAmount || invoice.abatementDisbursementsAmount) && 
            <>
              <Row>
                <Col sm={6}>
                  <Field
                    label={"Date"}
                    value={invoice?.abatementDate ? moment(invoice?.abatementDate).format(DateFormat.Moment) : "—"}
                  />
                </Col>
                <Col sm={6} className="mt-4 mt-sm-0">
                  <Field
                    label={"Recordable Items Amount"}
                    value={formatCurrency(invoice?.abatementRecordableItemsAmount)}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Field
                    label={"Incidental Expenses Amount"}
                    value={formatCurrency(invoice?.abatementIncidentalExpensesAmount)}
                  />
                </Col>
                <Col sm={6} className="mt-4 mt-sm-0">
                  <Field
                    label={"Disbursements Amount"}
                    value={formatCurrency(invoice?.abatementDisbursementsAmount)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    label={"Description"}
                    value={invoice?.abatementDescription}
                  />
                </Col>
              </Row>
              {invoice?.abatementForSingleClient &&
                <Row>
                  <Col>
                    <Field
                      label={"Client"}
                      value={invoice?.abatementMatterParticipatingEntity?.name ?? "—"}
                    />
                  </Col>
                </Row>
              }
            </>
          }

          {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterInvoices) && 
            !invoice.matter?.isClosed &&
            <div className="lp-slide-panel-sticky-bottom">
              <Form.Group className="d-flex justify-content-between">
                {!invoice.abatementRecordableItemsAmount && !invoice.abatementIncidentalExpensesAmount && !invoice.abatementDisbursementsAmount && !invoice.hasPayments
                  ? <FormSubmitButton text="Update" errors={{...errors}} />
                  : null
                }
                {(invoice.abatementRecordableItemsAmount || invoice.abatementIncidentalExpensesAmount || invoice.abatementDisbursementsAmount) && !invoice.hasPayments &&
                  <Button variant="danger" onClick={deleteModal}>Delete</Button>
                }
                {!invoice.hasPayments &&
                  <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
                }
              </Form.Group>
            </div>
          }
        </Form>
      }
    </>
  );
}
