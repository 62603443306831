import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { getColumnDefs } from "./columnDefs";
import { Card, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Title from "components/Title/index";
import SlidingPanel from "components/SlidingPanel/index";
import CustomSelect from "components/Select/Select";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { getMatterTaxonomiesSummary, getMatterTaxonomyById, getMatterTaxonomyTerms } from "actions/taxonomy";
import CreateMatterTaxonomyTermForm from "./CreateMatterTaxonomyTerm/CreateMatterTaxonomyTermForm";
import { GridIds } from "enums/GridIds";

type Props = {
  matterId: string,
  matterFileNumber?: string,
  matterDisplayName?: string,
}

export default function MatterTaxonomyTermList(props: Props) {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();
  const [genericErrors, setGenericErrors] = useState(null);
  const [taxonomies, setTaxonomies] = useState<DropDownOptionModel[]>([]);
  const [isLoadingTaxonomies, setIsLoadingTaxonomies] = useState<boolean>(false);
  const [selectedTaxonomyId, setSelectedTaxonomyId] = useState<string>();
  const [hideTaxonomyTermActions, setHideTaxonomyTermActions] = useState<boolean>(false);
  const [asyncColumnDefsLoaded, setAsyncColumnDefsLoaded] = useState<boolean>(false);

  const onGridReady = useCallback(() =>
  {
    if(!selectedTaxonomyId) {
      const columnDefs = getColumnDefs(
        props.matterId,
        selectedTaxonomyId ?? '',
        false,
        undefined,
        () => reloadTaxonomyTerms(selectedTaxonomyId)
      );
      gridActions.setGridColumnDefs([...columnDefs]);
      gridActions.setGridRowData([]);
      setHideTaxonomyTermActions(false);
    }
    else {
      const promises: Promise<any>[] = [];
      promises.push(getMatterTaxonomyById(props.matterId, selectedTaxonomyId));
      promises.push(getMatterTaxonomyTerms(props.matterId, selectedTaxonomyId));

      Promise.all(promises).then(([taxonomyResponse, taxonomyTermsResponse]) => {
        const columnDefs = getColumnDefs(
          props.matterId,
          selectedTaxonomyId!, 
          taxonomyResponse.data.isHierarchical,
          taxonomyResponse.data.matterId, 
          () => reloadTaxonomyTerms(selectedTaxonomyId)
        );
        setHideTaxonomyTermActions(taxonomyResponse.data.matterId != props.matterId);
        gridActions.setGridColumnDefs([...columnDefs]);
        gridActions.setGridRowData(taxonomyTermsResponse.data as any);
        setAsyncColumnDefsLoaded(true);
      }).catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      });
    }
  }, [selectedTaxonomyId]); 

  useEffect(() => {
    setIsLoadingTaxonomies(true);
    getMatterTaxonomiesSummary(props.matterId, true).then((response) => {
      setTaxonomies(response.data);
      if(response.data[0]) {
        setSelectedTaxonomyId(response.data[0].id);
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingTaxonomies(false);
    });
  }, []);

  useEffect(() => {
    gridActions.setGridId(GridIds.MatterTaxonomyTerms);
  }, []);

  const toggleSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Add Taxonomy Term",
        children: <CreateMatterTaxonomyTermForm
          matterId={props.matterId}
          taxonomyId={selectedTaxonomyId!}
          onSubmitCallback={() => reloadTaxonomyTerms(selectedTaxonomyId)}
        />
      });
  }

  const onTaxonomyChange = (value: string) => {
    setSelectedTaxonomyId(value);
  }

  const reloadTaxonomyTerms = (taxonomyId?: string) => {
    if(taxonomyId) {
      getMatterTaxonomyTerms(props.matterId, taxonomyId)
        .then((response) => 
        {
          gridActions.setGridRowData(response.data);
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
          gridActions.setGridRowData([]);
        });
    }
    else {
      gridActions.setGridRowData([]);
    }
  }

  return (
    <>
      <Title type="section" title={"Taxonomy Terms"}>
        {selectedTaxonomyId && !hideTaxonomyTermActions && 
          <Button onClick={toggleSlidingPanel} className="btn-icon" variant="success">
            <MdAdd />
          </Button>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card className="with-grid">
        <Card.Body>
          <div className="lp-client-ledger-select">
            <div className="lp-label">
              Taxonomy:
            </div>
            <CustomSelect
              id="taxonomyId"
              options={taxonomies}
              isLoading={isLoadingTaxonomies}
              onChange={val => onTaxonomyChange(val?.id ?? null)}
              value={selectedTaxonomyId}
            />
          </div>

          <Grid 
            onGridReady={onGridReady}
            asyncColumnDefsLoaded={asyncColumnDefsLoaded}
            exportTitle={`Matter: ${props.matterFileNumber} (${props.matterDisplayName}) - Taxonomy Terms`}
          />
        </Card.Body>
      </Card>

      <SlidingPanel />
    </>
  );
}
