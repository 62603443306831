import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Row, Col, Form, Button } from "react-bootstrap";
import { getLtePhoneNumberById, getPreferredLtePhoneNumber, updateLtePhoneNumber } from "actions/lte";
import useSlidingPanelActions from "actions/slidingPanel";
import CustomSelect from "components/Select/Select";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { CreateOrUpdateLtePhoneNumberModel } from "models/create/CreateOrUpdateLtePhoneNumberModel";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import useModalActions from "actions/modal";
import { ModalState } from "state/modalSlice";
import { removeEmptyFields } from "utils/form";
import { checkIfPhoneNumberIsLinked, getPhoneTypes } from "actions/phoneNumber";
import { LinkedPhoneNumberModel } from "models/view/LinkedPhoneNumberModel";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { MdWarning } from 'react-icons/md';
import FormSubmitButton from "components/Buttons/FormSubmitButton";

type Props = {
  lteId: string,
  phoneNumberId?: string,
  submitCallback?: Function
}

export default function EditLtePhoneNumberForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const modalActions = useModalActions();
  const slidingPanelActions = useSlidingPanelActions();
  const loggedInUser = useAppSelector((state) => state.user);

  useEffect(() => {
    if(props.phoneNumberId)
    {
      setIsLoading(true);
      getLtePhoneNumberById(props?.lteId, props.phoneNumberId)
        .then( (ltePhoneNumberResponse) => {
          const initialState = {
            phoneNumberId: ltePhoneNumberResponse.data.phoneNumberId,
            phoneNumber: ltePhoneNumberResponse.data.phoneNumber.number,
            lawPageTradingEntityId: ltePhoneNumberResponse.data.lawPageTradingEntityId,
            isPreferred: ltePhoneNumberResponse.data.isPreferred,
            systemOwned: ltePhoneNumberResponse.data.phoneNumber.systemOwned,
            extension: ltePhoneNumberResponse.data.phoneNumber.extension,
            phoneTypeId: ltePhoneNumberResponse.data.phoneNumber.phoneType?.id ?? ''
          }
          reset(initialState);
        }
      )
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
  }, []);

  const {register, reset,control,  handleSubmit, formState: {errors}} = useForm<CreateOrUpdateLtePhoneNumberModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: CreateOrUpdateLtePhoneNumberModel) {
    setIsLoading(true);
    data.lawPageTradingEntityId = props.lteId;
    data.phoneNumberId = props.phoneNumberId ?? '';
    checkIfPhoneNumberIsLinked(data.phoneNumberId!, props?.lteId)
      .then((response) => {
        const filteredResponse = response.data.filter((p: any) => p.phoneNumberId !== data.phoneNumberId || p.entityId !== props?.lteId);
        if(filteredResponse.length > 0) {
          const linkedEntites = filteredResponse.map((x: LinkedPhoneNumberModel) =>
            x.lawPageTradingEntity ? x.lawPageTradingEntity.legalName + ' (trading entity)': (x.entity ? x.entity.displayName + ' (entity)' : undefined) );
          let modal: ModalState = {
            title: "Update confirmation",
            body:
              <>
                <p>This phone number is also linked to following entities:</p>
                <ul>
                  {linkedEntites.filter((x: any) => x !== undefined).map((entityName: any) => {
                    return <li>{entityName}</li>;
                  })}
                </ul>
                <div>Do you want to update the number only for this entity or for all entities?</div>
              </>,
            actionText: "For all entities",
            onAction: () => updatePhoneNumberWithModal(data, false),
            secondActionText: "Just for this entity",
            onSecondAction: () => updatePhoneNumberWithModal(data, true),
            show: true
          }
          modalActions.setModal(modal);
        }
        else {
          updatePhoneNumberWithModal(data, false);
        }
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const updatePhoneNumberWithModal = (data: CreateOrUpdateLtePhoneNumberModel, onlyThisEntity: boolean = false) => {
    if(props.phoneNumberId){
      setIsLoading(true);
      data.shouldUpdateAllEntities = onlyThisEntity;

      if(data.isPreferred == true) {
        getPreferredLtePhoneNumber(props.lteId).then((response) => {
          let bodyMessage: React.ReactNode = <></>;
          if(response.data?.phoneNumberId && response.data?.phoneNumberId != props.phoneNumberId) {
            bodyMessage = <div className="lp-modal-warning">
              <MdWarning />This Phone Number is set as preferred which means that he will replace the current one.<br/>
              Are you sure you want to do that? If not, uncheck the Is Preferred checkbox.
            </div>;
          }
          else {
            updatePhoneNumber(data, false);
            return;
          }
          
          let modal: ModalState = {
            title: "Update confirmation",
            body: bodyMessage,
            actionText: "Update",
            onAction: () => updatePhoneNumber(data),
            show: false,
            onClose: () => setIsLoading(false)
          }
          modalActions.setModal(modal);
          modalActions.toggleModalShownStatus();
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
          setIsLoading(false);
        });
      }
      else {
        updatePhoneNumber(data, false);
      }
    }
  }

  function updatePhoneNumber(data: CreateOrUpdateLtePhoneNumberModel, fromModal: boolean = true) {
    if(fromModal)
    {
      modalActions.toggleModalLoading();
    }

    removeEmptyFields(data);
    updateLtePhoneNumber(props.phoneNumberId!, props?.lteId, data).then(() => {
      if(props.submitCallback) {
        props.submitCallback();
      }
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error: any) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
      if(fromModal)
      {
        modalActions.toggleModalLoading();
        modalActions.toggleModalShownStatus();
      }
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} controlId="phoneNumber">
            <Form.Label className="required">Phone Number</Form.Label>
            <Controller
              control={control}
              name="phoneNumber"
              shouldUnregister={true}
              render={({field: { onChange, value }}) => (
                <PhoneInput
                  containerClass={`lp-phone-input${errors?.phoneNumber?.message ? ' invalid' : ''}`}
                  autoFormat={false}
                  inputProps={{ name: 'phoneNumber'}}
                  country={'gb'}
                  placeholder="Phone Number"
                  value={value}
                  onChange={val => onChange('+' + val)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.phoneNumber?.message && (errors.phoneNumber.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="phoneTypeId">
            <Form.Label className="required">Phone Type</Form.Label>
            <Controller
              control={control}
              name="phoneTypeId"
              shouldUnregister={true}
              render={({field: { onChange, value, ref }}) => (
                <CustomSelect
                  id="phoneTypeId"
                  inputRef={ref}
                  className={`lp-select${errors?.phoneTypeId?.message ? ' invalid' : ''}`}
                  endpointCall={getPhoneTypes}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.phoneTypeId?.message && (errors.phoneTypeId.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="extension">
            <Form.Label>Extension</Form.Label>
            <Form.Control
              type="text"
              {...register("extension", {shouldUnregister: true})}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="isPreferred">
            <Form.Label>Is Preferred</Form.Label>
            <Controller
              control={control}
              name="isPreferred"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="isPreferred">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>

          {loggedInUser.userPermissions?.find(a => a == UserPermissionsNames.ManageSystemOwnedContacts) &&
            <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="systemOwned">
              <Form.Label>System Owned</Form.Label>
              <Controller
                control={control}
                name="systemOwned"
                shouldUnregister={true}
                render={({field: { onChange, value, name, ref }}) => (
                  <Form.Check 
                    type="switch"
                    id="systemOwned">
                      <Form.Check.Input
                        className= "form-check-input"
                        ref={ref}
                        checked={value ?? false}
                        onChange={(ev: any) => onChange(ev.target.checked)}
                      />
                  </Form.Check>
                )}
              />
            </Form.Group>
          }
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <FormSubmitButton text="Update" errors={{...errors}} />
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
