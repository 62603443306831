import SlidingPanel from "components/SlidingPanel/index";
import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useEffect, useRef, useState } from "react";
import { getColumnDefs, viewTransactionCallback } from "./columnDefs";
import { Row, Col, Card } from "react-bootstrap";
import SummaryBox from 'components/SummaryBox';
import { SummaryBoxTypes } from "enums/SummaryBoxTypes";
import {
  getMatterClientLedgerBalanceSummaries,
  getMatterClientLedgerEntries,
  getMatterClientLedgerSummaryInfo,
  getMatterClientsSummary,
  getMatterDefaultClient
} from "actions/matter";
import Title from "components/Title/index";
import Loader from "components/Loader/index";
import CustomSelect from "components/Select/Select";
import "./style.scss";
import SummaryBoxesSection from "components/SummaryBoxesSection";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { MatterClientLedgerSummaryInfoModel } from "models/view/MatterClientLedgerSummaryInfoModel";
import { useAppSelector } from "hooks/appSelector";
import { MatterClientLedgerBalanceSummaryModel } from "models/view/MatterClientLedgerBalanceSummaryModel";
import { formatCurrency } from "utils/misc";
import { MatterInvoiceParticipatingEntitySummaryInfoModel } from "models/view/MatterInvoiceParticipatingEntitySummaryInfoModel";
import React from "react";
import { isMobile } from 'react-device-detect';
import { AgGridReact } from "ag-grid-react";
import { GridIds } from "enums/GridIds";
import { SummaryIds } from "enums/SummaryIds";
import usePageActions from "actions/page";

type Props = {
  matterId: string,
  matterIsClosed: boolean,
  matterFileNumber: string,
  matterDisplayName: string,
}

export default function MatterClientLedger(props: Props) {
  const gridActions = useGridActions();
  const pageActions = usePageActions();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const [isSummaryInfoLoading, setIsSummaryInfoLoading] = useState<boolean>(false);
  const [genericErrorsForSummaryInfo, setGenericErrorsForSummaryInfo] = useState(undefined);
  const [selectedClient, setSelectedClient] = useState<string>()
  const [selectedClientName, setSelectedClientName] = useState<string>()
  const [clients, setClients] = useState<DropDownOptionModel[]>([]);
  const [isLoadingClients, setIsLoadingClients] = useState<boolean>(false);
  const [matterClientLedgerSummaryInfo, setMatterClientLedgerSummaryInfo] = useState<MatterClientLedgerSummaryInfoModel | undefined>(undefined);
  const [clientLedgerBalanceSummaries, setClientLedgerBalanceSummaries] = useState<MatterClientLedgerBalanceSummaryModel[]>([]);
  const loggedInUser = useAppSelector((state) => state.user);
  const gridRef = useRef<AgGridReact>(null);

  const onGridReady = useCallback(() =>
  {
    const columnDefs = getColumnDefs(props.matterIsClosed, !loggedInUser.lteHasClientBankAccounts);
    gridActions.setGridColumnDefs([...columnDefs]);
    gridActions.setGridRowData([]);
  }, [props.matterId, props.matterIsClosed]);

  useEffect(() => {
    gridActions.setGridId(GridIds.MatterClientLedger);

    return () => {
      pageActions.resetPageData();
    }
  }, []);

  useEffect(() => {
    if(selectedClient && selectedClientName) {
      pageActions.setReloadGridData(() => {
        onMatterParticipatingEntityChange(selectedClient, selectedClientName);
      });
    }
  }, [selectedClient, selectedClientName, props.matterId]);

  useEffect(() => {
    setIsLoading(true);
    setIsLoadingClients(true);

    Promise.all([
      getMatterDefaultClient(props.matterId),
      getMatterClientsSummary(props.matterId)
    ]).then(([defaultClientResponse, clientsResponse]) => {
      if(defaultClientResponse.data.id) {
        const defaultClientName = clientsResponse.data.find((x: any) => x.id === defaultClientResponse.data.id)?.name;
        onMatterParticipatingEntityChange(defaultClientResponse.data.id, defaultClientName);
      }
      else {
        gridActions.setGridRowData([]);
        setIsLoading(false);
      }

      setClients(clientsResponse.data);
      if(clientsResponse.data.length > 1) {
        getMatterClientLedgerBalanceSummaries(props.matterId).then((response) => {
          setClientLedgerBalanceSummaries(response.data);
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
        });
      }
      else {
        setClientLedgerBalanceSummaries([]);
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      setIsLoading(false);
    })
    .finally(() => {
      setIsLoadingClients(false);
    });
  }, [props.matterId]);

  const onMatterParticipatingEntityChange = (value: string, name?: string) => {
    setSelectedClient(value);
    setSelectedClientName(name);

    // Update additional export info
    gridActions.setGridAdditionalFilterExportInfo([`Client: ${name}`]);

    setIsLoading(true);
    getMatterClientLedgerEntries(props.matterId, value)
      .then((response) => {
        gridActions.setGridRowData(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
        gridActions.setGridRowData([]);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsSummaryInfoLoading(true);
    getMatterClientLedgerSummaryInfo(props.matterId, value)
      .then((response) => {
        setMatterClientLedgerSummaryInfo(response.data);
      })
      .catch((error) => {
        setGenericErrorsForSummaryInfo(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsSummaryInfoLoading(false);
      });
  }

  return (
    <>
      {isSummaryInfoLoading &&
        <div className="lp-summary-boxes-placeholder">
          <Loader inlineLoader />
        </div>
      }

      {genericErrorsForSummaryInfo && (
        <div className="lp-errors">
          {genericErrorsForSummaryInfo}
        </div>
      )}

      {matterClientLedgerSummaryInfo && 
        <Row>
          <Col>
            <SummaryBoxesSection id={SummaryIds.MatterClientLedgerSummary}>
              <SummaryBox type={SummaryBoxTypes.BalanceOnBusinessSide} values={[{value: matterClientLedgerSummaryInfo.businessSideBalance ?? 0}]}/>
              {loggedInUser.lteHasClientBankAccounts && 
                <SummaryBox type={SummaryBoxTypes.BalanceOnClientSide} values={[{value: matterClientLedgerSummaryInfo.clientSideBalance ?? 0}]}/>
              }
              <SummaryBox type={SummaryBoxTypes.UnbilledDisbursements} values={[{value: matterClientLedgerSummaryInfo.totalUnbilledDisbursements ?? 0}]}/>
              <SummaryBox type={SummaryBoxTypes.UnpaidDisbursements} values={[{value: matterClientLedgerSummaryInfo.totalUnpaidDisbursements ?? 0}]}/>
              <SummaryBox type={SummaryBoxTypes.UnbilledIncidentalExpenses} values={[{value: matterClientLedgerSummaryInfo.totalUnbilledIncidentalExpenses ?? 0}]}/>
              <SummaryBox
                type={SummaryBoxTypes.InvoicesDoughnut}
                values={[
                  {
                    value: matterClientLedgerSummaryInfo.totalPaidInvoices ?? 0,
                    label: "Paid",
                    className: "lp-primary"
                  },
                  {
                    value: matterClientLedgerSummaryInfo.totalUnpaidInvoices ?? 0,
                    label: "Unpaid",
                    className: "lp-warning"
                  },
                  ...matterClientLedgerSummaryInfo.totalWriteOffInvoices ? [{
                    value: matterClientLedgerSummaryInfo.totalWriteOffInvoices ?? 0,
                    label: "Write-Off",
                    className: "lp-danger"
                  }] : []
                ]}
              />

              {matterClientLedgerSummaryInfo?.invoiceParticipatingEntitySummaryInfos &&
                matterClientLedgerSummaryInfo.invoiceParticipatingEntitySummaryInfos.map((x: MatterInvoiceParticipatingEntitySummaryInfoModel, index: number) => (
                  <React.Fragment key={index}>
                    <SummaryBox
                      type={SummaryBoxTypes.InvoiceParticipatingEntityDoughnut}
                      title={x.matterInvoiceDisplayName + ' - ' + x.matterParticipatingEntity?.name + ' - ' + x.matterInvoiceStatus?.name}
                      values={[
                        {
                          value: x.totalRecordableItems ?? 0,
                          label: isMobile ? "Rec" : "Recordable Items",
                          className: "lp-primary"
                        },
                        {
                          value: x.totalDisbursements ?? 0,
                          label: isMobile ? "Dis" : "Disbursements",
                          className: "lp-primary-400"
                        },
                        {
                          value: x.totalIncidentalExpenses ?? 0,
                          label: isMobile ? "Exp" : "Incidental Expenses",
                          className: "lp-primary-700"
                        }
                      ]}
                    />
                  </React.Fragment>
                ))
              }
            </SummaryBoxesSection>
          </Col>
        </Row>
      }

      <Row>
        <Col>
          <Title
            type="section"
            title={
              clientLedgerBalanceSummaries.length > 1 ?
                <span>Client Ledger: {clientLedgerBalanceSummaries
                  .map(x => x.matterParticipatingEntity?.name + ' [' + formatCurrency(x.businessSideBalance) + '|' + formatCurrency(x.clientSideBalance) + "]")
                  .join(', ')}
                </span> :
                <span>Client Ledger</span> 
            }
          >
          </Title>

          {genericErrors && (
            <div className="lp-errors">
              {genericErrors}
            </div>
          )}

          <Card className="with-grid">
            {isLoading && <Loader inlineLoader />}

            <Card.Body>
              <div className="lp-client-ledger-select">
                <div className="lp-label">
                  Client:
                </div>
                <CustomSelect
                  id="matterParticipatingEntityId"
                  options={clients}
                  isLoading={isLoadingClients}
                  onChange={val => onMatterParticipatingEntityChange(val?.id ?? null, val?.name ?? null)}
                  value={selectedClient}
                />
              </div>
              <Grid 
                ref={gridRef}
                onGridReady={onGridReady}
                getRowId={params => params.data?.accountLedgerTransactionEntryId}
                exportTitle={`Matter: ${props.matterFileNumber} (${props.matterDisplayName}) - Client Ledger`}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <SlidingPanel gridRef={gridRef} onNavigation={(item: any) => viewTransactionCallback(item, props.matterIsClosed)} />
    </>
  );
}
