import { useCurrentEditor } from '@tiptap/react';
import {
  MdFormatBold,
  MdFormatClear,
  MdFormatItalic,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatUnderlined,
  MdRedo,
  MdUndo,
  MdStrikethroughS,
} from 'react-icons/md';
import { TextEditorTypes } from 'enums/TextEditorTypes';
import { TextEditorParagraphTypes } from 'enums/TextEditorParagraphTypes';
import ColorPicker from './ColorPicker';
import Link from './Link';
import './style.scss';

type Props = {
  showHTMLText: boolean,
  setShowHTMLText: Function,
};

const MenuBar = (props: Props) => {
  const { editor } = useCurrentEditor();

  if (!editor) {
    return null;
  }

  const handleParagraphChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    switch (e.target.value) {
      case TextEditorParagraphTypes.Heading1:
        editor.chain().focus().toggleHeading({ level: 1 }).run();
        break;
      case TextEditorParagraphTypes.Heading2:
        editor.chain().focus().toggleHeading({ level: 2 }).run();
        break;
      case TextEditorParagraphTypes.Heading3:
        editor.chain().focus().toggleHeading({ level: 3 }).run();
        break;
      case TextEditorParagraphTypes.Paragraph:
        editor.chain().focus().setParagraph().run();
        break;
    }
  }

  const getParagraphValue = () => {
    if(editor.isActive('heading', { level: 1 })) {
      return TextEditorParagraphTypes.Heading1;
    }
    else if(editor.isActive('heading', { level: 2 })) {
      return TextEditorParagraphTypes.Heading2;
    }
    else if(editor.isActive('heading', { level: 3 })) {
      return TextEditorParagraphTypes.Heading3;
    }
    else {
      return TextEditorParagraphTypes.Paragraph;
    }
  }

  const handleTextColorChange = (color: string) => {
    editor.chain().focus().setColor(color).run();
  }

  const handleApplyLink = (text: string, url: string) => {
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).insertContent(text).toggleMark('link').run();
  }

  const handleUnsetLinkMark = () => {
    editor.chain().focus().toggleMark('link').run();
  }

  const handleShowHTMLTextChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    props.setShowHTMLText(e.target.value === 'HTML');
  }

  return (
    <div className="tiptap-control-group">
      <div className="button-group">
        <select onChange={handleShowHTMLTextChange} value={props.showHTMLText ? TextEditorTypes.HTML : TextEditorTypes.TipTap}>
          <option value={TextEditorTypes.TipTap}>Text Editor</option>
          <option value={TextEditorTypes.HTML}>HTML</option>
        </select>

        <button
          onClick={(e) => {e.preventDefault(); editor.chain().focus().toggleBold().run()}}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={`menu-button ${editor.isActive('bold') ? 'is-active' : ''}`}
        >
          <MdFormatBold />
        </button>
        <button
          onClick={(e) => {e.preventDefault(); editor.chain().focus().toggleItalic().run()}}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={`menu-button ${editor.isActive('italic') ? 'is-active' : ''}`}
        >
          <MdFormatItalic />
        </button>
        <button
          onClick={(e) => {e.preventDefault(); editor.chain().focus().toggleUnderline().run()}}
          disabled={!editor.can().chain().focus().toggleUnderline().run()}
          className={`menu-button ${editor.isActive('underline') ? 'is-active' : ''}`}
        >
          <MdFormatUnderlined />
        </button>
        <button
          onClick={(e) => {e.preventDefault(); editor.chain().focus().toggleStrike().run()}}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={`menu-button ${editor.isActive('strike') ? 'is-active' : ''}`}
        >
          <MdStrikethroughS />
        </button>
        <button
          onClick={(e) => {e.preventDefault(); editor.chain().focus().unsetAllMarks().run()}}
          className='menu-button'
        >
          <MdFormatClear />
        </button>

        <div className="button-set">
          <select onChange={handleParagraphChange} value={getParagraphValue()}>
            <option value={TextEditorParagraphTypes.Heading1} className="heading1">Heading 1</option>
            <option value={TextEditorParagraphTypes.Heading2} className="heading2">Heading 2</option>
            <option value={TextEditorParagraphTypes.Heading3} className="heading3">Heading 3</option>
            <option value={TextEditorParagraphTypes.Paragraph} className="paragraph">Paragraph</option>
          </select>
          <ColorPicker onColorChange={handleTextColorChange} />
        </div>

        <div className="button-set">
          <button
            onClick={(e) => {e.preventDefault(); editor.chain().focus().toggleBulletList().run()}}
            className={`menu-button ${editor.isActive('bulletList') ? 'is-active' : ''}`}
          >
            <MdFormatListBulleted />
          </button>
          <button
            onClick={(e) => {e.preventDefault(); editor.chain().focus().toggleOrderedList().run()}}
            className={`menu-button ${editor.isActive('orderedList') ? 'is-active' : ''}`}
          >
            <MdFormatListNumbered />
          </button>
        </div>

        <div className="button-set">
          <Link applyLink={handleApplyLink} unsetLinkMark={handleUnsetLinkMark} />
          <button
            onClick={(e) => {e.preventDefault(); editor.chain().focus().undo().run()}}
            disabled={!editor.can().chain().focus().undo().run()}
            className='menu-button'
          >
            <MdUndo />
          </button>
          <button
            onClick={(e) => {e.preventDefault(); editor.chain().focus().redo().run()}}
            disabled={!editor.can().chain().focus().redo().run()}
            className='menu-button'
          >
            <MdRedo />
          </button>
        </div>
      </div>
    </div>
  )
}

export default MenuBar;