import { Controller, useForm } from "react-hook-form";
import React, { useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button, Row, Col } from 'react-bootstrap';
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { getEntityDocumentExchanges, linkEntityDocumentExchange } from "actions/entity";
import CustomSelect from "components/Select/Select";
import { removeEmptyFields } from "utils/form";
import { LinkEntityDocumentExchangeModel } from "models/create/LinkEntityDocumentExchangeModel";
import { EntityDocumentExchangeModel } from "models/view/EntityDocumentExchangeModel";
import FormSubmitButton from "components/Buttons/FormSubmitButton";

type Props = {
  toEntityId: string,
  fromEntityId: string,
  submitCallback?: Function
}

export default function LinkEntityDocumentExchangeForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();

  const {reset, control, handleSubmit, formState: {errors}} = useForm<LinkEntityDocumentExchangeModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: LinkEntityDocumentExchangeModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    linkEntityDocumentExchange(props.toEntityId, data).then((response) => {
      slidingPanelActions.clearSlidingPanel();
      if(props.submitCallback) {
        props.submitCallback(response.data);
      }
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }
      
  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}
      
      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} controlId="entityDocumentExchangeIdToCopy">
            <Form.Label className="required">Document Exchange</Form.Label>
            <Controller
              control={control}
              name="entityDocumentExchangeIdToCopy"
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="entityDocumentExchangeIdToCopy"
                  inputRef={ref}
                  className={`lp-select${errors?.entityDocumentExchangeIdToCopy?.message ? ' invalid' : ''}`}
                  getOptionLabel={(x: EntityDocumentExchangeModel) => x.dxExchange + ' (Number: ' + x.dxNumber + ')'}
                  getOptionValue={(x: any) => x.id}
                  endpointCall={() => getEntityDocumentExchanges(props.fromEntityId)}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.entityDocumentExchangeIdToCopy?.message && (errors.entityDocumentExchangeIdToCopy.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <FormSubmitButton text="Create" errors={{...errors}} />
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
