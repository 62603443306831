import { create, test, enforce } from "vest";
import isDate from "validator/lib/isDate";

export const validationSuite = create((data = {}) => {
  enforce.extend({ isDate });

  test('matterParticipatingEntityIds', 'Please select a Client', () => {
    enforce(data.matterParticipatingEntityIds.length).greaterThan(0);
  });

  test('invoicingPartyId', 'Please select an Invoicing Party', () => {
    enforce(data.invoicingPartyId).isNotEmpty();
    enforce(data.invoicingPartyId).isString();
  });

  test('date', 'Please select a Date', () => {
    enforce(data.date).isDate({ strictMode: false });
  });

  test('description', 'Description is required', () => {
    enforce(data.description).isNotEmpty();
    enforce(data.description).isString();
  });

  test('matterInvoiceStatusId', 'Please select a Status', () => {
    enforce(data.matterInvoiceStatusId).isNotEmpty();
    enforce(data.matterInvoiceStatusId).isString();
  });

  test('recordableItemsVatRateId', 'Please select a Recordable Items VAT Rate', () => {
    enforce(data.recordableItemsVatRateId).isNotEmpty();
    enforce(data.recordableItemsVatRateId).isString();
  });

  test('incidentalExpensesVatRateId', 'Please select a Incidental Expenses VAT Rate', () => {
    enforce(data.incidentalExpensesVatRateId).isNotEmpty();
    enforce(data.incidentalExpensesVatRateId).isString();
  });

  test('invoiceNetValue', `Net Value must be at least equal to ${data.totalNetValueWithoutRecordableItems}.`, () => {
    enforce(data.invoiceNetValue).greaterThanOrEquals(data.totalNetValueWithoutRecordableItems);
  });

  /*omitWhen(!data.filterStartDate || !data.filterEndDate, () => {
    test('filterEndDate', 'End Date needs to be higher than Start Date.', () => {
      const calculatedMinutesDif = data.filterEndDate.getTime() - data.filterStartDate.getTime();
      enforce(calculatedMinutesDif.toString()).isInt().greaterThan(0);
    });
  });*/
});