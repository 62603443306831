import SlidingPanel from "components/SlidingPanel/index";
import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useRef, useEffect, useState } from "react";
import { getColumnDefs, viewCallback } from "./columnDefs";
import { Row, Col, Card } from "react-bootstrap";
import SummaryBox from 'components/SummaryBox';
import { SummaryBoxTypes } from "enums/SummaryBoxTypes";
import {
  getLteRecordableItemsSummaryInfo,
  getMatterRecordableItemsForLte
} from "actions/matter";
import Title from "components/Title/index";
import { GridOptionButtonDataModel } from "models/view/GridOptionButtonDataModel";
import Loader from "components/Loader/index";
import useSlidingPanelActions from 'actions/slidingPanel';
import { useAppSelector } from "hooks/appSelector";
import SummaryBoxesSection from "components/SummaryBoxesSection";
import { GridFilterTypes } from "enums/GridFilterTypes";
import { getDateOnly } from "utils/date";
import { GridFilterButtonDataModel } from "models/view/GridFilterButtonDataModel";
import store from "state/store";
import { MatterRecordableItemsSummaryInfoModel } from "models/view/MatterRecordableItemsSummaryInfoModel";
import AdvancedGridFilterForm from "components/Grid/GridFilters/AdvancedGridFilter/AdvancedGridFilterForm";
import { AdvancedGridFilterModel } from "models/view/AdvancedGridFilterModel";
import { GridAdvancedFilterDateOptions } from "enums/GridAdvancedFilterDateOptions";
import { UserRoleNames } from "enums/UserRoleNames";
import { AgGridReact } from "ag-grid-react";
import { GridIds } from "enums/GridIds";
import { SummaryIds } from "enums/SummaryIds";
import usePageActions from "actions/page";
import { useParams } from "react-router-dom";
import { getAdvancedFilterOnInitialLoad } from "utils/misc";

export default function LteRecordableItemList() {
  const gridActions = useGridActions();
  const pageActions = usePageActions();
  const [summaryInfo, setSummaryInfo] = useState<MatterRecordableItemsSummaryInfoModel | undefined>(undefined);
  const [isSummaryInfoLoading, setIsSummaryInfoLoading] = useState<boolean>(false);
  const [genericErrorsForSummaryInfo, setGenericErrorsForSummaryInfo] = useState(undefined);
  const [genericErrors, setGenericErrors] = useState(null);
  const gridRef = useRef<AgGridReact>(null);

  const { tab } = useParams();

  const slidingPanelActions = useSlidingPanelActions();
  const loggedInUser = useAppSelector((state) => state.user);

  const reloadGridData = () => {
    const gridState = store.getState().grid;
    gridAdvancedFilterCallback(gridState.advancedFilter);
  };

  const onGridReady = useCallback(() =>
  {
    const columnDefs = getColumnDefs();
    gridActions.setGridColumnDefs([...columnDefs]);
    gridActions.setGridAdvancedFilterClearCallback(gridAdvancedFilterClearCallback);

    const initialAdvancedFilter: AdvancedGridFilterModel = {
      feeEarners: (loggedInUser.userId && loggedInUser.userRole?.name != UserRoleNames.Lawyer)
        ? [{id: loggedInUser.userId, name: loggedInUser.displayName ?? ''}]
        : []
    };
    const storedFilter = getAdvancedFilterOnInitialLoad(GridIds.LteRecordableItems, tab ?? '');
    gridAdvancedFilterCallback(storedFilter ?? initialAdvancedFilter);
  }, []);

  useEffect(() => {
    gridActions.setGridId(GridIds.LteRecordableItems);
    pageActions.setReloadGridData(reloadGridData);

    return () => {
      pageActions.resetPageData();
    }
  }, []);

  const populateLteRecordableItems = (advancedFilter?: AdvancedGridFilterModel) => {
    const filterFeeEarnerIds = advancedFilter?.feeEarners?.map(x => x.id);
    const filterStartDate = advancedFilter?.startDate ? getDateOnly(advancedFilter?.startDate) : undefined;
    const filterEndDate = advancedFilter?.endDate ? getDateOnly(advancedFilter?.endDate) : undefined;
    
    getMatterRecordableItemsForLte(filterFeeEarnerIds, filterStartDate, filterEndDate).then((response) => {
      gridActions.setGridRowData(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      gridActions.setGridRowData([]);
    });
  };

  const loadLteRecordableItemsSummaryInfo = (advancedFilter?: AdvancedGridFilterModel) => {
    setIsSummaryInfoLoading(true);
    const filterFeeEarnerIds = advancedFilter?.feeEarners?.map(x => x.id);
    const filterStartDate = advancedFilter?.startDate ? getDateOnly(advancedFilter?.startDate) : undefined;
    const filterEndDate = advancedFilter?.endDate ? getDateOnly(advancedFilter?.endDate) : undefined;

    getLteRecordableItemsSummaryInfo(filterFeeEarnerIds, filterStartDate, filterEndDate).then((response) => {
      setSummaryInfo(response.data);
    })
    .catch((error) => {
      setGenericErrorsForSummaryInfo(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsSummaryInfoLoading(false);
    });
  }

  const gridOptions: GridOptionButtonDataModel[] = [
  ];

  const gridAdvancedFilterOnClickCallback = () => {
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      title: "Recordable Items Advanced Filter",
      children: <AdvancedGridFilterForm
        filterCallback={gridAdvancedFilterCallback}
        showDateFilter
        showFeeEarnersFilter={loggedInUser.userRole?.name != UserRoleNames.Lawyer}
        showDateOptionsFilter
        dateOptionsFilterValues={[
          GridAdvancedFilterDateOptions.Today,
          GridAdvancedFilterDateOptions.CurrentWeek,
          GridAdvancedFilterDateOptions.PreviousWeek,
          GridAdvancedFilterDateOptions.CurrentMonth,
          GridAdvancedFilterDateOptions.PreviousMonth,
          GridAdvancedFilterDateOptions.CurrentPrevious3Months,
          GridAdvancedFilterDateOptions.CurrentPrevious6Months,
          GridAdvancedFilterDateOptions.CurrentFinancialYear,
          GridAdvancedFilterDateOptions.PreviousFinancialYear
        ]}
      />
    });
  };

  const gridFilters: GridFilterButtonDataModel[] = [
    {
      type: GridFilterTypes.AdvancedFilters,
      callback: gridAdvancedFilterOnClickCallback
    }
  ];

  const gridAdvancedFilterCallback = (advancedFilter?: AdvancedGridFilterModel) => {
    gridActions.setGridAdvancedFilter(advancedFilter, GridIds.LteRecordableItems);

    populateLteRecordableItems(advancedFilter);
    loadLteRecordableItemsSummaryInfo(advancedFilter);
  };

  const gridAdvancedFilterClearCallback = () => {
    gridAdvancedFilterCallback(undefined);
  };

  return (
    <>
      {isSummaryInfoLoading &&
        <div className="lp-summary-boxes-placeholder">
          <Loader inlineLoader />
        </div>
      }

      {genericErrorsForSummaryInfo && (
        <div className="lp-errors">
          {genericErrorsForSummaryInfo}
        </div>
      )}

      {summaryInfo && !isSummaryInfoLoading && 
        <Row>
          <Col>
            <SummaryBoxesSection id={SummaryIds.LteRecordableItemsSummary}>
              <SummaryBox
                type={SummaryBoxTypes.TimeDoughnut}
                values={[
                  {
                    value: summaryInfo.totalBilledMinutes ?? 0,
                    label: "Billed",
                    className: "lp-primary"
                  },
                  {
                    value: summaryInfo.totalUnbilledMinutes ?? 0,
                    label: "Unbilled",
                    className: "lp-warning"
                  }
                ]}
              />

              <SummaryBox 
                type={SummaryBoxTypes.FeeIncomeDoughnut}
                values={[
                  {
                    value: summaryInfo.totalBilled ?? 0,
                    label: "Billed",
                    className: "lp-primary"
                  },
                  {
                    value: summaryInfo.totalUnbilled ?? 0,
                    label: "Unbilled",
                    className: "lp-warning"
                  }
                ]}
              />
            </SummaryBoxesSection>
          </Col>
        </Row>
      }

      <Row>
        <Col>
          <Title
            type="section"
            title={<>Recordable Items</>}
          >
          </Title>

          {genericErrors && (
            <div className="lp-errors">
              {genericErrors}
            </div>
          )}

          <Card className="with-grid">
            <Card.Body>
              <Grid 
                ref={gridRef}
                onGridReady={onGridReady}
                gridOptions={gridOptions}
                gridFilters={gridFilters}
                pagination={true}
                exportTitle="Time & Incidental Expenses - Recordable Items"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <SlidingPanel gridRef={gridRef} onNavigation={(item: any) => viewCallback(item)} />
    </>
  );
}
