import Tippy from "@tippyjs/react";
import { Button } from "react-bootstrap";

type Props = {
  className: string,
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  icon: JSX.Element,
  tooltip?: string,
}

export default function ViewButtonGrid(props: Props) {
  return (
    <>
      {props.tooltip ?
          <Tippy
          content={props.tooltip}
          placement="top-start"
          className="lp-button-grid-tooltip"
          appendTo={document.body}
          interactive={true}
          delay={300}
        >
          <Button variant={`${props.className}`} onClick={props.onClick} className="btn-icon">
            {props.icon}
          </Button>
        </Tippy>
      : (
        <Button variant={`${props.className}`} onClick={props.onClick} className="btn-icon">
          {props.icon}
        </Button>
      )}
    </>
  );
}
