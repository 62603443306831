import { Controller, useForm } from "react-hook-form";
import React, { Dispatch, useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { useParams } from "react-router-dom";
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import Loader from "components/Loader/index";
import { EntityModel } from "models/view/EntityModel";
import { 
  getDisabilityStatuses,
  getEntityTypes,
  getEthnicities,
  getGenderPronouns,
  getLanguages,
  getMaritalStatuses,
  getSexes,
  updateEntity
} from "actions/entity";
import { EntityTypeIds } from "enums/EntityTypeIds";
import CustomSelect from "components/Select/Select";
import { removeEmptyFields } from "utils/form";
import DatePicker from "react-datepicker";
import { DateFormat } from "utils/constants";
import { validationSuite } from "./validationSuite";
import { getDateOnly } from "utils/date";
import { getEntityRolesSummary } from "actions/settings";
import { UpdateEntityModel } from "models/update/UpdateEntityModel";

type Props = {
  entity: EntityModel | undefined,
  setEntity: Dispatch<React.SetStateAction<EntityModel | undefined>>,
  setEdit: Dispatch<React.SetStateAction<boolean>>
}

export default function EditEntitySummary(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id } = useParams();

  const {register, reset, control, watch, trigger, setValue, handleSubmit, formState: {errors}} = useForm<UpdateEntityModel>({
    resolver: vestResolver(validationSuite)
  });

  useEffect(() => {
    if(props.entity) {
      let initialFields: UpdateEntityModel = {
        ...props.entity
      };

      if(props.entity?.humanBeingDetail?.languagesSpoken) {
        initialFields.humanBeingDetail.languagesSpokenIds = props.entity.humanBeingDetail.languagesSpoken.map(x => x.id);
      }

      reset(initialFields);
    }
  }, [props.entity]);

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: UpdateEntityModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    updateEntity(id!, data).then((response) => {
      props.setEntity(response.data);
      props.setEdit(false);
    })   
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const onBlurFirstName = () => {
    if(watch("humanBeingDetail.firstName") == '' || watch("humanBeingDetail.firstName") == undefined) {
      return;
    }
    if (watch("humanBeingDetail.salutation") == '' || watch("humanBeingDetail.salutation") == undefined) {
      setValue('humanBeingDetail.salutation', watch("humanBeingDetail.firstName"));
      trigger('humanBeingDetail.salutation');
    }
  }

  const onBlurLastName = () => {
    if(watch("humanBeingDetail.lastName") == '' || watch("humanBeingDetail.lastName") == undefined) {
      return;
    }
    if (watch("humanBeingDetail.lastNameAtBirth") == '' || watch("humanBeingDetail.lastNameAtBirth") == undefined) {
      setValue('humanBeingDetail.lastNameAtBirth', watch("humanBeingDetail.lastName"));
      trigger('humanBeingDetail.lastNameAtBirth');
    }
  }

  return (
    <Card>
      {isLoading && <Loader inlineLoader />}

      <Card.Body>
        {genericErrors && (
          <div className="lp-errors">
            {genericErrors}
          </div>
        )}

        <Form onSubmit={onSubmit}>
          <Row className="multiple">
            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="displayName">
              <Form.Label className="required">Display Name</Form.Label>
              <Form.Control
                type="text"
                className={`${errors?.displayName?.message ? 'invalid' : ''}`}
                {...register("displayName", {shouldUnregister: true})}
              />
              <Form.Text className="lp-error">
                {errors?.displayName?.message && (errors.displayName.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="entityTypeId">
              <Form.Label className="required">Type</Form.Label>
              <Controller
                control={control}
                name="entityTypeId"
                shouldUnregister={true}
                render={({field: { onChange, value, name, ref }}) => (
                  <CustomSelect
                    id="entityTypeId"
                    inputRef={ref}
                    className={`lp-select${errors?.entityTypeId?.message ? ' invalid' : ''}`}
                    endpointCall={getEntityTypes}
                    value={value}
                    isDisabled
                    onChange={val => onChange(val?.id ?? null)}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.entityTypeId?.message && (errors.entityTypeId.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="defaultRoleId">
              <Form.Label className="required">Default Role</Form.Label>
              <Controller
                control={control}
                name="defaultRoleId"
                shouldUnregister={true}
                render={({field: { onChange, value, name, ref }}) => (
                  <CustomSelect
                    className={`lp-select${errors?.defaultRoleId?.message ? ' invalid' : ''}`}
                    id="defaultRoleId"
                    inputRef={ref}
                    endpointCall={getEntityRolesSummary}
                    value={value}
                    onChange={val => onChange(val?.id ?? null)}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.defaultRoleId?.message && (errors.defaultRoleId.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="active">
              <Form.Label>Active</Form.Label>
              <Controller
                control={control}
                name="active"
                shouldUnregister={true}
                render={({field: { onChange, value, name, ref }}) => (
                  <Form.Check 
                    type="switch"
                    id="active">
                      <Form.Check.Input
                        className= "form-check-input"
                        ref={ref}
                        checked={value ?? false}
                        onChange={(ev: any) => onChange(ev.target.checked)}
                      />
                  </Form.Check>
                )}
              />
            </Form.Group>

            {props.entity?.entityTypeId === EntityTypeIds.CourtTribunalId &&
              <>
                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="courtCode">
                  <Form.Label>Court Code</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.courtDetail?.courtCode?.message ? 'invalid' : ''}`}
                    {...register("courtDetail.courtCode", {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.courtDetail?.courtCode?.message && (errors.courtDetail.courtCode.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="laaCode">
                  <Form.Label>LAA Code</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.courtDetail?.laaCode?.message ? 'invalid' : ''}`}
                    {...register("courtDetail.laaCode", {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.courtDetail?.laaCode?.message && (errors.courtDetail.laaCode.message)} 
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="isCommonPlatform">
                  <Form.Label>Is Common Platform</Form.Label>
                  <Controller
                    control={control}
                    name="courtDetail.isCommonPlatform"
                    shouldUnregister={true}
                    render={({field: { onChange, value, name, ref }}) => (
                      <Form.Check 
                        type="switch"
                        id="isCommonPlatform">
                          <Form.Check.Input
                            className= "form-check-input"
                            ref={ref}
                            checked={value ?? false}
                            onChange={(ev: any) => onChange(ev.target.checked)}
                          />
                      </Form.Check>
                    )}
                  />
                </Form.Group>
              </>
            }

            {props.entity?.entityTypeId === EntityTypeIds.CrownProsecutionServiceId &&
              <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="cpsArea">
                <Form.Label className="required">CPS Area</Form.Label>
                <Form.Control
                  type="text"
                  className={`${errors?.crownProsecutionServiceDetail?.cpsArea?.message ? 'invalid' : ''}`}
                  {...register("crownProsecutionServiceDetail.cpsArea", {shouldUnregister: true})}
                />
                <Form.Text className="lp-error">
                  {errors?.crownProsecutionServiceDetail?.cpsArea?.message && (errors.crownProsecutionServiceDetail.cpsArea.message)}
                </Form.Text>
              </Form.Group>
            }

            {props.entity?.entityTypeId === EntityTypeIds.HumanBeingId &&
              <>
                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="firstName">
                  <Form.Label className="required">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.humanBeingDetail?.firstName?.message ? 'invalid' : ''}`}
                    {...register("humanBeingDetail.firstName", {shouldUnregister: true})}
                    onBlur={onBlurFirstName}
                  />
                  <Form.Text className="lp-error">
                    {errors?.humanBeingDetail?.firstName?.message && (errors.humanBeingDetail.firstName.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="middleName">
                  <Form.Label>Middle Name</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("humanBeingDetail.middleName", {shouldUnregister: true})}
                  />
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="lastName">
                  <Form.Label className="required">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.humanBeingDetail?.lastName?.message ? 'invalid' : ''}`}
                    {...register("humanBeingDetail.lastName", {shouldUnregister: true})}
                    onBlur={onBlurLastName}
                  />
                  <Form.Text className="lp-error">
                    {errors?.humanBeingDetail?.lastName?.message && (errors.humanBeingDetail.lastName.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="lastNameAtBirth">
                  <Form.Label>Last Name At Birth</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.humanBeingDetail?.lastNameAtBirth?.message ? 'invalid' : ''}`}
                    {...register("humanBeingDetail.lastNameAtBirth", {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.humanBeingDetail?.lastNameAtBirth?.message && (errors.humanBeingDetail.lastNameAtBirth.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="dateOfBirth">
                  <Form.Label>Date Of Birth</Form.Label>
                  <Controller
                    control={control}
                    name="humanBeingDetail.dateOfBirth"
                    shouldUnregister={true}
                    render={({field: { onChange, value }}) => (
                      <DatePicker
                        className={`${errors?.humanBeingDetail?.dateOfBirth?.message ? 'invalid' : ''}`}
                        dateFormat={DateFormat.Datepicker}
                        selected={value ? getDateOnly(value) : null}
                        onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                      />
                    )}
                  />
                  <Form.Text className="lp-error">
                    {errors?.humanBeingDetail?.dateOfBirth?.message && (errors.humanBeingDetail.dateOfBirth.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="placeOfBirth">
                  <Form.Label>Place Of Birth</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.humanBeingDetail?.placeOfBirth?.message ? 'invalid' : ''}`}
                    {...register("humanBeingDetail.placeOfBirth", {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.humanBeingDetail?.placeOfBirth?.message && (errors.humanBeingDetail.placeOfBirth.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="sexId">
                  <Form.Label className="required">Sex</Form.Label>
                  <Controller
                    control={control}
                    name="humanBeingDetail.sexId"
                    shouldUnregister={true}
                    render={({field: { onChange, value, name, ref }}) => (
                      <CustomSelect
                        id="sexId"
                        inputRef={ref}
                        className={`lp-select${errors?.humanBeingDetail?.sexId?.message ? ' invalid' : ''}`}
                        endpointCall={getSexes}
                        value={value}
                        onChange={val => onChange(val?.id ?? null)}
                      />
                    )}
                  />
                  <Form.Text className="lp-error">
                    {errors?.humanBeingDetail?.sexId?.message && (errors.humanBeingDetail.sexId.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="genderPronounId">
                  <Form.Label className="required">Gender Pronoun</Form.Label>
                  <Controller
                    control={control}
                    name="humanBeingDetail.genderPronounId"
                    shouldUnregister={true}
                    render={({field: { onChange, value, name, ref }}) => (
                      <CustomSelect
                        id="genderPronounId"
                        inputRef={ref}
                        className={`lp-select${errors?.humanBeingDetail?.genderPronounId?.message ? ' invalid' : ''}`}
                        endpointCall={getGenderPronouns}
                        value={value}
                        onChange={val => onChange(val?.id ?? null)}
                      />
                    )}
                  />
                  <Form.Text className="lp-error">
                    {errors?.humanBeingDetail?.genderPronounId?.message && (errors.humanBeingDetail.genderPronounId.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="prefix">
                  <Form.Label>Prefix</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("humanBeingDetail.prefix", {shouldUnregister: true})}
                  />
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="suffix">
                  <Form.Label>Suffix</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("humanBeingDetail.suffix", {shouldUnregister: true})}
                  />
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="salutation">
                  <Form.Label className="required">Salutation</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.humanBeingDetail?.salutation?.message ? 'invalid' : ''}`}
                    {...register("humanBeingDetail.salutation", {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.humanBeingDetail?.salutation?.message && (errors.humanBeingDetail.salutation.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="ethnicityId">
                  <Form.Label>Ethnicity</Form.Label>
                  <Controller
                    control={control}
                    name="humanBeingDetail.ethnicityId"
                    shouldUnregister={true}
                    render={({field: { onChange, value, name, ref }}) => (
                      <CustomSelect
                        id="ethnicityId"
                        inputRef={ref}
                        endpointCall={getEthnicities}
                        value={value}
                        isClearable
                        onChange={val => onChange(val?.id ?? null)}
                      />
                    )}
                  />
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="maritalStatusId">
                  <Form.Label>Marital Status</Form.Label>
                  <Controller
                    control={control}
                    name="humanBeingDetail.maritalStatusId"
                    shouldUnregister={true}
                    render={({field: { onChange, value, name, ref }}) => (
                      <CustomSelect
                        id="maritalStatusId"
                        inputRef={ref}
                        endpointCall={getMaritalStatuses}
                        value={value}
                        isClearable
                        onChange={val => onChange(val?.id ?? null)}
                      />
                    )}
                  />
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="disabilityStatusId">
                  <Form.Label>Disability Status</Form.Label>
                  <Controller
                    control={control}
                    name="humanBeingDetail.disabilityStatusId"
                    shouldUnregister={true}
                    render={({field: { onChange, value, name, ref }}) => (
                      <CustomSelect
                        id="disabilityStatusId"
                        inputRef={ref}
                        endpointCall={getDisabilityStatuses}
                        value={value}
                        isClearable
                        onChange={val => onChange(val?.id ?? null)}
                      />
                    )}
                  />
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="nationalInsuranceNo">
                  <Form.Label>National Insurance No.</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="PP-NNNNNN-S"
                    className={`${errors?.humanBeingDetail?.nationalInsuranceNo?.message ? 'invalid' : ''}`}
                    {...register("humanBeingDetail.nationalInsuranceNo", {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.humanBeingDetail?.nationalInsuranceNo?.message && (errors.humanBeingDetail.nationalInsuranceNo.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="prisonNumber">
                  <Form.Label>Prison Number</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.humanBeingDetail?.prisonNumber?.message ? 'invalid' : ''}`}
                    {...register("humanBeingDetail.prisonNumber", {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.humanBeingDetail?.prisonNumber?.message && (errors.humanBeingDetail.prisonNumber.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="languagesSpokenIds">
                  <Form.Label>Languages Spoken</Form.Label>
                  <Controller
                    control={control}
                    name="humanBeingDetail.languagesSpokenIds"
                    shouldUnregister={true}
                    render={({field: { onChange, value, name, ref }}) => (
                      <CustomSelect
                        id="languagesSpokenIds"
                        inputRef={ref}
                        endpointCall={getLanguages}
                        value={value}
                        isClearable
                        isMulti
                        onChange={val => onChange(val?.map((x: { id: any; }) => x.id) ?? null)}
                      />
                    )}
                  />
                </Form.Group>
              </>
            }

            {(props.entity?.entityTypeId === EntityTypeIds.LimitedCompanyId || props.entity?.entityTypeId === EntityTypeIds.PublicLimitedCompanyId) &&
              <>
                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="companyName">
                  <Form.Label className="required">Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.limitedCompanyDetail?.companyName?.message ? 'invalid' : ''}`}
                    {...register("limitedCompanyDetail.companyName", {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.limitedCompanyDetail?.companyName?.message && (errors.limitedCompanyDetail.companyName.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="tradingName">
                  <Form.Label className="required">Trading Name</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.limitedCompanyDetail?.tradingName?.message ? 'invalid' : ''}`}
                    {...register("limitedCompanyDetail.tradingName", {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.limitedCompanyDetail?.tradingName?.message && (errors.limitedCompanyDetail.tradingName.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="companyNumber">
                  <Form.Label>Company Number</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.limitedCompanyDetail?.companyNumber?.message ? 'invalid' : ''}`}
                    {...register("limitedCompanyDetail.companyNumber", {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.limitedCompanyDetail?.companyNumber?.message && (errors.limitedCompanyDetail.companyNumber.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="incorporationDate">
                  <Form.Label>Incorporation Date</Form.Label>
                  <Controller
                    control={control}
                    name="limitedCompanyDetail.incorporationDate"
                    shouldUnregister={true}
                    render={({field: { onChange, value }}) => (
                      <DatePicker
                        className={`${errors?.limitedCompanyDetail?.incorporationDate?.message ? 'invalid' : ''}`}
                        id="incorporationDate"
                        dateFormat={DateFormat.Datepicker}
                        selected={value ? getDateOnly(value) : null}
                        onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                      />
                    )}
                  />
                  <Form.Text className="lp-error">
                    {errors?.limitedCompanyDetail?.incorporationDate?.message && (errors.limitedCompanyDetail.incorporationDate.message)}
                  </Form.Text>
                </Form.Group>
              </>
            }

            {props.entity?.entityTypeId === EntityTypeIds.LimitedLiabilityPartnershipId &&
              <>
                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="partnershipName">
                  <Form.Label className="required">Partnership Name</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.limitedLiabilityPartnershipDetail?.partnershipName?.message ? 'invalid' : ''}`}
                    {...register("limitedLiabilityPartnershipDetail.partnershipName", {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.limitedLiabilityPartnershipDetail?.partnershipName?.message && (errors.limitedLiabilityPartnershipDetail.partnershipName.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="tradingName">
                  <Form.Label className="required">Trading Name</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.limitedLiabilityPartnershipDetail?.tradingName?.message ? 'invalid' : ''}`}
                    {...register("limitedLiabilityPartnershipDetail.tradingName", {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.limitedLiabilityPartnershipDetail?.tradingName?.message && (errors.limitedLiabilityPartnershipDetail.tradingName.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="companyNumber">
                  <Form.Label>Company Number</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.limitedLiabilityPartnershipDetail?.companyNumber?.message ? 'invalid' : ''}`}
                    {...register("limitedLiabilityPartnershipDetail.companyNumber", {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.limitedLiabilityPartnershipDetail?.companyNumber?.message && (errors.limitedLiabilityPartnershipDetail.companyNumber.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="incorporationDate">
                  <Form.Label>Incorporation Date</Form.Label>
                  <Controller
                    control={control}
                    name="limitedLiabilityPartnershipDetail.incorporationDate"
                    shouldUnregister={true}
                    render={({field: { onChange, value }}) => (
                      <DatePicker
                        className={`${errors?.limitedLiabilityPartnershipDetail?.incorporationDate?.message ? 'invalid' : ''}`}
                        id="incorporationDate"
                        dateFormat={DateFormat.Datepicker}
                        selected={value ? getDateOnly(value) : null}
                        onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                      />
                    )}
                  />
                  <Form.Text className="lp-error">
                    {errors?.limitedLiabilityPartnershipDetail?.incorporationDate?.message && (errors.limitedLiabilityPartnershipDetail.incorporationDate.message)}
                  </Form.Text>
                </Form.Group>
              </>
            }

            {props.entity?.entityTypeId === EntityTypeIds.PartnershipAtWillId &&
              <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="tradingName">
                <Form.Label className="required">Trading Name</Form.Label>
                <Form.Control
                  type="text"
                  className={`${errors?.partnershipAtWillDetail?.tradingName?.message ? 'invalid' : ''}`}
                  {...register("partnershipAtWillDetail.tradingName", {shouldUnregister: true})}
                />
                <Form.Text className="lp-error">
                  {errors?.partnershipAtWillDetail?.tradingName?.message && (errors.partnershipAtWillDetail.tradingName.message)}
                </Form.Text>
              </Form.Group>
            }

            {props.entity?.entityTypeId === EntityTypeIds.PoliceStationId &&
              <>
                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="laaCode">
                  <Form.Label>LAA Code</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.policeStationDetail?.laaCode?.message ? 'invalid' : ''}`}
                    {...register("policeStationDetail.laaCode", {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.policeStationDetail?.laaCode?.message && (errors.policeStationDetail.laaCode.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="constabulary">
                  <Form.Label className="required">Constabulary</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.policeStationDetail?.constabulary?.message ? 'invalid' : ''}`}
                    {...register("policeStationDetail.constabulary", {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.policeStationDetail?.constabulary?.message && (errors.policeStationDetail.constabulary.message)}
                  </Form.Text>
                </Form.Group>
              </>
            }

            {props.entity?.entityTypeId === EntityTypeIds.HMPrisonServiceId &&
              <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="laaCode">
                <Form.Label>LAA Code</Form.Label>
                <Form.Control
                  type="text"
                  className={`${errors?.prisonDetail?.laaCode?.message ? 'invalid' : ''}`}
                  {...register("prisonDetail.laaCode", {shouldUnregister: true})}
                />
                <Form.Text className="lp-error">
                  {errors?.prisonDetail?.laaCode?.message && (errors.prisonDetail.laaCode.message)}
                </Form.Text>
              </Form.Group>
            }

            {props.entity?.entityTypeId === EntityTypeIds.RegisteredCharityId &&
              <>
                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="charityName">
                  <Form.Label className="required">Charity Name</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.registeredCharityDetail?.charityName?.message ? 'invalid' : ''}`}
                    {...register("registeredCharityDetail.charityName", {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.registeredCharityDetail?.charityName?.message && (errors.registeredCharityDetail.charityName.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="tradingName">
                  <Form.Label className="required">Trading Name</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.registeredCharityDetail?.tradingName?.message ? 'invalid' : ''}`}
                    {...register("registeredCharityDetail.tradingName", {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.registeredCharityDetail?.tradingName?.message && (errors.registeredCharityDetail.tradingName.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} lg={4} xxl={3} controlId="charityNumber">
                  <Form.Label>Charity Number</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.registeredCharityDetail?.charityNumber?.message ? 'invalid' : ''}`}
                    {...register("registeredCharityDetail.charityNumber", {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.registeredCharityDetail?.charityNumber?.message && (errors.registeredCharityDetail.charityNumber.message)}
                  </Form.Text>
                </Form.Group>
              </>
            }
          </Row>

          <Row>
            <Form.Group className="d-flex justify-content-between">
              <Button variant="success" type="submit">Update</Button>
              <Button variant="secondary-400" onClick={() => props.setEdit(false)}>Cancel</Button>
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
