/* eslint-disable arrow-parens */
/* eslint-disable prefer-destructuring */
import axios from 'axios';
import { AxiosRequestConfig } from 'axios';
import { handleDates } from './date';

let abortController = new AbortController();

const BASE_API_URL = `${process.env.REACT_APP_API_URL}`;

const instance = axios.create({
  baseURL: BASE_API_URL,
});

instance.interceptors.response.use(originalResponse => {
  handleDates(originalResponse.data);
  return originalResponse;
});

const parseErrors = (error: any) => error;

const setHeader = (headerName: string, headerValue: string) => {
  instance.defaults.headers.common[headerName] = headerValue;
};

const removeHeader = (headerName: string) => {
  delete instance.defaults.headers.common[headerName];
};

const getHeader = (headerName: string) => instance.defaults.headers.common[headerName];

const post = async<T, D>(url: string, data: T, config?: AxiosRequestConfig<T>) => {
  try {
    const response = await instance.post<D>(url, data, { ...{ signal: abortController.signal }, ...config });
    return response;
  } catch (error) {
    throw (parseErrors(error));
  }
};

const put = async<T, D>(url: string, data: T) => {
  try {
    const response = await instance.put<D>(url, data, { signal: abortController.signal });
    return response;
  } catch (error) {
    throw (parseErrors(error));
  }
};

const patch = async<T, D>(url: string, data: T) => {
  try {
    const response = await instance.patch<D>(url, data, { signal: abortController.signal });
    return response;
  } catch (error) {
    throw (parseErrors(error));
  }
};

const get = async<D>(url: string, config?: AxiosRequestConfig<any>) => {
  try {
    const response = await instance.get<D>(url, { ...{ signal: abortController.signal }, ...config });
    return response;
  } catch (error) {
    throw (parseErrors(error));
  }
};

const del = async (url: string) => {
  try {
    const response = await instance.delete(url, { signal: abortController.signal });
    return response;
  } catch (error) {
    throw (parseErrors(error));
  }
};

const delWithBody = async<T>(url: string, body: T) => {
  try {
    const response = await instance.delete(url, { ...{data: body}, ...{ signal: abortController.signal } });
    return response;
  } catch (error) {
    throw (parseErrors(error));
  }
};

const abortPendingRequests = () => {
  abortController.abort();
  abortController = new AbortController();
}

export {
  instance,
  post,
  patch,
  put,
  get,
  del,
  delWithBody,
  setHeader,
  getHeader,
  removeHeader,
  abortPendingRequests,
};
