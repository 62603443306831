import Title from "components/Title/index";
import { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import useSlidingPanelActions from "actions/slidingPanel";
import { ModalState } from "state/modalSlice";
import { Card, Button } from "react-bootstrap";
import Loader from "components/Loader/index";
import React from "react";
import useModalActions from "actions/modal";
import { useAppSelector } from 'hooks/appSelector';
import { EntityShareModel } from "models/view/EntityShareModel";
import { getEntityShares, unshareEntity } from "actions/entity";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import IconButtonGrid from "components/Buttons/IconButtonGrid";
import ShareEntitiesForm from "containers/Entity/ShareEntities/ShareEntitiesForm";

type Props = {
  entityId: string
}

export default function EntityShareList(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [entityShares, setEntityShares] = useState<EntityShareModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const modalActions = useModalActions();
  const slidingPanelActions = useSlidingPanelActions();
  const loggedInUser = useAppSelector((state) => state.user);

  const loadEntityShares = () => {
    setIsLoading(true);
    getEntityShares(props.entityId)
      .then((response) => {
        setEntityShares(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() =>
  {
    loadEntityShares();
  }, [props.entityId]);

  const openShareEntitySlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: `Share Entity`,
      children: <ShareEntitiesForm
                  entityIds={[props.entityId]}
                  onSubmitCallback={loadEntityShares}
                />
    });
  };

  const unshareEntityModal = (entityShareId: string) => {
    let modal: ModalState = {
      title: "Unshare confirmation",
      body: `Are you sure you want to unshare this Entity?`,
      actionText: "Unshare",
      actionVariant: "danger",
      onAction: () => unshareEntityCallback(entityShareId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };
  
  const unshareEntityCallback = (entityShareId: string) => {
    modalActions.toggleModalLoading();
    unshareEntity(props.entityId, entityShareId)
      .then(() => {
        loadEntityShares();
        modalActions.toggleModalShownStatus();
      })
      .catch((error) => {
        modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        modalActions.toggleModalLoading();
      });
  };

  return (
    <>
      <Title type="section" title={"Shared To"}>
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && 
          <Button onClick={openShareEntitySlidingPanel} className="btn-icon" variant="success">
            <MdAdd />
          </Button>
        }
      </Title>
      <Card className="managers-list shared">
        <Card.Body>
          {isLoading && <Loader inlineLoader={isLoading} />}

          {genericErrors && (
            <div className="lp-errors">
              {genericErrors}
            </div>
          )}

          <div className="lp-simple-list">
            <div className="lp-color-title secondary">Tranding Entities</div>
            <ul>
              {entityShares.length === 0 &&
                <li className="lp-list-empty">No shares found</li>
              }
              {entityShares.map((x: EntityShareModel, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <li>
                      <span className="lp-simple-list-name">{x.toLTE?.name}</span>
                      <div className="lp-actions-btns">
                        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && 
                          <IconButtonGrid
                            type={ActionButtonTypes.Delete}
                            callback={() => unshareEntityModal(x.id)}
                          />
                        }
                      </div>
                    </li>
                  </React.Fragment>
                )
              })}
            </ul>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
