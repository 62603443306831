import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import useGridActions from "actions/grid";
import { useAppSelector } from "hooks/appSelector";
import { Form, Button, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import { getValidationSuite } from "./validationSuite";
import Loader from "components/Loader/index";
import { getActivityTypes, getDefaultActivityById, updateDefaultActivity } from "actions/settings";
import { CreateOrUpdateDefaultActivityModel } from "models/create/CreateOrUpdateDefaultActivityModel";
import { DefaultActivityModel } from "models/view/DefaultActivityModel";
import CustomSelect from "components/Select/Select";
import FormSubmitButton from "components/Buttons/FormSubmitButton";

type Props = {
  defaultActivityId: string
}

export default function EditDefaultActivityForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const gridActions = useGridActions();
  const grid = useAppSelector((state) => state.grid);
  const slidingPanelActions = useSlidingPanelActions();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    getDefaultActivityById(props.defaultActivityId).then((response) => {
      reset(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const {register, reset, control, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateDefaultActivityModel>({
    resolver: vestResolver(getValidationSuite(props.defaultActivityId!))
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateDefaultActivityModel) {
    setIsLoading(true);
    updateDefaultActivity(props.defaultActivityId, data).then((response) => {
      let index: number = grid.rowData.findIndex((x: any) => x.id === response.data.id);
      let newEntry: DefaultActivityModel = {...response.data};
      let newArray: Array<any> = [...grid.rowData];
      newArray[index] = newEntry;
      gridActions.setGridRowData(newArray);
      
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }
      
  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} sm={6} controlId="code">
            <Form.Label className="required">Code</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.code?.message ? 'invalid' : ''}`}
              {...register("code", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.code?.message && (errors.code.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="name">
            <Form.Label className="required">Name</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.name?.message ? 'invalid' : ''}`}
              {...register("name", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.name?.message && (errors.name.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              {...register("description", {shouldUnregister: true})}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="activityTypeId">
            <Form.Label>Type</Form.Label>
            <Controller
              control={control}
              name="activityTypeId"
              shouldUnregister={true}
              render={({field: { onChange, value, ref }}) => (
                <CustomSelect
                  id="activityTypeId"
                  inputRef={ref}
                  className={'lp-select'}
                  endpointCall={getActivityTypes}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="active">
            <Form.Label>Active</Form.Label>
            <Controller
              control={control}
              name="active"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="active">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <FormSubmitButton text="Update" errors={{...errors}} />
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
