import { ICellRendererParams } from "ag-grid-community";
import { NavLink } from "react-router-dom";

export default function MatterRefCellRenderer(props: ICellRendererParams & { idFieldName: string }) {

  return (
    <>
      {props.data?.[props.idFieldName] &&
        <NavLink to={`/matter/${props.data?.[props.idFieldName]}`}>
          {props.value}
        </NavLink>
      }

      {!props.data?.[props.idFieldName] &&
        <span>{props.value}</span>
      }
    </>
  );
}

MatterRefCellRenderer.defaultProps = {
  idFieldName: 'matterId'
}