import { useState } from 'react';
import { EditorProvider } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Color } from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import { TextStyle } from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import MenuBar from './MenuBar/MenuBar';
import useModalActions from 'actions/modal';
import { ModalState } from 'state/modalSlice';
import { TextEditorTypes } from 'enums/TextEditorTypes';
import './style.scss';

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle,
  Underline,
  Link.configure({
    openOnClick: false,
    autolink: true,
    linkOnPaste: true,
  }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
];

type Props = {
  content: string,
  setContent: (content: string) => void,
};

export default function TextEditor(props: Props) {
  const [showHTMLText, setShowHTMLText] = useState(false);
  const modalActions = useModalActions();

  const changeEditorType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if(e.target.value === 'HTML') {
      setShowHTMLText(true);
    }
    else {
      // If switching from HTML to Text Editor, show a warning informing the user that some formatting may be lost
      let modal: ModalState = {
        title: "Editor change",
        body: "Are you sure you want to switch to the text editor? Some formatting options may be lost.",
        actionText: "Switch",
        actionVariant: "success",
        onAction: () => {
          setShowHTMLText(false);
          modalActions.toggleModalShownStatus();
        },
        show: false
      }
      modalActions.setModal(modal);
      modalActions.toggleModalShownStatus();
    }
  };

  return (
    <>
      { showHTMLText ? (
          <div className="tiptap-html-textarea">
            <div className="html-textarea-menu">
              <select
                onChange={changeEditorType}
                value={showHTMLText ? TextEditorTypes.HTML : TextEditorTypes.TipTap}
              >
                <option value={TextEditorTypes.TipTap}>Text Editor</option>
                <option value={TextEditorTypes.HTML}>HTML</option>
              </select>
            </div>
            <textarea onChange={(e) => props.setContent(e.target.value)} value={props.content}>
              {props.content}
            </textarea>
          </div>
        ) : (
          <EditorProvider
            onCreate={(e) => props.setContent(e.editor.getHTML())} // Parse data when switching from HTML editor
            onUpdate={(e) => props.setContent(e.editor.getHTML())}
            slotBefore={<MenuBar showHTMLText={showHTMLText} setShowHTMLText={setShowHTMLText} />}
            extensions={extensions}
            content={props.content}
          ></EditorProvider>
        )
      }
    </>
  )
}