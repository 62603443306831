import SlidingPanel from "components/SlidingPanel/index";
import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useEffect, useRef, useState } from "react";
import { MdAdd } from "react-icons/md";
import { getColumnDefs, viewCallback } from "./columnDefs";
import { Card, Button } from "react-bootstrap";
import { getAllUsers } from "actions/user";
import CreateLteUserForm from "./CreateLteUser/CreateLteUserForm";
import useSlidingPanelActions from "actions/slidingPanel";
import { LawPageTradingEntityModel } from "models/view/LawPageTradingEntityModel";
import Title from 'components/Title';
import { useAppSelector } from 'hooks/appSelector';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import { AgGridReact } from "ag-grid-react";
import { GridIds } from "enums/GridIds";

type Props = {
  lte: LawPageTradingEntityModel | undefined
}

export default function LteUserList(props: Props) {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();
  const [genericErrors, setGenericErrors] = useState(null);
  const loggedInUser = useAppSelector((state) => state.user);
  const gridRef = useRef<AgGridReact>(null);

  const onGridReady = useCallback(() =>
  {
    const columnDefs = getColumnDefs();
    gridActions.setGridColumnDefs([...columnDefs]);
  }, []); 

  useEffect(() => {
    gridActions.setGridId(GridIds.LteUsers);
  }, []);

  useEffect(() => {
    if(props.lte?.id) {
      getAllUsers(props.lte?.id!).then((response) => 
      {
        gridActions.setGridRowData(response.data as any)
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
        gridActions.setGridRowData([]);
      });
    }
    else {
      gridActions.setGridRowData([]);
    }
  }, [props.lte]);

  const toggleSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Add User",
        children: <CreateLteUserForm />
      });
  }

  return (
    <>
      <Title type="section" title={"Users"}>
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageLTEUsers) &&
          <Button onClick={toggleSlidingPanel} className="btn-icon" variant="success" disabled={!props.lte?.isActive}>
            <MdAdd />
          </Button>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card className="with-grid">
        <Card.Body>
          <Grid 
            ref={gridRef}
            onGridReady={onGridReady}
            exportTitle={`Trading Entity: ${props.lte?.legalName} - Users`}
          />
        </Card.Body>
      </Card>

      <SlidingPanel gridRef={gridRef} onNavigation={(item: any) => {viewCallback(item.id)}} />
    </>
  );
}
