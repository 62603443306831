import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { MdAdd } from "react-icons/md";
import { getColumnDefs } from "./columnDefs";
import { Card, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Title from "components/Title/index";
import { checkIfActivityIsInAnyChargingRate, getCompleteChargingRates } from "actions/chargingSchemes";
import CreateChargingRate from "containers/ChargingScheme/CreateChargingRate/CreateChargingRate";
import { ChargingSchemeModel } from "models/view/ChargingSchemeModel";
import { GridOptionButtonDataModel } from "models/view/GridOptionButtonDataModel";
import { GridOptionTypes } from "enums/GridOptionTypes";
import CreateRecordableEventTypeToChargingRate from "../ConfigureRecordableEventTypes/CreateRecordableType/CreateRecordableEventTypeToChargingRate";
import { useParams } from "react-router-dom";
import { ChargingRateModel } from "models/view/ChargingRateModel";
import TooltipIcon from "components/TooltipIcon";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { AgGridReact } from "ag-grid-react";
import { GridIds } from "enums/GridIds";

type Props = {
  chargingScheme: ChargingSchemeModel
}

const ChargingRatesList = forwardRef(function ChargingRatesList(props: Props, forwardedRef: Ref<AgGridReact | null>) {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();
  const [receivedEmailActivityInChargingRates, setReceivedEmailActivityInChargingRates] = useState<boolean>(false);
  const [sentEmailActivityInChargingRates, setSentEmailActivityInChargingRates] = useState<boolean>(false);
  const grid = useAppSelector((state) => state.grid);
  const [genericErrors, setGenericErrors] = useState(null);
  const loggedInUser = useAppSelector((state) => state.user);
  const { id } = useParams();

  const gridRef = useRef<AgGridReact>(null);
  useImperativeHandle(forwardedRef, () => gridRef.current!);

  const onGridReady = useCallback(() =>
  {
    const columnDefs = getColumnDefs();
    gridActions.setGridColumnDefs([...columnDefs]);
  }, []);

  useEffect(() => {
    gridActions.setGridId(GridIds.ChargeRates);
  }, []);

  useEffect(() => {
    getCompleteChargingRates(props.chargingScheme.id).then((response) => 
    {
      gridActions.setGridRowData(response.data as any)
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      gridActions.setGridRowData([]);
    });
  }, [props.chargingScheme]);

  useEffect(() => {
    if(props.chargingScheme)
    {
      checkIfActivityIsInAnyChargingRate(props.chargingScheme?.id, {
        value: props.chargingScheme?.receivedEmailActivityId
      }).then((response) => {
        setReceivedEmailActivityInChargingRates(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      });

      checkIfActivityIsInAnyChargingRate(props.chargingScheme?.id, {
        value: props.chargingScheme?.sentEmailActivityId
      }).then((response) => {
        setSentEmailActivityInChargingRates(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      });
    }
  }, [props.chargingScheme?.receivedEmailActivityId, props.chargingScheme?.sentEmailActivityId, grid.rowData]);

  const toggleSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Add Charging Rate",
        children: <CreateChargingRate
          chargingScheme={props.chargingScheme}
        />
      });
  }

  const gridOptions: GridOptionButtonDataModel[] = [
    {
      type: GridOptionTypes.AddChargingRateToRecordableEventType,
      callback: (rows: ChargingRateModel[]) => addChargingRatesToRecordableEvent(rows)
    },
  ];

  const addChargingRatesToRecordableEvent = (rows: ChargingRateModel[]) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Add to Recordable Event Type',
      children: <CreateRecordableEventTypeToChargingRate
        chargingSchemeId={id!}
        chargingRates={rows.map(x => x.id)}
      />
    });
  };

  return (
    <>
      <Title type="section" title={
        <>
          Charge Rates
          {(!receivedEmailActivityInChargingRates || !sentEmailActivityInChargingRates) &&
            <TooltipIcon type="error" text={`${!receivedEmailActivityInChargingRates ? "Received" : ""}${!receivedEmailActivityInChargingRates && !sentEmailActivityInChargingRates ? ", " : ""}${!sentEmailActivityInChargingRates ? "Sent" : ""} ${!receivedEmailActivityInChargingRates && !sentEmailActivityInChargingRates ? "Email Activities are" : "Email Activity is"} not assigned to any Charging Rate.`} />
          }
        </>
      }>
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageChargingSchemes) &&
          <Button onClick={toggleSlidingPanel} className="btn-icon" variant="success">
            <MdAdd />
          </Button>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card className="with-grid">
        <Card.Body>
          <Grid 
            ref={gridRef}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            exportTitle={`Charging Scheme: ${props.chargingScheme.displayName} - Charge Rates`}
          />
        </Card.Body>
      </Card>
    </>
  );
});

export default ChargingRatesList;
