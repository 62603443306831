import { cloneElement, ReactElement, useEffect, useState } from 'react';
import Field from 'components/Fields/Field';
import Loader from 'components/Loader';
import { Row, Col } from 'react-bootstrap';
import {
  decreasePriorityMatterActivityHistory,
  deleteMatterEmail,
  deleteMatterFilePermission,
  downloadActivityHistory,
  flagMatterActivityHistory,
  getMatterEmailById,
  getMatterFilePermissions,
  increasePriorityMatterActivityHistory,
  printActivityHistory,
  unflagMatterActivityHistory,
  validateCopyActivityHistory,
  validateMoveActivityHistory,
  validateShareActivityHistory,
} from 'actions/matter';
import { MatterEmailModel } from 'models/view/MatterEmailModel';
import BooleanField from 'components/Fields/BooleanField';
import moment from 'moment';
import { DateFormat } from 'utils/constants';
import { getEmailTypeName, getPriority } from 'utils/enums';
import { MatterFilePermissionsModel } from 'models/view/MatterFilePermissionsModel';
import ListSlidingPanel from 'components/List/ListSlidingPanel';
import { ActionButtonTypes } from 'enums/ActionButtonTypes';
import { ModalState } from 'state/modalSlice';
import { DeleteMatterFilePermissionModel } from 'models/delete/DeleteMatterFilePermissionModel';
import useModalActions from 'actions/modal';
import useSlidingPanelActions from 'actions/slidingPanel';
import { useAppSelector } from 'hooks/appSelector';
import EditMatterEmailDetailsForm from '../EditMatterEmailDetails/EditMatterEmailDetailsForm';
import EditActivityHistoryTaxonomyTermsForm from '../../ActivityHistoryGrid/ActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTermsForm';
import PreviewMatterEmail from '../PreviewMatterEmail/PreviewMatterEmail';
import SaveAttachments from '../SaveAttachments/SaveAttachments';
import { UserRoleNames } from 'enums/UserRoleNames';
import usePageActions from 'actions/page';
import { Priority } from 'enums/Priority';
import { downloadFile, formatEmailRecipient, getDecreasedPriority, getIncreasedPriority, printPdf } from 'utils/misc';
import CopyActivityHistoryForm from '../../ActivityHistoryGrid/CopyActivityHistory/CopyActivityHistoryForm';
import MoveActivityHistoryForm from '../../ActivityHistoryGrid/MoveActivityHistory/MoveActivityHistoryForm';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import ShareActivityHistoryForm from '../../ActivityHistoryGrid/ShareActivityHistory/ShareActivityHistoryForm';
import useNotificationActions from 'actions/notification';
import { NotificationTypes } from 'enums/NotificationTypes';

type Props = {
  matterId: string,
  emailId: string,
  activityHistoryId: string,
  isMatterClosed?: boolean,
  hasTaxonomies: boolean,
  syncMatterFiles?: Function,
  showMoreButtons?: boolean,
  isFlagged?: boolean,
  priority?: Priority,
};

function ViewMatterEmail(props: Props) {
  const [genericErrors, setGenericErrors] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [matterEmail, setMatterEmail] = useState<MatterEmailModel>();
  const [matterFilePermissions, setMatterFilePermissions] = useState<MatterFilePermissionsModel>();
  const [isFlagged, setIsFlagged] = useState<boolean | undefined>(props.isFlagged);
  const [priority, setPriority] = useState<Priority | undefined>(props.priority);
  const modalActions = useModalActions();
  const slidingPanelActions = useSlidingPanelActions();
  const pageActions = usePageActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const loggedInUser = useAppSelector((state) => state.user);
  const notificationActions = useNotificationActions();

  useEffect(() => {
    // When moving between multiple items of the same type, update local state for flag and priority
    setIsFlagged(props.isFlagged);
    setPriority(props.priority);

    setIsLoading(true);
    getMatterEmailById(props.matterId, props.emailId)
      .then((response) => {
        setMatterEmail(response.data);

        if (response.data?.isEmailFileDeletedOnStorage == false) {
          getMatterFilePermissions(props.matterId, response.data.matterFileId)
            .then((getMatterFilePermissionsResponse) => {
              setMatterFilePermissions(getMatterFilePermissionsResponse.data);
            })
            .catch((error) => {
              setGenericErrors(error.response?.data?.Message ?? error.message);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
        setIsLoading(false);
      });
  }, [props.emailId]);

  useEffect(() => {
    if (matterEmail) {
      const canManageMails = matterEmail.isCurrentUserSenderOrRecipient || 
        (loggedInUser.userRole?.name != UserRoleNames.Clerk &&
        loggedInUser.userRole?.name != UserRoleNames.BackOffice && 
        loggedInUser.userRole?.name != UserRoleNames.Accountant &&
        loggedInUser.userRole?.name != UserRoleNames.EntryLevel &&
        !matterEmail.isCurrentUserSenderOrRecipient);

      setSlidingPanelEditButton(canManageMails);
      setSlidingPanelMoreButtons(canManageMails);
    }
  }, [matterEmail, isFlagged, priority]);

  const setSlidingPanelEditButton = (canManageMails: boolean) => {
    if (!props.isMatterClosed && canManageMails) {
      slidingPanelActions.setOnEdit(() => {
        slidingPanelActions.clearSlidingPanel();

        slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          title: "Edit Matter Email Details",
          children: <EditMatterEmailDetailsForm
            matterId={props.matterId}
            emailId={props.emailId}
            onSubmitCallback={onChildPanelClose}
          />,
          onCancel: onChildPanelClose
        });
      });
    }
  }

  const setSlidingPanelMoreButtons = (canManageMails: boolean) => {
    if (!props.isMatterClosed && props.showMoreButtons && canManageMails) {
      slidingPanelActions.setMoreButtons([
        // Preview
        {
          type: ActionButtonTypes.PreviewEmail,
          callback: () => onClickPreview(),
        },
        // Taxonomies
        ...(props.hasTaxonomies ? [{
          type: ActionButtonTypes.EditTaxonomyTerms,
          callback: () => onClickTaxonomies(),
        }] : []),
        // Save Attachments
        {
          type: ActionButtonTypes.SaveAttachments,
          callback: () => onClickSaveAttachments(),
        },
        // Actions
        ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterActivityHistory) ? [
          {
            type: ActionButtonTypes.Download,
            callback: () => downloadItem(props.matterId, props.activityHistoryId!)
          },
          {
            type: ActionButtonTypes.Print,
            callback: () => printItem(props.matterId, props.activityHistoryId!)
          },
          {
            type: ActionButtonTypes.Copy,
            callback: () => copyItem(props.matterId, props.activityHistoryId!, props.syncMatterFiles)
          },
          {
            type: ActionButtonTypes.Move,
            callback: () => moveItem(props.matterId, props.activityHistoryId!, props.syncMatterFiles)
          },
          {
            type: ActionButtonTypes.Share,
            callback: () => shareItem(props.matterId, props.activityHistoryId!)
          }
        ] : []),
        // Flag
        ...(props.activityHistoryId && isFlagged !== undefined ? [
          isFlagged ? {
            type: ActionButtonTypes.Unflag,
            callback: () => unflagModal(props.matterId, props.activityHistoryId!)
          } : {
            type: ActionButtonTypes.Flag,
            callback: () => flagModal(props.matterId, props.activityHistoryId!)
          },
        ] : []),
        // Priority
        ...(props.activityHistoryId && priority !== undefined ? [
          ...(priority !== Priority.High ? [{
            type: ActionButtonTypes.IncreasePriority,
            callback: () => increasePriorityModal(props.matterId, props.activityHistoryId!)
          }] : []),
          ...(priority !== Priority.Low ? [{
            type: ActionButtonTypes.DecreasePriority,
            callback: () => decreasePriorityModal(props.matterId, props.activityHistoryId!)
          }] : []),
        ] : []),
        // Delete
        {
          type: ActionButtonTypes.Delete,
          callback: () => onClickDelete(),
        }
      ]);
    }
  }

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
      children: cloneElement(currentSlidingPanelState.children! as ReactElement, {priority: priority, isFlagged: isFlagged}),
    });
  }

  const flagModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Flag confirmation",
      body: "Are you sure you want to add a flag?",
      actionText: "Flag",
      onAction: () => flagCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const flagCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    flagMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setIsFlagged(true); // Local state flag update - used to identify which button to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const unflagModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Unflag confirmation",
      body: "Are you sure you want to remove this flag?",
      actionText: "Unflag",
      onAction: () => unflagCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const unflagCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    unflagMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setIsFlagged(false); // Local state flag update - used to identify which button to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const increasePriorityModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Increase priority confirmation",
      body: "Are you sure you want to increase the priority?",
      actionText: "Increase",
      onAction: () => increasePriorityCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const increasePriorityCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    increasePriorityMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setPriority(getIncreasedPriority(priority!)); // Local state priority update - used to identify which buttons to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const decreasePriorityModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Decrease priority confirmation",
      body: "Are you sure you want to decrease the priority?",
      actionText: "Decrease",
      onAction: () => decreasePriorityCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const decreasePriorityCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    decreasePriorityMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setPriority(getDecreasedPriority(priority!)); // Local state priority update - used to identify which buttons to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const onClickPreview = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        allowNavigation: true,
        title: "Preview Matter Email",
        children: <PreviewMatterEmail
          matterId={props.matterId}
          emailId={props.emailId!}
          activityHistoryId={props.activityHistoryId}
          isMatterClosed={props.isMatterClosed}
          hasTaxonomies={props.hasTaxonomies}
          syncMatterFiles={props.syncMatterFiles}
          isFlagged={isFlagged}
          priority={priority}
          showMoreButtons
        />
      }
    );
  };

  const onClickTaxonomies = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Edit Taxonomy Terms",
        children: <EditActivityHistoryTaxonomyTermsForm
          matterId={props.matterId}
          activityHistoryId={props.activityHistoryId}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  };

  const onClickSaveAttachments = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Save Attachments",
        children: <SaveAttachments
          matterId={props.matterId}
          emailId={props.emailId}
          syncMatterFiles={props.syncMatterFiles}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  };

  const onClickDelete = () => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Recordable Event?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.matterId, props.emailId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const deleteCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    deleteMatterEmail(matterId, activityId).then(() => {
      //reload the whole grid, because we might have RecordableEvents that changed by deleting the email
      pageActions.triggerReloadPage();

      modalActions.toggleModalShownStatus();
      slidingPanelActions.clearSlidingPanel();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  const deletePermissionModal = (email: string, isEditPermission: boolean) => {
    let modal: ModalState = {
      title: 'Delete confirmation',
      body: 'Are you sure you want to delete this permission?',
      actionText: 'Delete',
      actionVariant: 'danger',
      onAction: () => deletePermissionCallback(email, isEditPermission),
      show: true,
    };
    modalActions.setModal(modal);
  };

  const deletePermissionCallback = (
    email: string,
    isEditPermission: boolean
  ) => {
    setIsLoading(true);
    const data: DeleteMatterFilePermissionModel = { email: email };
    if (!matterEmail?.matterFileId) {
      return;
    }
    deleteMatterFilePermission(props.matterId, matterEmail.matterFileId, data)
      .then(() => {
        if (isEditPermission) {
          const perm = matterFilePermissions?.editPermissions?.find(
            (x) => x.email == email
          );
          if (!perm?.isInherited) {
            setMatterFilePermissions({
              editPermissions:
                matterFilePermissions?.editPermissions?.filter(
                  (x) => x.email != email
                ) ?? [],
              viewPermissions: matterFilePermissions?.viewPermissions ?? [],
            });
          }
        } else {
          const perm = matterFilePermissions?.viewPermissions?.find(
            (x) => x.email == email
          );
          if (!perm?.isInherited) {
            setMatterFilePermissions({
              editPermissions: matterFilePermissions?.editPermissions ?? [],
              viewPermissions:
                matterFilePermissions?.viewPermissions?.filter(
                  (x) => x.email != email
                ) ?? [],
            });
          }
        }
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const downloadItem = (matterId: string, activityHistoryId: string) => {
    setIsLoading(true);

    downloadActivityHistory(matterId, [activityHistoryId]).then((response) => {
      downloadFile(response);
    })
    .catch((error) => {
      error.response.data.text().then((text: any) => {
        let responseData = undefined;
        try {
          responseData = JSON.parse(text);
        }
        catch(e) {
          responseData = undefined;
        }

        notificationActions.addNotificationMessage({
          type: NotificationTypes.Error,
          title: "Download Error",
          body: `${responseData?.Message ?? "There was an error while downloading the file."}`,
          isDismissable: true
        });
      });
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const printItem = (matterId: string, activityHistoryId: string) => {
    setIsLoading(true);

    printActivityHistory(matterId, [activityHistoryId]).then((response) => {
      printPdf(response.data);
    })
    .catch((error) => {
      //the response is Blob here, we need to parse it
      error.response.data.text().then((text: any) => {
        let responseData = undefined;
        try {
          responseData = JSON.parse(text);
        }
        catch(e) {
          responseData = undefined;
        }

        notificationActions.addNotificationMessage({
          type: NotificationTypes.Error,
          title: "Print Error",
          body: `${responseData?.Message ?? "There was an error printing the file."}`,
          isDismissable: true
        });
      });
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const copyItem = (matterId: string, activityHistoryId: string, syncMatterFiles?: Function) => {
    setIsLoading(true);

    validateCopyActivityHistory(matterId, [activityHistoryId]).then((response) => {
      slidingPanelActions.setSlidingPanel({
        isShown: true,
        title: "Copy Activity History Items",
        children: <CopyActivityHistoryForm
          matterId={matterId}
          activityHistoryIds={[activityHistoryId]}
          syncMatterFiles={syncMatterFiles}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      });
    })
    .catch((error) => {
      notificationActions.addNotificationMessage({
        type: NotificationTypes.Error,
        title: "Copy Validation Error",
        body: error.response?.data?.Message ?? error.message,
        isDismissable: true
      });
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const moveItem = (matterId: string, activityHistoryId: string, syncMatterFiles?: Function) => {
    setIsLoading(true);
  
    validateMoveActivityHistory(matterId, [activityHistoryId]).then((response) => {
      slidingPanelActions.setSlidingPanel({
        isShown: true,
        title: "Move Activity History Items",
        children: <MoveActivityHistoryForm
          matterId={matterId}
          activityHistoryIds={[activityHistoryId]}
          displayClient={false}
          syncMatterFiles={syncMatterFiles}
        />,
        onCancel: onChildPanelClose
      });
    })
    .catch((error) => {
      notificationActions.addNotificationMessage({
        type: NotificationTypes.Error,
        title: "Move Validation Error",
        body: error.response?.data?.Message ?? error.message,
        isDismissable: true
      });
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const shareItem = (matterId: string, activityHistoryId: string) => {
    setIsLoading(true);
  
    validateShareActivityHistory(matterId, [activityHistoryId]).then((response) => {
      slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          title: "Share Files",
          children: <ShareActivityHistoryForm
            matterId={matterId}
            activityHistoryIds={[activityHistoryId]}
            onSubmitCallback={onChildPanelClose}
          />,
          onCancel: onChildPanelClose
        }
      );
    })
    .catch((error) => {
      notificationActions.addNotificationMessage({
        type: NotificationTypes.Error,
        title: "Share Files Validation Error",
        body: error.response?.data?.Message ?? error.message,
        isDismissable: true
      });
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && <div className="lp-errors">{genericErrors}</div>}

      <Row>
        <Col>
          <Field
            label={"Description"}
            value={matterEmail?.description}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field label={'Subject'} value={matterEmail?.subject} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={'Sender'}
            value={matterEmail ? formatEmailRecipient(matterEmail?.senderEmailAddress!, matterEmail?.senderName) : '—'}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={'To'}
            value={
              matterEmail?.toRecipients && matterEmail?.toRecipients.length > 0
                ? matterEmail?.toRecipients.map((recipient, index) => (
                    <span key={index}>{formatEmailRecipient(recipient.emailAddress, recipient.name)}{index !== matterEmail?.toRecipients?.length! - 1 ? ', ' : ''}</span>
                  ))
                : '—'
            }
          />
        </Col>
      </Row>
      {matterEmail?.ccRecipients &&
        matterEmail?.ccRecipients.length > 0 && (
          <Row>
            <Col>
              <Field
                label={'Cc'}
                value={matterEmail?.ccRecipients.map((recipient, index) => (
                  <span key={index}>{formatEmailRecipient(recipient.emailAddress, recipient.name)}{index !== matterEmail?.ccRecipients?.length! - 1 ? ', ' : ''}</span>
                ))}
              />
            </Col>
          </Row>
        )}
      {matterEmail?.bccRecipients &&
        matterEmail?.bccRecipients.length > 0 && (
          <Row>
            <Col>
              <Field
                label={'Bcc'}
                value={matterEmail?.bccRecipients.map((recipient, index) => (
                  <span key={index}>{formatEmailRecipient(recipient.emailAddress, recipient.name)}{index !== matterEmail?.bccRecipients?.length! - 1 ? ', ' : ''}</span>
                ))}
              />
            </Col>
          </Row>
        )}
      <Row>
        <Col sm={6}>
          <Field label={'Type'} value={getEmailTypeName(matterEmail?.type!)} />
        </Col>
        {matterEmail?.type == 0 && (
          <Col sm={6} className="mt-4 mt-sm-0">
            <Field
              label={'Sent Date Time'}
              value={
                matterEmail?.sentDateTime
                  ? moment(matterEmail?.sentDateTime).format(
                      DateFormat.MomentWithTime
                    )
                  : '—'
              }
            />
          </Col>
        )}
        {matterEmail?.type != 0 && (
          <Col sm={6} className="mt-4 mt-sm-0">
            <Field
              label={'Received Date Time'}
              value={
                matterEmail?.receivedDateTime
                  ? moment(matterEmail?.receivedDateTime).format(
                      DateFormat.MomentWithTime
                    )
                  : '—'
              }
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col sm={6}>
          <BooleanField
            label={'Has Attachments'}
            value={matterEmail?.hasAttachments ?? false}
          />
        </Col>
        <Col sm={6} className="mt-4 mt-sm-0">
          <BooleanField
            label={'Internal'}
            value={matterEmail?.isInternal ?? false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Additional Note"}
            value={matterEmail?.additionalNote}
          />
        </Col>
      </Row>

      { isFlagged !== undefined || priority !== undefined ?
        <Row>
          { isFlagged !== undefined &&
            <Col sm={6}>
              <BooleanField
                label={"Is Flagged"}
                value={isFlagged ?? false}
              />
            </Col>
          }
          { priority !== undefined &&
            <Col sm={6}>
              <Field
                label={"Priority"}
                value={priority !== undefined ? getPriority(priority) : "—"}
              />
            </Col>
          }
        </Row>
      : null }

      {
        matterEmail?.hasAttachments &&
        <Row>
          <Col>
            <div className="departments">
              <ListSlidingPanel
                listItems={
                  matterEmail?.attachments?.map((x) => ({ id: x, name: x })) ?? []
                }
                listButtons={[]}
              >
                Attachments
              </ListSlidingPanel>
            </div>
          </Col>
        </Row>
      }
      {matterEmail?.isEmailFileDeletedOnStorage == false && (
        <>
          <Row>
            <Col>
              <div className="departments">
                <ListSlidingPanel
                  listItems={
                    matterFilePermissions?.editPermissions?.map((x) => ({
                      id: x.email,
                      name: x.email,
                      hideDeleteBtn: x.isInherited,
                    })) ?? []
                  }
                  listButtons={!matterEmail.matter?.isClosed ? [
                    {
                      type: ActionButtonTypes.Delete,
                      callback: (id: string) => deletePermissionModal(id, true),
                    }
                  ] : []}
                >
                  People that can view & edit
                </ListSlidingPanel>
              </div>
              <div className="departments">
                <ListSlidingPanel
                  listItems={
                    matterFilePermissions?.viewPermissions?.map((x) => ({
                      id: x.email,
                      name: x.email,
                      hideDeleteBtn: x.isInherited,
                    })) ?? []
                  }
                  listButtons={!matterEmail.matter?.isClosed ? [
                    {
                      type: ActionButtonTypes.Delete,
                      callback: (id: string) =>
                        deletePermissionModal(id, false),
                    },
                  ] : []}
                >
                  People that can view
                </ListSlidingPanel>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default ViewMatterEmail;
