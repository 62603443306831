import { getEmailFolders, getEmailsForDashboard } from 'actions/user';
import React, { useEffect, useState } from 'react';
import Title from 'components/Title';
import Loader from 'components/Loader';
import { Button, Card, Form } from 'react-bootstrap';
import { MatterEmailModel } from 'models/view/MatterEmailModel';
import { DropDownOptionModel } from 'models/view/DropDownOptionModel';
import { useAppSelector } from 'hooks/appSelector';
import useSlidingPanelActions from 'actions/slidingPanel';
import CustomSelect from 'components/Select/Select';
import moment from 'moment';
import { DateFormat } from 'utils/constants';
import { MdOutlineAdd, MdOutlineAttachment, MdOutlineFlag, MdRefresh, MdAccessTime, MdOutgoingMail, MdMarkEmailUnread } from 'react-icons/md';
import ManualAddEmails from "containers/Matter/AddEmails/ManualAddEmails";
import ViewUserEmail from '../ViewUserEmail/ViewUserEmail';
import { formatEmailRecipient } from 'utils/misc';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { BsExclamationLg } from 'react-icons/bs';

type Props = {
  title?: string;
  dragIcon?: React.ReactNode;
}

export default function DashboardUserEmails(props: Props) {
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [existingNextPage, setExistingNextPage] = useState<boolean>(false);
  const [emails, setEmails] = useState<MatterEmailModel[]>();
  const [emailFolder, setEmailFolder] = useState<string>();
  const [emailFolderOptions, setEmailFolderOptions] = useState<DropDownOptionModel[]>([]);
  const [keyword, setKeyword] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(undefined);
  const user = useAppSelector((state) => state.user);
  const slidingPanelActions = useSlidingPanelActions();
  const pageSize = 10;
  const [windowOnFocus, setWindowOnFocus] = useState(true);

  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: 'emails' });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition: transition || undefined,
  };

  useEffect(() => {
    setIsLoading(true);
    getEmailFolders(user.msalAccessToken!).then((response) => {
      setEmailFolder(
        response.data.find((x: DropDownOptionModel) => x.name == 'Inbox')?.id
      );
      setEmailFolderOptions(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const checkTokensOnFocus = () => {
    setWindowOnFocus(true);
  };

  const onBlur = () => {
    setWindowOnFocus(false);
  };

  useEffect(() => {
    window.addEventListener('focus', checkTokensOnFocus);
    return () => {
      window.removeEventListener('focus', checkTokensOnFocus);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  useEffect(() => {
    //auto refresh emails every minute
    const getEmails = setInterval(() => {
      if(pageNumber == 0 && windowOnFocus) {
        loadUserEmails(pageNumber, pageSize, user.msalAccessToken!, emailFolder, keyword, true);
      }
    }, 60000);

    return () => clearInterval(getEmails);
  }, [pageNumber, emailFolder, keyword, windowOnFocus]);

  useEffect(() => {
    loadUserEmails(pageNumber, pageSize, user.msalAccessToken!, emailFolder, keyword);
  }, [pageNumber]);

  const loadUserEmails = (page: number, take: number, msalAccessToken: string, emailFolder?: string, keyword?: string, hideIsLoading?: boolean) => {
    if(!hideIsLoading) {
      setIsLoading(true);
    }

    getEmailsForDashboard(
      page,
      take,
      msalAccessToken,
      emailFolder,
      keyword
    )
    .then((response) => {
      setExistingNextPage(response.data.existingNextPage);
      setEmails(response.data.emails);
      setGenericErrors(undefined);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      if(!hideIsLoading) {
        setIsLoading(false);
      }
    });
  };

  const handlePageDown = () => {
    if (pageNumber > 0) {
      setIsLoading(true);
      setPageNumber(pageNumber - 1);
    }
  };

  const handlePageUp = () => {
    if (existingNextPage) {
      setIsLoading(true);
      setPageNumber(pageNumber + 1);
    }
  };

  const filter = () => {
    if(pageNumber == 0){
      loadUserEmails(pageNumber, pageSize, user.msalAccessToken!, emailFolder, keyword);
    }
    else {
      setPageNumber(0);
    }
  }

  const toggleReadStatus = (emailGraphId: string) => {
    const email = emails?.find(x => x.graphResourceId === emailGraphId);
    if(email) {
      email.isRead = !email.isRead;
      setEmails([...emails!]);
    }
  }

  const markAsRead = (emailGraphId: string) => {
    const email = emails?.find(x => x.graphResourceId === emailGraphId);
    if(email) {
      email.isRead = true;
      setEmails([...emails!]);
    }
  }

  const viewEmail = (emailGraphId: string) => {
    markAsRead(emailGraphId);
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "View Email Details",
        children: <ViewUserEmail
          emailGraphId={emailGraphId}
          toggleReadStatus={() => toggleReadStatus(emailGraphId)}
        />
      }
    );
  };

  const addEmailsToMatter = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Import Emails To Matter",
        children: <ManualAddEmails onSubmitCallback={() => loadUserEmails(pageNumber, pageSize, user.msalAccessToken!, emailFolder, keyword)}/>
      }
    );
  }

  const refreshEmails = () => {
    if(pageNumber == 0){
      loadUserEmails(pageNumber, pageSize, user.msalAccessToken!, emailFolder);
    }
    else {
      setPageNumber(0);
    }
  }

  return (
    <div ref={setNodeRef} style={style} className={`lp-dashboard-dnd-draggable-item ${isDragging ? 'dragging' : ''}`}>
      <Title
        type="section"
        title={props.title ?? "Emails"}
        icon={props.dragIcon}
        dragAttributes={{ listeners: listeners, attributes: attributes }}
      >
        {user.userPermissions?.some(a => a == UserPermissionsNames.ManageDashboardEmails) &&
          <div onClick={addEmailsToMatter} className="link">
            Import emails to matter
            <MdOutlineAdd />
          </div>
        }
        <Button onClick={refreshEmails} className="btn-icon" variant="primary">
          <MdRefresh />
        </Button>
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card className="with-emails dashboard">
        <Card.Body>
          {isLoading && <Loader inlineLoader />}

          <div className="lp-emails-filter">
            <Form.Control
              type="text"
              placeholder="Keyword"
              onBlur={(e: any) => setKeyword(e.target.value)}
            />

            <CustomSelect
              id="attachmentsIds"
              menuPlacement="bottom"
              placeholder="Select folder"
              options={emailFolderOptions}
              onChange={(val) => setEmailFolder(val?.id ?? null)}
              value={emailFolder}
            />

            <Button onClick={filter}>
              Filter
            </Button>
          </div>

          <div className="lp-emails-list">
            {emails == undefined || emails.length == 0 &&
              <div className="lp-empty emails">
                <div className="image"></div>
                <div className="text">No emails found!</div>
              </div>
            }

            {emails &&
              emails?.map((x: MatterEmailModel, index: number) => (
                <React.Fragment key={index}>
                  <div
                    key={index * (pageNumber + 1)}
                    onClick={x.isDraft ? () => {} : (() => viewEmail(x.graphResourceId))}
                    className={`lp-email-item ${x.type === 0 ? 'sent' : 'received'}`}
                  >
                    <div className="lp-email-item-wrapper">
                      <div className="lp-email-item-icon">
                        {x.type === 0 ? <MdOutgoingMail /> : <MdMarkEmailUnread />}
                      </div>
                      <div className="lp-email-item-content">
                        <div className="lp-email-item-header">
                          <h3 className="lp-email-item-subject">
                            {x.existsInDatabase &&
                              <span className="lp-email-item-existing">{x?.matter?.fileNumber}</span>
                            }
                            {x.isDraft &&
                              <span className="lp-email-item-draft">Draft</span>
                            }
                            {x.subject}
                          </h3>
                          <div className="lp-email-item-time">
                            <MdAccessTime />
                            <span>{moment(x.type === 0 ? x.sentDateTime : x.receivedDateTime).format(DateFormat.Moment)}</span>
                            <span>{moment(x.type === 0 ? x.sentDateTime : x.receivedDateTime).format(DateFormat.MomentTime)}</span>
                          </div>
                        </div>
                        <div className="lp-email-item-details">
                          <div className="lp-email-item-recipients">
                            {x.type === 0 ? (
                              <div className="lp-email-item-to">
                                <span>To:</span>
                                <span className="value">
                                  {x.toRecipients.length ? x.toRecipients.map((recipient, index) => (
                                    <span key={index}>{formatEmailRecipient(recipient.emailAddress, recipient.name)}{index !== x?.toRecipients?.length - 1 ? ', ' : ''}</span>
                                  )) : '—'}
                                </span>
                              </div>
                            ) : (
                              <div className="lp-email-item-from">
                                <span>From:</span>
                                <span className="value">{formatEmailRecipient(x.senderEmailAddress, x.senderName)}</span>
                              </div>
                            )}
                          </div>

                          <div className="lp-email-item-actions">
                            {x.hasAttachments && <MdOutlineAttachment />}
                            {x.isRead ? <FaEye /> : <FaEyeSlash />}
                            {x.flag?.flagStatus === "Flagged" && <MdOutlineFlag />}
                            {x.importance === "High" && <BsExclamationLg />}
                          </div>
                        </div>
                        <div className="lp-email-item-body-preview">
                          {x.bodyPreview}
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))
            }
          </div>

          {(pageNumber != 0 || existingNextPage) && (
            <div className="lp-emails-pagination">
              <Button
                onClick={handlePageDown}
                variant="secondary-400"
                disabled={pageNumber == 0}
              >
                Previous page
              </Button>

              <div className="lp-emails-current-page">
                Page
                {' ' + (pageNumber + 1)}
              </div>

              <Button
                onClick={handlePageUp}
                variant="secondary-400"
                disabled={!existingNextPage}
              >
                Next page
              </Button>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}