import Tippy from "@tippyjs/react";
import { Button } from "react-bootstrap";
import { MdError } from "react-icons/md";
import { useEffect, useState } from "react";
import { FormErrorTypes } from "enums/FormErrorTypes";
import "./style.scss";

type Props = {
  text: string;
  variant?: string;
  errors?: any;
  secondary?: boolean;
  clickAction?: React.MouseEventHandler<HTMLButtonElement>;
}

export default function FormSubmitButton(props: Props) {
  const [disabledButton, setDisabledButton] = useState(false);

  useEffect(() => {
    if(props.errors && Object.values(props.errors).length) {
      const errorsFlat = Object.values(props.errors).flat().filter(e => e).map((error: any) => error.ref ? error : Object.values(error)).flat();
      if(errorsFlat.every((error: any) => error?.type === FormErrorTypes.FileNotUploaded)) {
        // If the only present errors are of type FileNotUploaded, enable the button
        setDisabledButton(false);
        return;
      }
      setDisabledButton(true);
    }
    else {
      setDisabledButton(false);
    }
  }, [props.errors]);

  return disabledButton ? (
    <Tippy
      content="Some required fields are missing. Please fill in all the mandatory fields marked with an asterisk (*) to proceed."
      placement="top-start"
      className="lp-form-error-tooltip"
      appendTo={document.body}
      interactive={true}
    >
      <div className="lp-form-error-button">
        <Button variant={props.variant} type={props.secondary ? 'button' : 'submit'} disabled>
          <MdError />
          {props.text}
        </Button>
      </div>
    </Tippy>
  ) : (
    <Button
      variant={props.variant}
      type={props.secondary ? 'button' : 'submit'}
      {...(props.clickAction ? { onClick: props.clickAction } : {})}
    >
      {props.text}
    </Button>
  )
}

FormSubmitButton.defaultProps = {
  variant: 'success'
}