import Field from "components/Fields/Field";
import { Card, Row, Col, Button } from "react-bootstrap";
import Loader from "components/Loader/index";
import { LteAccountingSettingsModel } from "models/view/LteAccountingSettingsModel";
import { MdOutlineLink, MdOutlineLinkOff } from "react-icons/md";
import { Link } from "react-router-dom";
import { useState } from "react";
import { disconnectFromXero } from "actions/xero";
import { getLteAccountingSettingsById } from "actions/lte";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { isMobile } from "react-device-detect";
import useModalActions from "actions/modal";
import { ModalState } from "state/modalSlice";

type Props = {
  lteId: string,
  lteAccountingSettings: LteAccountingSettingsModel | undefined,
  disconnectXeroCallbackFn: Function,
  reloadAccountingSettingsFn: Function,
  isLoading: boolean
}

export default function ViewLteAccountingSettingsSummary(props: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(props.isLoading);
  const [genericErrors, setGenericErrors] = useState(null);
  const user = useAppSelector((state) => state.user);
  const modalActions = useModalActions();

  const openXeroConnectPopup = (event: React.MouseEvent) => {
    const popup = window.open(
      props.lteAccountingSettings?.xeroLoginURL ?? "",
      "Connect to Xero",
      `width=600, height=840, left=${event.screenX - 300}, top=${event.screenY - 420}`
    );

    // This seems to be the only solution to execute actions when closing popups that open a page from a different origin
    // https://stackoverflow.com/questions/6264907/check-if-a-popup-window-is-closed/73598276
    const popupCloseInterval = setInterval(() => {
      if(popup?.closed) {
        clearInterval(popupCloseInterval);
        props.reloadAccountingSettingsFn();
      }
    }, 500);
  };

  const disconnectXeroModal = () => {
    let modal: ModalState = {
      title: "Disconnect from Xero",
      body: "Are you sure you want to disconnect from Xero?",
      actionText: "Disconnect",
      onAction: () => disconnectXero(),  
      show: true
    }
    modalActions.setModal(modal);
  };

  const disconnectXero = () => {
    modalActions.toggleModalLoading();
    disconnectFromXero(props.lteId).then((r) => {
      modalActions.toggleModalShownStatus();

      setIsLoading(true);
      getLteAccountingSettingsById(props.lteId).then((response) => {
        if(props.disconnectXeroCallbackFn){
          props.disconnectXeroCallbackFn(response.data);
        }
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  return (
    <Card>
      {isLoading && <Loader inlineLoader />}
      <Card.Body>
        {genericErrors && (
          <div className="lp-errors">
            {genericErrors}
          </div>
        )}

        <Row>
          <Col>
            <Field
              label={"Recordable Items Default VAT Rate"}
              value={props.lteAccountingSettings?.recordableItemsDefaultVatRate?.name ?? "—"}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={"Incidental Expenses Default VAT Rate"}
              value={props.lteAccountingSettings?.incidentalExpensesDefaultVatRate?.name ?? "—"}
            />
          </Col>
        </Row>

        {props.lteAccountingSettings?.isXeroLinked &&
          <>
            <Row>
              <Col>
                <Field
                  label={"Xero Tenant"}
                  value={props.lteAccountingSettings?.xeroTenant?.name ?? "—"}
                />
              </Col>
            </Row>
            {user.userPermissions?.some(a => a == UserPermissionsNames.ManageLTESystemSettings) &&
              <Row>
                <Col>
                  <Button onClick={disconnectXeroModal} variant="primary">
                    <MdOutlineLinkOff /> Disconnect from Xero
                  </Button>
                </Col>
              </Row>
            }
          </>
        }

        {(user.userPermissions?.some(a => a == UserPermissionsNames.ManageLTESystemSettings) && 
        !props.lteAccountingSettings?.isXeroLinked && props.lteAccountingSettings?.xeroLoginURL) &&
          <Row>
            <Col className="d-flex">
              {isMobile ?
                <Link to={props.lteAccountingSettings?.xeroLoginURL} rel="noopener noreferrer" className="btn btn-primary">
                  <MdOutlineLink /> Connect to Xero
                </Link>
              :
                <Button onClick={openXeroConnectPopup}>
                  <MdOutlineLink /> Connect to Xero
                </Button>
              }
            </Col>
          </Row>
        }
      </Card.Body>
    </Card>
  );
}
