import { useCurrentEditor } from '@tiptap/react';
import { FaChevronDown, FaChevronUp, FaPen } from 'react-icons/fa';
import { useState } from 'react';
import './style.scss';

type Props = {
  onColorChange: Function,
};

const ColorPicker = (props: Props) => {
  const { editor } = useCurrentEditor();
  const [lastColor, setLastColor] = useState<string>('#19141E');
  const [colorPickerShown, setColorPickerShown] = useState<boolean>(false);

  // Outlook font colors
  const colors = [
    '#51a7f9', '#6fc040', '#f5d427', '#f3901d', '#ed5c57', '#b36ae2',
    '#0c64c0', '#0c882a', '#dcbe22', '#de6a19', '#c82613', '#763e9b',
    '#174e86', '#0f5c1a', '#c3971d', '#be5b17', '#861106', '#5e327c',
    '#002451', '#06400c', '#a37519', '#934511', '#570606', '#3b204d',
    '#ffffff', '#cccccc', '#999999', '#666666', '#333333', '#19141E',
  ];

  const toggleColorPicker = (e: any) => {
    e.preventDefault();
    setColorPickerShown(!colorPickerShown);
  };

  const applyLastColor = (e: any) => {
    e.preventDefault();
    props.onColorChange(lastColor);
  };

  const applyColor = (e: any, color: string) => {
    e.preventDefault();
    props.onColorChange(color);
    setLastColor(color);
    setColorPickerShown(false);
  };

  if (!editor) {
    return null;
  }

  return (
    <div className="tiptap-color-group">
      <div className="button-apply">
        <button onClick={applyLastColor} className='menu-button'>
          <FaPen />
          <div className="current-color-preview" style={{backgroundColor: lastColor}}></div>
        </button>
      </div>
      <div className="button-popup-open">
        <button onClick={toggleColorPicker} className='menu-button'>
          {colorPickerShown ? <FaChevronUp /> : <FaChevronDown />}
        </button>
      </div>
      {colorPickerShown && (
        <div className="tiptap-color-picker">
          {colors.map((color, index) => (
            <button
              key={index}
              className={`color-button ${color === '#ffffff' && 'white-border'}`}
              onClick={(e) => applyColor(e, color)} style={{backgroundColor: color}}
            ></button>
          ))}
        </div>
      )}
    </div>
  )
}

export default ColorPicker;