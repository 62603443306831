import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import Loader from "components/Loader";
import { updateLteAccountingSettings } from "actions/lte";
import { removeEmptyFields } from "utils/form";
import { getValidationSuite } from "./validationSuite";
import CustomSelect from "components/Select/Select";
import { LteAccountingSettingsModel } from "models/view/LteAccountingSettingsModel";
import { UpdateLteAccountingSettingsModel } from "models/update/UpdateLteAccountingSettingsModel";
import { getXeroTenantsSummary } from "actions/xero";
import { getVATRatesForPurchases, getVATRatesForSales } from "actions/settings";

type Props = {
  lteId: string,
  lteAccountingSettings: LteAccountingSettingsModel | undefined,
  submitCallbackFn: Function,
  cancelCallbackFn: Function,
  isLoading: boolean
}

export default function EditLteGeneralSettingsSummary(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(props.isLoading);

  const { register, reset, control, setValue, handleSubmit, formState: { errors } } = useForm<UpdateLteAccountingSettingsModel>({
    resolver: vestResolver(getValidationSuite(props.lteAccountingSettings?.isXeroLinked ?? false))
  });

  useEffect(() => {
    if (props.lteAccountingSettings) {
      let initialFields: UpdateLteAccountingSettingsModel = {
        xeroTenantId: props.lteAccountingSettings?.xeroTenantId,
        recordableItemsDefaultVatRateId: props.lteAccountingSettings?.recordableItemsDefaultVatRateId,
        incidentalExpensesDefaultVatRateId: props.lteAccountingSettings?.incidentalExpensesDefaultVatRateId
      };
      reset(initialFields);
    }
  }, [props.lteAccountingSettings]);

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: UpdateLteAccountingSettingsModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    updateLteAccountingSettings(props.lteId, data).then((response) => {
      if(props.submitCallbackFn){
        props.submitCallbackFn(response.data);
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const onCancelClick = () => {
    if(props.cancelCallbackFn){
      props.cancelCallbackFn();
    }
  };

  return (
    <Card>
      {isLoading && <Loader inlineLoader />}

      <Card.Body>
        {genericErrors && (
          <div className="lp-errors">
            {genericErrors}
          </div>
        )}

        <Form onSubmit={onSubmit}>
          <Row>
            <Form.Group as={Col} controlId="recordableItemsDefaultVatRateId">
              <Form.Label>Recordable Items Default VAT Rate</Form.Label>
              <Controller
                control={control}
                name={`recordableItemsDefaultVatRateId`}
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="recordableItemsDefaultVatRateId"
                    inputRef={ref}
                    className={`lp-select${errors?.recordableItemsDefaultVatRateId?.message ? ' invalid' : ''}`}
                    endpointCall={getVATRatesForSales}
                    value={value}
                    onChange={val => onChange(val?.id ?? null)}
                    isClearable
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.recordableItemsDefaultVatRateId?.message && (errors.recordableItemsDefaultVatRateId?.message)}
              </Form.Text>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="incidentalExpensesDefaultVatRateId">
              <Form.Label>Incidental Expenses Default VAT Rate</Form.Label>
              <Controller
                control={control}
                name={`incidentalExpensesDefaultVatRateId`}
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="incidentalExpensesDefaultVatRateId"
                    inputRef={ref}
                    className={`lp-select${errors?.incidentalExpensesDefaultVatRateId?.message ? ' invalid' : ''}`}
                    endpointCall={getVATRatesForPurchases}
                    value={value}
                    onChange={val => onChange(val?.id ?? null)}
                    isClearable
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.incidentalExpensesDefaultVatRateId?.message && (errors.incidentalExpensesDefaultVatRateId?.message)}
              </Form.Text>
            </Form.Group>
          </Row>

          {props.lteAccountingSettings?.isXeroLinked &&
            <Row>
              <Form.Group as={Col} controlId="xeroTenantId">
                <Form.Label className='required'>Xero Tenant</Form.Label>
                <Controller
                  control={control}
                  name={`xeroTenantId`}
                  shouldUnregister={true}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <CustomSelect
                      id="xeroTenantId"
                      inputRef={ref}
                      className={`lp-select${errors?.xeroTenantId?.message ? ' invalid' : ''}`}
                      endpointCall={() => getXeroTenantsSummary(props.lteId)}
                      value={value}
                      onChange={val => onChange(val?.id ?? null)}
                    />
                  )}
                />
                <Form.Text className="lp-error">
                  {errors?.xeroTenantId?.message && (errors.xeroTenantId?.message)}
                </Form.Text>
              </Form.Group>
            </Row>
          }

          <Row>
            <Form.Group className="d-flex justify-content-between">
              <Button variant="success" type="submit">Update</Button>
              <Button variant="secondary-400" onClick={onCancelClick}>Cancel</Button>
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
