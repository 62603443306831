import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import Title from 'components/Title';
import moment, { Moment } from 'moment';
import { MdArrowOutward, MdChevronLeft, MdChevronRight, MdLock, MdOutlineAdd, MdRefresh } from 'react-icons/md';
import { CalendarEventModel } from 'models/CalendarEventModel';
import useSlidingPanelActions from 'actions/slidingPanel';
import { getCalendarView } from 'actions/lte';
import ViewCalendarEvent from 'containers/Calendar/ViewCalendarEvent/ViewCalendarEvent';
import { Link } from "react-router-dom";
import ManualAddAppointments from 'containers/Matter/AddAppointments/ManualAddAppointments';
import { useAppSelector } from 'hooks/appSelector';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import { UserCalendarSettingsModel } from 'models/view/UserCalendarSettingsModel';
import { getUserCalendarSettings } from 'actions/user';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from "@dnd-kit/utilities";
import { DateFormat } from 'utils/constants';
import { getAllDaysInRange, getFreeBusyStatusFromValue, openUrlInNewtab } from 'utils/misc';
import Loader from 'components/Loader';
import { FaRegClock } from 'react-icons/fa';
import { DayOfWeek } from 'enums/DayOfWeek';
import "./style.scss";

type Props = {
  title?: string;
  dragIcon?: React.ReactNode;
}

export default function DashboardCalendarEvents(props: Props) {
  const todayDate = moment();
  const [startDate, setStartDate] = useState<Date>(moment().startOf('week').toDate());
  const [endDate, setEndDate] = useState<Date>(moment().endOf('week').toDate());
  const [dateRange, setDateRange] = useState<Moment[]>([]);
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const [eventsByDate, setEventsByDate] = useState<{ date: Moment, events: CalendarEventModel[] }[]>([]);
  
  const [showPastEvents, setShowPastEvents] = useState<boolean>(false);
  const [showPastEventsDisabled, setShowPastEventsDisabled] = useState<boolean>(false);
  const [timeUntilNextEvent, setTimeUntilNextEvent] = useState<string | undefined>(undefined);

  const [windowOnFocus, setWindowOnFocus] = useState(true);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const [userCalendarSettings, setUserCalendarSettings] = useState<UserCalendarSettingsModel>();

  const user = useAppSelector((state) => state.user);
  const slidingPanelActions = useSlidingPanelActions();

  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: 'calendar' });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition: transition || undefined,
  };

  const checkTokensOnFocus = () => {
    setWindowOnFocus(true);
  };

  const onBlur = () => {
    setWindowOnFocus(false);
  };

  useEffect(() => {
    window.addEventListener('focus', checkTokensOnFocus);
    return () => {
      window.removeEventListener('focus', checkTokensOnFocus);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  const getEventsForInterval = useCallback(() =>{
    if(startDate != null && endDate != null) {
      setIsLoading(true);
      const userTimeZone = moment.tz.guess();
      getCalendarView(startDate.toISOString(), endDate.toISOString(), userTimeZone).then((response) => {
        // Sort events by start date
        let eventData = response.data.sort((a,b) => moment(a.startDate).isBefore(moment(b.startDate)) ? -1 : 1);
        // If a date is selected, filter out "all day" events that end at 12:00 AM in that day
        if(selectedDate) {
          eventData = eventData.filter((event) => !event.isAllDay || (event.isAllDay && !moment(event.startDate).isBefore(selectedDate, 'day')));
        }
        const eventsByDate = dateRange.map((date) => {
          return {
            date: date,
            events: eventData.filter((event) => moment(event.startDate).isSame(date, 'day') &&
              (showPastEvents || showPastEventsDisabled ? true : moment(event.endDate).isAfter(moment()))
            )
          };
        }).filter((x) => x.events.length > 0);
        setEventsByDate(eventsByDate);

        //clear previous errors
        setGenericErrors(null);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
  }, [startDate, endDate, dateRange, selectedDate, showPastEvents, showPastEventsDisabled]);

  useEffect(() => {
    // Trigger getEvents when any of the dependencies change
    // If multiple dependencies change at the same time, the change is batched and will only trigger one getEvents call
    // https://github.com/reactwg/react-18/discussions/21
    if(userCalendarSettings) { // Only if user calendar settings are loaded ( -> the first day of week is set)
      getEventsForInterval();
    }
  }, [startDate, endDate, dateRange, selectedDate, showPastEvents, showPastEventsDisabled, userCalendarSettings]);

  useEffect(() => {
    setIsLoading(true);
    getUserCalendarSettings(user.userId!).then((calendarSettings) => {
      setUserCalendarSettings(calendarSettings.data);

      // Set first day of the week
      moment.updateLocale('en', {
        week: {
          dow: calendarSettings.data?.firstDayOfWeek ? DayOfWeek[calendarSettings.data?.firstDayOfWeek as keyof typeof DayOfWeek] ?? 0 : 0,
        }
      });

      // Set start and end date to the current week (will be based on the first day of the week)
      setStartDate(moment().startOf('week').toDate());
      setEndDate(moment().endOf('week').toDate());
      setDateRange(getAllDaysInRange(moment().startOf('week'), moment().endOf('week')));
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    });
  }, []);

  useEffect(() => {
    // Reset moment locale to first day of week on unmount
    return () => {
      moment.updateLocale('en', {
        week: {
          dow: 0,
        }
      });
    };
  }, []);

  const onNextRange = () => {
    // Get current range start and end date (do not use start date and end date state as those can be set to a single day)
    const startDate = dateRange[0].clone().startOf('day');
    const endDate = dateRange[dateRange.length - 1].clone().endOf('day');

    const newStartDateMoment = startDate.add(1, 'week');
    const newEndDateMoment = endDate.add(1, 'week');
    setStartDate(newStartDateMoment.toDate());
    setEndDate(newEndDateMoment.toDate());
    setDateRange(getAllDaysInRange(newStartDateMoment, newEndDateMoment));

    // Remove any selected date
    setSelectedDate(null);

    // Disable show past events if the range is not in the current week
    // In past weeks - All events are in the past so this switch is not needed
    // In future weeks - No events are in the past so this switch is not needed
    if(todayDate.isBetween(newStartDateMoment, newEndDateMoment)) {
      setShowPastEventsDisabled(false);
    }
    else {
      setShowPastEventsDisabled(true);
    }
  }

  const onPreviousRange = () => {
    // Get current range start and end date (do not use start date and end date state as those can be set to a single day)
    const startDate = dateRange[0].clone().startOf('day');
    const endDate = dateRange[dateRange.length - 1].clone().endOf('day');

    const newStartDateMoment = startDate.subtract(1, 'week');
    const newEndDateMoment = endDate.subtract(1, 'week');
    setStartDate(newStartDateMoment.toDate());
    setEndDate(newEndDateMoment.toDate());
    setDateRange(getAllDaysInRange(newStartDateMoment, newEndDateMoment));

    setSelectedDate(null);
    if(todayDate.isBetween(newStartDateMoment, newEndDateMoment)) {
      setShowPastEventsDisabled(false);
    }
    else {
      setShowPastEventsDisabled(true);
    }
  }

  const onSelectedDateChange = (date: Moment) => {
    // If the currently selected date is deselected, reset things to range view
    if(selectedDate?.isSame(date, 'day')) {
      const startDate = dateRange[0].clone().startOf('day');
      const endDate = dateRange[dateRange.length - 1].clone().endOf('day');
      setSelectedDate(null);
      setStartDate(startDate.toDate());
      setEndDate(endDate.toDate());
      setShowPastEventsDisabled(false);
    }
    else {
      setSelectedDate(date);
      setStartDate(date.startOf('day').toDate());
      setEndDate(date.endOf('day').toDate());
      setShowPastEventsDisabled(true);
    }
  }

  const onShowPastEventsChange = () => {
    setShowPastEvents(!showPastEvents);
  }

  const isInProgress = (startDate: Date, endDate: Date) => {
    return moment().isBetween(moment(startDate), moment(endDate));
  }

  const isUpcoming = (startDate: Date) => {
    const timeUntil = moment.duration(moment(startDate).diff(moment())).asSeconds();
    return timeUntil >= 0 && timeUntil < 7200;
  }

  const updateTimeUntil = (date?: Date) => {
    if(!date) {
      setTimeUntilNextEvent(undefined);
      return;
    }

    const timeUntil = moment.duration(moment(date).diff(moment())).asSeconds();
    if (timeUntil >= 0 && timeUntil < 7200) {
      setTimeUntilNextEvent(moment(date).fromNow());
    }
    else {
      setTimeUntilNextEvent(undefined);
    }
  }

  useEffect(() => {
    // Time until will only show on the first event of the first day (the event on the very top of the list)
    // If the provided param results to undefined, it will also set time until to undefined
    updateTimeUntil(eventsByDate?.[0]?.events?.[0]?.startDate);

    // Update time until the event at an interval to always have up to date information
    const interval = setInterval(() => {
      if(eventsByDate) {
        updateTimeUntil(eventsByDate?.[0]?.events?.[0]?.startDate);
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [eventsByDate]);

  const handleEventClick = (event: any) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'View Calendar Event',
      children: <ViewCalendarEvent
        eventData={event}
        reloadCalendarEvents={getEventsForInterval}
      />
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if(windowOnFocus) {
        getEventsForInterval();
      }
    }, 300000);

    return () => clearInterval(interval);
  }, [getEventsForInterval, windowOnFocus]);

  const addAppointmentsToMatter = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Import Appointments To Matter",
        children: <ManualAddAppointments />
      }
    );
  }

  return (
    <div ref={setNodeRef} style={style} className={`lp-dashboard-dnd-draggable-item ${isDragging ? 'dragging' : ''}`}>
      <Title
        type="section"
        title={props.title ?? "Calendar"}
        icon={props.dragIcon}
        dragAttributes={{ listeners: listeners, attributes: attributes }}
      >
        {
          <>
            {user.userPermissions?.some(a => a == UserPermissionsNames.ManageCalendars) && 
              <div onClick={addAppointmentsToMatter} className="link">
                Import appointments to matter
                <MdOutlineAdd />
              </div>
            }
            <Link to="/calendar" rel="noopener noreferrer" className="link">
              View full calendar
              <MdArrowOutward />
            </Link>
            <Button onClick={getEventsForInterval} className="btn-icon" variant="primary">
              <MdRefresh />
            </Button>
          </>
        }
      </Title>

      {genericErrors && <div className="lp-errors calendar-errors">{genericErrors}</div>}

      <Card className="with-calendar dashboard">
        <Card.Body>
          <div className="calendar-date-picker">
            <Button className="calendar-date-action calendar-date-action-left" onClick={onPreviousRange}>
              <MdChevronLeft />
            </Button>
            <div className="calendar-dates">
              {dateRange.map((date, index) => (
                <div
                  className={
                    `calendar-date ${selectedDate
                      ? selectedDate.isSame(date, 'day')
                        ? 'selected'
                        : ''
                      : todayDate.isSame(date, 'day')
                        ? 'today' 
                        : ''}`
                  }
                  onClick={() => onSelectedDateChange(date)}
                  key={index}
                >
                  <div className="calendar-date-formatted">{moment(date).format(DateFormat.MomentWithoutYear)}</div>
                  <div className="calendar-date-formatted-day">{moment(date).format(DateFormat.Day)}</div>
                </div>
              ))}
            </div>
            <Button className="calendar-date-action calendar-date-action-right" onClick={onNextRange}>
              <MdChevronRight />
            </Button>
          </div>

          <div className="calendar-events">
            {loading && <Loader inlineLoader />}
            {eventsByDate.length > 0 && !showPastEventsDisabled &&
              <div className="calendar-events-switch lp-switch mb-4">
                <Form.Check
                  type="switch"
                  id="showPastEventsSwitch"
                  label="Show past events"
                  reverse
                  checked={showPastEvents}
                  onChange={onShowPastEventsChange}
                />
              </div>
            }
            {eventsByDate.length === 0 && !loading && <div className="calendar-no-events">No events found in the selected time frame</div>}
            {eventsByDate.map((dateEvents, dateIndex) => (
              <div key={dateIndex} className={`calendar-events-by-date ${dateEvents.date.isSame(todayDate, 'day') ? 'today' : ''}`}>
                <div className="calendar-events-date">
                  {dateEvents.date.isSame(todayDate, 'day')
                    ? 'Today - '
                    : dateEvents.date.isSame(todayDate.clone().add(1, 'day'), 'day')
                      ? 'Tomorrow - '
                      : ''
                  }
                  {dateEvents.date.format(DateFormat.DayWithMomentWithoutYear)}
                </div>
                {dateEvents.events.map((event, eventIndex) => (
                  <React.Fragment key={eventIndex}>
                    {dateIndex === 0 && eventIndex === 0 && timeUntilNextEvent && (
                      <div className="calendar-event-upcoming">
                        <FaRegClock />
                        {timeUntilNextEvent}
                      </div>
                    )}
                    {dateIndex === 0 && eventIndex === 0 && isInProgress(event.startDate, event.endDate) && (
                      <div className="calendar-event-in-progress">
                        <FaRegClock />
                        In progress
                      </div>
                    )}
                    <div
                      className={`calendar-event ${getFreeBusyStatusFromValue(event.showAs ?? 0)} ${moment(event.endDate).isBefore(moment()) ? 'past' : ''}`}
                      onClick={() => handleEventClick(event)}
                    >
                      <div className="calendar-event-time">
                        {event.isAllDay ?
                          <span>All day</span>
                          :
                          <>
                            <span>{moment(event.startDate).format(DateFormat.MomentTime)}</span>
                            <span>{moment(event.endDate).format(DateFormat.MomentTime)}</span>
                          </>
                        }
                      </div>
                      <div className="calendar-event-content">
                        <div className="calendar-event-title">
                          {event.title}
                          {event.private &&
                            <span className="calendar-event-title-private">
                              <MdLock />
                            </span>
                          }
                        </div>
                        {event.location &&
                          <span className="calendar-event-location">{event.location}</span>
                        }
                      </div>
                      {event.onlineMeetingUrl && (isUpcoming(event.startDate) || isInProgress(event.startDate, event.endDate)) &&
                        <div className="calendar-event-join">
                          <Button variant="primary" onClick={(e) => {e.stopPropagation(); openUrlInNewtab(event?.onlineMeetingUrl)}}>
                            Join
                          </Button>
                        </div>
                      }
                    </div>
                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}