import { vestResolver } from "@hookform/resolvers/vest";
import {
  createMatterIncidentalExpenses,
  getMatterClientsSummary,
  getMatterDefaultClient
} from "actions/matter";
import useSlidingPanelActions from "actions/slidingPanel";
import { getUserSummaryForLte } from "actions/user";
import Loader from "components/Loader/index";
import CustomSelect from "components/Select/Select";
import { useAppSelector } from "hooks/appSelector";
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Controller, ErrorOption, FieldErrors, useFieldArray, useForm } from "react-hook-form";
import { DateFormat } from "utils/constants";
import { removeEmptyFields } from "utils/form";
import { getValidationSuite } from "./validationSuite";
import { GridIds } from "enums/GridIds";
import MatterFileSelect from "components/Select/MatterFileSelect";
import { CreateOrUpdateMatterIncidentalExpensesModel } from "models/create/CreateOrUpdateMatterIncidentalExpensesModel";
import { MdAdd, MdClose } from "react-icons/md";
import { getDateOnly } from "utils/date";
import currency from 'currency.js';
import usePageActions from "actions/page";
import { BadgeIds } from "enums/BadgeIds";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { FormErrorTypes } from "enums/FormErrorTypes";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import { CreateOrUpdateMatterIncidentalExpenseModel } from "models/create/CreateOrUpdateMatterIncidentalExpenseModel";
import FormSubmitButton from "components/Buttons/FormSubmitButton";

type Props = {
  matterId: string
}

export default function CreateMatterIncidentalExpensesForm(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const user = useAppSelector((state) => state.user);
  const slidingPanelActions = useSlidingPanelActions();
  const gridState = useAppSelector((state) => state.grid);
  const pageActions = usePageActions();
  const modalActions = useModalActions();
  const [clients, setClients] = useState<DropDownOptionModel[]>([]);
  const [isLoadingClients, setIsLoadingClients] = useState(false);

  const { register, handleSubmit, control, setValue, setError, clearErrors, watch, reset, formState: { errors } } = useForm<CreateOrUpdateMatterIncidentalExpensesModel>({
    resolver: vestResolver(getValidationSuite(clients.length)),
    defaultValues: {
      userId: user.userId,
      incidentalExpenses: [{
        displayName: "",
        description: "",
        date: undefined,
        grossValue: undefined,
        vatValue: undefined,
        netValue: undefined,
        paidByUserOwnFunds: false,
        fileIds: []
      }]
    }
  });

  const { fields, append, remove } = useFieldArray({
    name: "incidentalExpenses",
    control,
    rules: {
      required: "Please append at least 1 incidental expense"
    }
  });

  const appendNewIncidentalExpense = () => {
    append({
      displayName: "",
      description: "",
      date: undefined,
      grossValue: undefined,
      vatValue: undefined,
      netValue: undefined,
      paidByUserOwnFunds: false,
      fileIds: []
    }, { shouldFocus: false });
  }

  const onSubmit = handleSubmit((data) => submitData(data), () => restoreErrors(errors));

  async function submitData(data: CreateOrUpdateMatterIncidentalExpensesModel) {
    setIsLoading(true);

    // Create a flat map of the errors from the incidental expenses section to check if
    // there are files that are not yet uploaded
    const errorsFlat = errors.incidentalExpenses
      ? Object.values(errors.incidentalExpenses).flatMap(e => e ? Object.values(e) : undefined).filter(e => e)
      : [];
    // If there are new files that are not uploaded, show a warning modal
    // And wait for the user to confirm if the action should be performed without the files
    if(errorsFlat.some(e => e?.type === FormErrorTypes.FileNotUploaded)) {
      const modalResponse = await newFilesWarningModal();
      if(modalResponse === false) {
        restoreErrors(errors);
        setIsLoading(false);
        return;
      }
    }

    removeEmptyFields(data);
    createMatterIncidentalExpenses(props.matterId, data).then((response) => {
      if(gridState.id === GridIds.ActivityHistory
        || gridState.id === GridIds.MatterIncidentalExpenses
        || gridState.id === GridIds.ParticipatingEntities
        || gridState.id === GridIds.MatterClientLedger
      ) {
        pageActions.triggerReloadPage();
      }

      pageActions.triggerReloadBadge(BadgeIds.MatterIncidentalExpenses);

      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const newFilesWarningModal = (): Promise<boolean> => {
    return new Promise((resolve) => {
      let modal: ModalState = {
        title: "Selected files have not been saved",
        body: "You have selected some files that you did not save to Activity History. Do you want to continue without these files?",
        actionText: "Continue without the files",
        actionVariant: "success",
        onAction: () => {
          resolve(true);
          modalActions.toggleModalShownStatus();
        },
        onClose: () => {
          resolve(false);
        },
        show: false
      }
      modalActions.setModal(modal);
      modalActions.toggleModalShownStatus();
    });
  }

  const restoreErrors = (errors: FieldErrors<CreateOrUpdateMatterIncidentalExpensesModel>) => {
    // After handleSubmit, the custom errors will be lost and will not be displayed
    // Restore the custom errors from the incidental expenses section for the files that are not uploaded
    if(!errors.incidentalExpenses) {
      return;
    }
    for(const [key, value] of Object.entries(errors.incidentalExpenses)) {
      if(typeof value === 'object') {
        Object.entries(value).forEach(([field, error]) => {
          const fieldKey = field as keyof CreateOrUpdateMatterIncidentalExpenseModel;
          setError(`incidentalExpenses.${Number(key)}.${fieldKey}`, {type: error.type, message: error.message});
        });
      }
    }
  }

  useEffect(() => {
    setIsLoading(true);
    getMatterDefaultClient(props.matterId).then((response: any) => {
      setValue('matterParticipatingEntityId', response.data?.id);
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });

    setIsLoadingClients(true);
    getMatterClientsSummary(props.matterId).then((response) => {
      setClients(response.data)
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingClients(false);
    });
  }, []);

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  const computeNetValue = (index: number) => {
    const grossValue: number = watch(`incidentalExpenses.${index}.grossValue`) ?? 0;
    const vatValue: number = watch(`incidentalExpenses.${index}.vatValue`) ?? 0;

    setValue(`incidentalExpenses.${index}.netValue`, currency(grossValue).subtract(vatValue).value);
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} sm={6} controlId="userId">
            <Form.Label className="required">Fee Earner</Form.Label>
            <Controller
              control={control}
              name={`userId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="userId"
                  inputRef={ref}
                  className={`lp-select${errors?.userId?.message ? ' invalid' : ''}`}
                  endpointCall={() => getUserSummaryForLte(user.lawPageTradingEntityId!)}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.userId?.message && (errors.userId?.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="matterParticipatingEntityId">
            <Form.Label className={clients.length > 0 ? 'required' : ''}>Client</Form.Label>
            <Controller
              control={control}
              name={`matterParticipatingEntityId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="matterParticipatingEntityId"
                  inputRef={ref}
                  className={`lp-select${errors?.matterParticipatingEntityId?.message ? ' invalid' : ''}`}
                  options={clients}
                  isLoading={isLoadingClients}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.matterParticipatingEntityId?.message && (errors.matterParticipatingEntityId?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        {fields.map((field, index) => {
          return (
            <section key={field.id} className="lp-charge-rate-item">
              <Button
                variant="danger"
                onClick={() => remove(index)}
                className={`delete-item btn-icon${fields.length === 1 ? ' disabled' : ''}`}
              >
                <MdClose />
              </Button>

              <Row>
                <Form.Group as={Col} xs={12} sm={6} className="mb-4" controlId={`incidentalExpenses.${index}.date`}>
                  <Form.Label className="required">Date</Form.Label>
                  <Controller
                    control={control}
                    name={`incidentalExpenses.${index}.date`}
                    shouldUnregister={true}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        className={`${errors?.incidentalExpenses?.[index]?.date?.message ? 'invalid' : ''}`}
                        id={`incidentalExpenses.${index}.date`}
                        dateFormat={DateFormat.Datepicker}
                        selected={value ? getDateOnly(value) : null}
                        onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                      />
                    )}
                  />
                  <Form.Text className="lp-error">
                    {errors?.incidentalExpenses?.[index]?.date?.message && (errors.incidentalExpenses?.[index]?.date?.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={6} className="mb-4" controlId={`incidentalExpenses.${index}.displayName`}>
                  <Form.Label className="required">Display Name</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${errors?.incidentalExpenses?.[index]?.displayName?.message ? 'invalid' : ''}`}
                    {...register(`incidentalExpenses.${index}.displayName`, {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.incidentalExpenses?.[index]?.displayName?.message && (errors.incidentalExpenses?.[index]?.displayName?.message)}
                  </Form.Text>
                </Form.Group>
              </Row>

              <Form.Group className="mb-4" controlId={`incidentalExpenses.${index}.description`}>
                <Form.Label className="required">Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  className={`${errors?.incidentalExpenses?.[index]?.description?.message ? 'invalid' : ''}`}
                  {...register(`incidentalExpenses.${index}.description`, {shouldUnregister: true})}
                />
                <Form.Text className="lp-error">
                  {errors?.incidentalExpenses?.[index]?.description?.message && (errors.incidentalExpenses?.[index]?.description?.message)}
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-4" controlId={`incidentalExpenses.${index}.paidByUserOwnFunds`}>
                <Form.Label>Paid By User's Own Funds</Form.Label>
                <Controller
                  control={control}
                  name={`incidentalExpenses.${index}.paidByUserOwnFunds`}
                  shouldUnregister={true}
                  render={({field: { onChange, value, name, ref }}) => (
                    <Form.Check 
                      type="switch"
                      id={`incidentalExpenses.${index}.paidByUserOwnFunds`}>
                        <Form.Check.Input
                          className= "form-check-input"
                          ref={ref}
                          checked={value ?? false}
                          onChange={(ev: any) => onChange(ev.target.checked)}
                        />
                    </Form.Check>
                  )}
                />
              </Form.Group>

              <Row>
                <Form.Group as={Col} xs={12} sm={4} className="mb-4" controlId={`incidentalExpenses.${index}.grossValue`}>
                  <Form.Label className={"required"}>Gross Value</Form.Label>
                  <Form.Control
                    type="number"
                    className={`${errors?.incidentalExpenses?.[index]?.grossValue?.message ? 'invalid' : ''}`}
                    {...register(`incidentalExpenses.${index}.grossValue`, {shouldUnregister: true})}
                    min="0.00"
                    step="0.01"
                    onBlur={() => computeNetValue(index)}
                    onWheel={e => e.currentTarget.blur()}
                  />
                  <Form.Text className="lp-error">
                    {errors?.incidentalExpenses?.[index]?.grossValue?.message && (errors.incidentalExpenses?.[index]?.grossValue?.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={4} className="mb-4" controlId={`incidentalExpenses.${index}.vatValue`}>
                  <Form.Label>VAT Value</Form.Label>
                  <Form.Control
                    type="number"
                    className={`${errors?.incidentalExpenses?.[index]?.vatValue?.message ? 'invalid' : ''}`}
                    {...register(`incidentalExpenses.${index}.vatValue`, {shouldUnregister: true})}
                    min="0.00"
                    step="0.01"
                    onBlur={() => computeNetValue(index)}
                    onWheel={e => e.currentTarget.blur()}
                  />
                  <Form.Text className="lp-error">
                    {errors?.incidentalExpenses?.[index]?.vatValue?.message && (errors.incidentalExpenses?.[index]?.vatValue?.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={4} className="mb-4" controlId={`incidentalExpenses.${index}.netValue`}>
                  <Form.Label>Net Value</Form.Label>
                  <Form.Control
                    type="number"
                    disabled
                    className={`${errors?.incidentalExpenses?.[index]?.netValue?.message ? 'invalid' : ''}`}
                    {...register(`incidentalExpenses.${index}.netValue`, {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.incidentalExpenses?.[index]?.netValue?.message && (errors.incidentalExpenses?.[index]?.netValue?.message)}
                  </Form.Text>
                </Form.Group>
              </Row>

              <Form.Group controlId={`incidentalExpenses.${index}.fileIds`}>
                <Form.Label>Proofs of Purchase</Form.Label>
                <Controller
                  control={control}
                  name={`incidentalExpenses.${index}.fileIds`}
                  shouldUnregister={true}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <MatterFileSelect
                      id={`incidentalExpenses.${index}.fileIds`}
                      matterId={props.matterId}
                      inputRef={ref}
                      className={`lp-select${errors?.incidentalExpenses?.[index]?.fileIds?.message ? ' invalid' : ''}`}
                      setError={(error: ErrorOption) => setError(`incidentalExpenses.${index}.fileIds`, error)}
                      clearErrors={() => clearErrors(`incidentalExpenses.${index}.fileIds`)}
                      errors={errors?.incidentalExpenses?.[index]?.fileIds}
                      value={value}
                      onChange={val => onChange(val ?? null)}
                      isMulti
                      isClearable
                      menuPlacement="top"
                      canUploadNewFiles
                    />
                  )}
                />
              </Form.Group>
            </section>
          );
        })}

        <Form.Group className="mb-4">
          <Button variant="light" onClick={() => appendNewIncidentalExpense()}>
            <MdAdd />
            Add new Incidental Expense
          </Button>
        </Form.Group>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <FormSubmitButton text="Create" errors={{...errors}} />
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
