import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { DropDownOptionModel } from 'models/view/DropDownOptionModel';

export interface UserState {
  msalIdToken?: string,
  msalAccessToken?: string,
  apiToken?: string,
  email?: string,
  firstName?: string,
  lastName?: string,
  displayName?: string,
  userId?: string,
  lawPageTradingEntityId?: string,
  lawPageTradingEntity?: DropDownOptionModel,
  hasInfo?: boolean,
  isFeeEarner?: boolean,
  isDiaryManager?: boolean,
  userRole?: DropDownOptionModel,
  userPermissions?: string[],
  lteHasClientBankAccounts: boolean,
  canChangeLTE: boolean,
  isDelegated: boolean
};

export interface UserMsalTokensState {
  msalIdToken?: string,
  msalAccessToken?: string
};

const initialState: UserState = {
  email: undefined,
  msalIdToken: undefined,
  msalAccessToken: undefined,
  apiToken: undefined,
  firstName: undefined,
  lastName: undefined,
  displayName: undefined,
  userId: undefined,
  lawPageTradingEntityId: undefined,
  lawPageTradingEntity: undefined,
  isDiaryManager: undefined,
  userRole: undefined,
  userPermissions: undefined,
  lteHasClientBankAccounts: false,
  canChangeLTE: false,
  isDelegated: false
};

export const counterSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      state.msalIdToken = action.payload.msalIdToken;
      state.msalAccessToken = action.payload.msalAccessToken;
      state.apiToken = action.payload.apiToken;
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.displayName = action.payload.displayName;
      state.userId = action.payload.userId;
      state.lawPageTradingEntityId = action.payload.lawPageTradingEntityId;
      state.lawPageTradingEntity = action.payload.lawPageTradingEntity;
      state.isFeeEarner = action.payload.isFeeEarner;
      state.hasInfo = action.payload.hasInfo;
      state.userRole = action.payload.userRole;
      state.isDiaryManager = action.payload.isDiaryManager;
      state.userPermissions = action.payload.userPermissions;
      state.lteHasClientBankAccounts = action.payload.lteHasClientBankAccounts;
      state.canChangeLTE = action.payload.canChangeLTE;
      state.isDelegated = action.payload.isDelegated;
    },
    setUserMsalTokens: (state, action: PayloadAction<UserMsalTokensState>) => {
      state.msalIdToken = action.payload.msalIdToken;
      state.msalAccessToken = action.payload.msalAccessToken;
    },
    removeUser: (state) => {
      state.email = undefined;
      state.msalIdToken = undefined;
      state.msalAccessToken = undefined;
      state.apiToken = undefined;
      state.firstName = undefined;
      state.lastName = undefined;
      state.displayName = undefined;
      state.userId = undefined;
      state.lawPageTradingEntityId = undefined;
      state.lawPageTradingEntity = undefined;
      state.isFeeEarner = undefined;
      state.hasInfo = undefined;
      state.userRole = undefined;
      state.userPermissions = undefined;
      state.isDiaryManager = undefined;
      state.lteHasClientBankAccounts = false;
      state.canChangeLTE = false;
      state.isDelegated = false
    }
  }
});

// Action creators are generated for each case reducer function
export const { setUser, setUserMsalTokens, removeUser } = counterSlice.actions;

export default counterSlice.reducer;
