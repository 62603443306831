import { ICellRendererParams } from "ag-grid-community";
import { NavLink } from "react-router-dom";

export default function ChartOfAccountsAccountNameCellRenderer(props: ICellRendererParams) {
  return (
    <>
      {props.data?.accountId &&
        <NavLink to={`/chartOfAccounts/${props.data?.accountId}`}>
          {props.value}
        </NavLink>
      }

      {!props.data?.accountId &&
        <span>{props.value}</span>
      }
    </>
  );
}
