import { Controller, useForm } from "react-hook-form";
import { Dispatch, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import CustomSelect from "components/Select/Select";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { getMatterStagesSummary, reopenMatter } from "actions/matter";
import { GridIds } from "enums/GridIds";
import { ReopenMatterModel } from "models/update/ReopenMatterModel";
import { MatterModel } from "models/view/MatterModel";
import { useAppSelector } from "hooks/appSelector";
import usePageActions from "actions/page";
import FormSubmitButton from "components/Buttons/FormSubmitButton";

type Props = {
  matterId: string,
  matterTypeId: string,
  setMatter: Dispatch<React.SetStateAction<MatterModel | undefined>>
}

export default function ReopenMatterForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const gridState = useAppSelector((state) => state.grid);
  const pageActions = usePageActions();

  const {reset, control, handleSubmit, formState: {errors}} = useForm<ReopenMatterModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: ReopenMatterModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    reopenMatter(props.matterId, data)
      .then((response) => {
        props.setMatter && props.setMatter(response.data);

        if(gridState.id === GridIds.ActivityHistory) {
          pageActions.triggerReloadPage();
        }

        slidingPanelActions.clearSlidingPanel();
        reset();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} controlId="matterStageId">
            <Form.Label className="required">Stage</Form.Label>
            <Controller
              control={control}
              name="matterStageId"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <CustomSelect
                  id="matterStageId"
                  inputRef={ref}
                  className={`lp-select${errors?.matterStageId?.message ? ' invalid' : ''}`}
                  endpointCall={() => getMatterStagesSummary(props.matterTypeId)}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.matterStageId?.message && (errors.matterStageId.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <FormSubmitButton text="Reopen" errors={{...errors}} />
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
