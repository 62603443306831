import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { getMatterDisbursementById, updateMatterDisbursementWriteOff, deleteMatterDisbursementWriteOff } from "actions/matter";
import { UpdateWriteOffModel } from "models/update/UpdateWriteOffModel";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { DateFormat } from "utils/constants";
import { getDateOnly } from "utils/date";
import moment from "moment";
import Field from "components/Fields/Field";
import { ModalState } from "state/modalSlice";
import { formatCurrency } from "utils/misc";
import useModalActions from "actions/modal";
import { MatterDisbursementModel } from "models/view/MatterDisbursementModel";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import usePageActions from "actions/page";
import FormSubmitButton from "components/Buttons/FormSubmitButton";

type Props = {
  matterId: string,
  matterDisbursementId: string,
  onSubmitCallback?: Function,
  onDeleteCallback?: Function
}

export default function MatterDisbursementWriteOffForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const [disbursement, setDisbursement] = useState<MatterDisbursementModel>();
  const modalActions = useModalActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const loggedInUser = useAppSelector((state) => state.user);
  const pageActions = usePageActions();

  const {reset, control, register, handleSubmit, formState: {errors}} = useForm<UpdateWriteOffModel>({
    resolver: vestResolver(validationSuite)
  });

  useEffect(() => {
    setIsLoading(true);
    getMatterDisbursementById(props.matterId, props.matterDisbursementId).then((response) => {
      setDisbursement(response.data);
      let initialState: UpdateWriteOffModel = {
        date: response.data.writeOffDate,
        amount: response.data.writeOffAmount ?? response.data.amountDue,
        description: response.data.writeOffDescription,
        maxAmountPossible: response.data.amountDue
      };

      reset(initialState);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: UpdateWriteOffModel) {
    setIsLoading(true);
    if(data) {
      removeEmptyFields(data);
    }
    updateMatterDisbursementWriteOff(props.matterId, props.matterDisbursementId, data)
      .then((response) => {
        pageActions.triggerReloadPage();

        slidingPanelActions.clearSlidingPanel();
        reset();

        props.onSubmitCallback && props.onSubmitCallback();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();

    currentSlidingPanelState.onCancel !== undefined && currentSlidingPanelState?.onCancel();
  }

  const deleteModal = () => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Matter Disbursement Write-Off?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  }

  const deleteCallback = () => {
    modalActions.toggleModalLoading();
    deleteMatterDisbursementWriteOff(props.matterId, props.matterDisbursementId)
      .then((response) => {
        pageActions.triggerReloadPage();

        modalActions.toggleModalShownStatus();
        slidingPanelActions.clearSlidingPanel();
        reset();

        props.onDeleteCallback && props.onDeleteCallback();
      })
      .catch((error) => {
        modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        modalActions.toggleModalLoading();
      });
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      {disbursement &&
        <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
          <Row>
            <Col>
              <Field
                label={"Amount Due"}
                value={disbursement?.amountDue ? formatCurrency(disbursement?.amountDue) : "—"}
              />
            </Col>
          </Row>

          {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterDisbursements) && !disbursement.writeOffAmount && 
            <>
              <Row>
                <Form.Group as={Col} sm={6} controlId="note">
                  <Form.Label className="required">Date</Form.Label>
                    <Controller
                      control={control}
                      name="date"
                      shouldUnregister={true}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          className={`${errors?.date?.message ? 'invalid' : ''}`}
                          id="date"
                          dateFormat={DateFormat.Datepicker}
                          selected={value ? getDateOnly(value) : null}
                          onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                          showMonthDropdown
                          showYearDropdown
                          autoComplete="off"
                        />
                      )}
                    />
                    <Form.Text className="lp-error">
                      {errors?.date?.message && (errors.date.message)}
                    </Form.Text>
                </Form.Group>

                <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="amount">
                  <Form.Label className={"required"}>Amount</Form.Label>
                  <Form.Control
                    type="number"
                    className={`${errors?.amount?.message ? 'invalid' : ''}`}
                    {...register(`amount`, {shouldUnregister: true})}
                    min="0.00"
                    step="0.01"
                    max={disbursement.grossValue}
                    onWheel={e => e.currentTarget.blur()}
                  />
                  <Form.Text className="lp-error">
                    {errors?.amount?.message && (errors.amount?.message)}
                  </Form.Text>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} controlId="description">
                  <Form.Label className="required">Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    className={`${errors?.description?.message ? 'invalid' : ''}`}
                    {...register(`description`, {shouldUnregister: true})}
                  />
                  <Form.Text className="lp-error">
                    {errors?.description?.message && (errors.description?.message)}
                  </Form.Text>
                </Form.Group>
              </Row>
            </>
          }

          {(!loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterDisbursements) || disbursement.writeOffAmount) && 
            <>
              <Row>
                <Col sm={6}>
                  <Field
                    label={"Date"}
                    value={disbursement?.writeOffDate ? moment(disbursement?.writeOffDate).format(DateFormat.Moment) : "—"}
                  />
                </Col>
                <Col sm={6} className="mt-4 mt-sm-0">
                  <Field
                    label={"Amount"}
                    value={disbursement?.writeOffAmount ? formatCurrency(disbursement?.writeOffAmount) : "—"}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    label={"Description"}
                    value={disbursement?.writeOffDescription}
                  />
                </Col>
              </Row>
            </>
          }

          {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterDisbursements) && 
            !disbursement.matter?.isClosed &&
            <div className="lp-slide-panel-sticky-bottom">
              <Form.Group className="d-flex justify-content-between">
                {!disbursement.writeOffAmount && !disbursement.isBilled
                  ? <FormSubmitButton text="Update" errors={{...errors}} />
                  : null
                }
                {disbursement.writeOffAmount && !disbursement.isBilled &&
                  <Button variant="danger" onClick={deleteModal} >Delete</Button>
                }
                {!disbursement.isBilled &&
                  <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
                }
              </Form.Group>
            </div>
          }
        </Form>
      }
    </>
  );
}
