import { ColDef, ICellRendererParams } from "ag-grid-community";
import { MatterModel } from "models/view/MatterModel";
import RiskRatingCellRenderer from "components/Grid/RiskRatingCellRenderer";
import ConflictCellRenderer from "components/Grid/ConflictCellRenderer";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { NavigateFunction } from "react-router-dom";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import store from "state/store";
import { decreasePriorityMatter, deleteMatter, getConflicts, getFundingAssuranceChecks, getMatterTypesWithoutMatterStages, getRiskRatings, increasePriorityMatter } from 'actions/matter';
import { setRowData } from "state/gridSlice";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { getChargingSchemes } from "actions/chargingSchemes";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { Priority } from "enums/Priority";
import PriorityFormatter from "components/Grid/ValueFormatters/PriorityFormatter";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import { getPriorities } from "utils/misc";
import MatterRefCellRenderer from "components/Grid/MatterRefCellRenderer";
import { DropDownOptionWithChildrenModel } from "models/view/DropDownOptionWithChildrenModel";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";

const viewCallback = (id: string, navigate: NavigateFunction) => {
  navigate(`/matter/${id}`, {state: { "edit": false}});
};

const deleteModal = (id: string) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this Matter?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const deleteCallback = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteMatter(id).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: any) => x.id !== id)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

const increasePriorityModal = (matterId: string, triggerReloadPage: Function) => {
  let modal: ModalState = {
    title: "Increase priority confirmation",
    body: "Are you sure you want to increase the priority?",
    actionText: "Increase",
    onAction: () => increasePriorityCallback(matterId, triggerReloadPage),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
}

const increasePriorityCallback = (matterId: string, triggerReloadPage: Function) => {
  store.dispatch(toggleModalLoadingState());
  increasePriorityMatter(matterId).then(() => {
    triggerReloadPage(true, false);
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
  return;
}

const decreasePriorityModal = (matterId: string, triggerReloadPage: Function) => {
  let modal: ModalState = {
    title: "Decrease priority confirmation",
    body: "Are you sure you want to decrease the priority?",
    actionText: "Decrease",
    onAction: () => decreasePriorityCallback(matterId, triggerReloadPage),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
}

const decreasePriorityCallback = (matterId: string, triggerReloadPage: Function) => {
  store.dispatch(toggleModalLoadingState());
  decreasePriorityMatter(matterId).then(() => {
    triggerReloadPage(true, false);
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
  return;
}

export const getColumnDefs = (triggerReloadPage: Function, matterTypes: DropDownOptionWithChildrenModel[], openStages: DropDownOptionModel[]) => {
  const loggedInUser = store.getState().user;
  const buttonsColumnWidth = loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.DeleteMatters) ? 105 : 65;
  
  const columnDefs: (ColDef<MatterModel> | {excludeFromExport: boolean})[] = [
    { 
      headerName: 'Matter Ref',
      field: 'fileNumberWithDisplayName',
      filter: 'agTextColumnFilter',
      cellRenderer: (e: ICellRendererParams) => MatterRefCellRenderer({...e, idFieldName: 'id'}),
      suppressSizeToFit: true,
      minWidth: 300,
      width: 300
    },
    { 
      headerName: 'Description',
      field: 'description',
      filter: 'agTextColumnFilter',
      suppressSizeToFit: true,
      minWidth: 450,
      width: 450,
    },
    { 
      headerName: 'Type',
      field: 'matterType.name',
      filter: DropdownFilter,
      filterParams: {
        property: 'matterTypeId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getMatterTypesWithoutMatterStages
      },
      minWidth: 220
    },
    { 
      headerName: 'Stage',
      field: 'matterStage.name',
      filter: DropdownFilter,
      filterParams: {
        property: 'matterStageId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: () => Promise.resolve(matterTypes),
        defaultModel: openStages
      },
      minWidth: 220
    },
    { 
      headerName: 'Conflict',
      field: 'conflictStatus.name',
      cellRenderer: ConflictCellRenderer,
      filter: DropdownFilter,
      filterParams: {
        property: 'conflictStatusId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getConflicts
      },
      minWidth: 220
    },
    { 
      headerName: 'Funding Assurance Check',
      field: 'fundingAssuranceStatus.name',
      cellRenderer: ConflictCellRenderer,
      filter: DropdownFilter,
      filterParams: {
        property: 'fundingAssuranceStatusId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getFundingAssuranceChecks
      },
      minWidth: 220
    },
    { 
      headerName: 'Risk Rating',
      field: 'riskRating.name',
      cellRenderer: RiskRatingCellRenderer,
      filter: DropdownFilter,
      filterParams: {
        property: 'riskRatingId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getRiskRatings
      },
      minWidth: 220
    },
    { 
      headerName: 'Charging Scheme',
      field: 'chargingScheme.name', 
      filter: DropdownFilter,
      filterParams: {
        property: 'chargingSchemeId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getChargingSchemes
      },
      minWidth: 220
    },
    {
      headerName: 'Priority',
      field: 'priority',
      enableRowGroup: true,
      minWidth: 180,
      width: 180,
      valueFormatter: PriorityFormatter,
      filter: DropdownFilter,
      filterParams: {
        property: 'priority',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: () => Promise.resolve({data: getPriorities().map((x: any) => ({id: x.id.toString(), name: x.name}))})
      },
    },
    {
      headerName: "Is Current User Fee Earner",
      field: "isCurrentUserMatterFeeEarner",
      minWidth: 120,
      width: 120,
      filter: BooleanFilter,
      filterParams: {
        property: 'isCurrentUserMatterFeeEarner',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
      ]},
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter,
      hide: true,
    },
    { 
      excludeFromExport: true,
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        return { buttonsData: [
          {
            type: ActionButtonTypes.View,
            callback: (id: string, navigate: NavigateFunction) => viewCallback(id, navigate)
          },
          ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatters) ? [
            ...(params.data.priority !== Priority.High ? [{
              type: ActionButtonTypes.IncreasePriority,
              callback: (id: string) => increasePriorityModal(id, triggerReloadPage)
            }] : []),
            ...(params.data.priority !== Priority.Low ? [{
              type: ActionButtonTypes.DecreasePriority,
              callback: (id: string) => decreasePriorityModal(id, triggerReloadPage)
            }] : []),
          ] : []),
          ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.DeleteMatters) ? [{
            type: ActionButtonTypes.Delete,
            callback: (id: string) => deleteModal(id)
          }] : [])
        ]};
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: buttonsColumnWidth,
      maxWidth: buttonsColumnWidth,
      width: buttonsColumnWidth,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];
  return columnDefs;
}
