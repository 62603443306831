import React, { useEffect, useState } from "react";
import Title from "components/Title";
import { Button, Accordion, Col, Row } from 'react-bootstrap';
import { MdAdd, MdRemoveRedEye, MdOutlineLinkOff } from "react-icons/md";
import useSlidingPanelActions from "actions/slidingPanel";
import { deleteEntityLink, getOwnsEntityLinks } from "actions/entity";
import CreateOwnsEntityLinkForm from "./CreateOwnsEntityLink/CreateOwnsEntityLink";
import { LinkedEntityModel } from "models/view/LinkedEntityModel";
import Loader from "components/Loader";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import { useNavigate } from "react-router-dom";
import LinkedEntityEmailList from "../LinkedEntityEmails/LinkedEntityEmailList";
import LinkedEntityDocumentExchangeList from "../LinkedEntityDocumentExchanges/LinkedEntityDocumentExchangeList";
import LinkedEntityAddressList from "../LinkedEntityAddresses/LinkedEntityAddressList";
import LinkedEntityPhoneNumberList from "../LinkedEntityPhoneNumbers/LinkedEntityPhoneNumberList";
import CustomAccordionItem from "components/CustomAccordionItem/CustomAccordionItem";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

type Props = {
  entityId: string,
  edit: boolean,
  entityIsShared: boolean
}

export default function ViewLinkedEntitiesOwns(props: Props) {
  const [entityLinks, setEntityLinks] = useState<LinkedEntityModel[]>([]);
  const [edit, setEdit] = useState<boolean>(props.edit ?? false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();
  const navigate = useNavigate();
  const loggedInUser = useAppSelector((state) => state.user);

  useEffect(() =>
  {
    loadOwnsEntityLinks();
  }, [props.entityId]);

  const loadOwnsEntityLinks = () => {
    if(props.entityId) {
      setIsLoading(true);
      getOwnsEntityLinks(props.entityId).then((response) => {
        setEntityLinks(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
  }
  
  const openCreateOwnsEntityLinkSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Create Link',
      children: <CreateOwnsEntityLinkForm
                  entityId={props.entityId}
                  submitCallback={reloadAndResetLinkedEntities} />
    });
  };

  const reloadAndResetLinkedEntities = () => {
    loadOwnsEntityLinks();
  }

  const openViewEntity = (id: string) => {
    navigate(`/addressBook/${id}`, {state: { "edit": false}});
  };

  const deleteCallbackEntityLink = (id: string) => {
    modalActions.toggleModalLoading();
    deleteEntityLink(id).then(() => {
      reloadAndResetLinkedEntities();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
      modalActions.toggleModalShownStatus();
    });
  };

  const deleteEntityLinkModal = (id: string) => {
    let modal: ModalState = {
      title: "Unlink confirmation",
      body: "Are you sure you want to unlink this entity?",
      actionText: "Unlink",
      actionVariant: "danger",
      onAction: () => deleteCallbackEntityLink(id),
      show: true
    }
    modalActions.setModal(modal);
  };

  return (
    <>
      <Title
        type="section"
        title="Owns"
      >
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && !props.entityIsShared &&
          <Button onClick={openCreateOwnsEntityLinkSlidingPanel} className="btn-icon" variant="success">
            <MdAdd />
          </Button>
        }
      </Title>

      <div className="lp-linked-entity-content">
        {genericErrors && (
          <div className="lp-errors">
            {genericErrors}
          </div>
        )}

        {isLoading && 
          <Loader inlineLoader />
        }

        {!isLoading && entityLinks.length == 0 &&
          <div className="lp-list-empty">No links found</div>
        }

        {!isLoading && entityLinks.length > 0 &&
          <Accordion>
            {entityLinks.map((el: LinkedEntityModel, index: number) => 
              <React.Fragment key={index}>
                <CustomAccordionItem
                  header={
                    <div className="title">
                      <span className="name">{el.entity!.displayName}</span>
                      <Button onClick={() => openViewEntity(el.entity!.id)} className="btn-icon" variant="primary">
                        <MdRemoveRedEye />
                      </Button>
                      {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && !props.entityIsShared &&
                        <Button onClick={() => deleteEntityLinkModal(el.id)} className="btn-icon" variant="danger">
                          <MdOutlineLinkOff />
                        </Button>
                      }
                    </div>
                  }
                  eventKey={el.id}
                >
                  <>
                    <Row>
                      <Col xxl={6}>
                        <LinkedEntityPhoneNumberList
                          entityId={props.entityId}
                          linkedEntityId={el.entity!.id}
                          linkedEntityPhoneNumbers={el.linkedEntityPhoneNumbers ?? []}
                          linkType={'owns'}
                          edit={edit}
                          entityIsShared={props.entityIsShared}
                        />
                      </Col>
                      <Col xxl={6} className="mt-4 mt-xxl-0">
                        <LinkedEntityEmailList
                          entityId={props.entityId}
                          linkedEntityId={el.entity!.id}
                          linkedEntityEmails={el.linkedEntityEmails ?? []}
                          linkType={'owns'}
                          edit={edit}
                          entityIsShared={props.entityIsShared}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xxl={6}>
                        <LinkedEntityAddressList
                          entityId={props.entityId}
                          linkedEntityId={el.entity!.id}
                          linkedEntityAddresses={el.linkedEntityAddresses ?? []}
                          linkType={'owns'}
                          edit={edit}
                          entityIsShared={props.entityIsShared}
                        />
                      </Col>
                      <Col xxl={6} className="mt-4 mt-xxl-0">
                        <LinkedEntityDocumentExchangeList 
                          entityId={props.entityId}
                          linkedEntityId={el.entity!.id}
                          linkedEntityDocumentExchanges={el.linkedEntityDocumentExchanges ?? []}
                          linkType={'owns'}
                          edit={edit}
                          entityIsShared={props.entityIsShared}
                        />
                      </Col>
                    </Row>
                  </>
                </CustomAccordionItem>
              </React.Fragment>
            )}
          </Accordion>
        }
      </div>
    </>
  );
}
