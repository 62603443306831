import Field from "components/Fields/Field";
import { vestResolver } from "@hookform/resolvers/vest";
import { getAllRecordableEventTypes, getChargingSchemesFromLte } from "actions/chargingSchemes";
import { 
  getActivitiesForChargingSchemeFeeEarner,
  getActivitiesForChargingSchemeFeeEarnerAndEventType,
  getFeeEarnerFromMatterFeeEarnerOrDefault,
  getMatterClientsSummary,
  getMatterDefaultClient,
  recordTime 
} from "actions/matter";
import useSlidingPanelActions from "actions/slidingPanel";
import { getUserSummaryForLte } from "actions/user";
import Loader from "components/Loader/index";
import CustomSelect from "components/Select/Select";
import { useAppSelector } from "hooks/appSelector";
import { CreateOrUpdateMatterRecordableEventModel } from "models/create/CreateOrUpdateMatterRecordableEventModel";
import { CreateOrUpdateMatterRecordableItemModel } from "models/create/CreateOrUpdateMatterRecordableItemModel";
import { ActivityDropdownForRecordTimeModel } from "models/view/ActivityDropdownForRecordTimeModel";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Controller, ErrorOption, FieldErrors, useFieldArray, useForm } from "react-hook-form";
import { MdAdd, MdClose, MdWarning } from 'react-icons/md';
import { DateFormat, RecordableEventMinutesWarningLimit } from "utils/constants";
import { removeEmptyFields } from "utils/form";
import { getValidationSuite } from "./validationSuite";
import { ChargingBasisIds } from "enums/ChargingBasisIds";
import { GridIds } from "enums/GridIds";
import MatterFileSelect from "components/Select/MatterFileSelect";
import { getDateOnly } from "utils/date";
import TooltipIcon from "components/TooltipIcon";
import usePageActions from "actions/page";
import { BadgeIds } from "enums/BadgeIds";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import { FormErrorTypes } from "enums/FormErrorTypes";
import { CreateOrUpdateMatterIncidentalExpenseModel } from "models/create/CreateOrUpdateMatterIncidentalExpenseModel";
import FormSubmitButton from "components/Buttons/FormSubmitButton";

type Props = {
  chargingSchemeId: string,
  matterId: string
}

export default function RecordTime(props: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [genericErrors, setGenericErrors] = useState(null);
  const genericErrorsRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector((state) => state.user);
  const [feeEarnerLevel, setFeeEarnerLevel] = useState<DropDownOptionModel>();
  const [activities, setActivities] = useState<ActivityDropdownForRecordTimeModel[]>([]);
  const [isLoadingActivities, setIsLoadingActivities] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const gridState = useAppSelector((state) => state.grid);
  const pageActions = usePageActions();
  const modalActions = useModalActions();
  const [clients, setClients] = useState<DropDownOptionModel[]>([]);
  const [isLoadingClients, setIsLoadingClients] = useState(false);

  const { register, handleSubmit, control, setValue, setError, clearErrors, watch, trigger, reset, formState: { errors } } = useForm<CreateOrUpdateMatterRecordableEventModel>({
    resolver: vestResolver(getValidationSuite(clients.length)),
    defaultValues: {
      userId: user.userId,
      chargingSchemeId: props.chargingSchemeId,
      hasIncidentalExpenses: false
    }
  });

  const { fields: recordableItemFields, append: appendRecordableItem, remove: removeRecordableItem } = useFieldArray({
    name: "recordableItems",
    control,
    rules: {
      required: "Please append at least 1 item"
    }
  });

  const appendNewRecordableEvent = () => {
    appendRecordableItem({
      activityId: "",
      startTime: "",
      endTime: "",
      numberOfMinutes: undefined,
    }, { shouldFocus: false });
  };

  const { fields: incidentalExpenseFields, append: appendIncidentalExpense, remove: removeIncidentalExpense } = useFieldArray({
    name: "incidentalExpenses",
    control
  });

  const appendNewIncidentalExpense = () => {
    appendIncidentalExpense({
      displayName: "",
      description: "",
      date: undefined,
      grossValue: undefined,
      vatValue: undefined,
      netValue: undefined,
      paidByUserOwnFunds: false,
      fileIds: []
    }, { shouldFocus: false });
  };

  useEffect(() => {
    changeFeeEarnerLevel(user.userId!);
    appendNewRecordableEvent();

    setIsLoading(true);
    getMatterDefaultClient(props.matterId).then((response: any) => {
      setValue('matterParticipatingEntityId', response.data?.id);
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });

    setIsLoadingClients(true);
    getMatterClientsSummary(props.matterId).then((response) => {
      setClients(response.data)
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingClients(false);
    });
  }, []);

  useEffect(() => {
    if(genericErrorsRef.current)
    {
      genericErrorsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [genericErrors]);

  const changeFeeEarnerLevel = (userId: string) => {
    setIsLoading(true);
    getFeeEarnerFromMatterFeeEarnerOrDefault(props.matterId, userId).then((response) => {
      setFeeEarnerLevel(response.data);
      setValue('feeEarnerLevelId', response.data?.id);
      populateItemsOnChange();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  const populateActivities = () => {
    const chargingSchemeId: string = watch('chargingSchemeId');
    const feeEarnerLevelId: string | undefined = watch('feeEarnerLevelId');
    if(feeEarnerLevelId != undefined) {
      setIsLoadingActivities(true);
      getActivitiesForChargingSchemeFeeEarner(chargingSchemeId, feeEarnerLevelId!).then((response) => {
        setActivities(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoadingActivities(false);
      });
    }
  }

  useEffect(() => {
    populateActivities();
  }, [watch('feeEarnerLevelId'), watch('chargingSchemeId')]);

  const onBlurNumberOfMinutes = (index: number) => {
    const numberOfMinutes: number = watch(`recordableItems.${index}.numberOfMinutes`) ?? 0;
    const minutesPerUnit: number = watch(`recordableItems.${index}.minutesPerUnit`) ?? (watch(`recordableItems.${index}.chargingBasis`) == ChargingBasisIds.TimeId ? 1 : 0);

    if(minutesPerUnit) {
      setValue(`recordableItems.${index}.numberOfUnits`, Math.ceil(numberOfMinutes / minutesPerUnit));
    }

    trigger(`recordableItems.${index}.numberOfMinutes`);
  }

  const onBlurNumberOfUnits = (index: number) => {
    const numberOfUnits: number = watch(`recordableItems.${index}.numberOfUnits`) ?? 0;
    const minutesPerUnit: number = watch(`recordableItems.${index}.minutesPerUnit`) ?? (watch(`recordableItems.${index}.chargingBasis`) == ChargingBasisIds.TimeId ? 1 : 0);

    setValue(`recordableItems.${index}.numberOfMinutes`, Math.ceil(numberOfUnits * minutesPerUnit));

    trigger(`recordableItems.${index}.numberOfUnits`);
  }

  const onChangeActivity = (index: number) => {
    setValue(`recordableItems.${index}.startTime`, "");
    setValue(`recordableItems.${index}.endTime`, "");
    setValue(`recordableItems.${index}.numberOfMinutes`, undefined);
    setValue(`recordableItems.${index}.numberOfUnits`, undefined);
  }

  const onBlurTime = (index: number) => {
    const startTime: string = watch(`recordableItems.${index}.startTime`) ?? "";
    const endTime: string = watch(`recordableItems.${index}.endTime`) ?? "";    

    //clear startTime if it's not valid
    if (!startTime) {
      setValue(`recordableItems.${index}.startTime`, "");
      setValue(`recordableItems.${index}.numberOfMinutes`, undefined);
    }

    //clear endTime if it's not valid
    if (!endTime) {
      setValue(`recordableItems.${index}.endTime`, "");
      setValue(`recordableItems.${index}.numberOfMinutes`, undefined);
    }

    trigger(`recordableItems.${index}.startTime`);
    trigger(`recordableItems.${index}.endTime`);
    trigger(`recordableItems.${index}.numberOfMinutes`);

    //if startTime or endTime is invalid, don't go lower
    if(!startTime || !endTime) {
      return;
    }

    const calculatedMinutesStartTime: number = Number(startTime.split(":")[0]) * 60 + Number(startTime.split(":")[1]);
    const calculatedMinutesEndTime: number = Number(endTime.split(":")[0]) * 60 + Number(endTime.split(":")[1]);
    let calculatedMinutes: number;
    if(calculatedMinutesEndTime >= calculatedMinutesStartTime) {
      calculatedMinutes = calculatedMinutesEndTime - calculatedMinutesStartTime;
    }
    else {
      calculatedMinutes = calculatedMinutesEndTime + 24 * 60 - calculatedMinutesStartTime;
    }

    setValue(`recordableItems.${index}.numberOfMinutes`, calculatedMinutes);
    onBlurNumberOfMinutes(index);
  }

  const onSubmit = handleSubmit((data) => submitData(data), () => restoreErrors(errors));

  async function submitData(data: CreateOrUpdateMatterRecordableEventModel) {
    setIsLoading(true);

    // Create a flat map of the errors from the incidental expenses section to check if
    // there are files that are not yet uploaded
    const errorsFlat = errors.incidentalExpenses
      ? Object.values(errors.incidentalExpenses).flatMap(e => e ? Object.values(e) : undefined).filter(e => e)
      : [];
    // If there are new files that are not uploaded, show a warning modal
    // And wait for the user to confirm if the action should be performed without the files
    if(errorsFlat.some(e => e?.type === FormErrorTypes.FileNotUploaded)) {
      const modalResponse = await newFilesWarningModal();
      if(modalResponse === false) {
        restoreErrors(errors);
        setIsLoading(false);
        return;
      }
    }

    removeEmptyFields(data);
    const requestBody = { ...data };
    requestBody.matterId = props.matterId;
    requestBody.recordableItems = data.recordableItems.map((x: CreateOrUpdateMatterRecordableItemModel) => (
      {
        ...x,
        //pad with seconds only if it's needed.
        ...(x.startTime && { startTime: x.startTime + ":00" }),
        ...(x.endTime && { endTime: x.endTime + ":00" })
      }));
    recordTime(requestBody, props.matterId).then((response) => {
      if(gridState.id === GridIds.ActivityHistory
        || gridState.id === GridIds.MatterRecordableItems
        || gridState.id === GridIds.ParticipatingEntities
      ) {
        pageActions.triggerReloadPage();
      }
      pageActions.triggerReloadBadge(BadgeIds.MatterRecordableItems);

      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const newFilesWarningModal = (): Promise<boolean> => {
    return new Promise((resolve) => {
      let modal: ModalState = {
        title: "Selected files have not been saved",
        body: "You have selected some files that you did not save to Activity History. Do you want to continue without these files?",
        actionText: "Continue without the files",
        actionVariant: "success",
        onAction: () => {
          resolve(true);
          modalActions.toggleModalShownStatus();
        },
        onClose: () => {
          resolve(false);
        },
        show: false
      }
      modalActions.setModal(modal);
      modalActions.toggleModalShownStatus();
    });
  }

  const restoreErrors = (errors: FieldErrors<CreateOrUpdateMatterRecordableEventModel>) => {
    // After handleSubmit, the custom errors will be lost and will not be displayed
    // Restore the custom errors from the incidental expenses section for the files that are not uploaded
    if(!errors.incidentalExpenses) {
      return;
    }
    for(const [key, value] of Object.entries(errors.incidentalExpenses)) {
      if(typeof value === 'object') {
        Object.entries(value).forEach(([field, error]) => {
          const fieldKey = field as keyof CreateOrUpdateMatterIncidentalExpenseModel;
          setError(`incidentalExpenses.${Number(key)}.${fieldKey}`, {type: error.type, message: error.message});
        });
      }
    }
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  const populateItemsOnChange = () => {
    const chargingSchemeId: string = watch('chargingSchemeId');
    const feeEarnerLevelId: string | undefined = watch('feeEarnerLevelId');
    const eventTypeId: string | undefined = watch('eventTypeId');

    if (chargingSchemeId == undefined || feeEarnerLevelId == undefined) {
      return;
    }

    setIsLoading(true);
    removeRecordableItem();
    if(eventTypeId != undefined) {
      getActivitiesForChargingSchemeFeeEarnerAndEventType(chargingSchemeId, feeEarnerLevelId!, eventTypeId).then((response) => {
        if (response.data.length == 0) {
          appendNewRecordableEvent();
          return;
        }
        response.data.forEach((x: ActivityDropdownForRecordTimeModel, index: number) => {
          appendRecordableItem({
            activityId: x.id,
            startTime: "",
            endTime: "",
            numberOfMinutes: undefined,
            chargingBasis: x.chargingBasis,
            minutesPerUnit: Number(x.minutesPerUnit)
          }, { shouldFocus: false });
        })
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
    else {
      appendNewRecordableEvent();
      setIsLoading(false);
    }
  };

  const computeIncidentalExpenseNetValue = (index: number) => {
    const grossValue: number = watch(`incidentalExpenses.${index}.grossValue`) ?? 0;
    const vatValue: number = watch(`incidentalExpenses.${index}.vatValue`) ?? 0;

    setValue(`incidentalExpenses.${index}.netValue`, grossValue - vatValue);
  };

  const onChangeHasIncidentalExpenses = (value: boolean) => {
    if(value) {
      appendNewIncidentalExpense();
    }
    else {
      removeIncidentalExpense();
    }
  };

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div ref={genericErrorsRef} className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} sm={6} controlId="userId">
            <Form.Label className="required">Fee Earner</Form.Label>
            <Controller
              control={control}
              name={`userId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="userId"
                  inputRef={ref}
                  className={`lp-select${errors?.userId?.message ? ' invalid' : ''}`}
                  endpointCall={() => getUserSummaryForLte(user.lawPageTradingEntityId!)}
                  value={value}
                  onChange={val => (onChange(val?.id ?? null), changeFeeEarnerLevel(val?.id))}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.userId?.message && (errors.userId?.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0">
            <Field
              label={"Fee Earner Level"}
              value={feeEarnerLevel?.name}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="matterParticipatingEntityId">
            <Form.Label className={clients.length > 0 ? 'required' : ''}>Client</Form.Label>
            <Controller
              control={control}
              name={`matterParticipatingEntityId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="matterParticipatingEntityId"
                  inputRef={ref}
                  className={`lp-select${errors?.matterParticipatingEntityId?.message ? ' invalid' : ''}`}
                  options={clients}
                  isLoading={isLoadingClients}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.matterParticipatingEntityId?.message && (errors.matterParticipatingEntityId?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="date">
            <Form.Label className="required">Date</Form.Label>
            <Controller
              control={control}
              name="date"
              shouldUnregister={true}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  className={`${errors?.date?.message ? 'invalid' : ''}`}
                  id="date"
                  dateFormat={DateFormat.Datepicker}
                  selected={value ? getDateOnly(value) : null}
                  onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                  showMonthDropdown
                  showYearDropdown
                  autoComplete="off"
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.date?.message && (errors.date.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="displayName">
            <Form.Label className="required">Display Name</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.displayName?.message ? 'invalid' : ''}`}
              {...register(`displayName`, {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.displayName?.message && (errors.displayName?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="description">
            <Form.Label className="required">Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              className={`${errors?.description?.message ? 'invalid' : ''}`}
              {...register(`description`, {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.description?.message && (errors.description?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="chargingSchemeId">
            <Form.Label className="required">Charging Scheme</Form.Label>
            <Controller
              control={control}
              name={`chargingSchemeId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="chargingSchemeId"
                  inputRef={ref}
                  className={`lp-select${errors?.chargingSchemeId?.message ? ' invalid' : ''}`}
                  endpointCall={() => getChargingSchemesFromLte(user.lawPageTradingEntityId!)}
                  value={value}
                  onChange={val => (onChange(val?.id ?? null), populateItemsOnChange())}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.chargingSchemeId?.message && (errors.chargingSchemeId?.message)}
            </Form.Text>
          </Form.Group>

          {/*<Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="eventTypeId">
            <Form.Label>Event Type</Form.Label>
            <Controller
              control={control}
              name={`eventTypeId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="eventTypeId"
                  inputRef={ref}
                  className={`lp-select${errors?.eventTypeId?.message ? ' invalid' : ''}`}
                  endpointCall={getAllRecordableEventTypes}
                  value={value}
                  onChange={val => (onChange(val?.id ?? null), populateItemsOnChange())}
                  isClearable
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.eventTypeId?.message && (errors.eventTypeId?.message)}
            </Form.Text>
          </Form.Group>*/}
        </Row>

        {recordableItemFields.map((field, index) => {
          return (
            <section key={field.id} className="lp-charge-rate-item">
              <Button
                variant="danger"
                onClick={() => removeRecordableItem(index)}
                className={`delete-item btn-icon${recordableItemFields.length === 1 ? ' disabled' : ''}`}
              >
                <MdClose />
              </Button>

              <Form.Group className="mb-4" controlId={`recordableItems.${index}.activityId`}>
                <Form.Label className="required">Activity</Form.Label>
                <Controller
                  control={control}
                  name={`recordableItems.${index}.activityId`}
                  shouldUnregister={true}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <CustomSelect
                      id="activityId"
                      inputRef={ref}
                      className={`lp-select${errors?.recordableItems?.[index]?.activityId?.message ? ' invalid' : ''}`}
                      options={activities}
                      isLoading={isLoadingActivities}
                      value={value}
                      onChange={val => (onChange(val?.id ?? null), onChangeActivity(index), setValue(`recordableItems.${index}.chargingBasis`, val.chargingBasis), setValue(`recordableItems.${index}.minutesPerUnit`, val.minutesPerUnit))}
                    />
                  )}
                />
                <Form.Text className="lp-error">
                  {errors.recordableItems?.[index]?.activityId?.message && (errors.recordableItems?.[index]?.activityId?.message)}
                </Form.Text>
              </Form.Group>
              {watch(`recordableItems.${index}.chargingBasis`) == ChargingBasisIds.TimeId &&
                <Row>
                  <>
                    <Form.Group as={Col} xs={12} sm={6} className="mb-4 mb-sm-0" controlId={`recordableItems.${index}.startTime`}>
                      <Form.Label
                        className={!!watch(`recordableItems.${index}.endTime`) ? "required" : ""}
                      >
                        Start Time
                      </Form.Label>
                      <Form.Control
                        type="time"
                        disabled={!!watch(`recordableItems.${index}.numberOfMinutes`) && !watch(`recordableItems.${index}.startTime`) && !watch(`recordableItems.${index}.endTime`)}
                        className={`${errors?.recordableItems?.[index]?.startTime?.message ? 'invalid' : ''}`}
                        {...register(`recordableItems.${index}.startTime`, {shouldUnregister: true})}
                        onBlur={() => onBlurTime(index)}
                      />
                      <Form.Text className="lp-error">
                        {errors?.recordableItems?.[index]?.startTime?.message && (errors.recordableItems?.[index]?.startTime?.message)}
                      </Form.Text>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} sm={6} controlId={`recordableItems.${index}.endTime`}>
                      <Form.Label
                        className={!!watch(`recordableItems.${index}.startTime`) ? "required" : ""}
                      >
                        End Time
                      </Form.Label>
                      <Form.Control
                        type="time"
                        disabled={!!watch(`recordableItems.${index}.numberOfMinutes`) && !watch(`recordableItems.${index}.startTime`) && !watch(`recordableItems.${index}.endTime`)}
                        className={`${errors?.recordableItems?.[index]?.endTime?.message ? 'invalid' : ''}`}
                        {...register(`recordableItems.${index}.endTime`, {shouldUnregister: true})}
                        onBlur={() => onBlurTime(index)}
                      />
                      <Form.Text className="lp-error">
                        {errors?.recordableItems?.[index]?.endTime?.message && (errors.recordableItems?.[index]?.endTime?.message)}
                      </Form.Text>
                    </Form.Group>
                  </>
                </Row>
              }
              <Row>
                <Form.Group as={Col} xs={12} sm={6} className="mb-4 mb-sm-0" controlId={`recordableItems.${index}.numberOfMinutes`}>
                  <Form.Label className={watch(`recordableItems.${index}.chargingBasis`) == ChargingBasisIds.TimeId ? "required" : ""}>
                    Number of Minutes 
                    {(watch(`recordableItems.${index}.numberOfMinutes`) ?? 0) > RecordableEventMinutesWarningLimit && 
                      <TooltipIcon type="warning" text={`Exceeds ${RecordableEventMinutesWarningLimit} minutes`} />
                    }
                  </Form.Label>
                  <Form.Control
                    type="number"
                    disabled={(!!watch(`recordableItems.${index}.startTime`) || !!watch(`recordableItems.${index}.endTime`)) || watch(`recordableItems.${index}.chargingBasis`) != ChargingBasisIds.TimeId}
                    className={`${errors?.recordableItems?.[index]?.numberOfMinutes?.message ? 'invalid' : ''}`}
                    {...register(`recordableItems.${index}.numberOfMinutes`, {shouldUnregister: true})}
                    onBlur={() => onBlurNumberOfMinutes(index)}
                    min="0"
                    onWheel={e => e.currentTarget.blur()}
                  />
                  <Form.Text className="lp-error">
                    {errors?.recordableItems?.[index]?.numberOfMinutes?.message && (errors.recordableItems?.[index]?.numberOfMinutes?.message)}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={6} controlId={`recordableItems.${index}.numberOfUnits`}>
                  <Form.Label className={watch(`recordableItems.${index}.chargingBasis`) != ChargingBasisIds.TimeId ? "required" : ""}>
                    Number of Units
                  </Form.Label>
                  <Form.Control
                    type="number"
                    {...register(`recordableItems.${index}.numberOfUnits`, {shouldUnregister: true})}
                    disabled={watch(`recordableItems.${index}.chargingBasis`) == ChargingBasisIds.TimeId}
                    onBlur={() => onBlurNumberOfUnits(index)}
                    onWheel={e => e.currentTarget.blur()}
                  />
                  <Form.Text className="lp-error">
                    {errors?.recordableItems?.[index]?.numberOfUnits?.message && (errors.recordableItems?.[index]?.numberOfUnits?.message)}
                  </Form.Text>
                </Form.Group>
              </Row>
            </section>
          );
        })}

        {recordableItemFields.length > 0 &&
          <Form.Group className="mb-4">
            <Button variant="light" onClick={() => appendNewRecordableEvent()}>
              <MdAdd />
              Add new Activity
            </Button>
          </Form.Group>
        }

        <Form.Group className="lp-inline-radio-group mb-4" controlId="hasIncidentalExpenses">
          <Controller
            control={control}
            name="hasIncidentalExpenses"
            shouldUnregister={true}
            render={({field: { onChange, value, name, ref }}) => (
              <Form.Check 
                type="checkbox"
                id="hasIncidentalExpenses">
                  <Form.Check.Input
                    className="form-check-input"
                    ref={ref}
                    checked={value ?? false}
                    onChange={(ev: any) => { onChange(ev.target.checked); onChangeHasIncidentalExpenses(ev.target.checked); }}
                  />
              </Form.Check>
            )}
          />
          <Form.Label>Has Incidental Expenses</Form.Label>
        </Form.Group>

        {watch("hasIncidentalExpenses") &&
          <>
            <div className="lp-color-title primary full-width">Incidental Expenses</div>
            {incidentalExpenseFields.map((field, index) => {
              return (
                <section key={field.id} className="lp-charge-rate-item">
                  <Button
                    variant="danger"
                    onClick={() => removeIncidentalExpense(index)}
                    className={`delete-item btn-icon${incidentalExpenseFields.length === 1 ? ' disabled' : ''}`}
                  >
                    <MdClose />
                  </Button>

                  <Row>
                    <Form.Group as={Col} xs={12} sm={6} className="mb-4" controlId={`incidentalExpenses.${index}.date`}>
                      <Form.Label className="required">Date</Form.Label>
                      <Controller
                        control={control}
                        name={`incidentalExpenses.${index}.date`}
                        shouldUnregister={true}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            className={`${errors?.incidentalExpenses?.[index]?.date?.message ? 'invalid' : ''}`}
                            id={`incidentalExpenses.${index}.date`}
                            dateFormat={DateFormat.Datepicker}
                            selected={value ? getDateOnly(value) : null}
                            onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                            showMonthDropdown
                            showYearDropdown
                            autoComplete="off"
                          />
                        )}
                      />
                      <Form.Text className="lp-error">
                        {errors?.incidentalExpenses?.[index]?.date?.message && (errors.incidentalExpenses?.[index]?.date?.message)}
                      </Form.Text>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} sm={6} className="mb-4" controlId={`incidentalExpenses.${index}.displayName`}>
                      <Form.Label className="required">Display Name</Form.Label>
                      <Form.Control
                        type="text"
                        className={`${errors?.incidentalExpenses?.[index]?.displayName?.message ? 'invalid' : ''}`}
                        {...register(`incidentalExpenses.${index}.displayName`, {shouldUnregister: true})}
                      />
                      <Form.Text className="lp-error">
                        {errors?.incidentalExpenses?.[index]?.displayName?.message && (errors.incidentalExpenses?.[index]?.displayName?.message)}
                      </Form.Text>
                    </Form.Group>
                  </Row>

                  <Form.Group className="mb-4" controlId={`incidentalExpenses.${index}.description`}>
                    <Form.Label className="required">Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      className={`${errors?.incidentalExpenses?.[index]?.description?.message ? 'invalid' : ''}`}
                      {...register(`incidentalExpenses.${index}.description`, {shouldUnregister: true})}
                    />
                    <Form.Text className="lp-error">
                      {errors?.incidentalExpenses?.[index]?.description?.message && (errors.incidentalExpenses?.[index]?.description?.message)}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-4" controlId={`incidentalExpenses.${index}.paidByUserOwnFunds`}>
                    <Form.Label>Paid By User's Own Funds</Form.Label>
                    <Controller
                      control={control}
                      name={`incidentalExpenses.${index}.paidByUserOwnFunds`}
                      shouldUnregister={true}
                      render={({field: { onChange, value, name, ref }}) => (
                        <Form.Check 
                          type="switch"
                          id={`incidentalExpenses.${index}.paidByUserOwnFunds`}>
                            <Form.Check.Input
                              className= "form-check-input"
                              ref={ref}
                              checked={value ?? false}
                              onChange={(ev: any) => onChange(ev.target.checked)}
                            />
                        </Form.Check>
                      )}
                    />
                  </Form.Group>

                  <Row>
                    <Form.Group as={Col} xs={12} sm={4} className="mb-4" controlId={`incidentalExpenses.${index}.grossValue`}>
                      <Form.Label className={"required"}>Gross Value</Form.Label>
                      <Form.Control
                        type="number"
                        className={`${errors?.incidentalExpenses?.[index]?.grossValue?.message ? 'invalid' : ''}`}
                        {...register(`incidentalExpenses.${index}.grossValue`, {shouldUnregister: true})}
                        min="0.00"
                        step="0.01"
                        onBlur={() => computeIncidentalExpenseNetValue(index)}
                        onWheel={e => e.currentTarget.blur()}
                      />
                      <Form.Text className="lp-error">
                        {errors?.incidentalExpenses?.[index]?.grossValue?.message && (errors.incidentalExpenses?.[index]?.grossValue?.message)}
                      </Form.Text>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} sm={4} className="mb-4" controlId={`incidentalExpenses.${index}.vatValue`}>
                      <Form.Label>VAT Value</Form.Label>
                      <Form.Control
                        type="number"
                        className={`${errors?.incidentalExpenses?.[index]?.vatValue?.message ? 'invalid' : ''}`}
                        {...register(`incidentalExpenses.${index}.vatValue`, {shouldUnregister: true})}
                        min="0.00"
                        step="0.01"
                        onBlur={() => computeIncidentalExpenseNetValue(index)}
                        onWheel={e => e.currentTarget.blur()}
                      />
                      <Form.Text className="lp-error">
                        {errors?.incidentalExpenses?.[index]?.vatValue?.message && (errors.incidentalExpenses?.[index]?.vatValue?.message)}
                      </Form.Text>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} sm={4} className="mb-4" controlId={`incidentalExpenses.${index}.netValue`}>
                      <Form.Label>Net Value</Form.Label>
                      <Form.Control
                        type="number"
                        disabled
                        className={`${errors?.incidentalExpenses?.[index]?.netValue?.message ? 'invalid' : ''}`}
                        {...register(`incidentalExpenses.${index}.netValue`, {shouldUnregister: true})}
                      />
                      <Form.Text className="lp-error">
                        {errors?.incidentalExpenses?.[index]?.netValue?.message && (errors.incidentalExpenses?.[index]?.netValue?.message)}
                      </Form.Text>
                    </Form.Group>
                  </Row>

                  <Form.Group controlId={`incidentalExpenses.${index}.fileIds`}>
                    <Form.Label>Proofs of Purchase</Form.Label>
                    <Controller
                      control={control}
                      name={`incidentalExpenses.${index}.fileIds`}
                      shouldUnregister={true}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <MatterFileSelect
                          id={`incidentalExpenses.${index}.fileIds`}
                          matterId={props.matterId}
                          inputRef={ref}
                          className={`lp-select${errors?.incidentalExpenses?.[index]?.fileIds?.message ? ' invalid' : ''}`}
                          setError={(error: ErrorOption) => setError(`incidentalExpenses.${index}.fileIds`, error)}
                          clearErrors={() => clearErrors(`incidentalExpenses.${index}.fileIds`)}
                          errors={errors?.incidentalExpenses?.[index]?.fileIds}
                          value={value}
                          onChange={val => onChange(val ?? null)}
                          isMulti
                          isClearable
                          menuPlacement="top"
                          canUploadNewFiles
                        />
                      )}
                    />
                  </Form.Group>
                </section>
              );
            })}

            <Form.Group className="mb-4">
              <Button variant="light" onClick={() => appendNewIncidentalExpense()}>
                <MdAdd />
                Add new Incidental Expense
              </Button>
            </Form.Group>
          </>
        }

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <FormSubmitButton text="Create" errors={{...errors}} />
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
