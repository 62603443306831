import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useEffect, useState } from "react";
import { getColumnDefs } from "./columnDefs";
import { Card } from "react-bootstrap";
import Title from "components/Title/index";
import { getAccounts, updateXeroInfoForAllAccounts } from "actions/settings";
import SlidingPanel from "components/SlidingPanel/index";
import { GridIds } from "enums/GridIds";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { GridOptionButtonDataModel } from "models/view/GridOptionButtonDataModel";
import { GridOptionTypes } from "enums/GridOptionTypes";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import usePageActions from "actions/page";

export default function AccountList() {
  const gridActions = useGridActions();
  const [genericErrors, setGenericErrors] = useState(null);
  const user = useAppSelector((state) => state.user);
  const modalActions = useModalActions();
  const pageActions = usePageActions();

  const onGridReady = useCallback(() =>
  {
    const columnDefs = getColumnDefs();
    gridActions.setGridColumnDefs([...columnDefs]);
    reloadGridData();
  }, []);

  const reloadGridData = () => {
    getAccounts(user.userPermissions?.some(a => a == UserPermissionsNames.ManageLTESettings)).then((response) => 
    {
      gridActions.setGridRowData(response.data as any)
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      gridActions.setGridRowData([]);
    });
  };

  useEffect(() => {
    gridActions.setGridId(GridIds.SettingsAccounts);
    pageActions.setReloadGridData(reloadGridData);

    return () => {
      pageActions.resetPageData();
    }
  }, []);

  const gridOptions: GridOptionButtonDataModel[] = [
    ...(user.userPermissions?.some(a => a == UserPermissionsNames.ManageLTESettings) ? 
    [{
      type: GridOptionTypes.UpdateXeroInfo,
      callback: () => updateXeroInfo()
    }] : [])
  ];

  const updateXeroInfo = () => {
    let modal: ModalState = {
      title: "Update Xero Info confirmation",
      body: "Are you sure you want to update Xero info for all accounts?",
      actionText: "Update",
      actionVariant: "success",
      onAction: () => updateXeroInfoCallback(),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  }

  const updateXeroInfoCallback = () => {
    modalActions.toggleModalLoading();
    updateXeroInfoForAllAccounts().then(() => {
      pageActions.triggerReloadPage(true, false);

      modalActions.toggleModalShownStatus();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  }

  return (
    <>
      <Title type="section" title={"Accounts"}>
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card className="with-grid">
        <Card.Body>
          <Grid 
            onGridReady={onGridReady}
            gridOptions={gridOptions}
            exportTitle="Accounts Settings - Accounts"
          />
        </Card.Body>
      </Card>

      <SlidingPanel />
    </>
  );
}
