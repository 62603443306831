import { Outlet } from "react-router-dom";
import NavBar from 'components/NavBar';
import NotificationMessages from "components/NotificationMessages/NotificationMessages";
import './style.scss';
import CustomModal from "components/Modal/Modal";
import { useAppSelector } from "hooks/appSelector";

function MainLayout() {
  const user = useAppSelector((state) => state.user);

  return (
    <>
      <div className={`lp-body-content${user.isDelegated ? ' delegate' : ''}`}>
        <div className="lp-body-content-left">
          <NavBar />
        </div>
        <div className="lp-body-content-right">
          {user.isDelegated && <div className="lp-delegate-message">You are logged in to {user.lawPageTradingEntity?.name} as a delegate. Some features may be restricted.</div>}
          <Outlet />
          <NotificationMessages />
        </div>
      </div>

      <CustomModal />
    </>
  );
}

export default MainLayout;
