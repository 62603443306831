import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Row, Col, Form, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import CustomSelect from "components/Select/Select";
import Loader from "components/Loader";
import { getValidationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { useAppSelector } from "hooks/appSelector";
import { addMatterFeeEarner } from "actions/matter";
import { CreateOrUpdateMatterFeeEarnerModel } from "models/create/CreateOrUpdateMatterFeeEarnerModel";
import { getDelegateUsersSummary, getLteUser, getUserSummaryForLte } from "actions/user";
import { getFeeEarnerLevelsSummary } from "actions/settings";
import { ModalState } from "state/modalSlice";
import { MdWarning } from "react-icons/md";
import useModalActions from "actions/modal";
import { MatterFeeEarnerType } from "enums/MatterFeeEarnerType";
import FormSubmitButton from "components/Buttons/FormSubmitButton";

type Props = {
  matterId?: string,
  matterFeeEarnerType: MatterFeeEarnerType,
  submitCallback?: Function
}

export default function CreateMatterFeeEarnerForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFeeEarner, setIsFeeEarner] = useState<boolean>(true);
  const slidingPanelActions = useSlidingPanelActions();
  const loggedInUser = useAppSelector((state) => state.user);
  const modalActions = useModalActions();

  const { reset, control, setValue, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateMatterFeeEarnerModel>({
    resolver: vestResolver(getValidationSuite(props.matterId!, isFeeEarner, props.matterFeeEarnerType))
  });

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: CreateOrUpdateMatterFeeEarnerModel) {
    setIsLoading(true);
    if(data.isCaseManager == true) {
      var bodyMessage: React.ReactNode = <div className="lp-modal-warning">
        <MdWarning />This {props.matterFeeEarnerType == MatterFeeEarnerType.FeeEarner ? 'Fee Earner' : 'Delegate User'} is set as Case Manager which means that he will replace the current one.<br/>
        Are you sure you want to do that? If not, uncheck the Is Case Manager checkbox.
      </div>;
      let modal: ModalState = {
        title: "Add confirmation",
        body: bodyMessage,
        actionText: "Add",
        onAction: () => addFeeEarner(data),
        show: false,
        onClose: () => setIsLoading(false)
      }
      modalActions.setModal(modal);
      modalActions.toggleModalShownStatus();
    }
    else {
      addFeeEarner(data, false);
    }
  }

  function addFeeEarner(data: CreateOrUpdateMatterFeeEarnerModel, fromModal: boolean = true) {
    if(fromModal)
    {
      modalActions.toggleModalLoading();
    }
    removeEmptyFields(data);
    data.matterId = props.matterId!;
    data.type = props.matterFeeEarnerType;
    addMatterFeeEarner(data)
      .then((response) => {
        if(props.submitCallback) {
          props.submitCallback(response.data);
        }
        slidingPanelActions.clearSlidingPanel();
        reset();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);

        if(fromModal)
        {
          modalActions.toggleModalLoading();
          modalActions.toggleModalShownStatus();
        }
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  const onChangeFeeEarner = (id?: string) => {
    if(id) {
      if(props.matterFeeEarnerType == MatterFeeEarnerType.FeeEarner) {
        setIsLoading(true);
        getLteUser(id).then((response) => {
          if(response.data) {
            setIsFeeEarner(response.data.isFeeEarner);
            setValue("feeEarnerLevelId", response.data.defaultFeeEarnerLevelId);
          }
          else {
            setValue("feeEarnerLevelId", '');
          }
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
      }
      else {
        setIsFeeEarner(false);
        setValue("feeEarnerLevelId", '');
      }
    }
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}
      
      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} sm={6} controlId="userId">
            <Form.Label className="required">{props.matterFeeEarnerType == MatterFeeEarnerType.FeeEarner ? 'Fee Earner' : 'Delegate User'}</Form.Label>
            <Controller
              control={control}
              name="userId"
              shouldUnregister={true}
              render={({field: { onChange, value, ref }}) => (
                <CustomSelect
                  id="userId"
                  inputRef={ref}
                  className={`lp-select${errors?.userId?.message ? ' invalid' : ''}`}
                  endpointCall={props.matterFeeEarnerType == MatterFeeEarnerType.FeeEarner ? 
                    () => getUserSummaryForLte(loggedInUser.lawPageTradingEntityId!, true) : 
                    () => getDelegateUsersSummary(true)
                  }
                  value={value}
                  onChange={val => {onChange(val?.id ?? null); onChangeFeeEarner(val?.id);}}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.userId?.message && (errors.userId.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="feeEarnerLevelId">
            <Form.Label className={`${isFeeEarner ? "required" : ""}`}>Fee Earner Level</Form.Label>
            <Controller
              control={control}
              name="feeEarnerLevelId"
              shouldUnregister={true}
              render={({field: { onChange, value, ref }}) => (
                <CustomSelect
                  id="feeEarnerLevelId"
                  inputRef={ref}
                  className={`lp-select${errors?.feeEarnerLevelId?.message ? ' invalid' : ''}`}
                  endpointCall={getFeeEarnerLevelsSummary}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                  isClearable={!isFeeEarner}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.feeEarnerLevelId?.message && (errors.feeEarnerLevelId.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="isCaseManager">
            <Form.Label>Is Case Manager</Form.Label>
            <Controller
              control={control}
              name="isCaseManager"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="isCaseManager">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <FormSubmitButton text="Create" errors={{...errors}} />
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
