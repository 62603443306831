import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import useGridActions from "actions/grid";
import { useAppSelector } from "hooks/appSelector";
import { Col, Row, Form, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import { getValidationSuite } from "./validationSuite";
import Loader from "components/Loader/index";
import { getAccountById, updateAccount } from "actions/settings";
import { CreateOrUpdateAccountModel } from "models/create/CreateOrUpdateAccountModel";
import { AccountModel } from "models/view/AccountModel";
import Field from "components/Fields/Field";
import BooleanField from "components/Fields/BooleanField";
import FormSubmitButton from "components/Buttons/FormSubmitButton";
import CustomSelect from "components/Select/Select";
import { getXeroAccountsSummary } from "actions/xero";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";

type Props = {
  accountId: string
}

export default function EditAccountForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const gridActions = useGridActions();
  const grid = useAppSelector((state) => state.grid);
  const slidingPanelActions = useSlidingPanelActions();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [account, setAccount] = useState<AccountModel>();
  const [xeroAccounts, setXeroAccounts] = useState<DropDownOptionModel[]>([]);
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    setIsLoading(true);
    getAccountById(props.accountId).then((response) => {
      setAccount(response.data);
      reset(response.data);

      if(response.data.isLTEXeroLinked) {
        getXeroAccountsSummary(user.lawPageTradingEntityId!).then((response2) => {
          setXeroAccounts(response2.data);
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
      }
      else {
        setXeroAccounts([]);
        setIsLoading(false);
      }
      
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      setIsLoading(false);
    });
  }, []);

  const {register, reset, control, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateAccountModel>({
    resolver: vestResolver(getValidationSuite())
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateAccountModel) {
    setIsLoading(true);
    updateAccount(props.accountId, data).then((response) => {
      let index: number = grid.rowData.findIndex((x: any) => x.id === response.data.id);
      let newEntry: AccountModel = {...response.data};
      let newArray: Array<any> = [...grid.rowData];
      newArray[index] = newEntry;
      gridActions.setGridRowData(newArray);
      
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }
      
  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} controlId="displayName">
            <Form.Label className="required">Display Name</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.displayName?.message ? 'invalid' : ''}`}
              {...register("displayName", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.displayName?.message && (errors.displayName.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Col sm={6}>
            <Field
              label={"Account Code"}
              value={account?.accountCode}
            />
          </Col>
          <Form.Group as={Col} sm={6} controlId="xeroAccountId">
            <Form.Label>Xero Account</Form.Label>
            <Controller
              control={control}
              name={`xeroAccountId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="xeroAccountId"
                  inputRef={ref}
                  className={`lp-select${errors?.xeroAccountId?.message ? ' invalid' : ''}`}
                  options={xeroAccounts}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                  isClearable
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.xeroAccountId?.message && (errors.xeroAccountId?.message)}
            </Form.Text>
          </Form.Group>
        </Row>
        <Row>
          <Col sm={6}>
            <Field
              label={"Type"}
              value={account?.accountType?.displayName}
            />
          </Col>
          <Col sm={6} className="mt-4 mt-sm-0">
            <BooleanField
              label={"Is Active"}
              value={account?.isActive ?? false}
            />
          </Col>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <FormSubmitButton text="Update" errors={{...errors}} />
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
