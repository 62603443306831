import { changeLTEShareAddressBookStatus, deleteLTEShareAddressBook, getLTEShareAddressBooks } from "actions/lte";
import Title from "components/Title/index";
import { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { ModalState } from "state/modalSlice";
import { Button } from "react-bootstrap";
import { useAppSelector } from 'hooks/appSelector';
import { LTEShareAddressBookModel } from "models/view/LTEShareAddressBookModel";
import ListLTEShareAddressBook from "components/List/ListLTEShareAddressBook";
import CreateLTEShareAddressBookForm from "./CreateLTEShareAddressBook/CreateLTEShareAddressBookForm";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

type Props = {
  lteId: string
}

export default function LTEShareAddressBookList(props: Props) {
  const [lteShareAddressBooks, setLTEShareAddressBooks] = useState<LTEShareAddressBookModel[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);

  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();
  const loggedInUser = useAppSelector((state) => state.user);

  const loadLTEShareAddressBooks = () => {
    if(props.lteId) {
      setIsLoading(true);
      getLTEShareAddressBooks(props.lteId)
        .then((response) => {          
          setLTEShareAddressBooks(response.data);
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  useEffect(() =>
  {
    loadLTEShareAddressBooks();
  }, [props.lteId]);

  const openCreateLTEShareAddressBookSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Create Share Address Book Trading Entries',
      children: <CreateLTEShareAddressBookForm
                  lteId={props.lteId}
                  submitCallback={loadLTEShareAddressBooks} />
    });
  };

  const changeStatusModal = (id: string) => {
    let modal: ModalState = {
      title: "Confirmation",
      body: "Are you sure you want to change the status for this Trading Entity?",
      onAction: () => changeStatus(id),
      show: true
    }
    modalActions.setModal(modal);
  }
  
  const changeStatus = (id: string) => {
    modalActions.toggleModalLoading();
    changeLTEShareAddressBookStatus(props.lteId, id).then(() => {
      loadLTEShareAddressBooks();
      modalActions.toggleModalShownStatus();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  const deleteModal = (id: string) => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Trading Entity?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(id),
      show: true
    }
    modalActions.setModal(modal);
  };

  const deleteCallback = (id: string) => {
    modalActions.toggleModalLoading();
    deleteLTEShareAddressBook(props.lteId, id).then(() => {
      loadLTEShareAddressBooks();
      modalActions.toggleModalShownStatus();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  return (
    <>
      <Title type="section" title={"Share Address Book Entries"}>
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageAllLTEs) &&
          <Button onClick={openCreateLTEShareAddressBookSlidingPanel} className="btn-icon" variant="success">
            <MdAdd />
          </Button>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <ListLTEShareAddressBook
        listItems={lteShareAddressBooks ?? []}
        listButtons={
          [
            ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageAllLTEs) ?
              [{
                type: ActionButtonTypes.Activate,
                callback: (id: string) => changeStatusModal(id)
              },
              {
                type: ActionButtonTypes.Delete,
                callback: (id: string) => deleteModal(id)
              }] : [])
          ]
        }
        loadState={isLoading}
      />
    </>
  );
}
