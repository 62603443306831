import Title from 'components/Title';
import CustomTabs from "components/CustomTabs";
import { TabPanel } from "react-tabs";
import LteRecordableItemList from "./LteRecordableItems/LteRecordableItemList";
import LteIncidentalExpenseList from './LteIncidentalExpenses/LteIncidentalExpenseList';
import usePageActions from 'actions/page';
import { useEffect } from 'react';

function TimeAndIncidentalExpenses() {
  const pageActions = usePageActions();

  useEffect(() => {
    return () => {
      pageActions.abortRequests();
    }
  }, []);

  return (
    <div className="lp-page-content">
      <Title
        type="page"
        title="Time & Incidental Expenses"
      >
      </Title>

      <CustomTabs 
        tabList={[
          {
            name: 'Recordable Items',
            key: 'recordableItems',
            panel: <TabPanel>
              <LteRecordableItemList />
            </TabPanel>
          },
          {
            name: 'Incidental Expenses',
            key: 'incidentalExpenses',
            panel: <TabPanel>
              <LteIncidentalExpenseList />
            </TabPanel>
          }
        ]}
      />
    </div>
  );
}

export default TimeAndIncidentalExpenses;
