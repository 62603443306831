import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { useParams } from "react-router-dom";
import { CreateOrUpdateLteDepartmentModel } from "models/create/CreateOrUpdateLteDepartmentModel";
import { Form, Button, Row, Col } from "react-bootstrap";
import { getLteBranches, getLteDepartment, updateLteDepartment } from "actions/lte";
import useSlidingPanelActions from "actions/slidingPanel";
import CustomSelect from "components/Select/Select";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { useAppSelector } from "hooks/appSelector";
import FormSubmitButton from "components/Buttons/FormSubmitButton";

type Props = {
  departmentId: string,
  submitCallback?: Function
}

export default function EditLteDepartmentForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const {id: lteId} = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const user = useAppSelector((state) => state.user);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);

  useEffect(() => {
    if(lteId || user.lawPageTradingEntityId) {
      setIsLoading(true);
      getLteDepartment(props.departmentId)
        .then( (lteDepartmentResponse) => {
          lteDepartmentResponse.data.lteBranchId = lteDepartmentResponse.data.lteBranch.id;
          reset(lteDepartmentResponse.data);
        }
      )
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
  }, []);

  const {register, reset,control,  handleSubmit, formState: {errors}} = useForm<CreateOrUpdateLteDepartmentModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: CreateOrUpdateLteDepartmentModel) {
    setIsLoading(true);
    data.lteId = lteId ?? user.lawPageTradingEntityId!;
    updateLteDepartment(props.departmentId, data).then(() => {
      slidingPanelActions.clearSlidingPanel();
      reset();

      if(props.submitCallback) {
        props.submitCallback();
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();

    currentSlidingPanelState.onCancel && currentSlidingPanelState.onCancel();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} sm={6} controlId="name">
            <Form.Label className="required">Name</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.name?.message ? 'invalid' : ''}`}
              {...register("name", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.name?.message && (errors.name.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="lteBranchId">
            <Form.Label className="required">Branch</Form.Label>
            <Controller
              control={control}
              name="lteBranchId"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <CustomSelect
                  id="lteBranchId"
                  inputRef={ref}
                  className={`lp-select${errors?.lteBranchId?.message ? ' invalid' : ''}`}
                  endpointCall={() => getLteBranches(lteId ?? user.lawPageTradingEntityId!)}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.lteBranchId?.message && (errors.lteBranchId.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <FormSubmitButton text="Update" errors={{...errors}} />
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
