import { useState } from "react";
import { Row, Col } from 'react-bootstrap';
import SlidingPanel from "components/SlidingPanel/index";
import ViewLinkedEntitiesOwnedBy from "./ViewLinkedEntitiesOwnedBy/ViewLinkedEntitiesOwnedBy";
import ViewLinkedEntitiesOwns from "./ViewLinkedEntitiesOwns/ViewLinkedEntitiesOwns";
import { EntityModel } from "models/view/EntityModel";
import { EntityTypeIds } from "enums/EntityTypeIds";

type Props = {
  entity: EntityModel,
  edit: boolean
}

export default function ViewLinkedEntities(props: Props) {
  const [edit, setEdit] = useState<boolean>(props.edit ?? false);

  return (
    <>
      {props.entity.entityTypeId == EntityTypeIds.HumanBeingId &&
        <Row>
          <Col>
            <ViewLinkedEntitiesOwnedBy entityId={props.entity.id} edit={edit} entityIsShared={props.entity.isShared}/>
          </Col>
        </Row>
      }

      {props.entity.entityTypeId != EntityTypeIds.HumanBeingId &&
        <Row>
          <Col>
            <ViewLinkedEntitiesOwns entityId={props.entity.id} edit={edit} entityIsShared={props.entity.isShared}/>
          </Col>
        </Row>
      }

      <SlidingPanel />
    </>
  );
}
