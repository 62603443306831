import React, { useCallback, useEffect, useRef, useState } from "react";
import useGridActions from 'actions/grid';
import Grid from "components/Grid";
import { getColumnDefs, viewCallback } from "./columnDefs";
import Title from 'components/Title';
import SlidingPanel from 'components/SlidingPanel';
import { Card } from 'react-bootstrap';
import { getMatterDisbursementsForLTE } from "actions/lte";
import useSlidingPanelActions from "actions/slidingPanel";
import { GridOptionButtonDataModel } from "models/view/GridOptionButtonDataModel";
import { GridOptionTypes } from "enums/GridOptionTypes";
import { MatterDisbursementModel } from "models/view/MatterDisbursementModel";
import CreatePaymentForm from "./CreatePayment/CreatePaymentForm";
import useNotificationActions from "actions/notification";
import { NotificationTypes } from "enums/NotificationTypes";
import './style.scss';
import { useAppSelector } from 'hooks/appSelector';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import { AgGridReact } from "ag-grid-react";
import { GridIds } from "enums/GridIds";
import usePageActions from "actions/page";

function BillsToPayList() {
  const gridActions = useGridActions();
  const pageActions = usePageActions();
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const notificationActions = useNotificationActions();
  const loggedInUser = useAppSelector((state) => state.user);
  const gridRef = useRef<AgGridReact>(null);

  const onGridReady = useCallback(() => {
    const columnDefs = getColumnDefs();
    gridActions.setGridColumnDefs([...columnDefs]);
  }, []);

  useEffect(() => {
    populateDisbursements();
    gridActions.setGridId(GridIds.BillsToPay);
    pageActions.setReloadGridData(populateDisbursements);

    return () => {
      pageActions.resetPageData();
      pageActions.abortRequests();
    }
  }, []);

  const populateDisbursements = () => {
    getMatterDisbursementsForLTE(true).then((response) => {
      gridActions.setGridRowData(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      gridActions.setGridRowData([]);
    });
  };

  const gridOptions: GridOptionButtonDataModel[] = [
    ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageBillsToPay) ? 
    [{
      type: GridOptionTypes.MakePayment,
      callback: (rows: MatterDisbursementModel[]) => makePayment(rows)
    }] : [])
  ];

  const makePayment = (rows: MatterDisbursementModel[]) => {
    var suppliers = rows.map(x => x.supplierId).filter((value, index, self) => self.indexOf(value) === index);
    if(suppliers.length > 1) {
      notificationActions.addNotificationMessage(
        {
          type: NotificationTypes.Error,
          title: "Make Payment Validation Error",
          body: "You can only select bills that have the same supplier",
          isDismissable: true
        }
      );
      return;
    }

    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      title: "Make Payment",
      children: <CreatePaymentForm disbursements={rows} onSubmitCallback={populateDisbursements}/>
    });
  }

  return (
    <div className="lp-page-content">
      <Title
        type="page"
        title="Bills to Pay"
      >
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card className="with-grid">
        <Card.Body>
          <Grid
            ref={gridRef}
            onGridReady={onGridReady}
            gridOptions={gridOptions}
            exportTitle="Bills to Pay"
          />
        </Card.Body>
      </Card>

      <SlidingPanel gridRef={gridRef} onNavigation={(item: any) => viewCallback(item)} />
    </div>
  );
}

export default BillsToPayList;
