import { ColDef } from "ag-grid-community";
import { useDispatch } from "react-redux";
import {
  setColumnDefs,
  setId,
  setRowData,
  resetId,
  setAdvancedFilter,
  resetAdvancedFilter,
  setAdvancedFilterInfo,
  resetAdvancedFilterInfo,
  setAdvancedFilterClearCallback,
  resetAdvancedFilterClearCallback,
  resetGrid,
  setAdvancedFilterExportInfo,
  resetAdvancedFilterExportInfo,
  setAdditionalFilterExportInfo,
  resetAdditionalFilterExportInfo,
} from "../state/gridSlice";
import { AdvancedGridFilterModel } from "models/view/AdvancedGridFilterModel";
import { DateFormat } from "utils/constants";
import moment from "moment";
import _ from "lodash";
import store from "state/store";
import { GridIds } from "enums/GridIds";

const useGridActions = () => {
  const dispatch = useDispatch();

  const setGridRowData = (rowData: any[]) => {
    dispatch(setRowData(rowData));
  };

  const setGridColumnDefs = (columnDefs: (ColDef<any> | {excludeFromExport: boolean})[]) => {
    dispatch(setColumnDefs(columnDefs));
  };

  const setGridAdvancedFilter = (advancedFilter?: AdvancedGridFilterModel, gridId?: GridIds) => {
    if(!advancedFilter) {
      dispatch(resetAdvancedFilter());
      dispatch(resetAdvancedFilterInfo());
      dispatch(resetAdvancedFilterExportInfo());
      return;
    }

    const exportInfo: string[] = [];
    let appliedFilterCount = 0;
    for(const [key, value] of Object.entries(advancedFilter)) {
      // Exclude special keys from the count & export info
      if(key === "dateOption") {
        continue;
      }

      if(value) {
        appliedFilterCount++; // Increase the count of applied advanced filters

        const formattedKey = _.startCase(key); // Format keys with lodash (e.g. "feeEarners" -> "Fee Earners")

        // Date format
        if(value instanceof Date) {
          exportInfo.push(`${formattedKey}: ${moment(value).format(DateFormat.Moment)}`);
        }
        // Multiselect data
        else if (Array.isArray(value) && value.length > 0) {
          // If it's an object, it's a dropdown option -> retrieve the name
          if(typeof value[0] === "object") {
            exportInfo.push(`${formattedKey}: ${value.map(x => x.name).join(", ")}`);
          }
          else {
            exportInfo.push(`${formattedKey}: ${value.join(", ")}`);
          }
        }
        else {
          if(typeof value === "boolean") {
            exportInfo.push(`${formattedKey}: ${value ? "Yes" : "No"}`);
          }
          else {
            exportInfo.push(`${formattedKey}: ${value}`);
          }
        }
      }
    }

    // Store the advanced filter in local storage only if a gridId is provided
    // Filters are loaded only when navigating back to an already seen tab
    // therefore grids that do not have tabs do not need to store the filter
    if(gridId) {
      localStorage.setItem(`Grid_${gridId}_advanced_filter`, JSON.stringify(advancedFilter));
    }

    dispatch(setAdvancedFilterExportInfo(exportInfo));
    dispatch(setAdvancedFilterInfo(appliedFilterCount ? `${appliedFilterCount} advanced ${appliedFilterCount === 1 ? 'filter' : 'filters'} applied` : undefined));
    dispatch(setAdvancedFilter(advancedFilter));
  };

  const setGridAdvancedFilterInfo = (advancedFilterInfo: string) => {
    dispatch(setAdvancedFilterInfo(advancedFilterInfo));
  };

  const setGridAdvancedFilterClearCallback = (advancedFilterClearCallback: Function) => {
    dispatch(setAdvancedFilterClearCallback(() => {
      // On clear, set an empty filter in local storage - an empty filter is different from no filter
      // If there is no filter, the initial filter will be applied, with an empty filter, no filter will be applied
      const gridState = store.getState().grid;
      localStorage.setItem(`Grid_${gridState.id}_advanced_filter`, JSON.stringify({}));
      advancedFilterClearCallback();
    }));
  };

  const setGridAdvancedFilterExportInfo = (advancedFilterExportInfo: string[]) => {
    dispatch(setAdvancedFilterExportInfo(advancedFilterExportInfo));
  };

  const setGridAdditionalFilterExportInfo = (additionalFilterExportInfo: string[]) => {
    dispatch(setAdditionalFilterExportInfo(additionalFilterExportInfo));
  };

  const setGridId = (id: string) => {
    dispatch(setId(id));
  };

  const resetGridId = () => {
    dispatch(resetId());
  };

  const resetGridAdvancedFilter = () => {
    dispatch(resetAdvancedFilter());
  };

  const resetGridAdvancedFilterInfo = () => {
    dispatch(resetAdvancedFilterInfo());
  };

  const resetGridAdvancedFilterClearCallback = () => {
    dispatch(resetAdvancedFilterClearCallback());
  };

  const resetGridAdvancedFilterExportInfo = () => {
    dispatch(resetAdvancedFilterExportInfo());
  };

  const resetGridAdditionalFilterExportInfo = () => {
    dispatch(resetAdditionalFilterExportInfo());
  };

  const resetGridModel = () => {
    dispatch(resetGrid());
  };

  return {
    setGridRowData,
    setGridColumnDefs,
    setGridAdvancedFilter,
    setGridAdvancedFilterInfo,
    setGridAdvancedFilterClearCallback,
    setGridAdvancedFilterExportInfo,
    setGridAdditionalFilterExportInfo,
    setGridId,
    resetGridId,
    resetGridAdvancedFilter,
    resetGridAdvancedFilterInfo,
    resetGridAdvancedFilterClearCallback,
    resetGridAdvancedFilterExportInfo,
    resetGridAdditionalFilterExportInfo,
    resetGridModel
  };
}

export default useGridActions;
