import { useState } from "react";
import Title from "components/Title";
import { deleteEntityPhoneNumber } from "actions/entity";
import { Button } from 'react-bootstrap';
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { MdAdd } from "react-icons/md";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { ModalState } from "state/modalSlice";
import LinkEntityPhoneNumberForm from "./LinkEntityPhoneNumber/LinkEntityPhoneNumberForm";
import ListContact from "components/List/ListContact";
import { EntityPhoneNumberModel } from "models/view/EntityPhoneNumberModel";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

type EntityLinkType = 'ownedBy' | 'owns';

type Props = {
  entityId: string,
  linkedEntityId: string,
  linkedEntityPhoneNumbers: EntityPhoneNumberModel[],
  linkType: EntityLinkType,
  edit: boolean,
  entityIsShared: boolean
}

export default function LinkedEntityPhoneNumberList(props: Props) {
  const [linkedEntityPhoneNumbers, setLinkedEntityPhoneNumbers] = useState<EntityPhoneNumberModel[]>(props.linkedEntityPhoneNumbers);
  const [isLoadingEntityPhoneNumbers, setIsLoadingEntityPhoneNumbers] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();
  const loggedInUser = useAppSelector((state) => state.user);

  const openLinkEntityPhoneNumberSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Link Phone Number',
      children: <LinkEntityPhoneNumberForm 
                  toEntityId={props.linkType == 'ownedBy' ? props.entityId : props.linkedEntityId}
                  fromEntityId={props.linkType == 'ownedBy' ? props.linkedEntityId : props.entityId}
                  submitCallback={linkEntityPhoneNumberCallback} />
    });
  };

  const linkEntityPhoneNumberCallback = (newEntityPhoneNumber: EntityPhoneNumberModel) => {
    setIsLoadingEntityPhoneNumbers(true);
    setLinkedEntityPhoneNumbers([...linkedEntityPhoneNumbers, newEntityPhoneNumber]);
    setIsLoadingEntityPhoneNumbers(false);
  };

  const unlinkCallbackEntityPhoneNumber = (id: string) => {
    setIsLoadingEntityPhoneNumbers(true);
    const entityId = props.linkType == 'ownedBy' ? props.entityId : props.linkedEntityId;
    modalActions.toggleModalLoading();
    deleteEntityPhoneNumber(entityId, id, false).then(() => {
      setLinkedEntityPhoneNumbers(linkedEntityPhoneNumbers.filter(x => x.entityId != entityId || x.phoneNumberId != id));
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingEntityPhoneNumbers(false);
      modalActions.toggleModalLoading();
      modalActions.toggleModalShownStatus();
    });
  };

  const unlinkEntityPhoneNumberModal = (id: string) => {
    let modal: ModalState = {
      title: "Unlink confirmation",
      body: "Are you sure you want to unlink this phone number?",
      actionText: "Unlink",
      actionVariant: "danger",
      onAction: () => unlinkCallbackEntityPhoneNumber(id),
      show: true
    }
    modalActions.setModal(modal);
   };

  return (
    <>
      <Title type="section" title={"Phone Numbers"}>
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && !props.entityIsShared &&
          <Button onClick={openLinkEntityPhoneNumberSlidingPanel} className="btn-icon" variant="success">
            <MdAdd />
          </Button>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <ListContact
        listItems={linkedEntityPhoneNumbers.map((x: EntityPhoneNumberModel) => 
          ({
            id: x.phoneNumberId,
            contact: x.phoneNumber?.number,
            type: x.phoneNumber?.phoneType?.name,
            isPreferred: x.isPreferred,
            systemOwned: x.phoneNumber?.systemOwned,
            additionalInfo: x.phoneNumber?.extension,
          })
        ) ?? []}
        listButtons={
          [
            {
              type: ActionButtonTypes.Call,
            },
            ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && !props.entityIsShared ? [{
              type: ActionButtonTypes.Unlink,
              callback: (id: string) => unlinkEntityPhoneNumberModal(id)
            }] : [])
          ]
        }
        loadState={isLoadingEntityPhoneNumbers}
        displayUnlinkButton={loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && !props.entityIsShared}
      >
        <>
          <div>{"Phone Number"}</div>
          <div>{"Type"}</div>
          <div></div>
        </>
      </ListContact>
    </>
  );
}
