import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { getColumnDefs } from "./columnDefs";
import { Card, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Title from "components/Title/index";
import { getVatRates, updateXeroInfoForAllVatRates } from "actions/settings";
import SlidingPanel from "components/SlidingPanel/index";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { GridIds } from "enums/GridIds";
import CreateDefaultVatRateForm from "./CreateVatRate/CreateVatRateForm";
import usePageActions from "actions/page";
import { GridOptionTypes } from "enums/GridOptionTypes";
import useModalActions from "actions/modal";
import { ModalState } from "state/modalSlice";
import { GridOptionButtonDataModel } from "models/view/GridOptionButtonDataModel";

export default function VatRateList() {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();
  const [genericErrors, setGenericErrors] = useState(null);
  const loggedInUser = useAppSelector((state) => state.user);
  const pageActions = usePageActions();
  const modalActions = useModalActions();

  const onGridReady = useCallback(() =>
  {
    const columnDefs = getColumnDefs();
    gridActions.setGridColumnDefs([...columnDefs]);
    reloadGridData();
  }, []);

  const reloadGridData = () => {
    getVatRates().then((response) => 
    {
      gridActions.setGridRowData(response.data as any)
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      gridActions.setGridRowData([]);
    });
  };

  useEffect(() => {
    gridActions.setGridId(GridIds.SettingsVatRates);
    pageActions.setReloadGridData(reloadGridData);

    return () => {
      pageActions.resetPageData();
    }
  }, []);

  const gridOptions: GridOptionButtonDataModel[] = [
    ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageLTESettings) ? 
    [{
      type: GridOptionTypes.UpdateXeroInfo,
      callback: () => updateXeroInfo()
    }] : [])
  ];

  const updateXeroInfo = () => {
    let modal: ModalState = {
      title: "Update Xero Info confirmation",
      body: "Are you sure you want to update Xero info for all VAT Rates?",
      actionText: "Update",
      actionVariant: "success",
      onAction: () => updateXeroInfoCallback(),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  }

  const updateXeroInfoCallback = () => {
    modalActions.toggleModalLoading();
    updateXeroInfoForAllVatRates().then(() => {
      pageActions.triggerReloadPage(true, false);

      modalActions.toggleModalShownStatus();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  }

  const toggleSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Add Default VAT Rate",
        children: <CreateDefaultVatRateForm />
      });
  }

  return (
    <>
      <Title type="section" title={"VAT Rates"}>
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageLTESettings) &&
          <Button onClick={toggleSlidingPanel} className="btn-icon" variant="success">
            <MdAdd />
          </Button>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card className="with-grid">
        <Card.Body>
          <Grid
            onGridReady={onGridReady}
            gridOptions={gridOptions}
            exportTitle="Account Settings - VAT Rates"
          />
        </Card.Body>
      </Card>

      <SlidingPanel />
    </>
  );
}
