import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ColDef, ColGroupDef } from "ag-grid-community";

export type GridState = {
  columnDefs: (ColDef<any> | {excludeFromExport: boolean})[],
  rowData: any[]
  id: string | undefined,
  advancedFilter: any | undefined,
  advancedFilterInfo: string | undefined,
  advancedFilterClearCallback: Function | undefined,
  advancedFilterExportInfo: string[] | undefined,
  additionalFilterExportInfo: string[] | undefined,
}

const initialState: GridState = {
  columnDefs: undefined as any,
  rowData: undefined as any,
  id: undefined,
  advancedFilter: undefined as any,
  advancedFilterInfo: undefined,
  advancedFilterClearCallback: undefined,
  advancedFilterExportInfo: undefined,
  additionalFilterExportInfo: undefined,
}

export const gridSlice = createSlice({
  name: 'grid',
  initialState,
  reducers: {
    setColumnDefs: (state, action: PayloadAction<any[]>) => {
      state.columnDefs = action.payload;
    },
    setRowData: (state, action: PayloadAction<any[]>) => {
      state.rowData = action.payload;
    },
    setAdvancedFilter: (state, action: PayloadAction<any>) => {
      state.advancedFilter = action.payload;
    },
    setAdvancedFilterInfo: (state, action: PayloadAction<string | undefined>) => {
      state.advancedFilterInfo = action.payload;
    },
    setAdvancedFilterClearCallback: (state, action: PayloadAction<Function>) => {
      state.advancedFilterClearCallback = action.payload;
    },
    setAdvancedFilterExportInfo: (state, action: PayloadAction<string[]>) => {
      state.advancedFilterExportInfo = action.payload;
    },
    setAdditionalFilterExportInfo: (state, action: PayloadAction<string[]>) => {
      state.additionalFilterExportInfo = action.payload;
    },
    setId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    resetId: (state) => {
      state.id = undefined;
    },
    resetAdvancedFilter: (state) => {
      state.advancedFilter = undefined;
    },
    resetAdvancedFilterInfo: (state) => {
      state.advancedFilterInfo = undefined;
    },
    resetAdvancedFilterClearCallback: (state) => {
      state.advancedFilterClearCallback = undefined;
    },
    resetAdvancedFilterExportInfo: (state) => {
      state.advancedFilterExportInfo = undefined;
    },
    resetAdditionalFilterExportInfo: (state) => {
      state.additionalFilterExportInfo = undefined;
    },
    resetGrid: (state) => {
      state.columnDefs = [];
      state.rowData = [];
      state.id = undefined;
      state.advancedFilter = undefined;
      state.advancedFilterInfo = undefined;
      state.advancedFilterClearCallback = undefined;
      state.advancedFilterExportInfo = undefined;
      state.additionalFilterExportInfo = undefined;
    },
  },
})

export const {
  setColumnDefs,
  setRowData,
  setAdvancedFilter,
  setAdvancedFilterInfo,
  setAdvancedFilterClearCallback,
  setAdvancedFilterExportInfo,
  setAdditionalFilterExportInfo,
  setId,
  resetId,
  resetAdvancedFilter,
  resetAdvancedFilterInfo,
  resetAdvancedFilterClearCallback,
  resetAdvancedFilterExportInfo,
  resetAdditionalFilterExportInfo,
  resetGrid
} = gridSlice.actions;

export default gridSlice.reducer;
