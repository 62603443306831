import Title from "components/Title";
import { TabPanel } from "react-tabs";
import CustomTabs from "components/CustomTabs";
import ViewDiaryManagerList from "./ViewDiaryManagerList/ViewDiaryManagerList";
import ViewDiaryManagerCalendar from "./ViewDiaryManagerCalendar/ViewDiaryManagerCalendar";
import ViewDiaryManagerFreeBusy from "./ViewDiaryManagerFreeBusy/ViewDiaryManagerFreeBusy";
import usePageActions from "actions/page";
import { useEffect } from "react";

export default function DiaryManagerCalendar() {
  const pageActions = usePageActions();

  useEffect(() => {
    return () => {
      pageActions.abortRequests();
    }
  }, []);

  return (
    <div className="lp-page-content">
      <Title type="page" title="Diary Manager Calendar"></Title>

      <CustomTabs
        tabList={[
          {
            name: 'Calendar',
            key: 'calendar',
            panel: <TabPanel>
              <ViewDiaryManagerCalendar />
            </TabPanel>
          },
          {
            name: 'List',
            key: 'list',
            panel: <TabPanel>
              <ViewDiaryManagerList />
            </TabPanel>
          },
          {
            name: 'Free/Busy',
            key: 'freeBusy',
            panel: <TabPanel>
              <ViewDiaryManagerFreeBusy />
            </TabPanel>
          }
        ]}
      />
    </div>
  );
}
