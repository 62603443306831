import {
  getEmailFolders,
  getEmailsForManualSaving,
  sendEmailsForManualSaving,
} from 'actions/user';
import { useAppSelector } from 'hooks/appSelector';
import { MatterEmailModel } from 'models/view/MatterEmailModel';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import moment from 'moment';
import { DateFormat } from 'utils/constants';
import Loader from 'components/Loader/index';
import useSlidingPanelActions from 'actions/slidingPanel';
import { BsCheckCircle, BsCheckCircleFill, BsExclamationLg } from 'react-icons/bs';
import { MdAccessTime, MdMarkEmailUnread, MdOutgoingMail, MdOutlineAttachment, MdOutlineFlag } from 'react-icons/md';
import CustomSelect from 'components/Select/Select';
import { DropDownOptionModel } from 'models/view/DropDownOptionModel';
import { vestResolver } from '@hookform/resolvers/vest';
import { validationSuite } from './validationSuite';
import { Controller, useForm } from 'react-hook-form';
import { getOpenMattersSummary } from 'actions/matter';
import { formatEmailRecipient } from 'utils/misc';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './style.scss';

type Props = {
  matterId?: string;
  onSubmitCallback?: Function;
};

type ManualAddEmailModel = {
  matterId: string
};

export default function ManualAddEmails(props: Props) {
  const [genericErrors, setGenericErrors] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const user = useAppSelector((state) => state.user);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [existingNextPage, setExistingNextPage] = useState<boolean>(false);
  const [emails, setEmails] = useState<MatterEmailModel[]>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const slidingPanelActions = useSlidingPanelActions();
  const [emailFolder, setEmailFolder] = useState<string>();
  const [emailFolderOptions, setEmailFolderOptions] = useState<DropDownOptionModel[]>([]);
  const [keyword, setKeyword] = useState<string | undefined>();

  const { handleSubmit, control, watch, formState: { errors } } = useForm<ManualAddEmailModel>({
    resolver: vestResolver(validationSuite),
    defaultValues: {
      matterId: props.matterId
    }
  });

  const onSubmit = handleSubmit((data) => submitEmails(data));

  async function submitEmails(data: ManualAddEmailModel) {
    if (selectedIds.length == 0) {
      setGenericErrors('Please select at least one email.');
      return;
    }

    setIsLoading(true);
    sendEmailsForManualSaving(
      data.matterId,
      selectedIds,
      user.msalAccessToken!
    )
      .then((response) => {
        props.onSubmitCallback && props.onSubmitCallback();
        slidingPanelActions.clearSlidingPanel();
        setSelectedIds([]);
        setIsLoading(false);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);

        //reload emails and reset selection without reseting the error message
        loadUserEmails(data.matterId, pageNumber, user.msalAccessToken!, emailFolder, keyword, false);
        if(error.response?.data?.SucceededForIds) {
          setSelectedIds(selectedIds.filter(x => !error.response?.data?.SucceededForIds.includes(x)));
        }
        else {
          setSelectedIds([]);
        }
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getEmailFolders(user.msalAccessToken!).then((response) => {
      setEmailFolder(
        response.data.find((x: DropDownOptionModel) => x.name == 'Inbox')?.id
      );
      setEmailFolderOptions(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    var matterId = watch('matterId');
    if(matterId) {
      loadUserEmails(matterId, pageNumber, user.msalAccessToken!, emailFolder, keyword);
    }
  }, [pageNumber, watch('matterId')]);

  const loadUserEmails = (matterId: string, page: number, msalAccessToken: string, emailFolder?: string, keyword?: string, resetErrors: boolean = true) => {
    setIsLoading(true);
    getEmailsForManualSaving(
      matterId,
      page.toString(),
      msalAccessToken,
      emailFolder,
      keyword
    )
    .then((response) => {
      setExistingNextPage(response.data.existingNextPage);
      setEmails(response.data.emails);
      if(resetErrors) {
        setGenericErrors(undefined);
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  const handlePageDown = () => {
    if (pageNumber > 0) {
      setIsLoading(true);
      setPageNumber(pageNumber - 1);
    }
  };

  const handlePageUp = () => {
    if (existingNextPage) {
      setIsLoading(true);
      setPageNumber(pageNumber + 1);
    }
  };

  const handleSelection = (graphResourceId: string) => {
    if (selectedIds?.some((x: string) => x == graphResourceId)) {
      setSelectedIds(selectedIds.filter((x: string) => x != graphResourceId));
    } else {
      setSelectedIds([...selectedIds, graphResourceId]);
    }
  };

  const filter = () => {
    var matterId = watch('matterId');
    if(matterId) {
      if(pageNumber == 0) {
        loadUserEmails(matterId, pageNumber, user.msalAccessToken!, emailFolder, keyword);
      }
      else {
        setPageNumber(0);
      }
    }
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    setSelectedIds([]);
  };

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && <div className="lp-errors">{genericErrors}</div>}

      <Form onSubmit={onSubmit} className="lp-emails-form">
        {!props.matterId &&
          <Form.Group className="lp-emails-matter" controlId="matterId">
            <Form.Label className="required">Matter</Form.Label>
            <Controller
              control={control}
              name={`matterId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="matterId"
                  inputRef={ref}
                  className={`lp-select${errors?.matterId?.message ? ' invalid' : ''}`}
                  endpointCall={getOpenMattersSummary}
                  value={value}
                  onChange={val => {onChange(val?.id ?? null);}}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.matterId?.message && (errors.matterId?.message)}
            </Form.Text>
          </Form.Group>
        }

        <div className="lp-emails-filter">
          <Form.Control
            type="text"
            placeholder="Keyword"
            onBlur={(e: any) => setKeyword(e.target.value)}
          />

          <CustomSelect
            id="attachmentsIds"
            menuPlacement="bottom"
            placeholder="Select folder"
            options={emailFolderOptions}
            onChange={(val) => setEmailFolder(val?.id ?? null)}
            value={emailFolder}
          />

          <Button
            onClick={filter}
          >
            Filter
          </Button>
        </div>

        <div className={`lp-emails-list${(pageNumber != 0 || existingNextPage) ? ' with-pagination' : ''}`}>
          {emails == undefined || emails.length == 0 &&
            <div className="lp-empty emails">
              <div className="image"></div>
              <div className="text">No emails found!</div>
            </div>
          }

          {emails &&
            emails?.map((x: MatterEmailModel, index: number) => (
              <React.Fragment key={index}>
                <div
                  key={index * (pageNumber + 1)}
                  onClick={x.isDraft ? () => {} : (() => handleSelection(x.graphResourceId))}
                  className={`lp-email-item${
                    x.existsInDatabase ? ' existing-email' : ''
                  }${
                    selectedIds.some((y: string) => y == x.graphResourceId)
                      ? ' selected-email'
                      : ''
                  }${
                    x.isDraft ? ' draft-email' : ''
                  }${
                    x.type === 0 ? ' sent' : ' received'}
                  `}
                >
                  <div className="lp-email-item-wrapper">
                    <div className="lp-email-item-icon">
                      {x.type === 0 ? <MdOutgoingMail /> : <MdMarkEmailUnread />}
                    </div>

                    <div className="lp-email-item-selection">
                      {selectedIds.some((y: string) => y == x.graphResourceId) ? (
                        <span className="selected">
                          <BsCheckCircleFill />
                        </span>
                      ) : (
                        <span className="unselected">
                          <BsCheckCircle />
                        </span>
                      )}
                    </div>

                    <div className="lp-email-item-content">
                      <div className="lp-email-item-header">
                        <h3 className="lp-email-item-subject lp-email-manual-add">
                          {x.existsInDatabase &&
                            <span className="lp-email-item-existing">Exists</span>
                          }
                          {x.isDraft &&
                            <span className="lp-email-item-draft">Draft</span>
                          }
                          {x.subject}
                        </h3>
                        <div className="lp-email-item-time">
                          <MdAccessTime />
                          <span>{moment(x.type === 0 ? x.sentDateTime : x.receivedDateTime).format(DateFormat.Moment)}</span>
                          <span>{moment(x.type === 0 ? x.sentDateTime : x.receivedDateTime).format(DateFormat.MomentTime)}</span>
                        </div>
                      </div>
                      <div className="lp-email-item-details">
                        <div className="lp-email-item-recipients">
                          {x.type === 0 ? (
                            <div className="lp-email-item-to">
                              <span>To:</span>
                              <span className="value">
                                {x.toRecipients.length ? x.toRecipients.map((recipient, index) => (
                                  <span key={index}>{formatEmailRecipient(recipient.emailAddress, recipient.name)}{index !== x?.toRecipients?.length - 1 ? ', ' : ''}</span>
                                )) : '—'}
                              </span>
                            </div>
                          ) : (
                            <div className="lp-email-item-from">
                              <span>From:</span>
                              <span className="value">{formatEmailRecipient(x.senderEmailAddress, x.senderName)}</span>
                            </div>
                          )}
                        </div>

                        <div className="lp-email-item-actions">
                          {x.hasAttachments && <MdOutlineAttachment />}
                          {x.isRead ? <FaEye /> : <FaEyeSlash />}
                          {x.flag?.flagStatus === "Flagged" && <MdOutlineFlag />}
                          {x.importance === "High" && <BsExclamationLg />}
                        </div>
                      </div>
                      <div className="lp-email-item-body-preview">
                        {x.bodyPreview}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))
          }
        </div>

        {(pageNumber != 0 || existingNextPage) && (
          <div className="lp-emails-pagination">
            <Button
              onClick={handlePageDown}
              variant="secondary-400"
              disabled={pageNumber == 0}
            >
              Previous page
            </Button>

            <div className="lp-emails-current-page">
              Page
              {' ' + (pageNumber + 1)}
            </div>

            <Button
              onClick={handlePageUp}
              variant="secondary-400"
              disabled={!existingNextPage}
            >
              Next page
            </Button>
          </div>
        )}

        <div className="d-flex justify-content-between">
          <Button type="submit" variant="success">
            Import
          </Button>
          <Button variant="secondary-400" onClick={cancelForm}>
            Cancel
          </Button>
        </div>
      </Form>
    </>
  );
}
