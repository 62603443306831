import SlidingPanel from "components/SlidingPanel/index";
import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useEffect, useRef, useState } from "react";
import { getColumnDefs, viewCallback } from "./columnDefs";
import { Row, Col, Card } from "react-bootstrap";
import SummaryBox from 'components/SummaryBox';
import { SummaryBoxTypes } from "enums/SummaryBoxTypes";
import {
  downloadMatterIncidentalExpenses,
  getMatterIncidentalExpenses,
  getMatterIncidentalExpensesSummaryInfo,
  printMatterIncidentalExpenses
} from "actions/matter";
import Title from "components/Title/index";
import { GridIds } from "enums/GridIds";
import { GridOptionButtonDataModel } from "models/view/GridOptionButtonDataModel";
import Loader from "components/Loader/index";
import useNotificationActions from "actions/notification";
import SummaryBoxesSection from "components/SummaryBoxesSection";
import { MatterIncidentalExpenseModel } from "models/view/MatterIncidentalExpenseModel";
import { GridOptionTypes } from "enums/GridOptionTypes";
import { downloadFile, printPdf } from "utils/misc";
import { NotificationTypes } from "enums/NotificationTypes";
import { MatterIncidentalExpensesSummaryInfoModel } from "models/view/MatterIncidentalExpensesSummaryInfoModel";
import { useAppSelector } from 'hooks/appSelector';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import { AgGridReact } from "ag-grid-react";
import { SummaryIds } from "enums/SummaryIds";
import usePageActions from "actions/page";

type Props = {
  matterId: string,
  matterFileNumber: string,
  matterDisplayName: string,
  syncMatterFiles: Function,
  matterIsClosed?: boolean
}

export default function MatterIncidentalExpenseList(props: Props) {
  const gridActions = useGridActions();
  const pageActions = usePageActions();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<string | undefined>(undefined);
  const [matterIncidentalExpensesSummaryInfo, setMatterIncidentalExpensesSummaryInfo] = useState<MatterIncidentalExpensesSummaryInfoModel | undefined>(undefined);
  const [isSummaryInfoLoading, setIsSummaryInfoLoading] = useState<boolean>(false);
  const [genericErrorsForSummaryInfo, setGenericErrorsForSummaryInfo] = useState(undefined);
  const [genericErrors, setGenericErrors] = useState(null);
  const loggedInUser = useAppSelector((state) => state.user);
  const gridRef = useRef<AgGridReact>(null);

  const notificationActions = useNotificationActions();

  const onGridReady = useCallback(() =>
  {
    loadMatterIncidentalExpensesGrid();
  }, [props.matterId]);

  useEffect(() => {
    loadMatterIncidentalExpensesSummaryInfo();

    pageActions.setReloadGridData(reloadGridData);
    pageActions.setReloadSummaryData(loadMatterIncidentalExpensesSummaryInfo);
  }, [props.matterId]);

  useEffect(() => {
    gridActions.setGridId(GridIds.MatterIncidentalExpenses);

    return () => {
      pageActions.resetPageData();
    }
  }, []);

  const reloadGridData = () => populateMatterIncidentalExpenses(props.matterId);

  const loadMatterIncidentalExpensesGrid = () => {
    const columnDefs = getColumnDefs(pageActions.triggerReloadPage);
    gridActions.setGridColumnDefs([...columnDefs]);

    populateMatterIncidentalExpenses(props.matterId);
  }

  const populateMatterIncidentalExpenses = (matterId?: string) => {
    if(matterId) {
      getMatterIncidentalExpenses(props.matterId ?? '').then((response) => 
      {
        gridActions.setGridRowData(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
        gridActions.setGridRowData([]);
      });
    }
    else {
      gridActions.setGridRowData([]);
    }
  };

  const loadMatterIncidentalExpensesSummaryInfo = () => {
    if(props.matterId) {
      setIsSummaryInfoLoading(true);
      getMatterIncidentalExpensesSummaryInfo(props.matterId).then((response) => {
        setMatterIncidentalExpensesSummaryInfo(response.data);
      })
      .catch((error) => {
        setGenericErrorsForSummaryInfo(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsSummaryInfoLoading(false);
      });
    }
    else{
      setMatterIncidentalExpensesSummaryInfo(undefined);
    }
  }

  const gridOptions: GridOptionButtonDataModel[] = [
    ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterIncidentalExpenses) ? [{
      type: GridOptionTypes.Download,
      callback: (rows: MatterIncidentalExpenseModel[]) => downloadIncidentalExpenses(rows)
    }] : []),
    ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterIncidentalExpenses) ? [{
      type: GridOptionTypes.Print,
      callback: (rows: MatterIncidentalExpenseModel[]) => printIncidentalExpenses(rows)
    }] : [])
  ];

  const downloadIncidentalExpenses = (rows: MatterIncidentalExpenseModel[]) => {
    setIsLoading(true);
    setLoadingText("Downloading...");

    downloadMatterIncidentalExpenses(props.matterId, rows.map(x => x.id)).then((response) => {
      downloadFile(response);
    })
    .catch((error) => {
      error.response.data.text().then((text: any) => {
        let responseData = undefined;
        try {
          responseData = JSON.parse(text);
        }
        catch(e) {
          responseData = undefined;
        }
        notificationActions.addNotificationMessage(
          {
            type: NotificationTypes.Error,
            title: "Download Error",
            body: `${responseData?.Message ?? "There was an error while downloading the files."}`,
            isDismissable: true
          }
        );
      });
    })
    .finally(() => {
      setIsLoading(false);
      setLoadingText(undefined);
    });
  }

  const printIncidentalExpenses = (rows: MatterIncidentalExpenseModel[]) => {
    setIsLoading(true);
    setLoadingText("Preparing printing document...");

    printMatterIncidentalExpenses(rows[0].matterId, rows.map(x => x.id)).then((response) => {
      printPdf(response.data);
    })
    .catch((error) => {
      //the response is Blob here, we need to parse it
      error.response.data.text().then((text: any) => {
        let responseData = undefined;
        try {
          responseData = JSON.parse(text);
        }
        catch(e) {
          responseData = undefined;
        }
        notificationActions.addNotificationMessage(
          {
            type: NotificationTypes.Error,
            title: "Print Error",
            body: `${responseData?.Message ?? "There was an error printing the file."}`,
            isDismissable: true
          }
        );
      });
    })
    .finally(() => {
      setIsLoading(false);
      setLoadingText(undefined);
    });
  }

  return (
    <>
      {isSummaryInfoLoading &&
        <div className="lp-summary-boxes-placeholder">
          <Loader inlineLoader />
        </div>
      }

      {genericErrorsForSummaryInfo && (
        <div className="lp-errors">
          {genericErrorsForSummaryInfo}
        </div>
      )}

      {matterIncidentalExpensesSummaryInfo && 
        <Row>
          <Col>
            <SummaryBoxesSection id={SummaryIds.MatterIncidentalExpensesSummary}>
              <SummaryBox
                type={SummaryBoxTypes.IncidentalExpensesDoughnut}
                values={[
                  {
                    value: matterIncidentalExpensesSummaryInfo.totalBilled ?? 0,
                    label: "Billed",
                    className: "lp-primary"
                  },
                  {
                    value: matterIncidentalExpensesSummaryInfo.totalUnbilled ?? 0,
                    label: "Unbilled",
                    className: "lp-warning"
                  },
                  ...matterIncidentalExpensesSummaryInfo.totalWriteOff ? [{
                    value: matterIncidentalExpensesSummaryInfo.totalWriteOff ?? 0,
                    label: "Write-Off",
                    className: "lp-danger"
                  }] : []
                ]}
              />
            </SummaryBoxesSection>
          </Col>
        </Row>
      }
      
      {isLoading && <Loader text={loadingText} />}

      <Row>
        <Col>
          <Title
            type="section"
            title={<>Incidental Expenses</>}
          >
          </Title>

          {genericErrors && (
            <div className="lp-errors">
              {genericErrors}
            </div>
          )}

          <Card className="with-grid">
            <Card.Body>
              <Grid 
                ref={gridRef}
                onGridReady={onGridReady}
                gridOptions={gridOptions}
                exportTitle={`Matter: ${props.matterFileNumber} (${props.matterDisplayName}) - Incidental Expenses`}
                pagination={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <SlidingPanel gridRef={gridRef} onNavigation={(item: any) => viewCallback(item)} />
      <iframe className={"lp-print-helper-hidden"} />
    </>
  );
}
