import SlidingPanel from "components/SlidingPanel/index";
import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useEffect, useRef, useState } from "react";
import { getColumnDefs, viewCallback } from "./columnDefs";
import { Row, Col, Card } from "react-bootstrap";
import Title from "components/Title/index";
import CustomSelect from "components/Select/Select";
import "./style.scss";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { getDelegatedFromLTEsSummary, getDelegatedLTEById } from "actions/lte";
import { getDelegatedUsers } from "actions/user";
import { DelegatedLTEModel } from "models/view/DelegatedLTEModel";
import { AgGridReact } from "ag-grid-react";
import { GridIds } from "enums/GridIds";

type Props = {
  lteId: string,
  lteLegalName: string,
}

export default function DelegateUsers(props: Props) {
  const gridActions = useGridActions();
  const [genericErrors, setGenericErrors] = useState(null);
  const [selectedDelegatedLTEId, setSelectedDelegatedLTEId] = useState<string>();
  const [selectedDelegatedLTE, setSelectedDelegatedLTE] = useState<DelegatedLTEModel>();
  const [delegatedLTEs, setDelegatedLTEs] = useState<DropDownOptionModel[]>([]);
  const [isLoadingLTEs, setIsLoadingLTEs] = useState<boolean>(false);
  const gridRef = useRef<AgGridReact>(null);

  useEffect(() => {
    gridActions.setGridId(GridIds.LteDelegateUsers);
  }, []);

  const onGridReady = useCallback(() =>
  {
    const columnDefs = getColumnDefs(props.lteId, selectedDelegatedLTE);
    gridActions.setGridColumnDefs([...columnDefs]);
    if(selectedDelegatedLTE) {
      loadDelegatedUsers(selectedDelegatedLTE.id);
    }
    else {
      gridActions.setGridRowData([]);
    }
  }, [props.lteId, selectedDelegatedLTE]);

  useEffect(() => {
    setIsLoadingLTEs(true);
    getDelegatedFromLTEsSummary(props.lteId).then((response) => {
      setDelegatedLTEs(response.data);

      if(response.data.length > 0){
        onDelegatedLTEChange(response.data[0].id, response.data[0].name);
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingLTEs(false);
    });
  }, [props.lteId]);

  const onDelegatedLTEChange = (value: string, name: string) => {
    setSelectedDelegatedLTEId(value);

    // Update additional export info
    gridActions.setGridAdditionalFilterExportInfo([`Trading Entity: ${name}`]);

    getDelegatedLTEById(value)
      .then((response) => {
        setSelectedDelegatedLTE(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      });
  }

  const loadDelegatedUsers = (delegatedLTEId: string) => {
    getDelegatedUsers(delegatedLTEId)
      .then((response) => {
        gridActions.setGridRowData(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
        gridActions.setGridRowData([]);
      });
  }

  return (
    <>
      <Row>
        <Col>
          <Title type="section" title={"Delegate Users"} />

          {genericErrors && (
            <div className="lp-errors">
              {genericErrors}
            </div>
          )}

          <Card className="with-grid">
            <Card.Body>
              <div className="lp-delegated-ltes-select">
                <div className="lp-label">
                  Trading Entity:
                </div>
                <CustomSelect
                  id="delegatedLTEId"
                  options={delegatedLTEs}
                  isLoading={isLoadingLTEs}
                  onChange={val => onDelegatedLTEChange(val?.id ?? null, val?.name ?? null)}
                  value={selectedDelegatedLTEId}
                />
              </div>
              <Grid 
                ref={gridRef}
                onGridReady={onGridReady}
                exportTitle={`Trading Entity: ${props.lteLegalName} - Delegate Users`}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <SlidingPanel gridRef={gridRef} onNavigation={(item: any) => viewCallback(props.lteId, item, selectedDelegatedLTE)} />
    </>
  );
}
