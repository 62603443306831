import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button, InputGroup, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import { getValidationSuite } from "./validationSuite";
import Loader from "components/Loader/index";
import { CreateOrUpdateTaxonomyTermModel } from "models/create/CreateOrUpdateTaxonomyTermModel";
import { getMatterTaxonomyById, getMatterTaxonomyTermById, getMatterTaxonomyTermsSummary, getTaxonomyNextSequence, updateMatterTaxonomyTerm } from "actions/taxonomy";
import { TaxonomyModel } from "models/view/TaxonomyModel";
import CustomTreeSelect from "components/TreeSelect/CustomTreeSelect";
import FormSubmitButton from "components/Buttons/FormSubmitButton";

type Props = {
  matterId: string,
  taxonomyId: string,
  taxonomyTermId: string,
  onSubmitCallback?: Function
}

export default function EditMatterTaxonomyTermForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState<string | null>(null);
  const slidingPanelActions = useSlidingPanelActions();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [taxonomy, setTaxonomy] = useState<TaxonomyModel | undefined>(undefined);
  const [parentSequence, setParentSequence] = useState<string | undefined>(undefined);
  const [nextSequence, setNextSequence] = useState<number | undefined>(undefined);

  useEffect(() => {
    setIsLoading(true);
    const promises = [
      getMatterTaxonomyById(props.matterId, props.taxonomyId),
      getMatterTaxonomyTermById(props.matterId, props.taxonomyId, props.taxonomyTermId)
    ];

    Promise.all(promises)
    .then(([taxonomyResponse, taxonomyTermResponse]) => {
      setTaxonomy(taxonomyResponse.data);

      const initialState = {
        displayName: taxonomyTermResponse.data.displayName,
        description: taxonomyTermResponse.data.description,
        parentTaxonomyTermId: taxonomyTermResponse.data.parentTaxonomyTermId,
        active: taxonomyTermResponse.data.active,
        matterTypeIds: taxonomyTermResponse.data.matterTypes?.map((x: any) => x.id),
        sequence: taxonomyTermResponse.data.sequence
      };
      reset(initialState);

      setParentSequence(taxonomyTermResponse.data.parentSequence);
      getNextSequence(taxonomyResponse.data, false, taxonomyTermResponse.data.parentTaxonomyTermId);
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const {register, reset, control, setValue, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateTaxonomyTermModel>({
    resolver: vestResolver(getValidationSuite(props.matterId, props.taxonomyId, props.taxonomyTermId, taxonomy?.isHierarchical))
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateTaxonomyTermModel) {
    setIsLoading(true);
    updateMatterTaxonomyTerm(props.matterId, props.taxonomyId, props.taxonomyTermId, data).then((response) => {
      props.onSubmitCallback && props.onSubmitCallback();
      
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  const getNextSequence = (taxonomy: TaxonomyModel, setNewSequence: boolean, parentTaxonomyTermId?: string) => {
    if(taxonomy.isHierarchical) {
      getTaxonomyNextSequence(props.taxonomyId, parentTaxonomyTermId, props.taxonomyTermId).then((response) => {
        setNextSequence(response.data.nextSequence);
        if(setNewSequence) {
          setParentSequence(response.data.parentSequence);
          setValue('sequence', response.data.nextSequence);
        }
      }).catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      });
    }
  }
      
  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} controlId="displayName">
            <Form.Label className="required">Display Name</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.displayName?.message ? 'invalid' : ''}`}
              {...register("displayName", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.displayName?.message && (errors.displayName.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              className={`${errors?.description?.message ? 'invalid' : ''}`}
              {...register("description", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.description?.message && (errors.description.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row className={taxonomy?.isHierarchical ? '' : 'd-none'}>
          {taxonomy && taxonomy?.isHierarchical &&
            <Form.Group as={Col} sm={6} controlId="parentTaxonomyTermId">
              <Form.Label>Parent Term</Form.Label>
              <Controller
                control={control}
                name="parentTaxonomyTermId"
                shouldUnregister={true}
                render={({field: { onChange, value, name, ref }}) => (
                  <CustomTreeSelect
                    id="parentTaxonomyTermId"
                    className={`lp-select w-100${errors?.parentTaxonomyTermId?.message ? ' invalid' : ''}`}
                    endpointCall={() => getMatterTaxonomyTermsSummary(props.matterId, props.taxonomyId, true, props.taxonomyTermId)}
                    value={value}
                    onChange={(val: string) => { onChange(val ?? null); getNextSequence(taxonomy, true, val);}}
                    dropdownMatchSelectWidth
                    treeDefaultExpandAll
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.parentTaxonomyTermId?.message && (errors.parentTaxonomyTermId.message)}
              </Form.Text>
            </Form.Group>
          }

          {taxonomy?.isHierarchical &&
            <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="note">
              <Form.Label className="required">Sequence</Form.Label>
              <InputGroup className="mb-3">
                {parentSequence &&
                  <InputGroup.Text id="basic-addon1">{parentSequence}.</InputGroup.Text>
                }
                <Form.Control
                  type="number"
                  className={`${errors?.sequence?.message ? 'invalid' : ''}`}
                  {...register('sequence', { shouldUnregister: true })}
                  min="1"
                  max={nextSequence}
                  step="1"
                  onWheel={e => e.currentTarget.blur()}
                />
              </InputGroup>
              <Form.Text className="lp-error">
                {errors?.sequence?.message && errors.sequence.message}
              </Form.Text>
            </Form.Group>
          }
        </Row>

        <Row>
          <Form.Group as={Col} controlId="active">
            <Form.Label>Active</Form.Label>
            <Controller
              control={control}
              name="active"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="active">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <FormSubmitButton text="Update" errors={{...errors}} />
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}