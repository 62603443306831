export enum SlidingPanelIds {
  ViewMatterEmail = "ViewMatterEmail",
  ViewMatterFile = "ViewMatterFile",
  PreviewMatterEmail = "PreviewMatterEmail",
  PreviewMatterFile = "PreviewMatterFile",
  ViewMatterDisbursement = "ViewMatterDisbursement",
  ViewMatterIncidentalExpense = "ViewMatterIncidentalExpense",
  ViewMatterRecordableEvent = "ViewMatterRecordableEvent",
  ViewMatterInvoice = "ViewMatterInvoice",
  ViewMatterNote = "ViewMatterNote",
}