import { ColDef } from "ag-grid-community";
import store from "state/store";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import EditDefaultAccountForm from "./EditDefaultAccount/EditDefaultAccountForm";
import { DefaultAccountModel } from "models/view/DefaultAccountModel";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { getDefaultAccountTypesSummary } from "actions/settings";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

const editCallback = (id: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Edit Default Account",
        children: <EditDefaultAccountForm defaultAccountId={id}/>
      }
    )
  );
}

export const getColumnDefs = () => {
  const loggedInUser = store.getState().user;
  
  const columnDefs: (ColDef<DefaultAccountModel> | {excludeFromExport: boolean})[] = [
    {
      headerName: 'Display Name',
      field: 'displayName',
      filter: 'agTextColumnFilter',
      minWidth: 120,
      flex: 3,
    },
    {
      headerName: 'Account Code',
      field: 'accountCode',
      filter: 'agTextColumnFilter',
      suppressSizeToFit: true,
      minWidth: 120,
      flex: 2,
    },
    {
      headerName: 'Type',
      field: 'defaultAccountType.displayName',
      filter: DropdownFilter,
      filterParams: {
        property: 'defaultAccountTypeId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getDefaultAccountTypesSummary
      },
      minWidth: 220,
      flex: 3,
    },
    {
      headerName: 'Is Active',
      field: 'isActive',
      filter: BooleanFilter,
      filterParams: {
        property: 'isActive',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
      ]},
      minWidth: 100,
      width: 100,
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter
    },
    { 
      excludeFromExport: true,
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        return { 
          buttonsData: [{
            type: ActionButtonTypes.Edit,
            callback: editCallback
          }]
        };
      },
      hide: !loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageDefaultSettings),
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 65,
      maxWidth: 65,
      width: 65,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];
  return columnDefs;
}