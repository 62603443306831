import TreeSelect from "rc-tree-select";
import './style.scss';
import { CheckedStrategy } from "rc-tree-select/lib/utils/strategyUtil";

type Props = {
  data: any[],
  value: any,
  onChange: Function,
  showCheckedStrategy?: CheckedStrategy,
  className?: string,
  classNamePrefix?: string,
  id?: string,
  dropdownMatchSelectWidth?: boolean,
  treeCheckStrictly?: boolean,
  treeCheckable?: boolean
  treeDefaultExpandAll?: boolean,
  maxTagCount?: number | 'responsive',
  maxTagTextLength?: number
}

export default function TreeSelectForFilter(props: Props) {
  return (
    <TreeSelect
      id={props.id}
      value={props.data && props.data.length > 0 ? props.value : undefined}
      treeNodeFilterProp="name"
      className={props.className}
      prefixCls={props.classNamePrefix}
      fieldNames={
        {
          value: "id",
          label: "name",
          children: "children"
        }
      }
      treeData={props.data}
      onChange={props.onChange as any}
      maxTagTextLength={props.maxTagTextLength ?? 10}
      maxTagCount={props.maxTagCount ?? 0}
      maxTagPlaceholder={valueList => {
        return `${valueList.length} selected`;
      }}
      showCheckedStrategy={props.showCheckedStrategy}
      showTreeIcon={false}
      showArrow
      showSearch
      treeCheckable={props.treeCheckable}
      allowClear
      dropdownMatchSelectWidth={props.dropdownMatchSelectWidth ?? false}
      treeCheckStrictly={props.treeCheckStrictly}
      treeDefaultExpandAll={props.treeDefaultExpandAll}
    />
  );
}
