import { getMattersForUser } from 'actions/user';
import { useEffect, useState } from 'react';
import Title from 'components/Title';
import { MatterModel } from 'models/view/MatterModel';
import ListMatters from 'components/List/ListMatters';
import { ActionButtonTypes } from 'enums/ActionButtonTypes';
import { useNavigate, Link } from 'react-router-dom';
import { MdArrowOutward } from 'react-icons/md';
import { useAppSelector } from 'hooks/appSelector';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type Props = {
  title?: string;
  dragIcon?: React.ReactNode;
}

export default function DashboardMatters(props: Props) {
  const [matters, setMatters] = useState<MatterModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(undefined);
  const navigate = useNavigate();
  const loggedInUser = useAppSelector((state) => state.user);

  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: 'matters' });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition: transition || undefined,
  };

  useEffect(() => {
    loadMatters();
  }, []);

  const loadMatters = () => {
    setIsLoading(true);
    getMattersForUser(10).then((response) => {
      setMatters(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  const viewMatter = (id: string) => {
    navigate(`/matter/${id}`, {state: { "edit": false}});
  };

  return (
    <div ref={setNodeRef} style={style} className={`lp-dashboard-dnd-draggable-item ${isDragging ? 'dragging' : ''}`}>
      <Title
        type="section"
        title={props.title ?? "Recent Matters"}
        icon={props.dragIcon}
        dragAttributes={{ listeners: listeners, attributes: attributes }}
      >
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewMatters) &&
          <Link to="/matter" rel="noopener noreferrer" className="link">
            View all
            <MdArrowOutward />
          </Link>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <ListMatters
        listItems={matters}
        listButtons={
          [
            ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewMatters) ? [{
              type: ActionButtonTypes.View,
              callback: (id: string) => viewMatter(id)
            }] : [])
          ]
        }
        loadState={isLoading}
      />
    </div>
  );
}