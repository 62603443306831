import { create, test, enforce, omitWhen } from "vest";

export const getValidationSuite = () => {
  return create((data = {}) => {
    test('name', 'Name is required', () => {
      enforce(data.name).isNotEmpty();
      enforce(data.name).isString();
    });
  
    test('type', 'Please select a Type', () => {
      enforce(data.type).isNotEmpty();
    });
  
    test('vatRateValue', 'VAT Rate Value is required', () => {
      enforce(data.vatRateValue).isNotUndefined();
    });
  
    test('vatRateValue', 'VAT Rate Value needs to be a number > 0', () => {
      enforce(data.vatRateValue).greaterThanOrEquals(0);
    });
  
    omitWhen(!data.dateInForce || !data.dateOutForce, () => {
      test('dateOutForce', 'Date Out Force needs to be greater than or equals Date In Force', () => {
        const calculatedMinutesDif = data.dateOutForce.getTime() - data.dateInForce.getTime();
        enforce(calculatedMinutesDif).greaterThanOrEquals(0);
      });
    });
  });
}