import { useEffect, useState } from 'react';
import Field from 'components/Fields/Field';
import Loader from 'components/Loader';
import { Row, Col, Button } from 'react-bootstrap';
import { MatterEmailModel } from 'models/view/MatterEmailModel';
import BooleanField from 'components/Fields/BooleanField';
import moment from 'moment';
import { DateFormat } from 'utils/constants';
import { getEmailTypeName } from 'utils/enums';
import ListSlidingPanel from 'components/List/ListSlidingPanel';
import { createForwardDraftUserEmail, createReplyAllDraftUserEmail, createReplyDraftUserEmail, getUserEmailById, toggleEmailReadStatus } from 'actions/user';
import { useAppSelector } from 'hooks/appSelector';
import { BiLinkExternal } from 'react-icons/bi';
import { MdReply, MdReplyAll } from 'react-icons/md';
import { formatEmailRecipient, openUrlInNewtab } from 'utils/misc';
import React from 'react';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import useSlidingPanelActions from 'actions/slidingPanel';
import { ActionButtonTypes } from 'enums/ActionButtonTypes';

type Props = {
  emailGraphId: string,
  toggleReadStatus: Function,
};

function ViewUserEmail(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [matterEmail, setMatterEmail] = useState<MatterEmailModel>();
  const user = useAppSelector((state) => state.user);
  const slidingPanelActions = useSlidingPanelActions();

  useEffect(() => {
    setIsLoading(true);
    getUserEmailById(props.emailGraphId, user.msalAccessToken!)
      .then((response) => {
        setMatterEmail(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (matterEmail) {
      slidingPanelActions.setMoreButtons([
        {
          type: matterEmail?.isRead ? ActionButtonTypes.MarkAsUnread : ActionButtonTypes.MarkAsRead,
          callback: toggleReadStatus,
        },
      ]);
    }
  }, [matterEmail]);

  function createDraftReplyEmailHandler() {
    setIsLoading(true);
    createReplyDraftUserEmail(props.emailGraphId, user.msalAccessToken!)
      .then((response) => {
        openUrlInNewtab(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function createDraftReplyAllEmailHandler() {
    setIsLoading(true);
    createReplyAllDraftUserEmail(props.emailGraphId, user.msalAccessToken!)
      .then((response) => {
        openUrlInNewtab(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function createDraftForwardEmailHandler() {
    setIsLoading(true);
    createForwardDraftUserEmail(props.emailGraphId, user.msalAccessToken!)
      .then((response) => {
        openUrlInNewtab(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function toggleReadStatus() {
    if(matterEmail) {
      matterEmail.isRead = !matterEmail.isRead;
      setMatterEmail({...matterEmail});
    }
    props.toggleReadStatus();
    toggleEmailReadStatus(props.emailGraphId, user.msalAccessToken!);
  }

  return (
    <div className="lp-email-view lp-iframe-panel">
      {isLoading && <Loader inlineLoader />}

      {genericErrors && <div className="lp-errors">{genericErrors}</div>}

      <Row>
        <Col className="lp-email-action-btns">
          {matterEmail?.webLink &&
            <Button variant="primary" onClick={() => openUrlInNewtab(matterEmail?.webLink)}>
              <BiLinkExternal />
              <span>Open with Outlook</span>
            </Button>
          }
          {matterEmail && user.userPermissions?.some(a => a == UserPermissionsNames.ManageDashboardEmails) &&
            <>
              <Button onClick={createDraftReplyEmailHandler} variant="secondary-400">
                <MdReply />
                <span>Reply</span>
              </Button>
              <Button onClick={createDraftReplyAllEmailHandler} variant="secondary-400">
                <MdReplyAll />
                <span>Reply all</span>
              </Button>
              <Button onClick={createDraftForwardEmailHandler} variant="secondary-400 reverse-icon">
                <MdReply />
                <span>Forward</span>
              </Button>
            </>
          }
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Field
            label={'Subject'}
            className="lp-email-view-subject"
            value={
              <>
                {matterEmail && !matterEmail?.isRead && <span className="lp-email-view-unread">Unread</span>}
                {matterEmail?.matter?.fileNumber && <span className="lp-email-view-matter">{matterEmail?.matter?.fileNumber}</span>}
                {matterEmail?.subject ?? '—'}
              </>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={'Sender'}
            value={matterEmail ? formatEmailRecipient(matterEmail?.senderEmailAddress!, matterEmail?.senderName) : '—'}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={'To'}
            value={matterEmail?.toRecipients?.length ? matterEmail?.toRecipients.map((recipient, index) => (
              <span key={index}>{formatEmailRecipient(recipient.emailAddress, recipient.name)}{index !== matterEmail?.toRecipients?.length - 1 ? ', ' : ''}</span>
            )) : '—'}
          />
        </Col>
      </Row>
      {matterEmail?.ccRecipients &&
        matterEmail?.ccRecipients.length > 0 && (
          <Row>
            <Col>
              <Field
                label={'Cc'}
                value={matterEmail?.ccRecipients.map((recipient, index) => (
                  <span key={index}>{formatEmailRecipient(recipient.emailAddress, recipient.name)}{index !== matterEmail?.ccRecipients?.length! - 1 ? ', ' : ''}</span>
                ))}
              />
            </Col>
          </Row>
        )}
      {matterEmail?.bccRecipients &&
        matterEmail?.bccRecipients.length > 0 && (
          <Row>
            <Col>
              <Field
                label={'Bcc'}
                value={matterEmail?.bccRecipients.map((recipient, index) => (
                  <span key={index}>{formatEmailRecipient(recipient.emailAddress, recipient.name)}{index !== matterEmail?.bccRecipients?.length! - 1 ? ', ' : ''}</span>
                ))}
              />
            </Col>
          </Row>
        )}
      <Row>
        {matterEmail?.type == 0 && (
          <Col md={4}>
            <Field
              label={'Date'}
              value={
                matterEmail?.sentDateTime
                  ? moment(matterEmail?.sentDateTime).format(
                      DateFormat.MomentWithTime
                    )
                  : '—'
              }
            />
          </Col>
        )}
        {matterEmail?.type != 0 && (
          <Col md={4}>
            <Field
              label={'Date'}
              value={
                matterEmail?.receivedDateTime
                  ? moment(matterEmail?.receivedDateTime).format(
                      DateFormat.MomentWithTime
                    )
                  : '—'
              }
            />
          </Col>
        )}
        <Col md={4} className="mt-2 mt-md-0">
          <Field label={'Type'} value={getEmailTypeName(matterEmail?.type!)} />
        </Col>
        <Col md={4} className="mt-2 mt-md-0">
          <BooleanField
            label={'Internal'}
            value={matterEmail?.isInternal ?? false}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <BooleanField
            label={'Has Attachments'}
            value={matterEmail?.hasAttachments ?? false}
          />
        </Col>
        <Col md={4} className="mt-2 mt-md-0">
          <BooleanField
            label={'Is Flagged'}
            value={matterEmail?.flag.flagStatus === "Flagged"}
          />
        </Col>
        <Col md={4} className="mt-2 mt-md-0">
          <BooleanField
            label={'Is Important'}
            value={matterEmail?.importance === "High"}
          />
        </Col>
      </Row>
      
      {matterEmail?.hasAttachments &&
        <Row>
          <Col>
            <div className="departments">
              <ListSlidingPanel
                listItems={
                  matterEmail?.attachments?.map((x) => ({ id: x, name: x })) ?? []
                }
                listButtons={[]}
              >
                Attachments
              </ListSlidingPanel>
            </div>
          </Col>
        </Row>
      }

      {matterEmail?.bodyContent &&
        <>
          <Field label={'Email Content'} value={''} className="mt-3" />
          <Row className="full-height">
            <Col>
              <iframe srcDoc={matterEmail?.bodyContent} title="Email Preview" height="100%" width="100%"></iframe> 
            </Col>
          </Row>
        </>
      }
    </div>
  );
}

export default ViewUserEmail;
