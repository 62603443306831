import { useEffect, useState } from "react";
import { MenuPlacement, OptionProps } from "react-select";
import CustomSelect from "./Select";
import { getMatterFilesSummary, uploadFilesToSharepoint } from "actions/matter";
import { AttachmentDropDownOptionModel } from "models/view/AttachmentDropDownOptionModel";
import { Button, Tooltip, OverlayTrigger, Form } from "react-bootstrap";
import { MdAdd, MdOutlineClose, MdSave } from "react-icons/md";
import Upload from "components/Upload";
import Loader from "components/Loader";
import { MatterFileModel } from "models/view/MatterFileModel";
import "./style.scss";
import { MatterAttachmentTypes } from "enums/MatterAttachmentTypes";
import { getMatterEmailAttachmentTypeName } from "utils/enums";
import { DateFormat } from "utils/constants";
import moment from "moment";
import { FormErrorTypes } from "enums/FormErrorTypes";
import { ErrorOption } from "react-hook-form";

type Props = {
  matterId: string,
  id: string,
  onChange?: (value: any) => any,
  onBlur?: (event: any) => any,
  onInputChange?: (keyword: any) => void,
  setError?: Function,
  clearErrors?: Function,
  errors?: ErrorOption,
  className?: string,
  classNamePrefix?: string,
  value?: string | string[],
  defaultValue?: string | string[],
  isMulti?: boolean,
  isClearable?: boolean,
  isDisabled?: boolean,
  closeMenuOnSelect?: boolean,
  menuPlacement?: MenuPlacement,
  placeholder?: string,
  inputRef?: any,
  styles?: any,
  menuPortalTarget?: any,
  canUploadNewFiles?: boolean,
  showEmails?: boolean,
  groupByType?: boolean
}

export default function MatterFileSelect(props: Props) {
  const [options, setOptions] = useState<any[] | undefined>([]);
  const [value, setValue] = useState<string | string[] | undefined>(props.value);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openUploadFiles, setOpenUploadFiles] = useState<boolean>(false);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
  const [uploadGenericErrors, setUploadGenericErrors] = useState(null);

  useEffect(() => {
    loadMatterFiles();
  }, []);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if(newFiles.length) {
      props.setError && props.setError({type: FormErrorTypes.FileNotUploaded, message: "Selected files have not been saved to Activity History"});
    }
    else {
      props.clearErrors && props.clearErrors();
    }
  }, [newFiles]);

  const loadMatterFiles = () => {
    setIsLoading(true);
    getMatterFilesSummary(props.matterId, props.showEmails).then((response) => {
      let options = response.data;
      if(props.groupByType) {
        options = options
          .sort((a: AttachmentDropDownOptionModel, b: AttachmentDropDownOptionModel) => a.type - b.type)
          .reduce((acc: { key: MatterAttachmentTypes, label: string, options: AttachmentDropDownOptionModel[] }[], obj: AttachmentDropDownOptionModel) => {
            const key = obj.type;
            const group = acc.find(x => x.key === key);
            if(!group) {
              acc.push({ key: key, label: getMatterEmailAttachmentTypeName(key) ?? '', options: [obj] });
            }
            else {
              group.options.push(obj);
            }
            return acc;
          }, []);
      }
      setOptions(options);
    })
    .catch((error: any) => {
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const uploadFiles = () => {
    if(newFiles.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < newFiles.length; i++) {
        formData.append('files', newFiles[i]);
      }

      setIsUploadLoading(true);
      uploadFilesToSharepoint(props.matterId, formData)
        .then((response) => {
          const newFileIds = response.data.map((x: MatterFileModel) => x.id);
          if(Array.isArray(value)) {
            const newValues = [...value, ...newFileIds];
            setValue(newValues);
            if(props.onChange) {
              props.onChange(newValues);
            }
          }
          else {
            setValue(newFileIds);
            if(props.onChange) {
              props.onChange(newFileIds);
            }
          }

          props.clearErrors && props.clearErrors();
          setOpenUploadFiles(false);
          loadMatterFiles();
        })
        .catch((error) => {
          setUploadGenericErrors(error.response?.data?.Message ?? error.message);
        })
        .finally(() => {
          setIsUploadLoading(false);
        });
    }
    else {
      setOpenUploadFiles(false);
    }
  }

  const cancelUploadFiles = () => {
    setNewFiles([]);

    setOpenUploadFiles(false);
  }

  const onChange = (val: any) => {
    if(props.onChange) {
      if(props.isMulti) {
        props.onChange!(val?.map((x: { id: any; }) => x.id) ?? null);
      }
      else {
        props.onChange!(val?.id ?? null);
      }
    }
  }

  const DropdownRenderComponent = (props: OptionProps) => {
    const { children, className, cx, isDisabled, isFocused, isSelected, innerRef, innerProps } = props;
    return (
      <div
        ref={innerRef}
        className={cx(
          {
            option: true,
            'option--is-disabled': isDisabled,
            'option--is-focused': isFocused,
            'option--is-selected': isSelected,
          },
          className
        )}
        {...innerProps}
      >
        <div className="lp-matter-file-select-custom-dropdown-title">
          {children}
        </div>

        {(props.data as any)?.date && (
          <div className="lp-matter-file-select-custom-dropdown-date">
            {moment((props.data as any).date).format(DateFormat.MomentWithTime)}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="lp-matter-file-select">
        <CustomSelect
          id={props.id}
          value={value}
          inputRef={props.inputRef}
          options={options}
          renderComponent={DropdownRenderComponent}
          getOptionLabel={(x: AttachmentDropDownOptionModel) => x.name}
          getOptionValue={(x: AttachmentDropDownOptionModel) => x.id}
          isGrouped={props.groupByType}
          onChange={onChange}
          onBlur={props.onBlur}
          onInputChange={props.onInputChange}
          className={`${props.className ?? 'lp-select'} lp-matter-file-select-custom-dropdown`}
          classNamePrefix={props.classNamePrefix ?? 'lp-select'}
          defaultValue={props.defaultValue}
          isDisabled={props.isDisabled || isLoading}
          isMulti={props.isMulti ?? false}
          isClearable={props.isClearable}
          closeMenuOnSelect={props.closeMenuOnSelect}
          isLoading={isLoading}
          menuPlacement={props.menuPlacement}
          menuPortalTarget={props.menuPortalTarget}
          styles={props.styles}
          placeholder={props.placeholder ?? ""}
        />
        {props.canUploadNewFiles && !openUploadFiles &&
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip id="tooltip-left">Add new files</Tooltip>
            }
          >
            <Button variant={"success"} onClick={() => setOpenUploadFiles(true)} className="btn-icon">
              <MdAdd />
            </Button>
          </OverlayTrigger>
        }
        {props.canUploadNewFiles && openUploadFiles &&
          <>
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id="tooltip-left">Save to Activity History</Tooltip>
              }
            >
              <Button variant={"success"} onClick={uploadFiles} className="btn-icon">
                <MdSave />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id="tooltip-left">Cancel</Tooltip>
              }
            >
              <Button variant={"danger"} onClick={cancelUploadFiles} className="btn-icon">
                <MdOutlineClose />
              </Button>
            </OverlayTrigger>
          </>
        }
      </div>

      {props.errors && <Form.Text className="lp-error mb-1">{props.errors.message}</Form.Text>}

      {props.canUploadNewFiles && openUploadFiles &&
        <>
          {isUploadLoading && <Loader inlineLoader />}
          
          {uploadGenericErrors && <div className="lp-errors">{uploadGenericErrors}</div>}

          <Upload
            onChange={(val) => setNewFiles(val ?? [])}
            isMulti
          />
        </>
      }
    </>
  );
}
