import { callXeroCallback } from "actions/xero";
import Loader from "components/Loader";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { useAppSelector } from "hooks/appSelector";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import XeroError from 'assets/images/lp-xero-error.webp';
import './style.scss';

function XeroCallback() {
  const [genericErrors, setGenericErrors] = useState<string | undefined>(undefined);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lteId, setLteId] = useState<string | undefined>(undefined);
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    setIsLoading(true);
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    setLteId(state ?? undefined);

    if(code && state) {
      callXeroCallback(code, state).then((response) => {
        if(isMobile) {
          if(user.userPermissions?.some(a => a == UserPermissionsNames.ManageAllLTEs)) {
            navigate(`/lte/${state}/systemSettings`);
          }
          else {
            navigate(`/lteProfile/systemSettings`);
          }
        }
        else {
          closeWindow();
        }
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
    else {
      const error = searchParams.get('error');

      if(error == 'access_denied') {
        setGenericErrors(`You don't have permission to access Xero.`);
      }
      else {
        setGenericErrors(`An error occured while trying to connect to Xero.`);
      }

      setIsLoading(false);
    }
  }, []);

  const closeWindow = () => {
    window.close();
  }

  return (
    <div className="lp-page-content">
      {isLoading && <Loader inlineLoader />}
      
      {genericErrors &&
        <div className="lp-xero-error">
          <div className="lp-xero-error-wrapper">
            <img src={XeroError} alt="LawPage - Xero Connection Error" />
            <div className="lp-xero-error-text">
              <h2>Something's wrong here.</h2>
              <h4>{genericErrors}</h4>
              {isMobile ?
                <NavLink
                  to={(user.userPermissions?.some(a => a == UserPermissionsNames.ManageAllLTEs) && lteId) ? `/lte/${lteId}/systemSettings` : `/lteProfile/systemSettings`}
                  className="btn btn-primary"
                >
                  GO TO TRADING ENTITY PROFILE
                </NavLink>
              :
                <Button onClick={closeWindow}>
                  CLOSE WINDOW
                </Button>
              }
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default XeroCallback;
