import useSlidingPanelActions from "actions/slidingPanel";
import React, { RefObject, useEffect, useState } from "react";
import { MdClose, MdEdit } from 'react-icons/md';
import { useSelector } from "react-redux";
import { RootState } from "state/store";
import './style.scss';
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { AgGridReact } from "ag-grid-react";
import MoreButton from "components/Buttons/MoreButton";
import IconButtonGrid from "components/Buttons/IconButtonGrid";
import { initialState, SlidingPanelState } from "state/slidingPanelSlice";
import _ from "lodash";
import { MatterActivityHistoryModel } from "models/view/MatterActivityHistoryModel";
import { GridOptionButtonDataModel } from "models/view/GridOptionButtonDataModel";
import PreviewPanelGridOptions from "./PreviewPanelGridOptions";

type Props = {
  gridRef?: RefObject<AgGridReact>,
  navigationFilter?: (rowData: any) => boolean,
  onNavigation?: (rowData: any) => void,
  selectedRows?: MatterActivityHistoryModel[],
  gridOptions?: GridOptionButtonDataModel[],
};

export default function PreviewPanel(props: Props) {
  const gridState = useSelector((state: RootState) => state.grid);
  const slidingPanelState = useSelector((state: RootState) => state.slidingPanel);
  const slidingPanelActions = useSlidingPanelActions();

  const [navigationData, setNavigationData] = useState<any[]>([]);
  const [currentNavigationIndex, setCurrentNavigationIndex] = useState<number>(0);
  const [isDataGrouped, setIsDataGrouped] = useState<boolean>(false);
  const [showActions, setShowActions] = useState<boolean>(false);

  // Store panel state locally to prevent content dissapearing when opening a sliding panel (from create)
  const [localPanelState, setLocalPanelState] = useState<SlidingPanelState>(initialState);

  // Sync local state with sliding panel state
  useEffect(() => {
    // Return if a sliding panel is opened on top of the preview in order to keep the content of the preview
    if(!slidingPanelState.children || slidingPanelState.forceSlidingPanelView) {
      return;
    }
    setLocalPanelState({
      ...slidingPanelState,
      // If the user clicks view on the same panel multiple times, restore the moreButtons and onEdit (prevents issue with buttons disappearing)
      moreButtons: localPanelState.id === slidingPanelState.id && !slidingPanelState.moreButtons ? localPanelState.moreButtons : slidingPanelState.moreButtons,
      onEdit: localPanelState.id === slidingPanelState.id && !slidingPanelState.onEdit ? localPanelState.onEdit : slidingPanelState.onEdit,
    });
  }, [slidingPanelState]);

  // Handle showing preview / actions based on the selected rows
  useEffect(() => {
    // Clear panel state when no rows are selected
    if(props.selectedRows && props.selectedRows.length === 0) {
      setLocalPanelState(initialState);
      slidingPanelActions.clearSlidingPanel();
    }
    // If there is only one row selected, use navigation with the selected row data to show the content
    else if(props.selectedRows && props.selectedRows.length === 1) {
      props.onNavigation && props.onNavigation(props.selectedRows[0]);
    }

    // When multiple rows are selected show actions
    if(props.selectedRows && props.selectedRows.length >= 2) {
      setShowActions(true);
      setLocalPanelState(initialState);
    }
    else {
      setShowActions(false);
    }
  }, [props.selectedRows]);
  
  useEffect(() => {
    // Switch from a preview panel (of one of the actions) to the actions panel - only when multiple rows are selected
    if(!showActions && !slidingPanelState.isShown && props.selectedRows && props.selectedRows.length >= 2) {
      setShowActions(true);
      setLocalPanelState(initialState);
    }

    // Switch from actions panel to the preview of an action (e.g. add bulk taxonomies) - only when multiple rows are selected
    if(showActions && localPanelState.isShown && props.selectedRows && props.selectedRows.length >= 2) {
      setShowActions(false);
    }
  }, [props.selectedRows, slidingPanelState.isShown, localPanelState.isShown]);

  const cancel = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    if(!localPanelState.onCancel) {
      setLocalPanelState(initialState);
    }
    else {
      localPanelState?.onCancel();
    }
  }

  const edit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    localPanelState.onEdit !== undefined && localPanelState?.onEdit();
  }

  // When panel is opened get navigation data & index from the grid ref
  useEffect(() => {
    let rowData: any[] = [];
    let selectedIndex = 0;

    // Handle getting the navigation data when the grid is grouped differently than when it's not
    // When the grid is grouped, forEachNodeAfterFilterAndSort will return the group headers as well
    // Once we get the actual data, filter out the ones that are not in the same group as the selected node
    if (props.gridRef?.current?.api?.getRowGroupColumns()?.length) {
      setIsDataGrouped(true);

      let nodeArray: any[] = [];
      let selectedNode: any = null;
      props.gridRef?.current?.api?.forEachNodeAfterFilterAndSort(node => {
        if(node.data && (!props.navigationFilter || (props.navigationFilter && props.navigationFilter(node.data)))) {
          nodeArray.push(node);
          if((node as any)?.selected) {
            selectedNode = node;
          }
        }
      });

      // Filter nodeArray to contain only the ones in the same group as the selected node
      nodeArray = nodeArray.filter((row: any) => row?.parent?.id === selectedNode?.parent?.id);
      rowData = nodeArray.map((row: any) => ({...row.data, rowIndex: row?.rowIndex ?? 0}));
      selectedIndex = nodeArray.findIndex((row: any) => row?.rowIndex === selectedNode?.rowIndex);
    }
    else {
      props.gridRef?.current?.api?.forEachNodeAfterFilterAndSort((node, index) => {
        if(!props.navigationFilter || (props.navigationFilter && props.navigationFilter(node.data))) {
          rowData.push({...node.data, rowIndex: node?.rowIndex ?? 0});
          if((node as any)?.selected) {
            selectedIndex = index;
          }
        }
      });
    }

    setNavigationData(rowData ?? []);
    setCurrentNavigationIndex(selectedIndex);
  }, [localPanelState, gridState.rowData]);

  const onPreviousClick = () => {
    if (currentNavigationIndex === 0 || !props.onNavigation) {
      return;
    }

    setCurrentNavigationIndex((currentNavigationIndex ?? 1) - 1);

    // Get previous item and call onPreviousClick (which has to be set from each sliding panel content component)
    const prevIndex = (currentNavigationIndex ?? 1) - 1;
    const prevItem = navigationData?.[prevIndex];
    props.onNavigation(prevItem);

    // Change selected row & scroll to it
    props.gridRef?.current?.api?.forEachNode(node => {
      if (_.isEqual(node.data, _.omit(prevItem, 'rowIndex'))) {
        node.setSelected(true);
        document.querySelector(`[row-index="${node.rowIndex!}"]`)?.scrollIntoView({ block: isDataGrouped ? "center" :"nearest" });
      }
      else {
        node.setSelected(false);
      }
    });

    // Change page if needed (and if data is not grouped)
    if(!isDataGrouped) {
      const pageSize = props.gridRef?.current?.api?.paginationGetPageSize();
      const currentPage = props.gridRef?.current?.api?.paginationGetCurrentPage();
      if (pageSize !== undefined && currentPage !== undefined && ((prevItem.rowIndex + 1) <= (pageSize * currentPage))) {
        props.gridRef?.current?.api?.paginationGoToPreviousPage();

        const gridViewportElem = document.querySelectorAll('.grid .ag-body-viewport')?.[0];
        gridViewportElem?.scrollTo({ top: gridViewportElem.scrollHeight, behavior: 'smooth' });
      }
    }
  }

  const onNextClick = () => {
    if ((currentNavigationIndex === (navigationData?.length ?? 1) - 1) || !props.onNavigation) {
      return;
    }

    setCurrentNavigationIndex((currentNavigationIndex ?? 0) + 1);

    // Get next item and call onNextClick (which has to be set from each sliding panel content component)
    const nextIndex = (currentNavigationIndex ?? 0) + 1;
    const nextItem = navigationData?.[nextIndex];
    props.onNavigation(nextItem);

    // Change selected row & scroll to it
    props.gridRef?.current?.api?.forEachNode(node => {
      if (_.isEqual(node.data, _.omit(nextItem, 'rowIndex'))) {
        node.setSelected(true);
        document.querySelector(`[row-index="${node.rowIndex!}"]`)?.scrollIntoView({ block: isDataGrouped ? "center" :"nearest" });
      }
      else {
        node.setSelected(false);
      }
    });

    // Change page if needed (and if data is not grouped)
    if(!isDataGrouped) {
      const pageSize = props.gridRef?.current?.api?.paginationGetPageSize();
      const currentPage = props.gridRef?.current?.api?.paginationGetCurrentPage();
      if (pageSize !== undefined && currentPage !== undefined && ((nextItem.rowIndex + 1) > (pageSize * (currentPage + 1)))) {
        props.gridRef?.current?.api?.paginationGoToNextPage();

        const gridViewportElem = document.querySelectorAll('.grid .ag-body-viewport')?.[0];
        gridViewportElem?.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }

  return (
    <div className="lp-preview-panel-container">
      <div className="lp-preview-panel">
        {showActions ? (
          <>
            <div className="lp-preview-panel-title">
              <h4>Actions</h4> 
            </div>
            {props.gridOptions?.length ? (
              <>
                <div className="lp-preview-panel-content lp-preview-panel-options">
                  {props.gridOptions.map((option) => PreviewPanelGridOptions({
                    ...option,
                    gridRef: props.gridRef,
                    selectedRows: props.selectedRows,
                  }))}
                </div>
              </>
            ) : null}
          </>
        ) : (
          localPanelState.isShown ? (
            <>
              <div className="lp-preview-panel-title">
                <h4>{localPanelState.title}</h4>
                <div className="lp-preview-panel-title-btns">
                  {
                    localPanelState.moreButtons && localPanelState.moreButtons.length
                    ? <div className="lp-preview-panel-more-button">
                        { localPanelState.moreButtons.length === 1
                          ? IconButtonGrid({
                              type: localPanelState.moreButtons[0].type,
                              callback: () => localPanelState.moreButtons![0].callback()
                            })
                          : <MoreButton id="more-button" listButtons={localPanelState.moreButtons} />
                        }
                      </div>
                    : null
                  }
                  { localPanelState.onEdit &&
                    <div onClick={edit} className="lp-preview-panel-close">
                      <MdEdit />
                    </div>
                  }
                  <div onClick={cancel} className="lp-preview-panel-close">
                    <MdClose />
                  </div>
                </div>
              </div>
              <div className="lp-preview-panel-content">
                {localPanelState.children}
              </div>
              { (localPanelState.allowNavigation && navigationData && navigationData.length && currentNavigationIndex !== undefined)
                ? <div className="lp-preview-panel-footer">
                    <div className="lp-preview-panel-navigation">
                      {props.onNavigation &&
                        <>
                          <div className={`lp-preview-panel-navigation-button lp-panel-nav-left${currentNavigationIndex > 0 ? '' : ' disabled'}`} onClick={onPreviousClick}>
                            <HiChevronLeft /> Previous
                          </div>
                          <div className={`lp-preview-panel-navigation-button lp-panel-nav-right${currentNavigationIndex < navigationData.length - 1 ? '' : ' disabled'}`} onClick={onNextClick}>
                            Next <HiChevronRight />
                          </div>
                        </>
                      }
                    </div>
                  </div>
                : null
              }
            </>
          ) : (
            <>
              <div className="lp-empty">
                <div className="image"></div>
                <div className="text">No row selected!</div>
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
}
