import useNotificationActions from 'actions/notification';
import { syncEmails } from 'actions/user';
import { NotificationTypes } from 'enums/NotificationTypes';
import { useAppSelector } from 'hooks/appSelector';
import React, { cloneElement, useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import DashboardSummaryInfo from './DashboardSummaryInfo/DashboardSummaryInfo';
import DashboardMatters from './DashboardMatters/DashboardMatters';
import DashboardMatterInvoices from './DashboardMatterInvoices/DashboardMatterInvoices';
import DashboardUserEmails from './DashboardUserEmails/DashboardUserEmails';
import SlidingPanel from "components/SlidingPanel";
import './style.scss';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import usePageActions from 'actions/page';
import {DndContext, DragEndEvent, DragOverlay, DragStartEvent} from '@dnd-kit/core';
import { arrayMove, rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import DashboardCalendarEvents from './DashboardCalendarEvents/DashboardCalendarEvents';
import { MdCalendarMonth, MdEmail } from 'react-icons/md';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import useSlidingPanelActions from 'actions/slidingPanel';
import DashboardHideItems from './DashboardHideItems/DashboardHideItems';
import { DashboardCard } from 'models/view/DashboardCard';
import { DashboardCards } from 'enums/DashboardCards';
import { FaFileAlt } from 'react-icons/fa';
import { IoReceipt } from 'react-icons/io5';

function Dashboard() {
  const user = useAppSelector((state) => state.user);
  const notificationActions = useNotificationActions();
  const slidingPanelActions = useSlidingPanelActions();
  const pageActions = usePageActions();

  const [draggedItem, setDraggedItem] = useState<DashboardCard | null>(null);
  const [items, setItems] = useState<DashboardCard[]>([]);

  const handleDragStart = useCallback((e: DragStartEvent) => {
    setDraggedItem(items.find(item => item.id === e.active.id) ?? null);
  }, [items]);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over?.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setDraggedItem(null);
  }, [items]);

  const handleDragCancel = useCallback(() => {
    setDraggedItem(null);
  }, []);

  useEffect(() => {
    if(items.length === 0) return;
    localStorage.setItem('Dashboard_Items', JSON.stringify(items.map(item => item.id)));
  }, [items]);

  const synchronizeEmails = () => {
    //hack to avoid the clearNotifications from the NotificationMessages
    setTimeout(() => {
      notificationActions.addNotificationMessage(
        {
          type: NotificationTypes.Success,
          title: "Email Sync Started",
          body: "Emails for your User are being synced.",
          isDismissable: true
        }
      );
    }, 1);

    syncEmails(user.apiToken, user.msalAccessToken).then((response) => {
      if(!response.ok) {
        //turn ReadableStream into JSON 
        response.json().then((data: any) => {
          notificationActions.addNotificationMessage(
            {
              type: NotificationTypes.Error,
              title: "Email Sync Error",
              body: data.Message ?? "Emails for your User have not been synced due to an error.",
              isDismissable: true
            }
          );
        });
        return;
      }
      notificationActions.addNotificationMessage(
        {
          type: NotificationTypes.Success,
          title: "Email Sync Successful",
          body: "Emails for your User have been synced successfully.",
          isDismissable: true
        }
      );
    }).catch((error) => {
      notificationActions.addNotificationMessage(
        {
          type: NotificationTypes.Error,
          title: "Email Sync Error",
          body: "Emails for your User have not been synced due to an error.",
          isDismissable: true
        }
      );
    });
  };

  const getAvailableCards = () => {
    const availableCardsList: DashboardCards[] = [];
    if(user.userPermissions?.some(a => a === UserPermissionsNames.ViewCalendars || a === UserPermissionsNames.ManageCalendars)) {
      availableCardsList.push(DashboardCards.Calendar);
    }
    if(user.userPermissions?.some(a => a === UserPermissionsNames.ViewDashboardEmails || a === UserPermissionsNames.ManageDashboardEmails)) {
      availableCardsList.push(DashboardCards.Emails);
    }
    if(user.userPermissions?.some(a => a === UserPermissionsNames.ViewRecentMatters)) {
      availableCardsList.push(DashboardCards.Matters);
    }
    if(user.userPermissions?.some(a => a === UserPermissionsNames.ViewRecentInvoices)) {
      availableCardsList.push(DashboardCards.Invoices);
    }
    return availableCardsList;
  }

  const addCalendar = () => {
    return user.userPermissions?.some(a => a === UserPermissionsNames.ViewCalendars || a === UserPermissionsNames.ManageCalendars) ? [{
      id: DashboardCards.Calendar,
      title: 'Calendar',
      dragIcon: <MdCalendarMonth />,
      component: <DashboardCalendarEvents />
    }] : [];
  }

  const addEmails = () => {
    return user.userPermissions?.some(a => a === UserPermissionsNames.ViewDashboardEmails || a === UserPermissionsNames.ManageDashboardEmails) ? [{
      id: DashboardCards.Emails,
      title: 'Emails',
      dragIcon: <MdEmail />,
      component: <DashboardUserEmails />
    }] : [];
  }

  const addMatters = () => {
    return user.userPermissions?.some(a => a === UserPermissionsNames.ViewRecentMatters) ? [{
      id: DashboardCards.Matters,
      title: 'Recent Matters',
      dragIcon: <FaFileAlt />,
      component: <DashboardMatters />
    }] : [];
  }

  const addInvoices = () => {
    return user.userPermissions?.some(a => a === UserPermissionsNames.ViewRecentInvoices) ? [{
      id: DashboardCards.Invoices,
      title: 'Recent Invoices',
      dragIcon: <IoReceipt />,
      component: <DashboardMatterInvoices />
    }] : [];
  }

  const loadItems = (itemOrders?: string[]) => {
    const items = [];
    if(itemOrders?.length) {
      itemOrders.forEach((item) => {
        switch(item) {
          case DashboardCards.Emails:
            items.push(...addEmails());
            break;
          case DashboardCards.Calendar:
            items.push(...addCalendar());
            break;
          case DashboardCards.Matters:
            items.push(...addMatters());
            break;
          case DashboardCards.Invoices:
            items.push(...addInvoices());
            break;
        }
      });
    }
    else {
      items.push(...addCalendar());
      items.push(...addEmails());
      items.push(...addMatters());
      items.push(...addInvoices());
    }

    setItems(items);
  }

  useEffect(() => {
    const storedDashboardItems = localStorage.getItem('Dashboard_Items');
    if(storedDashboardItems) {
      const dashboardItems: string[] = JSON.parse(storedDashboardItems);
      loadItems(dashboardItems);
    }
    else {
      loadItems();
    }

    return () => {
      pageActions.abortRequests();
    }
  }, []);

  const openHideItemsSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Manage Dashboard Cards',
      children: <DashboardHideItems
        allAvailableItems={getAvailableCards()}
        currentState={items.map(item => item.id as DashboardCards)}
        applyChanges={loadItems}
      />
    });
  }

  useEffect(() => {
    if(user.userPermissions?.some(a => a == UserPermissionsNames.SyncUserEmailsAndCalendar)) {
      synchronizeEmails();
    }
  }, [user.lawPageTradingEntityId]);

  return (
    <div className="lp-page-content lp-dashboard-page">
      <div className="lp-dashboard-hide-items">
        <Button onClick={openHideItemsSlidingPanel} variant="primary" size="sm">
          Screen options
        </Button>
      </div>

      {user.userPermissions?.some(a => a == UserPermissionsNames.ViewDashboardStatistics) &&
        <Row>
          <Col>
            <DashboardSummaryInfo />
          </Col>
        </Row>
      }

      <DndContext
        onDragStart={(e) => handleDragStart(e)}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <SortableContext items={items} strategy={rectSortingStrategy}>
          <div className={`lp-dashboard-dnd-container ${draggedItem ? 'dragging' : ''}`}>
            {items.map((droppable) => (
              <div className={`lp-dashboard-dnd-item-container ${draggedItem?.id === droppable.id ? 'dragging' : ''}`} key={droppable.id}>
                {cloneElement(droppable.component, { title:droppable.title, dragIcon: droppable.dragIcon })}
              </div>
            ))}
          </div>
        </SortableContext>

        <DragOverlay
          adjustScale
          style={{ transformOrigin: '0 0 ' }}
          dropAnimation={null}
          modifiers={[restrictToWindowEdges]}
        >
          {draggedItem ?
            <div className={`lp-dashboard-dnd-item-drag-overlay`}>
              <span className="lp-dashboard-dnd-item-drag-overlay-icon">{draggedItem.dragIcon}</span>
              <span className="lp-dashboard-dnd-item-drag-overlay-title">{draggedItem.title}</span>
            </div>
          : null}
        </DragOverlay>
      </DndContext>

      <SlidingPanel />
    </div>
  );
}

export default Dashboard;