import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Row, Col, Form, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import CustomSelect from "components/Select/Select";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { CreateOrUpdateLtePhoneNumberModel } from "models/create/CreateOrUpdateLtePhoneNumberModel";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { PhoneNumberModel } from "models/view/PhoneNumberModel";
import { removeEmptyFields } from "utils/form";
import { getPhoneNumberSuggestions, getPhoneTypes } from "actions/phoneNumber";
import { addLtePhoneNumber, getPreferredLtePhoneNumber } from "actions/lte";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import useModalActions from 'actions/modal';
import { MdWarning } from 'react-icons/md';
import { ModalState } from 'state/modalSlice';
import FormSubmitButton from "components/Buttons/FormSubmitButton";

type Props = {
  lteId: string,
  submitCallback?: Function
}

export default function CreateLtePhoneNumberForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingPhoneNumberSuggestions, setIsLoadingPhoneNumberSuggestions] = useState<boolean>(false);
  const [phoneNumberSuggestions, setPhoneNumberSuggestions] = useState<PhoneNumberModel[]>([]);
  const slidingPanelActions = useSlidingPanelActions();
  const loggedInUser = useAppSelector((state) => state.user);
  const modalActions = useModalActions();

  const {register, reset, control, setValue, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateLtePhoneNumberModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: CreateOrUpdateLtePhoneNumberModel) {
    setIsLoading(true);
    if(data.isPreferred == true) {
      getPreferredLtePhoneNumber(props.lteId).then((response) => {
        let bodyMessage: React.ReactNode = <></>;
        if(response.data?.phoneNumberId) {
          bodyMessage = <div className="lp-modal-warning">
            <MdWarning />This Phone Number is set as preferred which means that he will replace the current one.<br/>
            Are you sure you want to do that? If not, uncheck the Is Preferred checkbox.
          </div>;
        }
        else {
          addPhoneNumber(data, false);
          return;
        }
        
        let modal: ModalState = {
          title: "Add confirmation",
          body: bodyMessage,
          actionText: "Add",
          onAction: () => addPhoneNumber(data),
          show: false,
          onClose: () => setIsLoading(false)
        }
        modalActions.setModal(modal);
        modalActions.toggleModalShownStatus();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
        setIsLoading(false);
      });
    }
    else {
      addPhoneNumber(data, false);
    }
  }

  function addPhoneNumber(data: CreateOrUpdateLtePhoneNumberModel, fromModal: boolean = true) {
    if(fromModal)
    {
      modalActions.toggleModalLoading();
    }

    removeEmptyFields(data);
    data.lawPageTradingEntityId = props.lteId;
    addLtePhoneNumber(props?.lteId, data).then((response) => {
      if(props.submitCallback) {
        props.submitCallback(response.data);
      }
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
      if(fromModal)
      {
        modalActions.toggleModalLoading();
        modalActions.toggleModalShownStatus();
      }
    });
  }

  const onChangePhoneNumber = (value: string) => {
    setIsLoadingPhoneNumberSuggestions(true);
    setPhoneNumberSuggestions([]);
    getPhoneNumberSuggestions(value, props?.lteId)
      .then((response) => {
        setPhoneNumberSuggestions(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoadingPhoneNumberSuggestions(false);
      });
  }

  const onChangePhoneNumberSuggestion = (id?: string) => {
    const selectedPhoneNumberSuggestion = phoneNumberSuggestions.find(x => x.id === id);
    if(selectedPhoneNumberSuggestion) {
      setValue("extension", selectedPhoneNumberSuggestion.extension);
      setValue("phoneTypeId", selectedPhoneNumberSuggestion.phoneType?.id ?? '');
      setValue("systemOwned", selectedPhoneNumberSuggestion.systemOwned ?? false);
      setValue("isPreferred", false);
    }
    else {
      setValue("extension", '');
      setValue("phoneTypeId", '');
      setValue("systemOwned", false);
      setValue("isPreferred", false);
    }
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} sm={6} controlId="phoneNumber">
            <Form.Label className="required">Phone Number</Form.Label>
            <Controller
              control={control}
              name="phoneNumber"
              shouldUnregister={true}
              render={({field: { onChange, value }}) => (
                <PhoneInput
                  containerClass={`lp-phone-input${errors?.phoneNumber?.message ? ' invalid' : ''}`}
                  autoFormat={false}
                  inputProps={{ name: 'phoneNumber'}}
                  country={'gb'}
                  placeholder="Phone Number"
                  value={value}
                  onChange={val => {onChange('+' + val); onChangePhoneNumber('+' + val);}}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.phoneNumber?.message && (errors.phoneNumber.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="phoneNumberId">
            <Form.Label>Phone Number Suggestions</Form.Label>
            <Controller
              control={control}
              name="phoneNumberId"
              shouldUnregister={true}
              render={({field: { onChange, value, ref }}) => (
                <CustomSelect
                  id="phoneNumberId"
                  options={phoneNumberSuggestions}
                  getOptionLabel={(x: PhoneNumberModel) => x?.number + (x?.extension ? ' (Extension: ' + x?.extension + ')': '')}
                  getOptionValue={(x: PhoneNumberModel) => x.id}
                  value={value}
                  onChange={val => {onChange(val?.id ?? null); onChangePhoneNumberSuggestion(val?.id);}}
                  isLoading={isLoadingPhoneNumberSuggestions}
                  isClearable
                />
              )}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="phoneTypeId">
            <Form.Label className="required">Phone Type</Form.Label>
            <Controller
              control={control}
              name="phoneTypeId"
              shouldUnregister={true}
              render={({field: { onChange, value, ref }}) => (
                <CustomSelect
                  id="phoneTypeId"
                  inputRef={ref}
                  className={`lp-select${errors?.phoneTypeId?.message ? ' invalid' : ''}`}
                  endpointCall={getPhoneTypes}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.phoneTypeId?.message && (errors.phoneTypeId.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="extension">
            <Form.Label>Extension</Form.Label>
            <Form.Control
              type="text"
              {...register("extension", {shouldUnregister: true})}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="isPreferred">
            <Form.Label>Is Preferred</Form.Label>
            <Controller
              control={control}
              name="isPreferred"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="isPreferred">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>

          {loggedInUser.userPermissions?.find(a => a == UserPermissionsNames.ManageSystemOwnedContacts) &&
            <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="systemOwned">
              <Form.Label>System Owned</Form.Label>
              <Controller
                control={control}
                name="systemOwned"
                shouldUnregister={true}
                render={({field: { onChange, value, name, ref }}) => (
                  <Form.Check 
                    type="switch"
                    id="systemOwned">
                      <Form.Check.Input
                        className= "form-check-input"
                        ref={ref}
                        checked={value ?? false}
                        onChange={(ev: any) => onChange(ev.target.checked)}
                      />
                  </Form.Check>
                )}
              />
            </Form.Group>
          }
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <FormSubmitButton text="Create" errors={{...errors}} />
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
