import React from "react";
import './style.scss';

type TitleType = 'page' | 'section';

type Props = {
  type: TitleType; // page, section
  title: React.ReactNode;
  className?: string;
  onClick?: Function;
  children?: React.ReactNode;
  dragAttributes?: {
    listeners: any,
    attributes: any,
  };
  icon?: React.ReactNode;
}

export default function Title(props: Props) {
  let titleClass = `${props.className ?? 'lp-title'}`;
  if (props.type === "page")
    titleClass += ' page';
  else if (props.type === "section")
    titleClass += ' section';

  return (
    <div className={titleClass} onClick={props.onClick ? (e) => props.onClick!(e) : undefined}>
      <div className="lp-title-text" {...props.dragAttributes?.attributes} {...props.dragAttributes?.listeners}>
        {props.icon && <div className="lp-title-icon">{props.icon}</div>}
        {props.title}
      </div>
      <div className="lp-title-actions">
        {props.children}
      </div>
    </div>
  );
}