import { create, test, enforce, omitWhen } from "vest";
import isDate from "validator/lib/isDate";

export const getValidationSuite = (clientsLength: number) => {
  return create((data = {}) => {
    enforce.extend({ isDate });

    test('userId', 'Please select a Fee Earner', () => {
      enforce(data.userId).isNotEmpty();
      enforce(data.userId).isString();
    });

    omitWhen(clientsLength == 0, () => {
      test('matterParticipatingEntityId', 'Please select a Client', () => {
        enforce(data.matterParticipatingEntityId).isNotEmpty();
        enforce(data.matterParticipatingEntityId).isString();
      });
    });

    test('date', 'Please select a Date', () => {
      enforce(data.date).isDate({ strictMode: false });
    });

    test('displayName', 'Display Name is required', () => {
      enforce(data.displayName).isNotEmpty();
      enforce(data.displayName).isString();
    });

    test('description', 'Description is required', () => {
      enforce(data.description).isNotEmpty();
      enforce(data.description).isString();
    });

    test('grossValue', 'Gross Value is required', () => {
      enforce(data.grossValue).isNotEmpty();
    });

    test('grossValue', 'Gross Value needs to be a number > 0', () => {
      enforce(data.grossValue).greaterThan(0);
    });

    omitWhen(!data.vatValue, () => {
      test('vatValue', 'VAT Value needs to be a number > 0', () => {
        enforce(data.vatValue).greaterThan(0);
      });
    });

    test('grossValue', 'Gross Value needs to be higher than VAT Value', () => {
      var netValue = data.grossValue - (data.vatValue ?? 0);
      enforce(netValue).greaterThan(0);
    });

    test('supplierId', 'Please select a Supplier', () => {
      enforce(data.supplierId).isNotEmpty();
      enforce(data.supplierId).isString();
    });

    test('supplierReference', 'Supplier Reference is required', () => {
      enforce(data.supplierReference).isNotEmpty();
      enforce(data.supplierReference).isString();
    });
  });
}