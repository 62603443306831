import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/types";
import { MdInfo, MdError, MdShare } from 'react-icons/md';
import { TbLink } from 'react-icons/tb';
import { TiWarning } from "react-icons/ti";
import './style.scss';

type TooltipType = 'info' | 'warning' | 'error' | 'linked' | 'shared';
type IconPosition = 'left' | 'right';

type Props = {
  type: TooltipType;
  text: React.ReactNode;
  tooltipPlacement?: Placement;
  iconPosition?: IconPosition;
}

export default function TooltipIcon(props: Props) {
  const getTooltipIcon = () => {
    switch(props.type) {
      case 'info': {
        return <MdInfo />;
      }
      case 'warning': {
        return <TiWarning />;
      }
      case 'error': {
        return <MdError />;
      }
      case 'linked': {
        return <TbLink />;
      }
      case 'shared': {
        return <MdShare />;
      }
    }
  };

  return (
    <>
      <OverlayTrigger
        placement={props.tooltipPlacement ?? 'right'}
        overlay={<Tooltip className={props.type}>{props.text}</Tooltip>}
      >
        <span className={`lp-tooltip-icon ${props.type} ${props.iconPosition ?? 'right'}`}>{getTooltipIcon()}</span>
      </OverlayTrigger>
    </>
  );
}
