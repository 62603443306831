import { cloneElement, ReactElement, useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { Row, Col } from "react-bootstrap";
import { decreasePriorityMatterActivityHistory, deleteMatterNote, downloadActivityHistory, flagMatterActivityHistory, getMatterNoteById, increasePriorityMatterActivityHistory, printActivityHistory, unflagMatterActivityHistory, validateCopyActivityHistory, validateMoveActivityHistory } from "actions/matter";
import { MatterNoteModel } from "models/view/MatterNoteModel";
import useSlidingPanelActions from "actions/slidingPanel";
import { useAppSelector } from "hooks/appSelector";
import EditMatterNoteForm from "../EditMatterNote/EditMatterNoteForm";
import { MatterActivityHistoryModel } from "models/view/MatterActivityHistoryModel";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import EditActivityHistoryTaxonomyTermsForm from "../../ActivityHistoryGrid/ActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTermsForm";
import { MatterActivityHistorySubtypes } from "enums/MatterActivityHistorySubtypes";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import useGridActions from "actions/grid";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { UserRoleNames } from "enums/UserRoleNames";
import { Priority } from "enums/Priority";
import usePageActions from "actions/page";
import { downloadFile, getDecreasedPriority, getIncreasedPriority, printPdf } from "utils/misc";
import BooleanField from "components/Fields/BooleanField";
import { getPriority } from "utils/enums";
import CopyActivityHistoryForm from "../../ActivityHistoryGrid/CopyActivityHistory/CopyActivityHistoryForm";
import MoveActivityHistoryForm from "../../ActivityHistoryGrid/MoveActivityHistory/MoveActivityHistoryForm";
import useNotificationActions from "actions/notification";
import { NotificationTypes } from "enums/NotificationTypes";

type Props = {
  matterId: string,
  noteId: string,
  activitySubtype: number,
  activityHistoryId: string,
  isMatterClosed?: boolean,
  hasTaxonomies: boolean,
  syncMatterFiles?: Function,
  showMoreButtons?: boolean,
  isFlagged?: boolean,
  priority?: number
}

function ViewMatterNote(props: Props){
  const [isLoading, setIsLoading] = useState(true);
  const [matterNote, setMatterNote] = useState<MatterNoteModel>();
  const [genericErrors, setGenericErrors] = useState<string | null>(null);
  const [isFlagged, setIsFlagged] = useState<boolean | undefined>(props.isFlagged);
  const [priority, setPriority] = useState<Priority | undefined>(props.priority);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const modalActions = useModalActions();
  const gridActions = useGridActions();
  const pageActions = usePageActions();
  const currentGridState = useAppSelector((state) => state.grid);
  const loggedInUser = useAppSelector((state) => state.user);
  const notificationActions = useNotificationActions();

  useEffect(() => {
    // When moving between multiple items of the same type, update local state for flag and priority
    setIsFlagged(props.isFlagged);
    setPriority(props.priority);

    setIsLoading(true);
    getMatterNoteById(props.matterId, props.noteId).then((response) => {
      setMatterNote(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, [props.noteId]);

  useEffect(() => {
    if (matterNote) {
      const canManageUserNotes = matterNote.createdById == loggedInUser.userId || 
        (loggedInUser.userRole?.name != UserRoleNames.Lawyer &&
        loggedInUser.userRole?.name != UserRoleNames.Clerk &&
        loggedInUser.userRole?.name != UserRoleNames.BackOffice && 
        loggedInUser.userRole?.name != UserRoleNames.Accountant &&
        loggedInUser.userRole?.name != UserRoleNames.EntryLevel &&
        matterNote.createdById != loggedInUser.userId);

      setSlidingPanelEditButton(canManageUserNotes);
      setSlidingPanelMoreButtons(canManageUserNotes);
    }
  }, [matterNote, isFlagged, priority]);

  const setSlidingPanelEditButton = (canManageUserNotes: boolean) => {
    if (!props.isMatterClosed && props.activitySubtype !== MatterActivityHistorySubtypes.SystemNote && canManageUserNotes) {
      slidingPanelActions.setOnEdit(() => {
        slidingPanelActions.clearSlidingPanel();

        slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          width: "55rem",
          title: "Edit Matter Note",
          children: <EditMatterNoteForm
            matterId={props.matterId}
            noteId={props.noteId}
            onSubmitCallback={onChildPanelClose}
          />,
          onCancel: onChildPanelClose
        });
      });
    }
  }

  const setSlidingPanelMoreButtons = (canManageUserNotes: boolean) => {
    if (!props.isMatterClosed && props.showMoreButtons) {
      slidingPanelActions.setMoreButtons([
        // Taxonomies
        ...(props.hasTaxonomies &&
          ((props.activitySubtype == MatterActivityHistorySubtypes.SystemNote &&
          loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterActivityHistory)) ||
          props.activitySubtype == MatterActivityHistorySubtypes.UserNote && canManageUserNotes)
        ? [{
          type: ActionButtonTypes.EditTaxonomyTerms,
          callback: () => onClickTaxonomies(),
        }] : []),
        // Actions
        ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterActivityHistory) ? [
          {
            type: ActionButtonTypes.Download,
            callback: () => downloadItem(props.matterId, props.activityHistoryId!)
          },
          {
            type: ActionButtonTypes.Print,
            callback: () => printItem(props.matterId, props.activityHistoryId!)
          },
          {
            type: ActionButtonTypes.Copy,
            callback: () => copyItem(props.matterId, props.activityHistoryId!, props.syncMatterFiles)
          },
          {
            type: ActionButtonTypes.Move,
            callback: () => moveItem(props.matterId, props.activityHistoryId!, props.syncMatterFiles)
          }
        ] : []),
        // Flag
        ...((props.activityHistoryId && isFlagged !== undefined &&
          ((props.activitySubtype == MatterActivityHistorySubtypes.SystemNote &&
          loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterActivityHistory)) ||
          props.activitySubtype == MatterActivityHistorySubtypes.UserNote && canManageUserNotes)
        ) ? [
          isFlagged ? {
            type: ActionButtonTypes.Unflag,
            callback: () => unflagModal(props.matterId, props.activityHistoryId!)
          } : {
            type: ActionButtonTypes.Flag,
            callback: () => flagModal(props.matterId, props.activityHistoryId!)
          },
        ] : []),
        // Priority
        ...((props.activityHistoryId && priority !== undefined &&
          ((props.activitySubtype == MatterActivityHistorySubtypes.SystemNote &&
          loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterActivityHistory)) ||
          props.activitySubtype == MatterActivityHistorySubtypes.UserNote && canManageUserNotes)
        ) ? [
          ...(priority !== Priority.High ? [{
            type: ActionButtonTypes.IncreasePriority,
            callback: () => increasePriorityModal(props.matterId, props.activityHistoryId!)
          }] : []),
          ...(priority !== Priority.Low ? [{
            type: ActionButtonTypes.DecreasePriority,
            callback: () => decreasePriorityModal(props.matterId, props.activityHistoryId!)
          }] : []),
        ] : []),
        // Delete
        ...(props.activitySubtype !== MatterActivityHistorySubtypes.SystemNote && canManageUserNotes
        ? [{
          type: ActionButtonTypes.Delete,
          callback: () => onClickDelete(),
        }] : [])
      ]);
    }
  }

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
      children: cloneElement(currentSlidingPanelState.children! as ReactElement, {priority: priority, isFlagged: isFlagged}),
    });
  };

  const onClickTaxonomies = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Edit Taxonomy Terms",
        children: <EditActivityHistoryTaxonomyTermsForm
          matterId={props.matterId}
          activityHistoryId={props.activityHistoryId}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  };

  const onClickDelete = () => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Matter Note?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.matterId, props.noteId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const deleteCallback = (matterId: string, activityId: string) => {
    const gridState = currentGridState;
    modalActions.toggleModalLoading();
    deleteMatterNote(matterId, activityId).then(() => {
      gridActions.setGridRowData(gridState.rowData.filter((x: MatterActivityHistoryModel) => x.matterNoteId !== activityId));
      modalActions.toggleModalShownStatus();
      slidingPanelActions.clearSlidingPanel();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  const flagModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Flag confirmation",
      body: "Are you sure you want to add a flag?",
      actionText: "Flag",
      onAction: () => flagCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const flagCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    flagMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setIsFlagged(true); // Local state flag update - used to identify which button to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const unflagModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Unflag confirmation",
      body: "Are you sure you want to remove this flag?",
      actionText: "Unflag",
      onAction: () => unflagCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const unflagCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    unflagMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setIsFlagged(false); // Local state flag update - used to identify which button to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const increasePriorityModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Increase priority confirmation",
      body: "Are you sure you want to increase the priority?",
      actionText: "Increase",
      onAction: () => increasePriorityCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const increasePriorityCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    increasePriorityMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setPriority(getIncreasedPriority(priority!)); // Local state priority update - used to identify which buttons to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const decreasePriorityModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Decrease priority confirmation",
      body: "Are you sure you want to decrease the priority?",
      actionText: "Decrease",
      onAction: () => decreasePriorityCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const decreasePriorityCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    decreasePriorityMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setPriority(getDecreasedPriority(priority!)); // Local state priority update - used to identify which buttons to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const downloadItem = (matterId: string, activityHistoryId: string) => {
    setIsLoading(true);

    downloadActivityHistory(matterId, [activityHistoryId]).then((response) => {
      downloadFile(response);
    })
    .catch((error) => {
      error.response.data.text().then((text: any) => {
        let responseData = undefined;
        try {
          responseData = JSON.parse(text);
        }
        catch(e) {
          responseData = undefined;
        }

        notificationActions.addNotificationMessage({
          type: NotificationTypes.Error,
          title: "Download Error",
          body: `${responseData?.Message ?? "There was an error while downloading the file."}`,
          isDismissable: true
        });
      });
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const printItem = (matterId: string, activityHistoryId: string) => {
    setIsLoading(true);

    printActivityHistory(matterId, [activityHistoryId]).then((response) => {
      printPdf(response.data);
    })
    .catch((error) => {
      //the response is Blob here, we need to parse it
      error.response.data.text().then((text: any) => {
        let responseData = undefined;
        try {
          responseData = JSON.parse(text);
        }
        catch(e) {
          responseData = undefined;
        }

        notificationActions.addNotificationMessage({
          type: NotificationTypes.Error,
          title: "Print Error",
          body: `${responseData?.Message ?? "There was an error printing the file."}`,
          isDismissable: true
        });
      });
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const copyItem = (matterId: string, activityHistoryId: string, syncMatterFiles?: Function) => {
    setIsLoading(true);

    validateCopyActivityHistory(matterId, [activityHistoryId]).then((response) => {
      slidingPanelActions.setSlidingPanel({
        isShown: true,
        width: "40rem",
        title: "Copy Activity History Items",
        children: <CopyActivityHistoryForm
          matterId={matterId}
          activityHistoryIds={[activityHistoryId]}
          syncMatterFiles={syncMatterFiles}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      });
    })
    .catch((error) => {
      notificationActions.addNotificationMessage({
        type: NotificationTypes.Error,
        title: "Copy Validation Error",
        body: error.response?.data?.Message ?? error.message,
        isDismissable: true
      });
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const moveItem = (matterId: string, activityHistoryId: string, syncMatterFiles?: Function) => {
    setIsLoading(true);
  
    validateMoveActivityHistory(matterId, [activityHistoryId]).then((response) => {
      slidingPanelActions.setSlidingPanel({
        isShown: true,
        width: "40rem",
        title: "Move Activity History Items",
        children: <MoveActivityHistoryForm
          matterId={matterId}
          activityHistoryIds={[activityHistoryId]}
          displayClient={false}
          syncMatterFiles={syncMatterFiles}
        />,
        onCancel: onChildPanelClose
      });
    })
    .catch((error) => {
      notificationActions.addNotificationMessage({
        type: NotificationTypes.Error,
        title: "Move Validation Error",
        body: error.response?.data?.Message ?? error.message,
        isDismissable: true
      });
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  return(
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Note"}
            value={matterNote?.note}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Type"}
            value={matterNote?.noteType?.name}
          />
        </Col>
      </Row>
      { isFlagged !== undefined &&
        <Row>
          <Col>
            <BooleanField
              label={"Is Flagged"}
              value={isFlagged ?? false}
            />
          </Col>
        </Row>
      }
      { priority !== undefined &&
        <Row>
          <Col>
            <Field
              label={"Priority"}
              value={priority !== undefined ? getPriority(priority) : "—"}
            />
          </Col>
        </Row>
      }
    </>
  );
}

export default ViewMatterNote;
