import { ColDef } from "ag-grid-community";
import store from "state/store";
import { ModalState, setModalData, setModalErrors, toggleModal, toggleModalLoadingState } from "state/modalSlice";
import { setRowData } from "state/gridSlice";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { deleteDefaultVatRate } from "actions/settings";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { DefaultVatRateModel } from "models/view/DefaultVatRateModel";
import { getVatRateTypes } from "utils/enums";
import VatRateTypeFormatter from "components/Grid/ValueFormatters/VatRateTypeFormatter";
import { DateFormatter } from "components/Grid/ValueFormatters/DateFormatter";
import { dateComparator } from "components/Grid/Comparators/DateComparator";
import EditDefaultVatRateForm from "./EditDefaultVatRate/EditDefaultVatRateForm";

const editCallback = (id: string) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        title: "Edit Default VAT Rate",
        children: <EditDefaultVatRateForm defaultVatRateId={id}/>
      }
    )
  );
}

const deleteModal = (id: string) => {
  let modal: ModalState = {
    title: "Delete confirmation",
    body: "Are you sure you want to delete this Default VAT Rate?",
    actionText: "Delete",
    actionVariant: "danger",
    onAction: () => deleteCallback(id),
    show: false
  }
  store.dispatch(setModalData(modal));
  store.dispatch(toggleModal());
};

const deleteCallback = (id: string) => {
  const gridState = store.getState().grid;
  store.dispatch(toggleModalLoadingState());
  deleteDefaultVatRate(id).then(() => {
    store.dispatch(setRowData(gridState.rowData.filter((x: any) => x.id !== id)));
    store.dispatch(toggleModal());
  })
  .catch((error) => {
    store.dispatch(setModalErrors(error.response?.data?.Message ?? error.message));
  })
  .finally(() => {
    store.dispatch(toggleModalLoadingState());
  });
};

export const getColumnDefs = () => {
  const loggedInUser = store.getState().user;
  
  const columnDefs: (ColDef<DefaultVatRateModel> | {excludeFromExport: boolean})[] = [
    {
      headerName: 'Name',
      field: 'name',
      filter: 'agTextColumnFilter',
      flex: 3,
    },
    {
      headerName: 'Type',
      field: 'type',
      valueFormatter: VatRateTypeFormatter,
      filter: DropdownFilter,
      filterParams: {
        property: 'priority',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: () => Promise.resolve({data: getVatRateTypes().map((x: any) => ({id: x.id.toString(), name: x.name}))})
      },
      flex: 3,
    },
    {
      headerName: 'VAT Rate Value',
      field: 'vatRateValue',
      filter: 'agNumberColumnFilter',
      flex: 2,
    },
    {
      headerName: 'Date In Force',
      field: 'dateInForce',
      filter: 'agDateColumnFilter',
      valueFormatter: DateFormatter,
      filterParams: {
        comparator: dateComparator
      },
      minWidth: 120
    },
    {
      headerName: 'Date Out Force',
      field: 'dateOutForce',
      filter: 'agDateColumnFilter',
      valueFormatter: DateFormatter,
      filterParams: {
        comparator: dateComparator
      },
      minWidth: 120
    },
    { 
      excludeFromExport: true,
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        return { buttonsData: [
          {
            type: ActionButtonTypes.Edit,
            callback: editCallback
          },
          {
            type: ActionButtonTypes.Delete,
            callback: deleteModal
          }
        ]};
      },
      hide: !loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageDefaultSettings),
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 105,
      maxWidth: 105,
      width: 105,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];
  return columnDefs;
}