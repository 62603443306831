import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BadgeIds } from "enums/BadgeIds";

export type PageState = {
  reloadGrid: Function | undefined,
  reloadSummary: Function | undefined,
  reloadBadge?: Partial<Record<BadgeIds, Function>>,
}

const initialState: PageState = {
  reloadGrid: undefined,
  reloadSummary: undefined,
  reloadBadge: undefined,
}

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setReloadGrid: (state, action: PayloadAction<Function>) => {
      state.reloadGrid = action.payload;
    },
    setReloadSummary: (state, action: PayloadAction<Function>) => {
      state.reloadSummary = action.payload;
    },
    setReloadBadge: (state, action: PayloadAction<Partial<Record<BadgeIds, Function>>>) => {
      state.reloadBadge = action.payload;
    },
    resetPage: (state) => {
      state.reloadGrid = undefined;
      state.reloadSummary = undefined;
    },
    resetPageReloadBadge: (state) => {
      state.reloadBadge = undefined;
    },
  },
})

export const {
  setReloadGrid,
  setReloadSummary,
  setReloadBadge,
  resetPage,
  resetPageReloadBadge,
} = pageSlice.actions;

export default pageSlice.reducer;
