import { useState } from "react";
import Title from "components/Title";
import { deleteEntityEmail } from "actions/entity";
import { Button } from 'react-bootstrap';
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { MdAdd } from "react-icons/md";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { ModalState } from "state/modalSlice";
import { EntityEmailModel } from "models/view/EntityEmailModel";
import LinkEntityEmailForm from "./LinkEntityEmail/LinkEntityEmailForm";
import ListContact from "components/List/ListContact";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

type EntityLinkType = 'ownedBy' | 'owns';

type Props = {
  entityId: string,
  linkedEntityId: string,
  linkedEntityEmails: EntityEmailModel[],
  linkType: EntityLinkType,
  edit: boolean,
  entityIsShared: boolean
}

export default function LinkedEntityEmailList(props: Props) {
  const [linkedEntityEmails, setLinkedEntityEmails] = useState<EntityEmailModel[]>(props.linkedEntityEmails);
  const [isLoadingEntityEmails, setIsLoadingEntityEmails] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();
  const loggedInUser = useAppSelector((state) => state.user);

  const openLinkEntityEmailSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Link Email Address',
      children: <LinkEntityEmailForm 
                  toEntityId={props.linkType == 'ownedBy' ? props.entityId : props.linkedEntityId}
                  fromEntityId={props.linkType == 'ownedBy' ? props.linkedEntityId : props.entityId}
                  submitCallback={linkEntityEmailCallback} />
    });
  };

  const linkEntityEmailCallback = (newEntityEmail: EntityEmailModel) => {
    setIsLoadingEntityEmails(true);
    setLinkedEntityEmails([...linkedEntityEmails, newEntityEmail]);
    setIsLoadingEntityEmails(false);
  };

  const unlinkCallbackEntityEmail = (id: string) => {
    setIsLoadingEntityEmails(true);
    modalActions.toggleModalLoading();
    deleteEntityEmail(id).then(() => {
      setLinkedEntityEmails(linkedEntityEmails.filter(x => x.id != id));
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingEntityEmails(false);
      modalActions.toggleModalLoading();
      modalActions.toggleModalShownStatus();
    });
  };

  const unlinkEntityEmailModal = (id: string) => {
    let modal: ModalState = {
      title: "Unlink confirmation",
      body: "Are you sure you want to unlink this email address?",
      actionText: "Unlink",
      actionVariant: "danger",
      onAction: () => unlinkCallbackEntityEmail(id),
      show: true
    }
    modalActions.setModal(modal);
   };

  return (
    <>
      <Title type="section" title={"Email Addresses"}>
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && !props.entityIsShared &&
          <Button onClick={openLinkEntityEmailSlidingPanel} className="btn-icon" variant="success">
            <MdAdd />
          </Button>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}
      
      <ListContact
        listItems={linkedEntityEmails?.map(x => ({
          id: x.id,
          contact: x.email,
          type: x.emailType?.name,
          isPreferred: x.isPreferred
        })) ?? []}
        listButtons={
          [
            {
              type: ActionButtonTypes.Email
            },
            ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && !props.entityIsShared ? [{
              type: ActionButtonTypes.Unlink,
              callback: (id: string) => unlinkEntityEmailModal(id)
            }] : [])
          ]
        }
        loadState={isLoadingEntityEmails}
        displayUnlinkButton={loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && !props.entityIsShared}
      >
        <>
          <div>{"Email Address"}</div>
          <div>{"Type"}</div>
          <div></div>
        </>
      </ListContact>
    </>
  );
}
