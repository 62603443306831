import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Loader from "components/Loader";
import { getAccountLedgerTransactionEntryById } from "actions/matter";
import { AccountLedgerTransactionEntryModel } from "models/view/AccountLedgerTransactionEntryModel";
import Field from "components/Fields/Field";
import moment from "moment";
import { DateFormat } from "utils/constants";
import useSlidingPanelActions from "actions/slidingPanel";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { AccountLedgerTransactionTypes } from "enums/AccountLedgerTransactionTypes";
import ViewMatterDisbursement from "containers/Matter/ViewMatter/ActivityHistory/MatterDisbursement/ViewMatterDisbursement/ViewMatterDisbursement";
import ViewMatterDisbursementPayments from "containers/Matter/ViewMatter/ActivityHistory/MatterDisbursement/ViewMatterDisbursementPayments/ViewMatterDisbursementPayments";
import MatterDisbursementWriteOffForm from "containers/Matter/ViewMatter/ActivityHistory/MatterDisbursement/MatterDisbursementWriteOff/MatterDisbursementWriteOffForm";
import ViewMatterIncidentalExpense from "containers/Matter/ViewMatter/ActivityHistory/MatterIncidentalExpense/ViewMatterIncidentalExpense/ViewMatterIncidentalExpense";
import MatterIncidentalExpenseWriteOffForm from "containers/Matter/ViewMatter/ActivityHistory/MatterIncidentalExpense/MatterIncidentalExpenseWriteOff/MatterIncidentalExpenseWriteOffForm";
import ViewMatterInvoice from "containers/Matter/ViewMatter/ActivityHistory/MatterInvoice/ViewMatterInvoice/ViewMatterInvoice";
import ViewMatterInvoicePayments from "containers/Matter/ViewMatter/ActivityHistory/MatterInvoice/ViewMatterInvoicePayments/ViewMatterInvoicePayments";
import MatterInvoiceWriteOffForm from "containers/Matter/ViewMatter/ActivityHistory/MatterInvoice/MatterInvoiceWriteOff/MatterInvoiceWriteOffForm";
import MatterInvoiceAbatementForm from "containers/Matter/ViewMatter/ActivityHistory/MatterInvoice/MatterInvoiceAbatement/MatterInvoiceAbatementForm";
import ViewMatterReceiveMoney from "containers/Matter/ViewMatter/ActivityHistory/MatterReceiveMoney/ViewMatterReceiveMoney/ViewMatterReceiveMoney";
import ViewMatterReturnMoney from "containers/Matter/ViewMatter/ActivityHistory/MatterReturnMoney/ViewMatterReturnMoney/ViewMatterReturnMoney";
import ViewMatterFundsTransfer from "containers/Matter/ViewMatter/ActivityHistory/MatterFundsTransfer/ViewMatterFundsTransfer/ViewMatterFundsTransfer";
import ViewAccountLedgerTransaction from "../ViewAccountLedgerTransaction/ViewAccountLedgerTransaction";
import { getAccountIncreaseRepresentedByName } from "utils/enums";
import { formatCurrency } from "utils/misc";
import EditAccountLedgerEntryForm from "../EditAccountLedgerEntry/EditAccountLedgerEntryForm";

type Props = {
  accountLedgerTransactionId: string,
  accountLedgerTransactionEntryId: string,
  accountLedgerTransactionType?: AccountLedgerTransactionTypes,
  matterId?: string,
  parentResourceId?: string,
  showMatterInfo?: boolean,
  hideEdit?: boolean,
}

export default function ViewAccountLedgerEntry(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accountLedgerTransactionEntry, setAccountLedgerTransactionEntry] = useState<AccountLedgerTransactionEntryModel>();

  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const loggedInUser = useAppSelector((state) => state.user);

  useEffect(() => {
    setIsLoading(true);

    getAccountLedgerTransactionEntryById(props.accountLedgerTransactionEntryId).then((response) => {
      setAccountLedgerTransactionEntry(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });

    // Set edit button action
    if(!props.hideEdit && (loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageChartOfAccounts || 
      a == UserPermissionsNames.ManageMatterLedger || a == UserPermissionsNames.ManageEntities))) {
      slidingPanelActions.setOnEdit(() => {
        slidingPanelActions.clearSlidingPanel();

        slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          title: "Edit Ledger Entry",
          children: <EditAccountLedgerEntryForm
            ledgerEntryId={props.accountLedgerTransactionEntryId}
            showMatterInfo={props.showMatterInfo}
            onSubmitCallback={onChildPanelClose}
          />,
          onCancel: onChildPanelClose
        });
      });
    }

    slidingPanelActions.setMoreButtons([
      {
        type: ActionButtonTypes.ViewTransaction,
        callback: () => viewTransactionCallback()
      },
      ...(props.parentResourceId ? [{
        type: ActionButtonTypes.ViewParentResource,
        callback: () => viewParentResourceCallback(),
      }] : []),
    ]);
  }, [props.accountLedgerTransactionEntryId]);

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
    });
  };

  const viewTransactionCallback = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "View Ledger Transaction",
        children: <ViewAccountLedgerTransaction
          accountLedgerTransactionId={props.accountLedgerTransactionId}
          accountLedgerTransactionEntryId={props.accountLedgerTransactionEntryId}
          showMatterInfo={props.showMatterInfo}
        />,
        onCancel: onChildPanelClose
      }
    );
  }

  const viewParentResourceCallback = () => {
    if(!props.matterId || !props.parentResourceId || !props.accountLedgerTransactionType) {
      return;
    }

    switch(props.accountLedgerTransactionType) { 
      case AccountLedgerTransactionTypes.Disbursement: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            title: "View Disbursement",
            children: <ViewMatterDisbursement
              matterId={props.matterId}
              matterDisbursementId={props.parentResourceId}
              showMoreButtons
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.DisbursementPayment: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            title: "Disbursement Payments",
            children: <ViewMatterDisbursementPayments
              matterId={props.matterId}
              matterDisbursementId={props.parentResourceId}
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.DisbursementWriteOff: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            title: "Disbursement Write-off",
            children: <MatterDisbursementWriteOffForm
              matterId={props.matterId}
              matterDisbursementId={props.parentResourceId}
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.IncidentalExpense: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            title: "View Incidental Expense",
            children: <ViewMatterIncidentalExpense
              matterId={props.matterId}
              matterIncidentalExpenseId={props.parentResourceId}
              showMoreButtons
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.IncidentalExpenseWriteOff: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            title: "Incidental Expense Write-off",
            children: <MatterIncidentalExpenseWriteOffForm
              matterId={props.matterId}
              matterIncidentalExpenseId={props.parentResourceId}
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.Invoice: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            title: "View Invoice",
            children: <ViewMatterInvoice
              matterId={props.matterId}
              matterInvoiceId={props.parentResourceId}
              showMoreButtons
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.InvoicePayment: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            title: "Invoice Payments",
            children: <ViewMatterInvoicePayments
              matterId={props.matterId}
              matterInvoiceId={props.parentResourceId}
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.InvoiceWriteOff: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            title: "Invoice Write-Off",
            children: <MatterInvoiceWriteOffForm
              matterId={props.matterId}
              matterInvoiceId={props.parentResourceId}
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.InvoiceAbatement: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            title: "Invoice Abatement",
            children: <MatterInvoiceAbatementForm
              matterId={props.matterId}
              matterInvoiceId={props.parentResourceId}
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.ReceiveMoney: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            title: "View Receive Money",
            children: <ViewMatterReceiveMoney
              matterId={props.matterId}
              matterReceiveMoneyId={props.parentResourceId}
              showMoreButtons
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.ReturnMoney: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            title: "View Return Money",
            children: <ViewMatterReturnMoney
              matterId={props.matterId}
              matterReturnMoneyId={props.parentResourceId}
              showMoreButtons
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      case AccountLedgerTransactionTypes.FundsTransfer: { 
        slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            title: "View Funds Transfer",
            children: <ViewMatterFundsTransfer
              matterId={props.matterId}
              matterFundsTransferId={props.parentResourceId}
              showMoreButtons
            />,
            onCancel: onChildPanelClose
          }
        );
        return;
      }
      default: { 
        return;
      }
    }
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Date"}
            value={accountLedgerTransactionEntry?.date ? moment(accountLedgerTransactionEntry?.date).format(DateFormat.Moment) : "—"}
          />
        </Col>
      </Row>
      <Row>
        {props.showMatterInfo && 
          <Col sm={6}>
            <Field
              label={"Matter"}
              value={accountLedgerTransactionEntry?.matter?.name ?? "—"}
            />
          </Col>
        }
        <Col sm={props.showMatterInfo ? 6 : 12} className="mt-4 mt-sm-0">
          <Field
            label={"Details"}
            value={accountLedgerTransactionEntry?.accountLedgerTransaction?.name ?? "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            label={"Account Name"}
            value={accountLedgerTransactionEntry?.account?.displayName ?? "—"}
          />
        </Col>
        <Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Fee Earner"}
            value={accountLedgerTransactionEntry?.user?.name ?? "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            label={"Amount"}
            value={formatCurrency(accountLedgerTransactionEntry?.amount)}
          />
        </Col>
        <Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Type"}
            value={accountLedgerTransactionEntry?.entryType ? getAccountIncreaseRepresentedByName(accountLedgerTransactionEntry.entryType) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Notes"}
            value={accountLedgerTransactionEntry?.notes ?? "—"}
          />
        </Col>
      </Row>
    </>
  );
}