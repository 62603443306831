import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import useGridActions from "actions/grid";
import { useAppSelector } from "hooks/appSelector";
import { Form, Button, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import { getValidationSuite } from "./validationSuite";
import Loader from "components/Loader/index";
import { getDefaultVatRateById, updateDefaultVatRate } from "actions/settings";
import CustomSelect from "components/Select/Select";
import FormSubmitButton from "components/Buttons/FormSubmitButton";
import { CreateOrUpdateDefaultVatRateModel } from "models/create/CreateOrUpdateDefaultVatRateModel";
import { DefaultVatRateModel } from "models/view/DefaultVatRateModel";
import { getVatRateTypes } from "utils/enums";
import DatePicker from "react-datepicker";
import { DateFormat } from "utils/constants";
import { getDateOnly } from "utils/date";

type Props = {
  defaultVatRateId: string
}

export default function EditDefaultVatRateForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const gridActions = useGridActions();
  const grid = useAppSelector((state) => state.grid);
  const slidingPanelActions = useSlidingPanelActions();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    getDefaultVatRateById(props.defaultVatRateId).then((response) => {
      reset(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const {register, reset, control, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateDefaultVatRateModel>({
    resolver: vestResolver(getValidationSuite())
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateDefaultVatRateModel) {
    setIsLoading(true);
    updateDefaultVatRate(props.defaultVatRateId, data).then((response) => {
      let index: number = grid.rowData.findIndex((x: any) => x.id === response.data.id);
      let newEntry: DefaultVatRateModel = {...response.data};
      let newArray: Array<any> = [...grid.rowData];
      newArray[index] = newEntry;
      gridActions.setGridRowData(newArray);
      
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }
      
  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
      <Row>
          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="name">
            <Form.Label className="required">Name</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.name?.message ? 'invalid' : ''}`}
              {...register("name", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.name?.message && (errors.name.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} controlId="type">
            <Form.Label className="required">Type</Form.Label>
            <Controller
              control={control}
              name={`type`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="type"
                  inputRef={ref}
                  className={`lp-select${errors?.type?.message ? ' invalid' : ''}`}
                  endpointCall={() => Promise.resolve({data: getVatRateTypes()})}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.type?.message && (errors.type?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="vatRateValue">
            <Form.Label className={"required"}>VAT Rate Value</Form.Label>
            <Form.Control
              type="number"
              className={`${errors?.vatRateValue?.message ? 'invalid' : ''}`}
              {...register(`vatRateValue`, {shouldUnregister: true})}
              min="0.00"
              step="0.01"
              onWheel={e => e.currentTarget.blur()}
            />
            <Form.Text className="lp-error">
              {errors?.vatRateValue?.message && (errors.vatRateValue?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="dateInForce">
            <Form.Label>Date In Force</Form.Label>
            <Controller
              control={control}
              name="dateInForce"
              shouldUnregister={true}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  className={`${errors?.dateInForce?.message ? 'invalid' : ''}`}
                  id="dateInForce"
                  dateFormat={DateFormat.Datepicker}
                  selected={value ? getDateOnly(value) : null}
                  onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                  showMonthDropdown
                  showYearDropdown
                  autoComplete="off"
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.dateInForce?.message && (errors.dateInForce.message)}
            </Form.Text>
          </Form.Group>
          
          <Form.Group as={Col} sm={6} controlId="dateOutForce">
            <Form.Label>Date Out Force</Form.Label>
            <Controller
              control={control}
              name="dateOutForce"
              shouldUnregister={true}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  className={`${errors?.dateOutForce?.message ? 'invalid' : ''}`}
                  id="dateOutForce"
                  dateFormat={DateFormat.Datepicker}
                  selected={value ? getDateOnly(value) : null}
                  onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                  showMonthDropdown
                  showYearDropdown
                  autoComplete="off"
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.dateOutForce?.message && (errors.dateOutForce.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <FormSubmitButton text="Update" errors={{...errors}} />
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
