import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import { vestResolver } from '@hookform/resolvers/vest';
import useGridActions from 'actions/grid';
import { useAppSelector } from 'hooks/appSelector';
import { Form, Button, Row, Col } from 'react-bootstrap';
import useSlidingPanelActions from 'actions/slidingPanel';
import Loader from 'components/Loader/index';
import { validationSuite } from './validationSuite';
import { CreateOrUpdateEntityBankAccountModel } from 'models/create/CreateOrUpdateEntityBankAccountModel';
import { addEntityBankAccount } from 'actions/entity';
import FormSubmitButton from "components/Buttons/FormSubmitButton";

type Props = {
  entityId: string
};

export default function CreateEntityBankAccountForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const gridActions = useGridActions();
  const grid = useAppSelector((state) => state.grid);
  const slidingPanelActions = useSlidingPanelActions();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function submitData(data: CreateOrUpdateEntityBankAccountModel) {
    setIsLoading(true);
    addEntityBankAccount(props.entityId, data)
      .then((response) => {
        gridActions.setGridRowData(grid.rowData.concat(response.data));
        slidingPanelActions.clearSlidingPanel();
        reset();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CreateOrUpdateEntityBankAccountModel>({
    resolver: vestResolver(validationSuite),
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  };

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && <div className="lp-errors">{genericErrors}</div>}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} sm={6} controlId="bankName">
            <Form.Label className="required">Bank Name</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.bankName?.message ? 'invalid' : ''}`}
              {...register('bankName', {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.bankName?.message && errors.bankName.message}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="bankAccountName">
            <Form.Label className="required">Bank Account Name</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.bankAccountName?.message ? 'invalid' : ''}`}
              {...register('bankAccountName', {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.bankAccountName?.message && errors.bankAccountName.message}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="bankSortCode">
            <Form.Label className="required">Bank Sort Code</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.bankSortCode?.message ? 'invalid' : ''}`}
              placeholder="NN-NN-NN"
              {...register('bankSortCode', {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.bankSortCode?.message && errors.bankSortCode.message}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="bankAccountNumber">
            <Form.Label className="required">Bank Account Number</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.bankAccountNumber?.message ? 'invalid' : ''}`}
              {...register('bankAccountNumber', {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.bankAccountNumber?.message &&
                errors.bankAccountNumber.message}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="bankIBAN">
            <Form.Label>Bank IBAN</Form.Label>
            <Form.Control
              type="text"
              {...register("bankIBAN", {shouldUnregister: true})}
              />
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="isPreferred">
            <Form.Label>Is Preferred</Form.Label>
            <Controller
              control={control}
              name="isPreferred"
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <Form.Check type="switch" id="isPreferred">
                  <Form.Check.Input
                    className="form-check-input"
                    ref={ref}
                    checked={value ?? false}
                    onChange={(ev: any) => {
                      onChange(ev.target.checked);
                    }}
                  />
                </Form.Check>
              )}
            />
          </Form.Group>
        </Row>
        
        <Row>
          <Form.Group as={Col} controlId="description">
            <Form.Label className="required">Description</Form.Label>
            <Form.Control
              as="textarea" rows={5}
              className={`${errors?.description?.message ? 'invalid' : ''}`}
              {...register('description', {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.description?.message && errors.description.message}
            </Form.Text>
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <FormSubmitButton text="Create" errors={{...errors}} />
            <Button variant="secondary-400" onClick={cancelForm}>
              Cancel
            </Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
