import { useEffect, useState } from "react";
import Title from "components/Title";
import { deleteEntityDocumentExchange, getEntityDocumentExchanges } from "actions/entity";
import { Button } from 'react-bootstrap';
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { MdAdd } from "react-icons/md";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { ModalState } from "state/modalSlice";
import { EntityDocumentExchangeModel } from "models/view/EntityDocumentExchangeModel";
import CreateEntityDocumentExchangeForm from "./CreateEntityDocumentExchange/CreateEntityDocumentExchangeForm";
import EditEntityDocumentExchangeForm from "./EditEntityDocumentExchange/EditEntityDocumentExchangeForm";
import ListContact from "components/List/ListContact";
import EditEntityDocumentExchangeOnlyIsPreferredForm from "./EditEntityDocumentExchangeOnlyIsPreferred/EditEntityDocumentExchangeOnlyIsPreferredForm";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

type Props = {
  entityId?: string,
  edit: boolean,
  isUserEntity?: boolean,
  entityIsShared?: boolean
}

export default function EntityDocumentExchangeList(props: Props) {
  const [entityDocumentExchanges, setEntityDocumentExchanges] = useState<any[]>();
  const [isLoadingEntityDocumentExchanges, setIsLoadingEntityDocumentExchanges] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();
  const loggedInUser = useAppSelector((state) => state.user);

  const getAllEntityDocumentExchanges = () => {
    setIsLoadingEntityDocumentExchanges(true);
    getEntityDocumentExchanges(props.entityId!).then((response) => {
      const mappedDocumentExchanges = response.data.map((dx: EntityDocumentExchangeModel) => 
        ({
          id: dx.id, 
          contact: dx.dxExchange + ' (Number: ' + dx.dxNumber + ')', 
          isPreferred: dx.isPreferred,
          isLinked: dx.sourceEntityDocumentExchangeId != undefined
        })
      );
      setEntityDocumentExchanges(mappedDocumentExchanges);
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      
    })
    .finally(() => {
      setIsLoadingEntityDocumentExchanges(false);
    });
  }

  useEffect(() =>
  {
    if(props.entityId) {
      getAllEntityDocumentExchanges();
    }
  }, [props.entityId]);

  const openCreateEntityDocumentExchangeSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Create Document Exchange',
      children: <CreateEntityDocumentExchangeForm entityId={props.entityId ?? ''} submitCallback={getAllEntityDocumentExchanges} />
    });
  };

  const openEditEntityDocumentExchangeSlidingPanel = (id: string) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Edit Document Exchange',
      children: <EditEntityDocumentExchangeForm
                  entityId={props.entityId ?? ''}
                  documentExchangeId={id}
                  submitCallback={getAllEntityDocumentExchanges}
                />
    });
  };

  const openEditEntityDocumentExchangeOnlyIsPreferredSlidingPanel = (id: string) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Edit Document Exchange',
      children: <EditEntityDocumentExchangeOnlyIsPreferredForm
                  entityId={props.entityId ?? ''}
                  documentExchangeId={id}
                  submitCallback={getAllEntityDocumentExchanges}
                />
    });
  };

  const deleteCallbackEntityDocumentExchange = (id: string) => {
    modalActions.toggleModalLoading();
    deleteEntityDocumentExchange(id).then(() => {
      getAllEntityDocumentExchanges();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
      modalActions.toggleModalShownStatus();
    });
  };

  const deleteEntityDocumentExchangeModal = (id: string) => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this document exchange?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallbackEntityDocumentExchange(id),
      show: true
    }
    modalActions.setModal(modal);
  };

  const unlinkCallbackEntityDocumentExchange = (id: string) => {
    modalActions.toggleModalLoading();
    deleteEntityDocumentExchange(id).then(() => {
      getAllEntityDocumentExchanges();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
      modalActions.toggleModalShownStatus();
    });
  };

  const unlinkEntityDocumentExchangeModal = (id: string) => {
    let modal: ModalState = {
      title: "Unlink confirmation",
      body: "Are you sure you want to unlink this document exchange?",
      actionText: "Unlink",
      actionVariant: "danger",
      onAction: () => unlinkCallbackEntityDocumentExchange(id),
      show: true
    }
    modalActions.setModal(modal);
   };

  return (
    <>
      <Title type="section" title={"Documents Exchange"}>
        {(props.isUserEntity || (loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && !props.isUserEntity && !props.entityIsShared)) &&
          <Button onClick={openCreateEntityDocumentExchangeSlidingPanel} className="btn-icon" variant="success">
            <MdAdd />
          </Button>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}
      
      <ListContact
        listItems={entityDocumentExchanges ?? []}
        listButtons={
          [
            ...((props.isUserEntity || (loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && !props.isUserEntity && !props.entityIsShared)) ? [{
              type: ActionButtonTypes.Edit,
              callback: (id: string) => openEditEntityDocumentExchangeSlidingPanel(id)
            }] : []),
            ...((props.isUserEntity || (loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && !props.isUserEntity && !props.entityIsShared)) ? [{
              type: ActionButtonTypes.EditOnlyIsPreferred,
              callback: (id: string) => openEditEntityDocumentExchangeOnlyIsPreferredSlidingPanel(id)
            }] : []),
            ...((props.isUserEntity || (loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && !props.isUserEntity && !props.entityIsShared)) ? [{
              type: ActionButtonTypes.Delete,
              callback: (id: string) => deleteEntityDocumentExchangeModal(id)
            }] : []),
            ...((props.isUserEntity || (loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities) && !props.isUserEntity && !props.entityIsShared)) ? [{
              type: ActionButtonTypes.Unlink,
              callback: (id: string) => unlinkEntityDocumentExchangeModal(id)
            }] : [])
          ]
        }
        loadState={isLoadingEntityDocumentExchanges}
        hideTypeColumn
      >
        <div>{"Document Exchange"}</div>
      </ListContact>
    </>
  );
}
