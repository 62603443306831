import { EntityTypeIds } from "enums/EntityTypeIds";
import { CreateEntityModel } from "models/create/CreateEntityModel";
import isDate from "validator/lib/isDate";
import { create, test, enforce, omitWhen } from "vest";

export const validationSuite = create((data: CreateEntityModel) => {
  enforce.extend({isDate})
  test('displayName', 'Display Name is required', () => {
    enforce(data.displayName).isNotEmpty();
    enforce(data.displayName).isString();
  });

  test('entityTypeId', 'Please select a Type', () => {
    enforce(data.entityTypeId).isNotEmpty();
    enforce(data.entityTypeId).isString();
  });

  test('defaultRoleId', 'Please select a Default Role', () => {
    enforce(data.defaultRoleId).isNotEmpty();
    enforce(data.defaultRoleId).isString();
  });

  omitWhen(
    data.entityTypeId !== EntityTypeIds.CrownProsecutionServiceId || !data.crownProsecutionServiceDetail,
    () => {
      test('crownProsecutionServiceDetail.cpsArea', 'CPS Area is required', () => {
        enforce(data.crownProsecutionServiceDetail.cpsArea).isNotEmpty();
        enforce(data.crownProsecutionServiceDetail.cpsArea).isString();
      });
    }
  );

  omitWhen(
    data.entityTypeId !== EntityTypeIds.HumanBeingId || !data.humanBeingDetail,
    () => {
      test('humanBeingDetail.firstName', 'First Name is required', () => {
        enforce(data.humanBeingDetail.firstName).isNotEmpty();
        enforce(data.humanBeingDetail.firstName).isString();
      });

      test('humanBeingDetail.salutation', 'Salutation is required', () => {
        enforce(data.humanBeingDetail.salutation).isNotEmpty();
        enforce(data.humanBeingDetail.salutation).isString();
      });
  
      test('humanBeingDetail.lastName', 'Last Name is required', () => {
        enforce(data.humanBeingDetail.lastName).isNotEmpty();
        enforce(data.humanBeingDetail.lastName).isString();
      });
  
      test('humanBeingDetail.sexId', 'Please select a Sex', () => {
        enforce(data.humanBeingDetail.sexId).isNotEmpty();
        enforce(data.humanBeingDetail.sexId).isString();
      });
  
      test('humanBeingDetail.genderPronounId', 'Please select a Gender Pronoun', () => {
        enforce(data.humanBeingDetail.genderPronounId).isNotEmpty();
        enforce(data.humanBeingDetail.genderPronounId).isString();
      });

      omitWhen(!data.humanBeingDetail?.nationalInsuranceNo,
        () => {
          test('humanBeingDetail.nationalInsuranceNo', 'National Insurance No. must match PP-NNNNNN-S patern', () => {
            enforce(data.humanBeingDetail.nationalInsuranceNo).matches('([A-Z]{2})-(\\d{6})-([A-D])');
          });
        }
      );
    }
  );

  omitWhen(
    (data.entityTypeId !== EntityTypeIds.LimitedCompanyId && data.entityTypeId !== EntityTypeIds.PublicLimitedCompanyId) || 
    !data.limitedCompanyDetail,
    () => {
      test('limitedCompanyDetail.companyName', 'Company Name is required', () => {
        enforce(data.limitedCompanyDetail.companyName).isNotEmpty();
        enforce(data.limitedCompanyDetail.companyName).isString();
      });
  
      test('limitedCompanyDetail.tradingName', 'Trading Name is required', () => {
        enforce(data.limitedCompanyDetail.tradingName).isNotEmpty();
        enforce(data.limitedCompanyDetail.tradingName).isString();
      });
    }
  );

  omitWhen(
    data.entityTypeId !== EntityTypeIds.LimitedLiabilityPartnershipId || !data.limitedLiabilityPartnershipDetail,
    () => {
      test('limitedLiabilityPartnershipDetail.partnershipName', 'Partnership Name is required', () => {
        enforce(data.limitedLiabilityPartnershipDetail.partnershipName).isNotEmpty();
        enforce(data.limitedLiabilityPartnershipDetail.partnershipName).isString();
      });
  
      test('limitedLiabilityPartnershipDetail.tradingName', 'Trading Name is required', () => {
        enforce(data.limitedLiabilityPartnershipDetail.tradingName).isNotEmpty();
        enforce(data.limitedLiabilityPartnershipDetail.tradingName).isString();
      });
    }
  );

  omitWhen(
    data.entityTypeId !== EntityTypeIds.PartnershipAtWillId || !data.partnershipAtWillDetail,
    () => {
      test('partnershipAtWillDetail.tradingName', 'Trading Name is required', () => {
        enforce(data.partnershipAtWillDetail.tradingName).isNotEmpty();
        enforce(data.partnershipAtWillDetail.tradingName).isString();
      });
    }
  );

  omitWhen(
    data.entityTypeId !== EntityTypeIds.PoliceStationId || !data.policeStationDetail,
    () => {  
      test('policeStationDetail.constabulary', 'Constabulary is required', () => {
        enforce(data.policeStationDetail.constabulary).isNotEmpty();
        enforce(data.policeStationDetail.constabulary).isString();
      });
    }
  );

  omitWhen(
    data.entityTypeId !== EntityTypeIds.RegisteredCharityId || !data.registeredCharityDetail,
    () => {
      test('registeredCharityDetail.charityName', 'Charity Name is required', () => {
        enforce(data.registeredCharityDetail.charityName).isNotEmpty();
        enforce(data.registeredCharityDetail.charityName).isString();
      });
  
      test('registeredCharityDetail.tradingName', 'Trading Name is required', () => {
        enforce(data.registeredCharityDetail.tradingName).isNotEmpty();
        enforce(data.registeredCharityDetail.tradingName).isString();
      });
    }
  );
});