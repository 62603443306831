import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { vestResolver } from '@hookform/resolvers/vest';
import { Form, Button, InputGroup, Row, Col } from 'react-bootstrap';
import useSlidingPanelActions from 'actions/slidingPanel';
import Loader from 'components/Loader';
import { removeEmptyFields } from 'utils/form';
import { createMatterDocument, getDocumentTemplatesForMatterType, getMatterParticipatingEntitiesSummary } from 'actions/matter';
import { validationSuite } from './validationSuite';
import { CreateMatterDocumentModel } from 'models/create/CreateMatterDocumentModel';
import { GridIds } from "enums/GridIds";
import CustomSelect from 'components/Select/Select';
import { TemplateModel } from 'models/view/TemplateModel';
import { openUrlInNewtab } from 'utils/misc';
import { getMatterTemplateTypesSummary } from 'actions/settings';
import { useAppSelector } from 'hooks/appSelector';
import usePageActions from 'actions/page';
import FormSubmitButton from "components/Buttons/FormSubmitButton";

type Props = {
  matterId: string,
  matterTypeId: string
};

export default function CreateMatterDocument(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const [templates, setTemplates] = useState<TemplateModel[]>([]);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState<boolean>(false);
  const gridState = useAppSelector((state) => state.grid);
  const pageActions = usePageActions();

  const {
    reset,
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CreateMatterDocumentModel>({
    resolver: vestResolver(validationSuite),
    defaultValues: {
      useParticipatingEntity: false
    }
  });

  useEffect(() => {
    loadTemplates();
  }, []);

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateMatterDocumentModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    createMatterDocument(props.matterId, data)
      .then((response) => {
        openUrlInNewtab(response.data);

        if (gridState.id === GridIds.ActivityHistory) {
          pageActions.triggerReloadPage();
        }

        slidingPanelActions.clearSlidingPanel();
        reset();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  };

  const onChangeTemplate = (value?: TemplateModel) => {
    if(value) {
      setValue('useParticipatingEntity', value.useParticipatingEntity);
      setValue('participatingEntityIsInvoicingParty', value.participatingEntityIsInvoicingParty);
      setValue('participatingEntityEntityRoleId', value.participatingEntityEntityRoleId);
    }
  }

  const onChangeTemplateType = (value?: string) => {
    loadTemplates(value);
  }

  const loadTemplates = (templateTypeId?: string) => {
    setIsLoadingTemplates(true);
    getDocumentTemplatesForMatterType(props.matterTypeId, templateTypeId).then((response) => {
      setTemplates(response.data);
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingTemplates(false);
    });
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && <div className="lp-errors">{genericErrors}</div>}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} sm={6} controlId="templateTypeId">
            <Form.Label>Template Type</Form.Label>
            <Controller
              control={control}
              name={`templateTypeId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="templateTypeId"
                  inputRef={ref}
                  className={`lp-select${errors?.templateTypeId?.message ? ' invalid' : ''}`}
                  endpointCall={getMatterTemplateTypesSummary}
                  value={value}
                  onChange={val => {onChange(val?.id ?? null); onChangeTemplateType(val?.id);}}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.templateTypeId?.message && (errors.templateTypeId?.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="templateId">
            <Form.Label className="required">Template</Form.Label>
            <Controller
              control={control}
              name={`templateId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="templateId"
                  inputRef={ref}
                  className={`lp-select${errors?.templateId?.message ? ' invalid' : ''}`}
                  options={templates}
                  isLoading={isLoadingTemplates}
                  value={value}
                  onChange={val => {onChange(val?.id ?? null); onChangeTemplate(val);}}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.templateId?.message && (errors.templateId?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        {watch('useParticipatingEntity') &&
          <Row>
            <Form.Group as={Col} controlId="matterParticipatingEntityId">
              <Form.Label className="required">Participating Entity</Form.Label>
              <Controller
                control={control}
                name={`matterParticipatingEntityId`}
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="matterParticipatingEntityId"
                    inputRef={ref}
                    className={`lp-select${errors?.matterParticipatingEntityId?.message ? ' invalid' : ''}`}
                    endpointCall={() => getMatterParticipatingEntitiesSummary(props.matterId, watch('participatingEntityIsInvoicingParty'), watch('participatingEntityEntityRoleId'))}
                    value={value}
                    onChange={val => onChange(val?.id ?? null)}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.matterParticipatingEntityId?.message && (errors.matterParticipatingEntityId?.message)}
              </Form.Text>
            </Form.Group>
          </Row>
        }

        <Row>
          <Form.Group as={Col} controlId="fileName">
            <Form.Label className="required">File Name</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                className={`${errors?.fileName?.message ? 'invalid' : ''}`}
                {...register('fileName', { shouldUnregister: true })}
              />
              <InputGroup.Text id="basic-addon1">{`.docx`}</InputGroup.Text>
            </InputGroup>
            <Form.Text className="lp-error">
              {errors?.fileName?.message && (errors.fileName?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <FormSubmitButton text="Create" errors={{...errors}} />
            <Button variant="secondary-400" onClick={cancelForm}>
              Cancel
            </Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
