import Loader from "components/Loader/index";
import IconButtonGrid from "components/Buttons/IconButtonGrid";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { Card } from "react-bootstrap";
import React from "react";
import { MatterInvoiceModel } from "models/view/MatterInvoiceModel";
import InvoiceStatusFieldValue from "components/Fields/InvoiceStatusFieldValue";
import './style.scss';
import { DropDownOptionModel } from "models/view/DropDownOptionModel";

type Props = {
  listItems: MatterInvoiceModel[],
  listButtons: Array<{type: ActionButtonTypes, callback: Function, id?: string}>,
  onClick?: Function,
  selected?: string,
  loadState?: boolean,
  value?: DropDownOptionModel
}

export default function ListMatterInvoices(props: Props) {
  return (
    <Card className={`with-list${props.listItems.length == 0 ? ' empty-list' : ' dashboard-list'}`}>
      <div className="inner-content">
        <Card.Header>
          <div>{"Ref Number"}</div>
          <div>{"Matter"}</div>
          <div>{"Status"}</div>
          {props.listButtons.length != 0 && <div></div>}
        </Card.Header>
        {props.loadState && <Loader inlineLoader={props.loadState} />}
        <Card.Body className="lp-list-grid">
          {!props.loadState && props.listItems.length == 0 &&
            <div className="lp-empty list">
              <div className="image"></div>
              <div className="text">No invoices found!</div>
            </div>
          }

          {props.listItems.map((x: MatterInvoiceModel, index: number) => {
            return (
              <React.Fragment key={index}>
                <div className="lp-list-row">
                  <div>
                    {x.invoiceRefNumber}
                  </div>
                  <div>
                    {x.matter?.fileNumber + ' (' + x.matter?.displayName + ')' }
                  </div>
                  <div>
                    {x.matterInvoiceStatus?.name ? <InvoiceStatusFieldValue value={x.matterInvoiceStatus} /> : <></>}
                  </div>
                  {props.listButtons.length != 0 && 
                    <div className="lp-list-action">
                      {
                        props.listButtons.map((el: {type: ActionButtonTypes, callback: Function, id?: string}, index: number) => 
                          IconButtonGrid({...el, callback: () => {
                            el.callback(x.id, x.matterId)
                          }, id: x.id + index.toString()})
                        )
                      }
                    </div>
                  }
                </div>
              </React.Fragment>
            )
          })}
        </Card.Body>
      </div>
    </Card>
  );
}
