import { LteTypeIds } from "enums/LteTypeIds";

const DateFormat = {
  Moment: "DD/MM/yyyy",
  MomentWithoutYear: "DD/MM",
  MomentWithTime: "DD/MM/yyyy h:mm A",
  MomentWithTimeNoSpaces: "DD/MM/yyyyh:mmA",
  MomentTime: "h:mm A",
  Moment24HourTime: "HH:mm",
  GridExportDate: "DD MMMM yyyy",
  GridExportFileNameDate: "DD-MM-yyyy HH-mm-ss",
  Datepicker: "dd/MM/yyyy",
  DayMonthpicker: "dd/MM",
  DatepickerWithTime: "dd/MM/yyyy h:mm aa",
  DatepickerTime: "HH:mm",
  FreeBusyHeaderDate: "DD/MM/yyyy - dddd",
  Meridiem: 'A',
  Day: 'ddd',
  DayWithMomentWithoutYear: 'ddd, DD/MM',
}

const CompanyLteTypes: string[] = [
  LteTypeIds.LimitedCompanyId,
  LteTypeIds.PublicLimitedCompanyId,
  LteTypeIds.LimitedLiabilityPartnershipId,
  LteTypeIds.RegisteredCharityId
];

const DefaultCurrency = "GBP";
const DefaultCurrencySymbol = "￡";
const DefaultLocalization = 'en-GB';

const RecordableEventMinutesWarningLimit = 600;

export {
  DateFormat,
  CompanyLteTypes,
  DefaultCurrency,
  DefaultCurrencySymbol,
  DefaultLocalization,
  RecordableEventMinutesWarningLimit
};
