function removeEmptyFields(data: any) {
  Object.keys(data).forEach(key => {
    if(data[key] !== null && typeof data[key] === 'object'){
      if(Array.isArray(data[key]) && data[key].length === 0){
        delete data[key];
      }
      else {
        removeEmptyFields(data[key]);
      }
    }
    else {
      if (data[key] === '' || data[key] == null || data[key] === "—") {
        delete data[key];
      }
    }
  });
}

function getInitialsForName(name?: string) {
  if(name){
    const splittedName = name.split(' ');

    if(splittedName.length === 1){
      return `${splittedName.at(0)?.charAt(0)?.toUpperCase()}`;
    }
    else{
      return `${splittedName.at(0)?.charAt(0)?.toUpperCase()}${splittedName.at(1)?.charAt(0)?.toUpperCase()}`;
    }
  }
  else {
    return '';
  }
}

//resource used: https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s03.html
function checkIfValidPhoneNumber(phoneNumber: string) {
  let regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  return regex.test(phoneNumber);
}

export {
  removeEmptyFields,
  getInitialsForName,
  checkIfValidPhoneNumber
};
