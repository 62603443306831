import { cloneElement, ReactElement, useEffect, useState } from "react";
import Loader from "components/Loader";
import { Row, Col, Button } from "react-bootstrap";
import { createForwardDraftEmail, createReplyAllDraftEmail, createReplyDraftEmail, decreasePriorityMatterActivityHistory, deleteMatterEmail, downloadActivityHistory, flagMatterActivityHistory, getMatterEmailById, getMatterEmailPreview, increasePriorityMatterActivityHistory, printActivityHistory, unflagMatterActivityHistory, validateCopyActivityHistory, validateMoveActivityHistory, validateShareActivityHistory } from "actions/matter";
import { BiLinkExternal } from 'react-icons/bi';
import { useAppSelector } from "hooks/appSelector";
import { MdReply, MdReplyAll } from 'react-icons/md';
import "./style.scss";
import { downloadFile, getDecreasedPriority, getIncreasedPriority, openUrlInNewtab, printPdf } from "utils/misc";
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import EditActivityHistoryTaxonomyTermsForm from "../../ActivityHistoryGrid/ActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTermsForm";
import SaveAttachments from "../SaveAttachments/SaveAttachments";
import { ModalState } from "state/modalSlice";
import ViewMatterEmail from "../ViewMatterEmail/ViewMatterEmail";
import { UserRoleNames } from "enums/UserRoleNames";
import usePageActions from "actions/page";
import { Priority } from "enums/Priority";
import { MatterEmailModel } from "models/view/MatterEmailModel";
import CopyActivityHistoryForm from "../../ActivityHistoryGrid/CopyActivityHistory/CopyActivityHistoryForm";
import MoveActivityHistoryForm from "../../ActivityHistoryGrid/MoveActivityHistory/MoveActivityHistoryForm";
import ShareActivityHistoryForm from "../../ActivityHistoryGrid/ShareActivityHistory/ShareActivityHistoryForm";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import useNotificationActions from "actions/notification";
import { NotificationTypes } from "enums/NotificationTypes";

type Props = {
  matterId: string,
  emailId: string,
  activityHistoryId: string,
  isMatterClosed?: boolean,
  hasTaxonomies: boolean,
  syncMatterFiles?: Function,
  showMoreButtons?: boolean,
  isFlagged?: boolean,
  priority?: Priority,
}

function PreviewMatterEmail(props: Props){
  const [genericErrors, setGenericErrors] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [matterEmail, setMatterEmail] = useState<MatterEmailModel>();
  const [matterEmailPreviewUrl, setMatterEmailPreviewUrl] = useState<string>();
  const [isFlagged, setIsFlagged] = useState<boolean | undefined>(props.isFlagged);
  const [priority, setPriority] = useState<Priority | undefined>(props.priority);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const modalActions = useModalActions();
  const pageActions = usePageActions();
  const notificationActions = useNotificationActions();

  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    // When moving between multiple items of the same type, update local state for flag and priority
    setIsFlagged(props.isFlagged);
    setPriority(props.priority);

    setIsLoading(true);
    getMatterEmailById(props.matterId, props.emailId)
      .then((response) => {
        setMatterEmail(response.data);

        getMatterEmailPreview(props.matterId, props.emailId).then((response2) => {
          setMatterEmailPreviewUrl(response2.data.getUrl);
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
          setMatterEmailPreviewUrl(undefined);
        })
        .finally(() => {
          setIsLoading(false);
        });
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
        setIsLoading(false);
      });
  }, [props.emailId]);

  useEffect(() => {
    if (matterEmail) {
      const canManageMails = matterEmail.isCurrentUserSenderOrRecipient || 
        (user.userRole?.name != UserRoleNames.Clerk &&
        user.userRole?.name != UserRoleNames.BackOffice && 
        user.userRole?.name != UserRoleNames.Accountant &&
        user.userRole?.name != UserRoleNames.EntryLevel &&
        !matterEmail.isCurrentUserSenderOrRecipient);

      setSlidingPanelMoreButtons(canManageMails);
    }
  }, [matterEmail, isFlagged, priority]);

  const setSlidingPanelMoreButtons = (canManageMails: boolean) => {
    if (!props.isMatterClosed && props.showMoreButtons && canManageMails) {
      slidingPanelActions.setMoreButtons([
        // View Details
        {
          type: ActionButtonTypes.View,
          callback: () => onClickViewDetails(),
        },
        // Taxonomies
        ...(props.hasTaxonomies ? [{
          type: ActionButtonTypes.EditTaxonomyTerms,
          callback: () => onClickTaxonomies(),
        }] : []),
        // Save Attachments
        {
          type: ActionButtonTypes.SaveAttachments,
          callback: () => onClickSaveAttachments(),
        },
        // Actions
        ...(user.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterActivityHistory) ? [
          {
            type: ActionButtonTypes.Download,
            callback: () => downloadItem(props.matterId, props.activityHistoryId!)
          },
          {
            type: ActionButtonTypes.Print,
            callback: () => printItem(props.matterId, props.activityHistoryId!)
          },
          {
            type: ActionButtonTypes.Copy,
            callback: () => copyItem(props.matterId, props.activityHistoryId!, props.syncMatterFiles)
          },
          {
            type: ActionButtonTypes.Move,
            callback: () => moveItem(props.matterId, props.activityHistoryId!, props.syncMatterFiles)
          },
          {
            type: ActionButtonTypes.Share,
            callback: () => shareItem(props.matterId, props.activityHistoryId!)
          }
        ] : []),
        // Flag
        ...(props.activityHistoryId && isFlagged !== undefined ? [
          isFlagged ? {
            type: ActionButtonTypes.Unflag,
            callback: () => unflagModal(props.matterId, props.activityHistoryId!)
          } : {
            type: ActionButtonTypes.Flag,
            callback: () => flagModal(props.matterId, props.activityHistoryId!)
          },
        ] : []),
        // Priority
        ...(props.activityHistoryId && priority !== undefined ? [
          ...(priority !== Priority.High ? [{
            type: ActionButtonTypes.IncreasePriority,
            callback: () => increasePriorityModal(props.matterId, props.activityHistoryId!)
          }] : []),
          ...(priority !== Priority.Low ? [{
            type: ActionButtonTypes.DecreasePriority,
            callback: () => decreasePriorityModal(props.matterId, props.activityHistoryId!)
          }] : []),
        ] : []),
        // Delete
        {
          type: ActionButtonTypes.Delete,
          callback: () => onClickDelete(),
        }
      ]);
    }
  }

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
      children: cloneElement(currentSlidingPanelState.children! as ReactElement, {priority: priority, isFlagged: isFlagged}),
    });
  }

  const flagModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Flag confirmation",
      body: "Are you sure you want to add a flag?",
      actionText: "Flag",
      onAction: () => flagCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const flagCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    flagMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setIsFlagged(true); // Local state flag update - used to identify which button to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const unflagModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Unflag confirmation",
      body: "Are you sure you want to remove this flag?",
      actionText: "Unflag",
      onAction: () => unflagCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const unflagCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    unflagMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setIsFlagged(false); // Local state flag update - used to identify which button to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const increasePriorityModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Increase priority confirmation",
      body: "Are you sure you want to increase the priority?",
      actionText: "Increase",
      onAction: () => increasePriorityCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const increasePriorityCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    increasePriorityMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setPriority(getIncreasedPriority(priority!)); // Local state priority update - used to identify which buttons to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const decreasePriorityModal = (matterId: string, activityId: string) => {
    let modal: ModalState = {
      title: "Decrease priority confirmation",
      body: "Are you sure you want to decrease the priority?",
      actionText: "Decrease",
      onAction: () => decreasePriorityCallback(matterId, activityId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus(); 
  }

  const decreasePriorityCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    decreasePriorityMatterActivityHistory(matterId, activityId).then(() => {
      pageActions.triggerReloadPage(true, false);
      setPriority(getDecreasedPriority(priority!)); // Local state priority update - used to identify which buttons to show
      modalActions.toggleModalShownStatus(); 
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
    return;
  }

  const onClickViewDetails = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        allowNavigation: true,
        title: "View Matter Email Details",
        children: <ViewMatterEmail
          matterId={props.matterId}
          emailId={props.emailId!}
          activityHistoryId={props.activityHistoryId}
          isMatterClosed={props.isMatterClosed}
          hasTaxonomies={props.hasTaxonomies}
          syncMatterFiles={props.syncMatterFiles}
          isFlagged={isFlagged}
          priority={priority}
          showMoreButtons
        />,
      }
    );
  };

  const onClickTaxonomies = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Edit Taxonomy Terms",
        children: <EditActivityHistoryTaxonomyTermsForm
          matterId={props.matterId}
          activityHistoryId={props.activityHistoryId}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  };

  const onClickSaveAttachments = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Save Attachments",
        children: <SaveAttachments
          matterId={props.matterId}
          emailId={props.emailId}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  };

  const onClickDelete = () => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Recordable Event?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.matterId, props.emailId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const deleteCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    deleteMatterEmail(matterId, activityId).then(() => {
      //reload the whole grid, because we might have RecordableEvents that changed by deleting the email
      pageActions.triggerReloadPage();

      modalActions.toggleModalShownStatus();
      slidingPanelActions.clearSlidingPanel();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  function createDraftReplyEmailHandler() {
    setIsLoading(true);
    createReplyDraftEmail(props.matterId, props.emailId, user.msalAccessToken!)
      .then((response) => {
        openUrlInNewtab(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function createDraftReplyAllEmailHandler() {
    setIsLoading(true);
    createReplyAllDraftEmail(props.matterId, props.emailId, user.msalAccessToken!)
      .then((response) => {
        openUrlInNewtab(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function createDraftForwardEmailHandler() {
    setIsLoading(true);
    createForwardDraftEmail(props.matterId, props.emailId, user.msalAccessToken!)
      .then((response) => {
        openUrlInNewtab(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const downloadItem = (matterId: string, activityHistoryId: string) => {
    setIsLoading(true);

    downloadActivityHistory(matterId, [activityHistoryId]).then((response) => {
      downloadFile(response);
    })
    .catch((error) => {
      error.response.data.text().then((text: any) => {
        let responseData = undefined;
        try {
          responseData = JSON.parse(text);
        }
        catch(e) {
          responseData = undefined;
        }

        notificationActions.addNotificationMessage({
          type: NotificationTypes.Error,
          title: "Download Error",
          body: `${responseData?.Message ?? "There was an error while downloading the file."}`,
          isDismissable: true
        });
      });
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const printItem = (matterId: string, activityHistoryId: string) => {
    setIsLoading(true);

    printActivityHistory(matterId, [activityHistoryId]).then((response) => {
      printPdf(response.data);
    })
    .catch((error) => {
      //the response is Blob here, we need to parse it
      error.response.data.text().then((text: any) => {
        let responseData = undefined;
        try {
          responseData = JSON.parse(text);
        }
        catch(e) {
          responseData = undefined;
        }

        notificationActions.addNotificationMessage({
          type: NotificationTypes.Error,
          title: "Print Error",
          body: `${responseData?.Message ?? "There was an error printing the file."}`,
          isDismissable: true
        });
      });
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const copyItem = (matterId: string, activityHistoryId: string, syncMatterFiles?: Function) => {
    setIsLoading(true);

    validateCopyActivityHistory(matterId, [activityHistoryId]).then((response) => {
      slidingPanelActions.setSlidingPanel({
        isShown: true,
        title: "Copy Activity History Items",
        children: <CopyActivityHistoryForm
          matterId={matterId}
          activityHistoryIds={[activityHistoryId]}
          syncMatterFiles={syncMatterFiles}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      });
    })
    .catch((error) => {
      notificationActions.addNotificationMessage({
        type: NotificationTypes.Error,
        title: "Copy Validation Error",
        body: error.response?.data?.Message ?? error.message,
        isDismissable: true
      });
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const moveItem = (matterId: string, activityHistoryId: string, syncMatterFiles?: Function) => {
    setIsLoading(true);
  
    validateMoveActivityHistory(matterId, [activityHistoryId]).then((response) => {
      slidingPanelActions.setSlidingPanel({
        isShown: true,
        title: "Move Activity History Items",
        children: <MoveActivityHistoryForm
          matterId={matterId}
          activityHistoryIds={[activityHistoryId]}
          displayClient={false}
          syncMatterFiles={syncMatterFiles}
        />,
        onCancel: onChildPanelClose
      });
    })
    .catch((error) => {
      notificationActions.addNotificationMessage({
        type: NotificationTypes.Error,
        title: "Move Validation Error",
        body: error.response?.data?.Message ?? error.message,
        isDismissable: true
      });
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const shareItem = (matterId: string, activityHistoryId: string) => {
    setIsLoading(true);
  
    validateShareActivityHistory(matterId, [activityHistoryId]).then((response) => {
      slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          title: "Share Files",
          children: <ShareActivityHistoryForm
            matterId={matterId}
            activityHistoryIds={[activityHistoryId]}
            onSubmitCallback={onChildPanelClose}
          />,
          onCancel: onChildPanelClose
        }
      );
    })
    .catch((error) => {
      notificationActions.addNotificationMessage({
        type: NotificationTypes.Error,
        title: "Share Files Validation Error",
        body: error.response?.data?.Message ?? error.message,
        isDismissable: true
      });
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  return(
    <div className="lp-iframe-panel">
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      {matterEmailPreviewUrl &&
        <>
          <Row>
            <Col className="lp-email-action-btns">
              <Button onClick={createDraftReplyEmailHandler} variant="secondary-400">
                <MdReply />
                <span>Reply</span>
              </Button>
              <Button onClick={createDraftReplyAllEmailHandler} variant="secondary-400">
                <MdReplyAll />
                <span>Reply all</span>
              </Button>
              <Button onClick={createDraftForwardEmailHandler} variant="secondary-400 reverse-icon">
                <MdReply />
                <span>Forward</span>
              </Button>
            </Col>
          </Row>

          <Row className="full-height">
            <Col>
              <iframe src={matterEmailPreviewUrl} title="Matter Email Preview" height="100%" width="100%"></iframe> 
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="d-flex justify-content-between">
                <Button variant="primary" onClick={() => openUrlInNewtab(matterEmailPreviewUrl)}>
                  <BiLinkExternal />
                  Open in new tab
                </Button>
              </div>
            </Col>
          </Row>
        </>
      }
    </div>
  );
}

export default PreviewMatterEmail;
