import { Controller, useForm } from "react-hook-form";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import { validationSuite } from "./validationSuite";
import FormSubmitButton from "components/Buttons/FormSubmitButton";
import { DashboardCards } from "enums/DashboardCards";

type Props = {
  currentState: DashboardCards[],
  allAvailableItems: DashboardCards[],
  applyChanges: (items: string[]) => void,
};

function DashboardHideItems(props: Props) {
  const slidingPanelActions = useSlidingPanelActions();

  const {reset, control, register, handleSubmit, formState: {errors}} = useForm<Record<DashboardCards, boolean>>({
    resolver: vestResolver(validationSuite),
    defaultValues: props.currentState.reduce((acc, item) => {
      acc[item] = true;
      return acc;
    }, {} as Record<DashboardCards, boolean>)
  });

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: Record<DashboardCards, boolean>) {
    const items = [];
    for(const [key, value] of Object.entries(data)) {
      if(value) {
        items.push(key);
      }
    }
    props.applyChanges(items);

    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        {props.allAvailableItems.map((item, index) => (
          <Row key={index}>
            <Form.Group as={Col} sm={6} className="d-flex gap-2" controlId={item}>
              <Controller
                control={control}
                name={item}
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Form.Check type="switch" id={item}>
                    <Form.Check.Input
                      className="form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => {
                        onChange(ev.target.checked);
                      }}
                    />
                  </Form.Check>
                )}
              />
              <Form.Label>{item.charAt(0).toUpperCase() + item.slice(1)}</Form.Label>
            </Form.Group>
          </Row>
        ))}

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <FormSubmitButton text="Apply" errors={{...errors}} />
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}

export default DashboardHideItems;
