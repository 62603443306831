import { useCurrentEditor } from '@tiptap/react';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { MdLink } from 'react-icons/md';
import './style.scss';

type Props = {
  applyLink: Function,
  unsetLinkMark: Function,
};

const Link = (props: Props) => {
  const { editor } = useCurrentEditor();
  const [displayAs, setDisplayAs] = useState<string>('');
  const [link, setLink] = useState<string>('');
  const [linkPopupShown, setLinkPopupShown] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const toggleLinkPopup = (e: any) => {
    e.preventDefault();
    setLinkPopupShown(!linkPopupShown);
  };

  const onApplyLink = (e: any) => {
    e.preventDefault();

    // Validate values, must make direct validations as this cannot be a form - form inside a form in not allowed
    if(!displayAs || !link) {
      setErrorMessage('Please fill in all fields');
      return;
    }

    // Regex: https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
    const validUrlRegex = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi);
    if(!link.match(validUrlRegex)) {
      setErrorMessage('Link is not valid');
      return;
    }

    props.applyLink(displayAs, link);
    resetValues();
  };

  const onUnsetLinkMark = (e: any) => {
    e.preventDefault();
    props.unsetLinkMark();
  }

  const onCancelClick = (e: any) => {
    e.preventDefault();
    resetValues();
  }

  const resetValues = () => {
    setDisplayAs('');
    setLink('');
    setErrorMessage('');
    setLinkPopupShown(false);
  }

  if (!editor) {
    return null;
  }

  return (
    <div className="tiptap-link">
      {editor.isActive('link') ? (
        <button onClick={onUnsetLinkMark} className='menu-button is-active'>
          <MdLink />
        </button>
      ) : (
        <button onClick={toggleLinkPopup} className='menu-button'>
          <MdLink />
        </button>
      )}

      {linkPopupShown && (
        <div className="link-popup">
          <div className="link-popup-title">Insert link</div>
          <Form.Group>
            <Form.Control size='sm' type="text" placeholder="Display link as..." value={displayAs} onChange={(e: any) => setDisplayAs(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Control size='sm' type="text" placeholder="Enter link..." value={link} onChange={(e: any) => setLink(e.target.value)} />
          </Form.Group>
          <Form.Group className="d-flex justify-content-between">
            <Button size='sm' variant="success" onClick={onApplyLink}>
              Link
            </Button>
            <Button size='sm' variant="secondary-400" onClick={onCancelClick}>
              Cancel
            </Button>
          </Form.Group>
          {errorMessage && <div className="lp-errors my-1">{errorMessage}</div>}
        </div>
      )}
    </div>
  )
}

export default Link;