import { useDispatch } from "react-redux";
import {
  setReloadGrid,
  setReloadSummary,
  setReloadBadge,
  resetPage,
  resetPageReloadBadge,
} from "../state/pageSlice";
import store from "state/store";
import { abortPendingRequests } from "utils/request";
import { BadgeIds } from "enums/BadgeIds";

const usePageActions = () => {
  const dispatch = useDispatch();

  const setReloadGridData = (reloadFunction: Function) => {
    dispatch(setReloadGrid(reloadFunction));
  }

  const setReloadSummaryData = (reloadFunction: Function) => {
    dispatch(setReloadSummary(reloadFunction));
  }

  const setReloadBadgeData = (reloadData: Partial<Record<BadgeIds, Function>>) => {
    dispatch(setReloadBadge(reloadData));
  }

  const resetPageData = () => {
    dispatch(resetPage());
  }
  const resetPageReloadBadgeData = () => {
    dispatch(resetPageReloadBadge());
  }

  const abortRequests = () => {
    abortPendingRequests();
  }

  const triggerReloadPage = (reloadGrid: boolean = true, reloadSummary: boolean = true) => {
    const pageState = store.getState().page;
    if(reloadGrid && pageState.reloadGrid){
      pageState.reloadGrid();
    }
    if(reloadSummary && pageState.reloadSummary){
      pageState.reloadSummary();
    }
  }

  const triggerReloadBadge = (badgeId: BadgeIds) => {
    const pageState = store.getState().page;
    if(pageState.reloadBadge && pageState.reloadBadge[badgeId]){
      pageState.reloadBadge[badgeId]!();
    }
  }

  return {
    setReloadGridData,
    setReloadSummaryData,
    setReloadBadgeData,
    resetPageData,
    resetPageReloadBadgeData,
    abortRequests,
    triggerReloadPage,
    triggerReloadBadge,
  };
}

export default usePageActions;
