import { Button } from "react-bootstrap";
import { GridOptionTypes } from "enums/GridOptionTypes";
import { GridOptionButtonDataModel } from "models/view/GridOptionButtonDataModel";

const PreviewPanelGridOptions = (
  buttonData: GridOptionButtonDataModel
) => {
  switch (buttonData.type) {
    case GridOptionTypes.Download: {
      return <Button
        key={"1"}
        className={buttonData.selectedRows?.length === 0 ? "disabled" : ""}
        onClick={() => buttonData.callback && buttonData.callback(buttonData.selectedRows ?? [])}
      >{buttonData.text ?? "Download"}</Button>;
    }
    case GridOptionTypes.Merge: {
      return <Button
        key={"2"}
        className={buttonData.selectedRows?.length === 0 ? "disabled" : ""}
        onClick={() => buttonData.callback && buttonData.callback(buttonData.selectedRows ?? [])}
      >{buttonData.text ?? "Merge"}</Button>;
    }
    case GridOptionTypes.Print: {
      return <Button
        key={"3"}
        className={buttonData.selectedRows?.length === 0 ? "disabled" : ""}
        onClick={() => buttonData.callback && buttonData.callback(buttonData.selectedRows ?? [])}
      >{buttonData.text ?? "Print"}</Button>;
    }
    case GridOptionTypes.Copy: {
      return <Button
        key={"4"}
        className={buttonData.selectedRows?.length === 0 ? "disabled" : ""}
        onClick={() => buttonData.callback && buttonData.callback(buttonData.selectedRows ?? [])}
      >{buttonData.text ?? "Copy"}</Button>;
    }
    case GridOptionTypes.Move: {
      return <Button
        key={"5"}
        className={buttonData.selectedRows?.length === 0 ? "disabled" : ""}
        onClick={() => buttonData.callback && buttonData.callback(buttonData.selectedRows ?? [])}
      >{buttonData.text ?? "Move"}</Button>;
    }
    case GridOptionTypes.Share: {
      return <Button
        key={"6"}
        className={buttonData.selectedRows?.length === 0 ? "disabled" : ""}
        onClick={() => buttonData.callback && buttonData.callback(buttonData.selectedRows ?? [])}
      >{buttonData.text ?? "Share"}</Button>;
    }
    case GridOptionTypes.SendFiles: {
      return <Button
        key={"7"}
        className={
          buttonData.selectedRows?.length === 0 ||
          buttonData.selectedRows?.every(row => row.activityType != 2)
            ? "disabled"
            : ""
        }
        onClick={() => buttonData.callback && buttonData.callback(buttonData.selectedRows ?? [])}
      >{buttonData.text ?? "Send Files"}</Button>;
    }
    case GridOptionTypes.ConvertToMail: {
      return <Button
        key={"8"}
        className={buttonData.selectedRows?.length === 0 ? "disabled" : ""}
        onClick={() => buttonData.callback && buttonData.callback(buttonData.selectedRows ?? [])}
      >{buttonData.text ?? "Convert to Mail"}</Button>;
    }
    case GridOptionTypes.BulkAddTaxonomyTerms: {
      return <Button
        key={"9"}
        className={buttonData.selectedRows?.length === 0 ? "disabled" : ""}
        onClick={() => buttonData.callback && buttonData.callback(buttonData.selectedRows ?? [])}
      >{buttonData.text ?? "Add Taxonomy Terms"}</Button>;
    }
    case GridOptionTypes.BulkDeleteTaxonomyTerms: {
      return <Button
        key={"10"}
        className={buttonData.selectedRows?.length === 0 ? "disabled" : ""}
        onClick={() => buttonData.callback && buttonData.callback(buttonData.selectedRows ?? [])}
      >{buttonData.text ?? "Delete Taxonomy Terms"}</Button>;
    }
    default:
      return <></>;
  }
}

export default PreviewPanelGridOptions;
