import { create, test, enforce, omitWhen, each } from "vest";
import isDate from "validator/lib/isDate";
import { CreateOrUpdateMatterIncidentalExpenseModel } from "models/create/CreateOrUpdateMatterIncidentalExpenseModel";

export const getValidationSuite = (clientsLength: number) => {
  return create((data = {}) => {
    enforce.extend({ isDate });

    test('userId', 'Please select a Fee Earner', () => {
      enforce(data.userId).isNotEmpty();
      enforce(data.userId).isString();
    });

    omitWhen(clientsLength == 0, () => {
      test('matterParticipatingEntityId', 'Please select a Client', () => {
        enforce(data.matterParticipatingEntityId).isNotEmpty();
        enforce(data.matterParticipatingEntityId).isString();
      });
    });

    each(data.incidentalExpenses, (field: CreateOrUpdateMatterIncidentalExpenseModel, index: number) => {
      test(`incidentalExpenses.${index}.date`, 'Please select a Date', () => {
        enforce(field.date).isDate({ strictMode: false });
      });
    
      test(`incidentalExpenses.${index}.displayName`, 'Display Name is required', () => {
        enforce(field.displayName).isNotEmpty();
        enforce(field.displayName).isString();
      });
    
      test(`incidentalExpenses.${index}.description`, 'Description is required', () => {
        enforce(field.description).isNotEmpty();
        enforce(field.description).isString();
      });
    
      test(`incidentalExpenses.${index}.grossValue`, 'Gross Value is required', () => {
        enforce(field.grossValue).isNotEmpty();
      });
    
      test(`incidentalExpenses.${index}.grossValue`, 'Gross Value needs to be a number > 0', () => {
        enforce(field.grossValue).greaterThan(0);
      });
    
      omitWhen(!field.vatValue, () => {
        test(`incidentalExpenses.${index}.vatValue`, 'VAT Value needs to be a number > 0 or left blank', () => {
          enforce(field.vatValue).greaterThan(0);
        });
      });
    
      test(`incidentalExpenses.${index}.grossValue`, 'Gross Value needs to be higher than VAT Value', () => {
        var netValue = (field.grossValue ?? 0) - (field.vatValue ?? 0);
        enforce(netValue).greaterThan(0);
      });
    });
  });
}