import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { useNavigate, useParams } from "react-router-dom";
import useGridActions from "actions/grid";
import { useAppSelector } from "hooks/appSelector";
import { Row, Col, Form, Button } from "react-bootstrap";
import { editLteUser, getUserPermissions, getUserRoles, getLteUser } from "actions/user";
import { CreateOrUpdateLteUserModel } from "models/create/CreateOrUpdateLteUserModel";
import { getLteBranchesWithDepartmentsAndTeams } from "actions/lte";
import { LteBranchModel } from "models/view/LteBranchModel";
import { LteDepartmentModel } from "models/view/LteDepartmentModel";
import { LteTeamModel } from "models/view/LteTeamModel";
import { LteUserModel } from "models/view/LteUserModel";
import useSlidingPanelActions from "actions/slidingPanel";
import Field from "components/Fields/Field";
import { removeEmptyFields } from "utils/form";
import Loader from "components/Loader/index";
import CustomSelect from "components/Select/Select";
import {getValidationSuite} from "./validationSuite";
import FormSubmitButton from "components/Buttons/FormSubmitButton";

type Props = {
  id: string,
  onSubmitCallback?: Function,
}

export default function EditLteUserForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lteBranches, setLteBranches] = useState<LteBranchModel[]>([]);
  const [isLoadingBranches, setIsLoadingBranches] = useState<boolean>(false);
  const [lteDepartments, setLteDepartments] = useState<LteDepartmentModel[]>([]);
  const [isLoadingDepartments, setIsLoadingDepartments] = useState<boolean>(false);
  const [lteDepartmentsDisabled, setLteDepartmentsDisabled] = useState<boolean>(true);
  const [lteTeams, setLteTeams] = useState<LteTeamModel[]>([]);
  const [isLoadingTeams, setIsLoadingTeams] = useState<boolean>(false);
  const [lteTeamsDisabled, setLteTeamsDisabled] = useState<boolean>(true);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  
  const gridActions = useGridActions();
  const grid = useAppSelector((state) => state.grid);

  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);
  const { id: lteId } = useParams();

  useEffect(() => {
    setIsLoadingBranches(true);
    setIsLoadingDepartments(true);
    setIsLoadingTeams(true);
    getLteUser(props.id!)
      .then((lteUserResponse) => {
        getLteBranchesWithDepartmentsAndTeams(lteUserResponse.data.lawPageTradingEntityId)
          .then((response) => {
            setLteBranches(response.data);
            setIsLoadingBranches(false);

            var lteDepartments = response.data.find((x: any) => x.id === lteUserResponse.data.lteBranch.id).children;
            setLteDepartments(lteDepartments);
            setIsLoadingDepartments(false);

            var lteTeams = lteDepartments.find((x: any) => x.id === lteUserResponse.data.lteDepartment?.id)?.children;
            setLteTeams(lteTeams ?? []);
            setIsLoadingTeams(false);

            let userResponseToUserForm: CreateOrUpdateLteUserModel = {
              firstName: lteUserResponse.data.firstName,
              lastName: lteUserResponse.data.lastName,
              email: lteUserResponse.data.email,
              active: lteUserResponse.data.active,
              isFeeEarner: lteUserResponse.data.isFeeEarner,
              lawPageTradingEntityId: lteUserResponse.data.lawPageTradingEntityId,
              userRoleId: lteUserResponse.data.userRole.id,
              lteBranchId: lteUserResponse.data.lteBranch?.id,
              userPermissions: lteUserResponse.data.userPermissions?.map((x: any) => x.id),
              lteDepartmentId: lteUserResponse.data.lteDepartment?.id,
              lteTeamId: lteUserResponse.data.lteTeam?.id
            }

            if(userResponseToUserForm.lteDepartmentId || lteDepartments !== undefined) {
              setLteDepartmentsDisabled(false);
            }
            if(userResponseToUserForm.lteTeamId || lteTeams !== undefined) {
              setLteTeamsDisabled(false);
            }
            reset(userResponseToUserForm);
          })
          .catch((error) => {
            setGenericErrors(error.response?.data?.Message ?? error.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    )
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
  }, []);

  const {register, reset, control, watch, setValue, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateLteUserModel>({
    resolver: vestResolver(getValidationSuite(lteBranches))
  });
  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateLteUserModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    data.lawPageTradingEntityId = lteId ?? user.lawPageTradingEntityId!;
    editLteUser(props.id, data).then((response) => {
      let index: number = grid.rowData.findIndex((x: any) => x.id === response.data.id);
      let newEntry: LteUserModel = {...response.data};
      let newArray: Array<any> = [...grid.rowData];
      newArray[index] = newEntry;
      gridActions.setGridRowData(newArray);
      slidingPanelActions.clearSlidingPanel();
      reset();

      props.onSubmitCallback && props.onSubmitCallback();
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();

    currentSlidingPanelState.onCancel && currentSlidingPanelState.onCancel();
  }

  const onGetLteBranch = (value: any) => {
    try {
      setValue("lteDepartmentId", undefined);
      setValue("lteTeamId", undefined);
      setIsLoadingDepartments(true);

      let departments: LteDepartmentModel[] = [];
      if(value && value != null){
        const selectedBranch = lteBranches?.find(x => x.id === value);
        departments = selectedBranch?.children ?? [];
        setLteDepartments(departments);
        setLteTeams([]);
        setLteDepartmentsDisabled(false);
      }
      else {
        setLteDepartments([]);
        setLteTeams([]);
        setLteDepartmentsDisabled(true);
        setLteTeamsDisabled(true);
      }

      setIsLoadingDepartments(false);
    } catch (err: any) {
      setGenericErrors(err);
    };
  }

  const onGetLteDepartment = (value: any) => {
    try {
      setValue("lteTeamId", undefined);
      setIsLoadingTeams(true);

      let teams: LteTeamModel[] = [];
      if(value && value != null){
        const selectedDepart = lteDepartments?.find(x => x.id === value);
        teams = selectedDepart?.children ?? [];
        setLteTeams(teams);
        setLteTeamsDisabled(false);
      }
      else {
        setLteTeams([]);
        setLteTeamsDisabled(true);
      }

      setIsLoadingTeams(false);
    } catch (err: any) {
      setGenericErrors(err);
    };
  }
      
  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Col>
            <Field
              label={"First Name"}
              value={watch('firstName')}
            />
          </Col>
          <Col>
            <Field
              label={"Last Name"}
              value={watch('lastName')}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <Field
              label={"Email Address"}
              value={watch('email')}
            />
          </Col>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="userRoleId">
            <Form.Label className="required">User Role</Form.Label>
            <Controller
              control={control}
              name="userRoleId"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <CustomSelect
                  id="userRoleId"
                  inputRef={ref}
                  className={`lp-select${errors?.userRoleId?.message ? ' invalid' : ''}`}
                  endpointCall={() => getUserRoles(lteId ?? user.lawPageTradingEntityId!)}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.userRoleId?.message && (errors.userRoleId.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="userPermissions">
            <Form.Label>Additional Permissions</Form.Label>
            <Controller
              control={control}
              name="userPermissions"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <CustomSelect
                  id="userPermissions"
                  inputRef={ref}
                  endpointCall={() => getUserPermissions(lteId ?? user.lawPageTradingEntityId!)}
                  value={value}
                  isMulti
                  isClearable
                  onChange={val => onChange(val?.map((x: { id: any; }) => x.id) ?? null)}
                />
              )}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="active">
            <Form.Label>Active</Form.Label>
            <Controller
              control={control}
              name="active"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="active">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="isFeeEarner">
            <Form.Label>Fee earner</Form.Label>
            <Controller
              control={control}
              name="isFeeEarner"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="isFeeEarner">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="lteBranchId">
            <Form.Label className="required">LTE Branch</Form.Label>
            <Controller
              control={control}
              name="lteBranchId"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <CustomSelect
                  id="lteBranchId"
                  inputRef={ref}
                  className={`lp-select${errors?.lteBranchId?.message ? ' invalid' : ''}`}
                  options={lteBranches.map(x => ({id: x.id, name: x.name}))}
                  value={value}
                  isLoading={isLoadingBranches}
                  onChange={val => {onChange(val?.id ?? null); onGetLteBranch(val?.id ?? null);}}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.lteBranchId?.message && (errors.lteBranchId.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="lteDepartmentId">
            <Form.Label className={`${lteDepartments.length > 0 ? "required" : ""}`}>LTE Department</Form.Label>
            <Controller
              control={control}
              name="lteDepartmentId"
              shouldUnregister={true}
              defaultValue={undefined}
              render={({field: { onChange, value, name, ref }}) => (
                <CustomSelect
                  id="lteDepartmentId"
                  inputRef={ref}
                  className={`lp-select${errors?.lteDepartmentId?.message ? ' invalid' : ''}`}
                  options={lteDepartments.map(x => ({id: x.id, name: x.name}))}
                  value={value}
                  isLoading={isLoadingDepartments}
                  isDisabled={lteDepartmentsDisabled}
                  onChange={val => {onChange(val?.id ?? null); onGetLteDepartment(val?.id ?? null);}}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.lteDepartmentId?.message && (errors.lteDepartmentId.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="lteTeamId">
            <Form.Label className={`${lteTeams.length > 0 ? "required" : ""}`}>LTE Team</Form.Label>
            <Controller
              control={control}
              name="lteTeamId"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <CustomSelect
                  id="lteTeamId"
                  inputRef={ref}
                  className={`lp-select${errors?.lteTeamId?.message ? ' invalid' : ''}`}
                  options={lteTeams.map(x => ({id: x.id, name: x.name}))}
                  value={value}
                  isLoading={isLoadingTeams}
                  isDisabled={lteTeamsDisabled}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.lteTeamId?.message && (errors.lteTeamId.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <FormSubmitButton text="Update" errors={{...errors}} />
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
